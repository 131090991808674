import React from 'react'
import { useTranslation } from 'react-i18next'

const NoResults = () => {
	const { t } = useTranslation()

	return <div>{t('common:noResults')}</div>
}

export default NoResults
