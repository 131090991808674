import React, { useState } from 'react'
import Button from '../../../components/UI/Button/Button'
import { modalConfirmShowHandler } from '../../../store/actionCreators/UI/modalConfirm'
import { connect, ConnectedProps } from 'react-redux'
import { IProduct, IProductUpdate } from '../../../interfaces/cabinet/products'
import { useForm } from 'react-hook-form'
import Input from '../../../components/UI/Input/Input'
import Textarea from '../../../components/UI/Textarea/Textarea'
import { useTranslation } from 'react-i18next'

type TProps = ConnectedProps<typeof connector> & {
	item: IProduct
	onRemove: (id: string) => Promise<any>
	onConfirm: (data: IProductUpdate) => Promise<any>
	hideModal: () => void
}

interface IFormInputs {
	name: string
	description: string
}

const ModalEditProduct = ({ item, hideModal, showModalConfirm, onRemove, onConfirm }: TProps) => {
	const { t } = useTranslation()
	const { register, errors, handleSubmit, formState, watch } = useForm<IFormInputs>({
		defaultValues: {
			name: item.name,
			description: item.description
		}
	})
	const { isDirty } = formState
	const [isSubmitting, setIsSubmitting] = useState(false)

	const cancelHandler = () => {
		if (isDirty) {
			showModalConfirm({
				type: 'cancel',
				onConfirm: hideModal
			})
		} else {
			hideModal()
		}
	}

	const removeHandler = () => {
		showModalConfirm({
			type: 'remove',
			elementText: item.name,
			onConfirm: async () => {
				try {
					return await onRemove(item.id)
				} finally {
					hideModal()
				}
			}
		})
	}

	const onSubmit = async (data: IFormInputs) => {
		setIsSubmitting(true)

		try {
			const response = await onConfirm(data)

			if (response?.status === 200) {
				hideModal()
			}
		} finally {
			setIsSubmitting(false)
		}
	}

	return (
		<>
			<button className="modal-close" onClick={cancelHandler} disabled={isSubmitting} />

			<h3 className="modal-title">{t('common:appName')}</h3>

			<p className="modal-footnote">{t('pages:products:modalUpdateText')}</p>

			<form className="form" onSubmit={handleSubmit(onSubmit)}>
				<div className="form-item">
					<Input
						currentValue={watch('name')}
						type={'text'}
						name={'name'}
						placeholder={t('pages:products:fieldName')}
						isDisabled={isSubmitting}
						isError={!!errors.name}
						isRequired={true}
						reference={register({
							required: true,
							maxLength: 255
						})}
						errors={errors.name}
						rules={{
							maxLength: 255
						}}
					/>
				</div>

				<div className="form-item">
					<Textarea
						currentValue={watch('description')}
						name={'description'}
						placeholder={t('pages:products:fieldDescription')}
						isDisabled={isSubmitting}
						isError={!!errors.description}
						reference={register({
							maxLength: 5000
						})}
						errors={errors.description}
						rules={{
							maxLength: 5000
						}}
					/>
				</div>

				<div className="form-controls form-controls--justify">
					<Button type={'secondary'} htmlType={'button'} onClick={removeHandler} isDisabled={isSubmitting}>
						{t('common:actionRemove')}
					</Button>

					<Button type={'secondary'} htmlType={'button'} onClick={cancelHandler} isDisabled={isSubmitting}>
						{t('common:actionCancel')}
					</Button>

					<Button type={'primary'} htmlType={'submit'} isDisabled={isSubmitting || !isDirty}>
						{t('common:actionSave')}
					</Button>
				</div>
			</form>
		</>
	)
}

const mapDispatch = {
	showModalConfirm: modalConfirmShowHandler
}

const connector = connect(null, mapDispatch)

export default connector(ModalEditProduct)
