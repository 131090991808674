import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { notificationRemoveHandler } from '../../../store/actionCreators/UI/notification'
import { INotification } from '../../../interfaces/UI/notification'
import { useTranslation } from 'react-i18next'

type TProps = ConnectedProps<typeof connector> & {
	notifications: INotification[]
}

function Notification({ notifications, remove }: TProps) {
	const { t } = useTranslation()

	return (
		<div className="notification" style={{ zIndex: 2000 }}>
			{notifications.map(({ id, message, type }) => {
				return (
					<div key={id} className={`notification-item notification-item--show notification-item--${type}`}>
						<button className="notification-close" onClick={() => remove(id)}>
							{t('common:actionClose')}
						</button>

						<div className="notification-content">
							<div className="notification-title">
								<strong>{t(`common:Notification_${type}`)}</strong>
							</div>
							<div className="notification-text" dangerouslySetInnerHTML={{ __html: message }} />
						</div>
					</div>
				)
			})}
		</div>
	)
}

const mapDispatch = {
	remove: notificationRemoveHandler
}

const connector = connect(null, mapDispatch)

export default connector(Notification)
