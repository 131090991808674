import { AppThunk } from '../../interfaces/helpers'
import { IMarketingForm, IMarketingFormResponse } from '../../interfaces/passport/marketing'
import { API_PASSPORT_PATH, APIServices } from '../../helpers/api'
import { serviceHandlerErrorOptions } from '../helpers'
import { setErrorHandler } from '../../store/actionCreators/error'
import axios from 'axios'

export function sendMarketingForm(
	data: IMarketingForm,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IMarketingFormResponse> {
	return async dispatch => {
		try {
			return await axios.request<IMarketingFormResponse>({
				method: 'POST',
				url: `${API_PASSPORT_PATH}/marketing/public/request`,
				data
			})
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response
		}
	}
}
