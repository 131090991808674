import { IContact } from '../../interfaces/passport/organizations'
import React from 'react'

interface IProps {
	contact: IContact
}

const Contact = ({ contact }: IProps) => {
	const { name, jobName, phone, email } = contact

	return (
		<>
			{name && <div>{name},</div>}
			{jobName && <>{jobName}, </>}
			{phone && <>{phone}, </>}
			{email && (
				<div>
					<a href={`mailto:${email}`}>{email}</a>
				</div>
			)}
		</>
	)
}

export default Contact
