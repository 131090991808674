import React, { useState } from 'react'
import Pagetitle from '../../../components/UI/Pagetitle/Pagetitle'
import { connect, ConnectedProps } from 'react-redux'
import Input from '../../../components/UI/Input/Input'
import { useForm } from 'react-hook-form'
import { setUserDataHandler } from '../../../services/passport/user'
import {
    getFacultyByIdHandler,
    getFacultyGroupsByIdHandler,
    getGradesByIdHandler
} from '../../../services/passport/organization'
import { modalShowHandler } from '../../../store/actionCreators/UI/modal'
import ModalChangePassword from './ModalChangePassword'
import ModalChangeEmail from './ModalChangeEmail'
import { notificationAddHandler } from '../../../store/actionCreators/UI/notification'
import Button from '../../../components/UI/Button/Button'
import { getUserRoleName, USER } from '../../../helpers/roles'
import { toggleLoaderHandler } from '../../../store/actionCreators/UI/loader'
import { RootState } from '../../../store/reducers/rootReducer'
import { IUserAccountUpdate, IUserSelfUpdate, IUserUpdate } from '../../../interfaces/passport/user'
import { useTranslation } from 'react-i18next'
import SelectLanguage from '../../../components/UI/Select/SelectLanguage'
import AccountForm, { IAccountFormResult } from './AccountForm'

interface IFormInputs {
    lastName: string
    firstName: string
    patronymic: string
}

const Profile = ({
    user,
    setUserData,
    toggleLoader,
    showModal,
    notificationAdd
}: ConnectedProps<typeof connector>) => {
    const { t } = useTranslation()
    const [updateAccountsMap, setUpdateAccountsMap] = useState<{ [id: string]: IUserAccountUpdate }>({})
    const [isAccountsValid, setIsAccountsValid] = useState<{ [id: string]: boolean }>({})
    const { register, watch, handleSubmit, errors, formState, reset, getValues } = useForm<IFormInputs>({
        defaultValues: {
            lastName: user.lastName,
            firstName: user.firstName,
            patronymic: user.patronymic,
        }
    })
    const { isDirty } = formState
    const [isSubmitting, setIsSubmitting] = useState(false)

    const onSubmit = async (data: IFormInputs) => {
        const validIds = Object.keys(isAccountsValid);
        if (validIds.length > 0 && !validIds.reduce((isValid, id) => isValid && isAccountsValid[id], true)) {
            return;
        }
        toggleLoader(true)
        setIsSubmitting(true)

        try {
            const updatedData: IUserSelfUpdate = {
                role: user.role,
                email: user.email,
                ...data,
                accounts: Object.keys(updateAccountsMap).map(id => updateAccountsMap[id])
            }

            const response = await setUserData(updatedData)

            if (response?.status === 200) {
                setUpdateAccountsMap({})
                reset(getValues(), { isDirty: false })
                notificationAdd(t('pages:profile:notificationProfileSave'), 'info')
            }
        } finally {
            setIsSubmitting(false)
            toggleLoader(false)
        }
    }

    const changePasswordHandler = () => {
        showModal({
            type: 'large',
            Component: ModalChangePassword,
            ComponentProps: {
                user,
                onConfirm: async (data: IUserSelfUpdate) => {
                    toggleLoader(true)

                    try {
                        return await setUserData(data, {
                            show: false
                        })
                    } finally {
                        toggleLoader(false)
                    }
                }
            }
        })
    }

    const changeEmailHandler = () => {
        showModal({
            type: 'middle',
            Component: ModalChangeEmail,
            ComponentProps: {
                user,
                onConfirm: async (data: IUserUpdate) => {
                    toggleLoader(true)

                    try {
                        return await setUserData(data)
                    } finally {
                        toggleLoader(false)
                    }
                }
            }
        })
    }

    const updateAccount = (value: IAccountFormResult, isValid: boolean) => {
        const updatedValid = { ...isAccountsValid }
        updatedValid[value.accountId] = isValid;
        setIsAccountsValid(updatedValid)
        if (isValid) {
            const updatedMap = { ...updateAccountsMap }
            updatedMap[value.accountId] = value;
            setUpdateAccountsMap(updatedMap)
        }
    }

    return (
        <>
            <Pagetitle title={t('pages:profile:title')} />

            <div className="profile-settings-info">
                <p>
                    {t('pages:profile:userName')} <strong>{user.email}</strong>
                </p>
                {user.role !== USER && (<p>
                    {t('pages:profile:userRole')} <strong>{t(getUserRoleName(user))}</strong>
                </p>)}

                <ul className="list list--links">
                    <li className="list-item">
                        <button className="button-link" type="button" onClick={changePasswordHandler}>
                            {t('pages:profile:actionChangePassword')}
                        </button>
                    </li>
                    <li className="list-item">
                        <button className="button-link" type="button" onClick={changeEmailHandler}>
                            {t('pages:profile:actionChangeEmail')}
                        </button>
                    </li>
                </ul>
            </div>

            <div className="profile-settings-edit-form">
                <form className="form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-title">{t('pages:profile:userInfo')}</div>

                    <div className="form-row">
                        <div className="form-col form-col-33">
                            <div className="form-item">
                                <Input
                                    currentValue={watch('lastName')}
                                    type={'text'}
                                    name={'lastName'}
                                    placeholder={t('pages:profile:fieldLastName')}
                                    isDisabled={isSubmitting}
                                    isError={!!errors.lastName}
                                    reference={register({
                                        required: true
                                    })}
                                    errors={errors.lastName}
                                />
                            </div>
                        </div>

                        <div className="form-col form-col-33">
                            <div className="form-item">
                                <Input
                                    currentValue={watch('firstName')}
                                    type={'text'}
                                    name={'firstName'}
                                    placeholder={t('pages:profile:fieldFirstName')}
                                    isDisabled={isSubmitting}
                                    isError={!!errors.firstName}
                                    reference={register({
                                        required: true
                                    })}
                                    errors={errors.firstName}
                                />
                            </div>
                        </div>

                        <div className="form-col form-col-33">
                            <div className="form-item">
                                <Input
                                    currentValue={watch('patronymic')}
                                    type={'text'}
                                    name={'patronymic'}
                                    placeholder={t('pages:profile:fieldPatronymic')}
                                    isDisabled={isSubmitting}
                                    isError={!!errors.patronymic}
                                    reference={register({
                                        required: true
                                    })}
                                    errors={errors.patronymic}
                                />
                            </div>
                        </div>
                    </div>

                    {(!!user.accounts && user.accounts.filter(a => !!a.organization).length > 0) && user.accounts.filter(a => !!a.organization).map(account => (<AccountForm key={account.accountId} account={account} isSubmitting={isSubmitting} onChange={updateAccount} />))}

                    <div className="form-title">{t('pages:profile:userSettings')}</div>

                    <div className="form-row">
                        <div className="form-col">
                            <div className="form-item">
                                <SelectLanguage />
                            </div>
                        </div>
                    </div>

                    <div className="form-controls form-controls--margin">
                        <Button type={'primary'} htmlType={'submit'} isDisabled={isSubmitting || (!isDirty && Object.keys(updateAccountsMap).length === 0)}>
                            {t('pages:profile:actionSave')}
                        </Button>
                    </div>
                </form>
            </div>
        </>
    )
}

const mapState = (state: RootState) => ({
    user: state.user.user
})

const mapDispatch = {
    setUserData: setUserDataHandler,
    getFaculties: getFacultyByIdHandler,
    getGrades: getGradesByIdHandler,
    getGroups: getFacultyGroupsByIdHandler,
    toggleLoader: toggleLoaderHandler,
    showModal: modalShowHandler,
    notificationAdd: notificationAddHandler
}

const connector = connect(mapState, mapDispatch)

export default connector(Profile)
