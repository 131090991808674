import React, { useEffect, useState } from 'react'
import Checkbox from '../../../components/UI/Checkbox/Checkbox'
import Button from '../../../components/UI/Button/Button'
import { useForm } from 'react-hook-form'
import { INotificationCreate, INotificationRecipientShort } from '../../../interfaces/passport/notifications'
import Textarea from '../../../components/UI/Textarea/Textarea'
import Input from '../../../components/UI/Input/Input'
import { IOrganization } from '../../../interfaces/passport/organizations'
import Multiselect from './components/Multiselect'
import { useTranslation } from 'react-i18next'
import { modalConfirmShowHandler } from '../../../store/actionCreators/UI/modalConfirm'
import { connect, ConnectedProps } from 'react-redux'

export interface IOrganizationCustom extends IOrganization {
	selected: boolean
}

type TProps = ConnectedProps<typeof connector> & {
	organizations: Array<IOrganizationCustom>
	hideModal: () => void
	onConfirm: (data: INotificationCreate) => Promise<any>
}

interface IFormInputs {
	topic: string
	message: string
	toAdmin: boolean
	toClient: boolean
	recipients: Array<INotificationRecipientShort>
}

interface IRecipient {
	id: string
	email: string
	name: string
	orgId: string
	selected: boolean
	disabled: boolean
}

interface IGrouped {
	[key: string]: INotificationRecipientShort
}

const ModalAddNotification = ({ organizations, hideModal, onConfirm, showModalConfirm }: TProps) => {
	const { t } = useTranslation()

	const [toAdmin, setToAdmin] = useState(false)
	const [toClient, setToClient] = useState(false)

	const { register, errors, formState, handleSubmit, watch, setValue, reset } = useForm<IFormInputs>({
		defaultValues: {
			topic: '',
			message: '',
			toAdmin: toAdmin,
			toClient: toClient,
			recipients: []
		}
	})
	const { isDirty } = formState
	const [isSubmitting, setIsSubmitting] = useState(false)

	const [organizationsList, setOrganizationsList] = useState<IOrganizationCustom[]>([])
	const [organizationsSelectedList, setOrganizationsSelectedList] = useState<IOrganizationCustom[]>([])
	const [organizationsIds, setOrganizationsIds] = useState<string[]>([])
	const [recipientsList, setRecipientsList] = useState<IRecipient[]>([])
	const [recipientsListFiltered, setRecipientsListFiltered] = useState<IRecipient[]>([])
	const [recipients, setRecipients] = useState<INotificationRecipientShort[]>([])

	const [isAddDisabled, setIsAddDisabled] = useState(true)
	const [isRemoveDisabled, setIsRemoveDisabled] = useState(true)

	const organizationsListHandler = (item: IOrganizationCustom, selected: boolean) => {
		item.selected = selected
		const index = organizationsList.findIndex(el => el.id === item.id)
		const updatedList = [...organizationsList]
		updatedList[index] = item

		setOrganizationsList(updatedList)
		setIsAddDisabled(updatedList.filter(el => el.selected).length === 0)
	}

	const organizationsSelectedListHandler = (item: IOrganizationCustom, selected: boolean) => {
		item.selected = selected
		const index = organizationsSelectedList.findIndex(el => el.id === item.id)
		const updatedList = [...organizationsSelectedList]

		updatedList[index] = item

		setOrganizationsSelectedList(updatedList)
		setIsRemoveDisabled(updatedList.filter(el => el.selected).length === 0)
	}

	const addAllHandler = () => {
		setOrganizationsSelectedList([
			...organizationsSelectedList,
			...organizationsList.map(item => {
				return {
					...item,
					selected: false
				}
			})
		])

		setOrganizationsList([])
		setIsAddDisabled(true)
	}

	const removeAllHandler = () => {
		setOrganizationsList([
			...organizationsList,
			...organizationsSelectedList.map(item => {
				return {
					...item,
					selected: false
				}
			})
		])

		setOrganizationsSelectedList([])
		setIsRemoveDisabled(true)
	}

	const addHandler = () => {
		const list = organizationsList.filter(el => el.selected)
		const updatedList = organizationsList.filter(el => !el.selected)

		setOrganizationsSelectedList([
			...organizationsSelectedList,
			...list.map(item => {
				return {
					...item,
					selected: false
				}
			})
		])
		setOrganizationsList(updatedList)
		setIsAddDisabled(true)
	}

	const removeHandler = () => {
		const list = organizationsSelectedList.filter(el => el.selected)
		const updatedList = organizationsSelectedList.filter(el => !el.selected)

		setOrganizationsList([
			...organizationsList,
			...list.map(item => {
				return {
					...item,
					selected: false
				}
			})
		])
		setOrganizationsSelectedList(updatedList)
		setIsRemoveDisabled(true)
	}

	const recipientHandler = (id: string) => {
		const list = [...recipientsList]
		const recipientIndex = list.findIndex(item => item.id === id)

		list[recipientIndex].selected = !list[recipientIndex].selected

		setRecipientsList(list)
	}

	const onSubmit = async (data: IFormInputs) => {
		setIsSubmitting(true)

		const result: INotificationCreate = {
			...data,
			recipients
		}

		const totalRecipients = recipientsListFiltered.filter(item => item.selected && !item.disabled).length
		let questionText = ''

		if (toClient && !toAdmin) {
			questionText = t('pages:notifications:modalConfirmSendTextClient', {
				value: totalRecipients
			})
		}

		if (!toClient && toAdmin) {
			questionText = t('pages:notifications:modalConfirmSendTextAdmin')
		}

		if (toClient && toAdmin) {
			questionText = t('pages:notifications:modalConfirmSendTextClientAndAdmin', {
				value: totalRecipients
			})
		}

		showModalConfirm({
			type: 'custom',
			questionText,
			onCancel: () => {
				setIsSubmitting(false)
			},
			onConfirm: async () => {
				try {
					const response = await onConfirm(result)

					if (response?.status === 200) {
						reset()
						hideModal()
					}
				} finally {
					setIsSubmitting(false)
				}
			}
		})
	}

	useEffect(() => {
		const list = organizations.map(item => {
			return {
				...item,
				selected: false
			}
		})

		const listClient: Array<IRecipient> = []

		organizations.forEach(org => {
			listClient.push({
				id: org.mainContact.id,
				email: org.mainContact.email,
				name: org.mainContact.name,
				orgId: org.id,
				selected: true,
				disabled: true
			})

			if (org.contacts) {
				org.contacts.forEach(contact => {
					listClient.push({
						id: contact.id,
						email: contact.email,
						name: contact.name,
						orgId: org.id,
						selected: true,
						disabled: true
					})
				})
			}
		})

		setOrganizationsList(list)
		setRecipientsList(listClient)
	}, [organizations])

	useEffect(() => {
		setOrganizationsIds(organizationsSelectedList.map(item => item.id))
	}, [organizationsSelectedList])

	useEffect(() => {
		if (!organizationsSelectedList.length) {
			setToAdmin(false)
			setToClient(false)
			setValue('toAdmin', false)
			setValue('toClient', false)
		}
	}, [organizationsSelectedList, setValue])

	useEffect(() => {
		const list = recipientsList.filter(item => organizationsIds.includes(item.orgId))

		setRecipientsListFiltered(
			list.map(item => {
				return {
					...item,
					disabled: !toClient
				}
			})
		)
	}, [organizationsIds, toClient, recipientsList])

	useEffect(() => {
		const grouped: IGrouped = {}

		recipientsListFiltered.forEach(item => {
			if (!(item.orgId in grouped)) {
				grouped[item.orgId] = {
					orgId: item.orgId,
					orgContactIds: toClient && item.selected && !item.disabled ? [item.id] : []
				}
			} else {
				if (toClient && item.selected && !item.disabled) {
					grouped[item.orgId].orgContactIds = [...grouped[item.orgId].orgContactIds, item.id]
				}
			}
		})

		setRecipients(Object.values(grouped))
	}, [recipientsListFiltered, toClient])

	return (
		<>
			<button className="modal-close" onClick={hideModal} disabled={isSubmitting} />

			<h3 className="modal-title">{t('common:appName')}</h3>
			<p className="modal-footnote">{t('pages:notifications:modalNewText')}</p>

			<form className="form form--update" onSubmit={handleSubmit(onSubmit)}>
				<div className="form-row">
					<div className="form-col form-col-39">
						<Multiselect
							name={'list'}
							list={organizationsList}
							title={t('pages:notifications:modalListOrganizations')}
							onChange={organizationsListHandler}
						/>
					</div>

					<div className="form-col form-col-22">
						<div className="multiselect-controls">
							<div className="multiselect-controls-item">
								<Button
									type={'secondary'}
									htmlType={'button'}
									onClick={addAllHandler}
									isDisabled={!organizationsList.length}
								>
									<span dangerouslySetInnerHTML={{ __html: t('common:actionMoveAddAll') }} />
								</Button>
								<Button type={'secondary'} htmlType={'button'} onClick={addHandler} isDisabled={isAddDisabled}>
									<span dangerouslySetInnerHTML={{ __html: t('common:actionMoveAdd') }} />
								</Button>
							</div>

							<div className="multiselect-controls-item">
								<Button type={'secondary'} htmlType={'button'} onClick={removeHandler} isDisabled={isRemoveDisabled}>
									<span dangerouslySetInnerHTML={{ __html: t('common:actionMoveRemove') }} />
								</Button>
								<Button
									type={'secondary'}
									htmlType={'button'}
									onClick={removeAllHandler}
									isDisabled={!organizationsSelectedList.length}
								>
									<span dangerouslySetInnerHTML={{ __html: t('common:actionMoveRemoveAll') }} />
								</Button>
							</div>
						</div>
					</div>

					<div className="form-col form-col-39">
						<Multiselect
							name={'listSelected'}
							list={organizationsSelectedList}
							title={t('pages:notifications:modalListSelectedOrganizations')}
							onChange={organizationsSelectedListHandler}
						/>
					</div>
				</div>

				<div
					className="form-item form-item--checkbox"
					style={{
						opacity: !organizationsSelectedList.length ? 0.5 : 1
					}}
				>
					<Checkbox
						name={'toAdmin'}
						isDisabled={isSubmitting || !organizationsSelectedList.length}
						reference={register}
						onChange={() => setToAdmin(!toAdmin)}
					>
						{t('pages:notifications:modalToAdmin')}
					</Checkbox>
				</div>

				<div
					className="form-item form-item--checkbox"
					style={{
						opacity: !organizationsSelectedList.length ? 0.5 : 1
					}}
				>
					<Checkbox
						name={'toClient'}
						isDisabled={isSubmitting || !organizationsSelectedList.length}
						reference={register}
						onChange={() => setToClient(!toClient)}
					>
						{t('pages:notifications:modalToClient')}
					</Checkbox>
				</div>

				<div className="form-row">
					<div className="form-col">
						<div className="form-headnote">{t('pages:notifications:modalListRecipients')}</div>

						<div className="modal-log">
							{recipientsListFiltered.map((item, idx) => {
								return (
									<div
										key={idx}
										className="modal-log-item"
										style={{
											opacity: item.disabled ? 0.5 : 1
										}}
									>
										<Checkbox
											name={'recepient'}
											isDisabled={isSubmitting || item.disabled}
											isChecked={item.selected}
											onChange={() => recipientHandler(item.id)}
										>
											{item.email} - {item.name}
										</Checkbox>
									</div>
								)
							})}
						</div>
					</div>

					<div className="form-col">
						<div className="form-item">
							<div className="form-headnote">{t('pages:notifications:modalTopicFull')}</div>
							<Input
								type={'text'}
								currentValue={watch('topic')}
								name={'topic'}
								reference={register({
									required: true,
									maxLength: 255
								})}
								isRequired={true}
								isDisabled={isSubmitting}
								isError={!!errors.topic}
								errors={errors.topic}
								rules={{
									maxLength: 255
								}}
							/>
						</div>

						<div className="form-item">
							<div className="form-headnote">{t('pages:notifications:modalMessageFull')}</div>
							<Textarea
								currentValue={watch('message')}
								name={'message'}
								reference={register({
									required: true,
									maxLength: 5000
								})}
								isRequired={true}
								isDisabled={isSubmitting}
								isError={!!errors.message}
								errors={errors.message}
								rules={{
									maxLength: 5000
								}}
							/>
						</div>
					</div>
				</div>

				<div className="form-controls form-controls--right">
					<Button type={'secondary'} htmlType={'button'} onClick={hideModal} isDisabled={isSubmitting}>
						{t('common:actionCancel')}
					</Button>

					<Button
						type={'primary'}
						htmlType={'submit'}
						isDisabled={
							isSubmitting ||
							!isDirty ||
							(!toAdmin && !toClient) ||
							(toClient && !recipients.length) ||
							(!toAdmin && !toClient && !recipients.length)
						}
					>
						{t('common:actionSend')}
					</Button>
				</div>
			</form>
		</>
	)
}

const mapDispatch = {
	showModalConfirm: modalConfirmShowHandler
}

const connector = connect(null, mapDispatch)

export default connector(ModalAddNotification)
