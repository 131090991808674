import React, { Fragment, useEffect, useMemo } from 'react'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { CLOUD_ADMIN, getUserRoleName, ORG_ADMIN, roles, SUPPORT, USER } from '../../helpers/roles'
import { IUser } from '../../interfaces/passport/user'
import { useTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { hideSidebar } from '../../store/actionCreators/UI/sidebar'
import { RootState } from '../../store/reducers/rootReducer'
import { logoutHandler } from '../../services/passport/auth'

type TProps = ConnectedProps<typeof connector> & {
	user: IUser
}

interface IMenu {
	[key: string]: Array<{
		to: string
		title: string
		meta?: {
			count?: number
			highlighted?: boolean
			icon?: string
			separate?: boolean
		}
	}>
}

function Sidebar({ user, sidebar, hideSidebar, logout, licensePackageAmount }: TProps) {
	const { t } = useTranslation()
	const { pathname } = useLocation()
	const history = useHistory()

	const { role } = user
	const classNames = ['sidebar', roles[role].sidebarClassName]

	if (sidebar.isOpen) {
		classNames.push('sidebar--active')
	}

	const menu: IMenu = useMemo(() => {
		return {
			[CLOUD_ADMIN]: [
				{
					to: '/',
					title: 'common:menuOrganizations',
					meta: {
						separate: true
					}
				},
				{
					to: '/support',
					title: 'common:menuSupportRequest'
				},
				{
					to: '/notifications',
					title: 'common:menuNotifications'
				},
				{
					to: '/reports',
					title: 'common:menuReports',
					meta: {
						separate: true
					}
				},
				{
					to: '/products',
					title: 'common:menuProducts'
				},
				{
					to: '/versions',
					title: 'common:menuVersions'
				},
				{
					to: '/builds',
					title: 'common:menuBuilds'
				},
				{
					to: '/functions',
					title: 'common:menuFunctions'
				},
				{
					to: '/orders',
					title: 'common:menuPackageRequest',
					meta: {
						count: licensePackageAmount
					}
				},
				{
					to: '/hardware-keys',
					title: 'common:menuHardwareKeys'
				},
				{
					to: '/system-users',
					title: 'common:menuSystemUsers',
					meta: {
						separate: true
					}
				},
				{
					to: '/tenants',
					title: 'common:menuTenants'
				},
				{
					to: '/product-updates',
					title: 'common:menuProductUpdates'
				}
			],
			[ORG_ADMIN]: [
				{
					to: '/',
					title: 'common:menuMyOrganization'
				},
				{
					to: '/faculties',
					title: 'common:menuFaculties'
				},
				{
					to: '/users',
					title: 'common:menuUsers'
				},
				{
					to: '/support',
					title: 'common:menuSupportRequest'
				},
				{
					to: '/distributives',
					title: 'common:menuDistributives'
				},
				{
					to: '/license-packages',
					title: 'common:menuLicensePackages'
				},
				{
					to: '/settings',
					title: 'common:menuSettings'
				},
				{
					to: '/orders',
					title: 'common:menuLicensePackagesRequest',
					meta: {
						highlighted: true,
						icon:
							'<svg xmlns="http://www.w3.org/2000/svg" width="16" height="18.678"><path d="M11.227 0v18.678l4.774-3.8V2.595zm-2.22 14.6l-3.844 1.677V1.623l3.844 1.138zm-9.006-.43l2.942-.777V4.038L.019 3.566z" /></svg>'
					}
				}
			],
			[SUPPORT]: [
				{
					to: '/',
					title: 'common:menuHome'
				}
			],
			[USER]: [
				{
					to: '/',
					title: 'common:menuHome',
					meta: {
						icon:
							'<svg xmlns="http://www.w3.org/2000/svg" width="16" height="14.516"><path d="M16 8.739l-8-6.21-8 6.21V6.21L8 0l8 6.21zm-2-.226v6h-4v-4H6v4H2v-6l6-4.5z" /></svg>'
					}
				},
				{
					to: '/support',
					title: 'common:menuSupport',
					meta: {
						icon:
							'<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path d="M8 0a8 8 0 108 8 8 8 0 00-8-8zm2.1 1.4a6.941 6.941 0 014.5 4.5l-3.415.231a3.693 3.693 0 00-1.3-1.308zm-7 1.7a6.874 6.874 0 012.8-1.7l.219 3.425a3.778 3.778 0 00-1.3 1.3L1.396 5.9a6.95 6.95 0 011.708-2.796zm2.8 11.5a6.941 6.941 0 01-4.5-4.5l3.425-.219a3.724 3.724 0 001.3 1.3zM8 10.615A2.615 2.615 0 1110.615 8 2.618 2.618 0 018 10.615zm4.9 2.281a6.9 6.9 0 01-2.8 1.7l-.227-3.419a3.7 3.7 0 001.312-1.312l3.415.231a6.887 6.887 0 01-1.704 2.8z" /></svg>'
					}
				},
				{
					to: '/faq',
					title: 'common:menuFaq',
					meta: {
						icon:
							'<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path d="M8 1.615a6.382 6.382 0 11-4.515 1.87A6.358 6.358 0 018 1.615M8 0a8 8 0 108 8 8 8 0 00-8-8z" /><path class="a" d="M7.192 11.192h1.615v1.615H7.192zM8.8 10.381H7.2c0-2.577 2.4-2.392 2.4-3.992a1.607 1.607 0 00-1.6-1.6 1.623 1.623 0 00-1.6 1.6H4.8a3.2 3.2 0 016.4 0c0 1.999-2.4 2.226-2.4 3.992z" /></svg>'
					}
				},
				{
					to: '/profile',
					title: 'common:menuProfile',
					meta: {
						icon:
							'<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path d="M8-.001a8 8 0 108 8 8 8 0 00-8-8zm0 3.1a2.839 2.839 0 11-2.839 2.838A2.839 2.839 0 018 3.096zm0 11.1a6.182 6.182 0 01-4.726-2.2 3.6 3.6 0 013.177-1.929.789.789 0 01.229.035 4.271 4.271 0 001.32.217 4.255 4.255 0 001.319-.223.789.789 0 01.229-.035 3.6 3.6 0 013.177 1.929A6.182 6.182 0 018 14.193z" /></svg>'
					}
				}
			]
		}
	}, [licensePackageAmount])

	useEffect(() => {
		if (role === USER) {
			hideSidebar()
		}
	}, [role, pathname, hideSidebar])

	const Logout = () => {
		logout()
		history.push('/')
	}

	return (
		<div className={classNames.join(' ')}>
			<div className="sidebar-wrapper">
				{role === USER && (
					<div className="sidebar-heading">
						<div className="sidebar-heading-controls">
							<button className="sidebar-close" type="button" onClick={hideSidebar} />
							<button className="sidebar-account-logout" type="button" onClick={Logout} />
						</div>
						<div className="sidebar-account">
							<div className="sidebar-account-image">
								<img src="/assets/img/account.svg" alt="" />
							</div>
							<div className="sidebar-account-name">
								{user.firstName} {user.lastName}
							</div>
							<div className="sidebar-account-email">{user.email}</div>
							<div className="sidebar-account-role">{t(getUserRoleName(user))}</div>
						</div>
					</div>
				)}

				<div className="sidebar-title">{t('common:sidebarTitle')}</div>

				<ul className="list list-sidebar">
					{menu[role].map((item, idx) => {
						const classNames = ['list-item']
						const urls = pathname.split('/').slice(1)

						/*
							В переменной urls считаем вложенность текущего path

							Активный класс добавляется при следующих условиях:
							1. Если вложенности нет и url в меню совпадает с path
							2. Если вложенность есть и path начитается с url в меню, но не для главной страницы
							3. Тут особое условие, для главной страницы у нас есть вложенность при выборе организации,
								 но url в меню /, а вложенность начинается с url /organization
						*/
						if (
							(urls.length === 1 && item.to === `/${urls[0]}`) ||
							(urls.length > 1 && item.to !== '/' && pathname.startsWith(item.to)) ||
							(urls.length > 1 && item.to === '/' && pathname.startsWith('/organization'))
						)
							classNames.push('list-item--active')

						return (
							<Fragment key={idx}>
								<li className={classNames.join(' ')}>
									<NavLink
										exact
										to={item.to}
										className={`list-link ${item?.meta?.icon ? 'list-link--icon' : ''} ${
											item?.meta?.highlighted ? 'list-link--request' : ''
										} `}
									>
										{item?.meta?.icon ? (
											<>
												<div dangerouslySetInnerHTML={{ __html: item.meta.icon }} />
												<span>{t(item.title)}</span>
											</>
										) : (
											t(item.title)
										)}
										{item?.meta?.count ? <strong className="list-item-count">{item.meta.count}</strong> : ''}
									</NavLink>
								</li>
								{item?.meta?.separate && <li className="list-item list-item--separate" />}
							</Fragment>
						)
					})}
				</ul>
			</div>
		</div>
	)
}

const mapState = (state: RootState) => ({
	sidebar: state.sidebar,
	licensePackageAmount: state.licencePackageRequest.count
})

const mapDispatch = {
	hideSidebar: hideSidebar,
	logout: logoutHandler
}

const connector = connect(mapState, mapDispatch)

export default connector(Sidebar)
