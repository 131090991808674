import React from 'react'
import { useParams } from 'react-router-dom'
import { connect, ConnectedProps } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { RootState } from '../../../store/reducers/rootReducer'
import UsersEditor from '../../Common/Users/UsersEditor'
import Error from '../../Error/Error'

const UserEdit = ({ user }: ConnectedProps<typeof connector>) => {
	const { t } = useTranslation()
	const { userId } = useParams<{ userId: string }>()
	const account = user.accounts?.[0]

	if (!account || !userId) return <Error />

	return (
		<UsersEditor
			organizationId={account.organization.id}
			accountId={account.accountId}
			userId={userId}
			crumbs={[
				{
					title: t('pages:users:title'),
					to: `/users`
				}
			]}
		/>
	)
}

const mapState = (state: RootState) => ({
	user: state.user.user
})

const connector = connect(mapState)

export default connector(UserEdit)
