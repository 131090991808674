import { TModalConfirm } from '../../../interfaces/UI/modalConfirm'
import { MODAL_CONFIRM_HIDE, MODAL_CONFIRM_SHOW } from '../../actions/UI/modalConfirm'
import { ModalConfirmActionTypes } from '../../../interfaces/UI/actionCreators/modalConfirm'

interface IInitialState {
	isOpened: boolean
	onConfirm: () => void
	onCancel?: () => void
	type: TModalConfirm
	questionText?: string
	elementText?: string
	buttonText?: string
}

const initialState: IInitialState = {
	isOpened: false,
	onConfirm: () => {},
	onCancel: () => {},
	type: 'custom',
	questionText: '',
	elementText: '',
	buttonText: ''
}

export default function ModalConfirmReducer(state = initialState, action: ModalConfirmActionTypes): IInitialState {
	switch (action.type) {
		case MODAL_CONFIRM_SHOW:
			return {
				...state,
				// ...action.payload,
				isOpened: true,
				onConfirm: action.payload.onConfirm,
				onCancel: action.payload.onCancel,
				type: action.payload.type || 'custom',
				questionText: action.payload.questionText || '',
				elementText: action.payload.elementText || '',
				buttonText: action.payload.buttonText || ''
			}

		case MODAL_CONFIRM_HIDE:
			return {
				...state,
				isOpened: false
			}

		default:
			return state
	}
}
