import React, { useEffect, useState } from 'react'
import Pagetitle from '../../../components/UI/Pagetitle/Pagetitle'
import Button from '../../../components/UI/Button/Button'
import { IPagination, TSortColumnsList, TStatus } from '../../../interfaces/helpers'
import { useTranslation } from 'react-i18next'
import Loading from '../../../components/UI/Loading/Loading'
import Error from '../../Error/Error'
import { connect, ConnectedProps } from 'react-redux'
import {
	createSupportRequestEmployeeHandler,
	supportRequestEmployeeGetAllHandler
} from '../../../services/support/supportRequestEmployee'
import {
	ISupportRequestEmployeeAPIRequest,
	ISupportRequestEmployeeAPIResponse,
	ISupportRequestEmployeeCreate,
	TSupportRequestEmployeeSortFields
} from '../../../interfaces/support/supportRequestEmployee'
import {
	DATE_FORMAT_FULL,
	DEFAULT_PAGE_SIZE,
	getPriorityName,
	getStatusName,
	INFINITE_NUMBER,
	stringToLocalization,
	SUPPORT_STATUSES
} from '../../../helpers/helpers'
import NoResults from '../../../components/UI/NoResults/NoResults'
import Pagination from '../../../components/UI/Pagination/Pagination'
import { selectProps, TOnChange } from '../../../components/UI/Select/helpers'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import SearchField from '../../../components/UI/SearchField/SearchField'
import Select from 'react-select'
import SelectLimit from '../../../components/UI/Select/SelectLimit'
import { getAllOrganizationsHandler } from '../../../services/passport/organization'
import { IOrganization } from '../../../interfaces/passport/organizations'
import { prioritiesGetAllHandler } from '../../../services/support/priorities'
import { IPriority } from '../../../interfaces/support/priorities'
import { toggleLoaderHandler } from '../../../store/actionCreators/UI/loader'
import { getAllUsersHandler } from '../../../services/passport/user'
import { requestTopicGetAllHandler } from '../../../services/support/requestTopic'
import { modalShowHandler } from '../../../store/actionCreators/UI/modal'
import ModalAddTicket from './ModalAddTicket'

const Support = ({
	getTopics,
	getUsers,
	getAllSupportRequestEmployee,
	getOrganizations,
	getPriorities,
	createSupportRequestEmployee,
	showModal,
	toggleLoader
}: ConnectedProps<typeof connector>) => {
	const { t } = useTranslation()
	const [status, setStatus] = useState<TStatus>('loading')
	const [organizations, setOrganizations] = useState<IOrganization[]>([])
	const [priorities, setPriorities] = useState<IPriority[]>([])
	const [table, setTable] = useState<ISupportRequestEmployeeAPIResponse[]>([])

	const [pagination, setPagination] = useState<IPagination>({
		pageNo: 0,
		totalPages: 0,
		totalCount: 0
	})
	const [filterSupportRequestEmployee, setFilterSupportRequestEmployee] = useState<ISupportRequestEmployeeAPIRequest>({
		requestEmployeeSortField: 'CHANGE_DATE',
		onlyMy: false,
		numOrTopicFilter: '',
		orgId: '',
		stateFilter: '',
		priorityFilter: '',
		desc: true,
		pageSize: DEFAULT_PAGE_SIZE,
		pageNo: 0
	})

	const searchHandle = (value: string) => {
		setFilterSupportRequestEmployee({
			...filterSupportRequestEmployee,
			numOrTopicFilter: value,
			pageNo: 0
		})
	}

	const paginationHandle = (pageNo: number) => {
		setFilterSupportRequestEmployee({
			...filterSupportRequestEmployee,
			pageNo
		})
	}

	const limitHandle: TOnChange = option => {
		if (option) {
			setFilterSupportRequestEmployee({
				...filterSupportRequestEmployee,
				pageSize: parseInt(option.value) || INFINITE_NUMBER,
				pageNo: 0
			})
		}
	}

	const selectStateHandle: TOnChange = option => {
		if (option) {
			setFilterSupportRequestEmployee({
				...filterSupportRequestEmployee,
				stateFilter: option.value
			})
		}
	}

	const selectOrganizationHandle: TOnChange = option => {
		if (option) {
			setFilterSupportRequestEmployee({
				...filterSupportRequestEmployee,
				orgId: option.value
			})
		}
	}

	const selectPriorityHandle: TOnChange = option => {
		if (option) {
			setFilterSupportRequestEmployee({
				...filterSupportRequestEmployee,
				priorityFilter: option.value
			})
		}
	}

	const [sortColumns, setSortColumns] = useState<TSortColumnsList>({
		CHANGE_DATE: {
			desc: true,
			classNames: ['list-item', 'list-item--down']
		},
		NUMBER: {
			desc: true,
			classNames: ['list-item']
		},
		CREATE_DATE: {
			desc: true,
			classNames: ['list-item']
		},
		TOPIC: {
			desc: true,
			classNames: ['list-item']
		},
		STATE: {
			desc: true,
			classNames: ['list-item']
		},
		PRIORITY: {
			desc: true,
			classNames: ['list-item']
		}
	})

	const sortHandle = (requestEmployeeSortField: TSupportRequestEmployeeSortFields) => {
		const updatedSortColumns = { ...sortColumns }

		Object.keys(updatedSortColumns).forEach((key: string) => {
			if (key !== requestEmployeeSortField) {
				updatedSortColumns[key] = {
					desc: true,
					classNames: ['list-item']
				}
			} else {
				updatedSortColumns[key].desc = !updatedSortColumns[key].desc
				updatedSortColumns[key].classNames = [
					'list-item',
					updatedSortColumns[key].desc ? 'list-item--down' : 'list-item--up'
				]
			}
		})

		setFilterSupportRequestEmployee({
			...filterSupportRequestEmployee,
			requestEmployeeSortField,
			desc: updatedSortColumns[requestEmployeeSortField].desc
		})

		setSortColumns(updatedSortColumns)
	}

	const createHandler = async () => {
		toggleLoader(true)

		try {
			const responseUsers = await getUsers({
				userSortField: 'Name',
				desc: false,
				pageNo: 0,
				pageSize: INFINITE_NUMBER
			})

			// const responseOrganizations = await getOrganizations({
			// 	orgSortField: 'Name',
			// 	desc: false,
			// 	pageNo: 0,
			// 	pageSize: INFINITE_NUMBER
			// })

			const responseTopics = await getTopics({
				topicSortField: 'NAME',
				desc: false,
				pageNo: 0,
				pageSize: INFINITE_NUMBER
			})

			showModal({
				type: 'large-form',
				Component: ModalAddTicket,
				ComponentProps: {
					users: responseUsers.data.data,
					organizations,
					topics: responseTopics.data.data,

					onConfirm: async (data: ISupportRequestEmployeeCreate) => {
						toggleLoader(true)

						try {
							const response = await createSupportRequestEmployee(data)

							if (response?.status === 200) {
								paginationHandle(0)
							}

							return response
						} finally {
							toggleLoader(false)
						}
					}
				}
			})
		} finally {
			toggleLoader(false)
		}
	}

	useEffect(() => {
		getOrganizations({
			orgSortField: 'Name',
			pageNo: 0,
			pageSize: INFINITE_NUMBER,
			desc: false
		}).then(response => {
			if (response?.status === 200) {
				setOrganizations(response.data.data)
			}
		})

		getPriorities().then(response => {
			if (response?.status === 200) {
				setPriorities(response.data)
			}
		})
	}, [getOrganizations, getPriorities])

	useEffect(() => {
		getAllSupportRequestEmployee(filterSupportRequestEmployee).then(response => {
			if (response?.status === 200) {
				const { data, pageNo, totalPages, totalCount } = response.data

				setTable(data)

				setPagination({
					pageNo,
					totalPages,
					totalCount
				})

				setStatus('ready')
			}
		})
	}, [getAllSupportRequestEmployee, filterSupportRequestEmployee])

	if (status === 'loading') return <Loading />
	if (status === 'error') return <Error />

	return (
		<>
			<Pagetitle title={t('pages:support:titleAdmin')} />

			<div className="button-row button-row--support">
				<Button type={'primary'} htmlType={'button'} onClick={createHandler}>
					{t('pages:support:addNew')}
				</Button>
			</div>

			<div className="filters filters--lines">
				<div className="filters-line">
					<div className="filters-item filters-item--search">
						<SearchField onSubmit={searchHandle} placeholder={t('pages:support:searchField')} />
					</div>

					<div className="filters-item filters-item--select">
						<Select
							name={'organization'}
							onChange={selectOrganizationHandle}
							options={[
								{
									label: t('common:selectDefaultValue'),
									value: ''
								},
								...organizations.map(item => {
									return {
										label: item.name,
										value: item.id
									}
								})
							]}
							defaultValue={{
								label: t('common:selectDefaultValue'),
								value: ''
							}}
							placeholder={t('pages:support:organization')}
							{...selectProps}
						/>
					</div>
				</div>

				<div className="filters-line">
					<div className="filters-item filters-item--select-middle">
						<Select
							name={'state'}
							onChange={selectStateHandle}
							options={[
								{
									label: t('common:selectDefaultValue'),
									value: ''
								},
								...SUPPORT_STATUSES.map(item => {
									return {
										label: t(getStatusName(item)),
										value: item
									}
								})
							]}
							defaultValue={{
								label: t('common:selectDefaultValue'),
								value: ''
							}}
							placeholder={t('pages:support:state')}
							{...selectProps}
						/>
					</div>

					<div className="filters-item filters-item--select-middle">
						<Select
							name={'priority'}
							onChange={selectPriorityHandle}
							options={[
								{
									label: t('common:selectDefaultValue'),
									value: ''
								},
								...priorities.map(item => {
									return {
										label: t(getPriorityName(item.priority)),
										value: item.priority
									}
								})
							]}
							defaultValue={{
								label: t('common:selectDefaultValue'),
								value: ''
							}}
							placeholder={t('pages:support:priority')}
							{...selectProps}
						/>
					</div>

					<div className="filters-item filters-item--view">
						<SelectLimit total={pagination.totalCount} onChange={limitHandle} />
					</div>
				</div>
			</div>

			<div className="sorting">
				<div className="sorting-title">
					<strong>{t('pages:support:sortBy')}</strong>
				</div>
				<div className="sorting-list">
					<ul className="list">
						<li onClick={() => sortHandle('CREATE_DATE')} className={sortColumns.CREATE_DATE.classNames.join(' ')}>
							<span>{t('pages:support:sortByCreateDate')}</span>
						</li>
						<li onClick={() => sortHandle('CHANGE_DATE')} className={sortColumns.CHANGE_DATE.classNames.join(' ')}>
							<span>{t('pages:support:sortByDate')}</span>
						</li>
						<li onClick={() => sortHandle('NUMBER')} className={sortColumns.NUMBER.classNames.join(' ')}>
							<span>{t('pages:support:sortByNum')}</span>
						</li>
					</ul>
				</div>
			</div>

			{table.length > 0 ? (
				<div className="pagination-wrapper">
					<div className="support-list">
						{table.map(item => {
							return (
								<Link
									key={item.id}
									className={`support-item ${
										(!item.readDate && dayjs(item.updatedWhen).diff(dayjs(item.fromDate)) > 0) ||
										(item.readDate && dayjs(item.updatedWhen).diff(dayjs(item.readDate)) > 0)
											? 'support-item--update'
											: ''
									} `}
									to={`/support/${item.id}`}
								>
									<div className="support-item-holder">
										<div className="support-item-number">#{item.num}</div>
										<div className="support-item-content">
											<h4>{item.subject}</h4>
											<div className="support-item-data">
												<span>
													{t('pages:support:owner')}: {item.ownerName} {item.clientName && `(${item.clientName})`}
												</span>
												<span>
													{t('pages:support:fromDate')}: {dayjs(item.fromDate).format(DATE_FORMAT_FULL)}
												</span>
											</div>
											<div className="support-item-theme">
												{t(`common:topic_${stringToLocalization(item.topic.name)}`)}
											</div>
										</div>
										<div className="support-item-status">
											<span className={`task-status task-status--${item.state.toLowerCase()}`}>
												{t(getStatusName(item.state))}
											</span>
										</div>
									</div>
								</Link>
							)
						})}
					</div>

					<Pagination onClick={paginationHandle} {...pagination} />
				</div>
			) : (
				<NoResults />
			)}
		</>
	)
}

const mapDispatch = {
	getAllSupportRequestEmployee: supportRequestEmployeeGetAllHandler,
	getOrganizations: getAllOrganizationsHandler,
	getUsers: getAllUsersHandler,
	getTopics: requestTopicGetAllHandler,
	getPriorities: prioritiesGetAllHandler,
	createSupportRequestEmployee: createSupportRequestEmployeeHandler,

	toggleLoader: toggleLoaderHandler,
	showModal: modalShowHandler
}

const connector = connect(null, mapDispatch)

export default connector(Support)
