import React, { useEffect, useRef, useState } from 'react'
import Button from '../../../components/UI/Button/Button'
import { modalConfirmShowHandler } from '../../../store/actionCreators/UI/modalConfirm'
import { connect, ConnectedProps } from 'react-redux'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import Textarea from '../../../components/UI/Textarea/Textarea'
import { IBuild, IBuildUpdate } from '../../../interfaces/cabinet/builds'
import { IVersion } from '../../../interfaces/cabinet/versions'
import uniqBy from 'lodash/uniqBy'
import { INPUT_FILE_ACCEPTED_FILES_HARDWARE_KEYS, PLATFORMS } from '../../../helpers/helpers'
import Input from '../../../components/UI/Input/Input'
import InputFile from '../../../components/UI/InputFile/InputFile'
import { useTranslation } from 'react-i18next'
import orderBy from 'lodash/orderBy'
import { selectProps } from '../../../components/UI/Select/helpers'
import Select from 'react-select'
import { MultiselectList } from '../../../components/UI/MultiselectList/MultiselectList'

type TProps = ConnectedProps<typeof connector> & {
	item: IBuild
	versions: IVersion[]
	onRemove: (id: string) => Promise<any>
	onConfirm: (data: IBuildUpdate, file: File[]) => Promise<any>
	hideModal: () => void
}

interface IFormInputs {
	productId: string
	versionIds: string[]
	buildVersionCode: string
	platform: string
	downloadLink: string
	description: string
	file: File[]
}

interface IProduct {
	id: string
	name: string
}

type IVersionWithSelect = IVersion & {
	selected: boolean
}

const ModalEditBuild = ({ item, versions, hideModal, showModalConfirm, onRemove, onConfirm }: TProps) => {
	const { t } = useTranslation()
	const [product, setProduct] = useState<IProduct>({
		id: '',
		name: ''
	})
	const [productsList, setProductsList] = useState<IProduct[]>([])
	const [versionsList, setVersionsList] = useState<IVersionWithSelect[]>([])

	const methods = useForm<IFormInputs>({
		defaultValues: {
			productId: item.product?.id,
			versionIds: item.versions?.map(version => version.id) || [],
			buildVersionCode: item.buildVersionCode,
			platform: item.platform,
			downloadLink: item.downloadLink,
			description: item.description
		}
	})
	const { setValue } = methods
	const { isDirty } = methods.formState
	const [isSubmitting, setIsSubmitting] = useState(false)

	const selectProductRef = useRef<any>(null)

	const cancelHandler = () => {
		if (isDirty) {
			showModalConfirm({
				type: 'cancel',
				onConfirm: hideModal
			})
		} else {
			hideModal()
		}
	}

	const removeHandler = () => {
		showModalConfirm({
			type: 'remove',
			elementText: `${item.platform} ${product?.name}`,
			onConfirm: async () => {
				try {
					await onRemove(item.id)
					hideModal()
				} finally {
				}
			}
		})
	}

	const onSubmit = async (data: IFormInputs) => {
		setIsSubmitting(true)

		const product = productsList.find(product => product.id === data.productId)
		const versions = versionsList.filter(version => data.versionIds.includes(version.id))

		if (!product) {
			return
		}

		try {
			await onConfirm(
				{
					product: {
						id: product?.id,
						name: product?.name
					},
					versions: versions.map(version => ({
						id: version.id,
						name: version.name
					})),
					platform: data.platform,
					buildVersionCode: data.buildVersionCode,
					downloadLink: data.downloadLink,
					description: data.description
				},
				data.file
			)
			hideModal()
		} finally {
			setIsSubmitting(false)
		}
	}

	useEffect(() => {
		const prodList = uniqBy(
			versions.map(item => ({ ...item.product })),
			el => el.id
		)

		const versionIds = item.versions?.map(v => v.id) || []

		const product = versions.filter(el => versionIds.includes(el.id)).map(el => ({ ...el.product }))[0]

		setProductsList(orderBy(prodList, ['name']))
		setProduct(product)
	}, [versions, item.versions])

	useEffect(() => {
		setValue('productId', product.id)

		selectProductRef.current?.select.setValue({
			label: product.name,
			value: product.id
		})

		const versionIds = item.versions?.map(v => v.id) || []

		setValue('versionIds', versionIds)

		setVersionsList(
			versions
				.filter(item => item.product.id === product.id)
				.map(v => ({
					...v,
					selected: versionIds.includes(v.id)
				}))
		)
	}, [product, setValue, item.versions, versions])

	return (
		<>
			<button className="modal-close" onClick={cancelHandler} disabled={isSubmitting} />

			<h3 className="modal-title">{t('common:appName')}</h3>

			<p className="modal-footnote">{t('pages:builds:modalUpdateText')}</p>

			<FormProvider {...methods}>
				<form className="form" onSubmit={methods.handleSubmit(onSubmit)}>
					<div className="form-item">
						<Controller
							render={({ onChange, name }) => (
								<Select
									name={name}
									defaultValue={{
										label: item.platform,
										value: item.platform
									}}
									options={PLATFORMS.map(item => ({
										label: item,
										value: item
									}))}
									placeholder={t('pages:builds:fieldPlatform')}
									isDisabled={isSubmitting}
									isRequired={true}
									isError={!!methods.errors.platform}
									errors={methods.errors.platform}
									onChange={option => option && onChange(option.value)}
									{...selectProps}
								/>
							)}
							name={'platform'}
							control={methods.control}
							rules={{
								required: true
							}}
						/>
					</div>

					<div className="form-item">
						<Controller
							render={({ onChange, name }) => (
								<Select
									name={name}
									options={productsList.map(item => ({
										label: item.name,
										value: item.id
									}))}
									placeholder={t('pages:builds:fieldProduct')}
									isDisabled={isSubmitting}
									isRequired={true}
									isError={!!methods.errors.productId}
									errors={methods.errors.productId}
									onChange={option => {
										if (option) {
											onChange(option.value)
											setVersionsList(
												versions
													.filter(item => item.product.id === option.value)
													.map(v => ({
														...v,
														selected: false
													}))
											)
											methods.setValue('versionIds', [])
										}
									}}
									ref={selectProductRef}
									{...selectProps}
								/>
							)}
							name={'productId'}
							control={methods.control}
							rules={{
								required: true
							}}
						/>
					</div>

					<div className="form-item">
						<Controller
							render={({ onChange }) => (
								<MultiselectList
									variant="short"
									label={t('pages:builds:fieldAvailableVersions')}
									searchLabel={t('pages:builds:fieldSearchVersion')}
									isDisabled={isSubmitting || !methods.getValues().productId}
									isError={!!methods.errors.versionIds}
									isRequired
									onChange={(version, selected) => {
										const updatedVersions = versionsList.map(item => ({
											...item,
											selected: item.id === version.id ? selected : item.selected
										}))
										const selectedVersions = updatedVersions.filter(v => v.selected).map(v => v.id)

										onChange(selectedVersions.length ? selectedVersions : undefined)

										setVersionsList(updatedVersions)
									}}
									list={versionsList}
								/>
							)}
							name={'versionIds'}
							control={methods.control}
							rules={{
								required: true
							}}
						/>
					</div>

					<div className="form-item">
						<Input
							currentValue={methods.watch('buildVersionCode')}
							type={'text'}
							name={'buildVersionCode'}
							placeholder={t('pages:builds:fieldBuildVersionCode')}
							isDisabled={isSubmitting}
							isError={!!methods.errors.buildVersionCode}
							isRequired={true}
							reference={methods.register({
								required: true,
								maxLength: 255
							})}
							errors={methods.errors.buildVersionCode}
							rules={{
								maxLength: 255
							}}
						/>
					</div>

					<div className="form-item">
						<InputFile
							name={'file'}
							errors={methods.errors.file}
							isError={!!methods.errors.file}
							isDisabled={isSubmitting}
							placeholder={t('pages:builds:fieldFile')}
							// reference={register}
							accept={INPUT_FILE_ACCEPTED_FILES_HARDWARE_KEYS}
						/>
					</div>

					<div className="form-item">
						<Input
							currentValue={methods.watch('downloadLink')}
							type={'text'}
							name={'downloadLink'}
							placeholder={t('pages:builds:fieldDownloadLink')}
							isDisabled={isSubmitting}
							isError={!!methods.errors.downloadLink}
							isRequired={true}
							reference={methods.register({
								required: true
							})}
							errors={methods.errors.downloadLink}
						/>
					</div>

					<div className="form-item">
						<Textarea
							currentValue={methods.watch('description')}
							name={'description'}
							placeholder={t('pages:builds:fieldDescription')}
							isDisabled={isSubmitting}
							isError={!!methods.errors.description}
							reference={methods.register({
								maxLength: 5000
							})}
							errors={methods.errors.description}
							rules={{
								maxLength: 5000
							}}
						/>
					</div>

					<div className="form-controls form-controls--justify">
						<Button type={'secondary'} htmlType={'button'} onClick={removeHandler} isDisabled={isSubmitting}>
							{t('common:actionRemove')}
						</Button>

						<Button type={'secondary'} htmlType={'button'} onClick={cancelHandler} isDisabled={isSubmitting}>
							{t('common:actionCancel')}
						</Button>

						<Button type={'primary'} htmlType={'submit'} isDisabled={isSubmitting || !isDirty}>
							{t('common:actionSave')}
						</Button>
					</div>
				</form>
			</FormProvider>
		</>
	)
}

const mapDispatch = {
	showModalConfirm: modalConfirmShowHandler
}

const connector = connect(null, mapDispatch)

export default connector(ModalEditBuild)
