import React, { useLayoutEffect, useRef, useState } from 'react'
import InputError from '../InputError/InputError'

interface IProps {
	type: 'text' | 'search' | 'email' | 'password' | 'tel' | 'number' | 'readonly'
	name: string
	placeholder?: string
	currentValue?: string
	isError?: boolean
	isReadonly?: boolean
	isRequired?: boolean
	isDisabled?: boolean
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
	onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void
	onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void
	reference?: any
	defaultValue?: string
	errors?: any
	min?: number
	max?: number
	rules?: {
		minLength?: number
		maxLength?: number
		max?: number
		min?: number
	}
}

const Input = ({
	type,
	name,
	placeholder,
	currentValue,
	isError,
	isReadonly,
	isRequired,
	isDisabled,
	onChange,
	onBlur,
	onKeyPress,
	reference,
	defaultValue = '',
	errors,
	rules,
	min,
	max
}: IProps) => {
	const [localValue, setLocalValue] = useState(currentValue || defaultValue)
	const inputRef = useRef<HTMLInputElement | null>(null)
	const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)

	const classNames = ['field']

	if (isError) classNames.push('field--error')
	if (isRequired) classNames.push('field--required')
	if (isDisabled) classNames.push('field--disabled')
	if (currentValue || defaultValue || localValue) classNames.push('field--notempty')
	if (type === 'password') classNames.push('field--password')

	const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		setLocalValue(e.currentTarget.value)

		onChange?.(e)
	}

	useLayoutEffect(() => {
		let interval = setInterval(() => {
			if (inputRef.current) {
				// chrome autofill fix
				if (getComputedStyle(inputRef.current).backgroundColor !== 'rgb(255, 255, 255)') {
					inputRef.current.parentElement?.classList.add('field--notempty')
					clearInterval(interval)
				}
			}
		}, 100)
	}, [])

	return (
		<>
			<label className={classNames.join(' ')}>
				<input
					className="field-input"
					type={type === 'password' && isPasswordVisible ? 'text' : type}
					name={name}
					ref={el => {
						reference?.(el)
						inputRef.current = el
					}}
					onChange={onChangeHandler}
					readOnly={isReadonly}
					disabled={isDisabled}
					defaultValue={defaultValue}
					min={min}
					max={max}
					maxLength={rules?.maxLength}
					minLength={rules?.minLength}
					onBlur={onBlur}
					onKeyPress={onKeyPress}
				/>

				{placeholder && <span className="field-label">{placeholder}</span>}

				{type === 'password' && (
					<button
						className={`field-toggle-password ${
							isPasswordVisible ? 'field-toggle-password_hide' : 'field-toggle-password_show'
						}`}
						type="button"
						onClick={() => setIsPasswordVisible(prevState => !prevState)}
					/>
				)}
			</label>

			{isError && <InputError errors={errors} rules={rules} />}
		</>
	)
}

export default Input
