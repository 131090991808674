import { SidebarActionTypes } from '../../../interfaces/UI/sidebar'
import { SIDEBAR_HIDE, SIDEBAR_SHOW } from '../../actions/UI/sidebar'

interface IInitialState {
	isOpen: boolean
}

const initialState: IInitialState = {
	isOpen: false
}

export default function SidebarReducer(state = initialState, action: SidebarActionTypes): IInitialState {
	switch (action.type) {
		case SIDEBAR_SHOW:
			return {
				...state,
				isOpen: true
			}

		case SIDEBAR_HIDE:
			return {
				...state,
				isOpen: false
			}

		default:
			return state
	}
}
