import React, { useState } from 'react'
import InputError from '../InputError/InputError'
// @ts-ignore
import { IMaskInput } from 'react-imask'

interface IProps {
	type: 'text' | 'search' | 'email' | 'password' | 'tel' | 'number'
	name: string
	placeholder?: string
	isError?: boolean
	isReadonly?: boolean
	isRequired?: boolean
	isDisabled?: boolean
	onChange?: (value: string) => void
	reference?: any
	defaultValue?: string
	errors?: any
	min?: number
	max?: number
	rules?: {
		minLength?: number
		maxLength?: number
		max?: number
		min?: number
	}
	maskProps?: {
		[index: string]: any
	}
}

const InputMask = ({
	type,
	name,
	placeholder,
	isError,
	isReadonly,
	isRequired,
	isDisabled,
	onChange,
	reference,
	defaultValue = '',
	errors,
	rules,
	min,
	max,
	maskProps
}: IProps) => {
	const [localValue, setLocalValue] = useState(defaultValue)

	const classNames = ['field']

	if (isError) classNames.push('field--error')
	if (isRequired) classNames.push('field--required')
	if (isDisabled) classNames.push('field--disabled')
	if (localValue) classNames.push('field--notempty')

	const onChangeHandler = (value: string) => {
		setLocalValue(value)

		onChange?.(value)
	}

	return (
		<>
			<label className={classNames.join(' ')}>
				<IMaskInput
					{...maskProps}
					className="field-input"
					type={type}
					name={name}
					inputRef={reference}
					onAccept={onChangeHandler}
					disabled={isDisabled}
					readOnly={isReadonly}
					min={min}
					max={max}
					value={localValue}
				/>

				{placeholder && <span className="field-label">{placeholder}</span>}
			</label>

			{isError && <InputError errors={errors} rules={rules} />}
		</>
	)
}

export default InputMask
