import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { logoutHandler } from '../../services/passport/auth'
import { Link, useHistory } from 'react-router-dom'
import { getUserRoleName } from '../../helpers/roles'
import { IUser } from '../../interfaces/passport/user'
import { useTranslation } from 'react-i18next'

type TProps = ConnectedProps<typeof connector> & {
	user: IUser
}

function Account({ user, logout }: TProps) {
	const { t } = useTranslation()
	const history = useHistory()

	const Logout = () => {
		logout()
		history.push('/')
	}

	return (
		<div className="account">
			<Link className="account-detail" to={'/profile'}>
				<div className="account-name">
					{user.firstName} {user.lastName}
				</div>
				<div className="account-email">{user.email}</div>
				<div className="account-role">{t(getUserRoleName(user))}</div>
			</Link>

			<button className="account-logout" type="button" onClick={Logout}>
				<svg xmlns="http://www.w3.org/2000/svg" width="18.75" height="20">
					<path d="M15 12.5V10H8.75V7.5H15V5l3.75 3.75zm-1.25-1.25v5H7.5V20L0 16.25V0h13.75v6.25H12.5v-5h-10l5 2.5V15h5v-3.75z" />
				</svg>
			</button>
		</div>
	)
}

const mapDispatch = {
	logout: logoutHandler
}

const connector = connect(null, mapDispatch)

export default connector(Account)
