import React, { ReactNode, MouseEvent } from 'react'

interface IProps {
	children: ReactNode
	type: 'primary' | 'secondary'
	htmlType?: 'submit' | 'button' | 'reset'
	onClick?: (e: MouseEvent<HTMLButtonElement>) => void
	isDisabled?: boolean
	className?: string
}

export default function Button({ children, type, onClick, htmlType, isDisabled, className }: IProps) {
	return (
		<button
			className={`button button--${type} ${className || ''}`}
			onClick={onClick}
			type={htmlType}
			disabled={isDisabled}
		>
			{children}
		</button>
	)
}
