import React, { useEffect, useRef } from 'react'

interface IProps {
	placeholder?: string
	onChange?: (value: string) => void
	onSubmit: (value: string) => void
	isDisabled?: boolean
}

export default function SearchField({ isDisabled, placeholder, onChange, onSubmit }: IProps) {
	const ref = useRef<HTMLInputElement | null>(null)

	const classNames = ['field', 'field--search']

	const onChangeHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (typeof onChange === 'function') {
			onChange(e.target.value)
		}
	}

	const onSubmitHandle = () => {
		if (typeof onSubmit === 'function') {
			onSubmit(ref.current?.value || '')
		}
	}

	const onKeyPressHandle = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter' && typeof onSubmit === 'function') {
			e.preventDefault()
			onSubmit(ref.current?.value || '')
		}
	}

	useEffect(() => {
		const node = ref.current

		if (!node) {
			return
		}

		const listener = () => {
			if (node.value) {
				node.parentElement?.classList.add('field--notempty')
			} else {
				node.parentElement?.classList.remove('field--notempty')
			}
		}

		listener()
		node.addEventListener('input', listener)

		return () => {
			node.removeEventListener('input', listener)
		}
	}, [])

	return (
		<label className={classNames.join(' ')}>
			<input
				ref={ref}
				className="field-input"
				type="text"
				onChange={onChangeHandle}
				onKeyPress={onKeyPressHandle}
				disabled={isDisabled}
			/>

			{placeholder && <span className="field-label">{placeholder}</span>}

			<button className="field-search" onClick={onSubmitHandle} type="button" disabled={isDisabled}>
				<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21">
					<path d="M15.367 12.572a8.3 8.3 0 10-2.8 2.793l5.635 5.634 2.794-2.8zm-7.081.84a5.123 5.123 0 115.126-5.12 5.132 5.132 0 01-5.126 5.121z" />
				</svg>
			</button>
		</label>
	)
}
