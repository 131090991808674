import { MODAL_HIDE, MODAL_SHOW } from '../../actions/UI/modal'
import { TModal } from '../../../interfaces/UI/modal'
import { ModalActionTypes } from '../../../interfaces/UI/actionCreators/modal'

interface IInitialState {
	isOpened: boolean
	type: TModal
	Component: any
	ComponentProps: any
}

const initialState: IInitialState = {
	isOpened: false,
	type: '',
	Component: null,
	ComponentProps: null
}

export default function ModalReducer(state = initialState, action: ModalActionTypes): IInitialState {
	switch (action.type) {
		case MODAL_SHOW:
			return {
				...state,
				// ...action.payload,
				type: action.payload.type || '',
				Component: action.payload.Component || null,
				ComponentProps: action.payload.ComponentProps || null,
				isOpened: true
			}

		case MODAL_HIDE:
			return {
				...state,
				isOpened: false
			}

		default:
			return state
	}
}
