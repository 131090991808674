import React, { useState } from 'react'
import Button from '../UI/Button/Button'
import { useForm } from 'react-hook-form'
import Input from '../UI/Input/Input'
import { REGEX_PASSWORD } from '../../helpers/helpers'
import { IUser, IUserUpdate } from '../../interfaces/passport/user'
import { useTranslation } from 'react-i18next'

interface IProps {
	hideModal: () => void
	currentUser: IUser
	onConfirm: (data: IUserUpdate) => Promise<any>
}

interface IFormInputs {
	newPassword: string
	repeatPassword: string
}

const ModalChangePassword = ({ hideModal, currentUser, onConfirm }: IProps) => {
	const { t } = useTranslation()
	const [isSuccess, setIsSuccess] = useState(false)
	const { register, errors, watch, formState, handleSubmit, reset, getValues } = useForm<IFormInputs>()
	const { isDirty } = formState
	const [isSubmitting, setIsSubmitting] = useState(false)

	const onSubmit = async (data: IFormInputs) => {
		const organizationId = currentUser.accounts?.[0]?.organization.id

		if (!organizationId) {
			return
		}

		setIsSubmitting(true)

		try {
			const response = await onConfirm({
				role: currentUser.role,
				firstName: currentUser.firstName,
				lastName: currentUser.lastName,
				patronymic: currentUser.patronymic,
				email: currentUser.email,
				phone: currentUser.phone,
				newPassword: data.newPassword,
			})

			if (response?.status === 200) {
				reset()
				setIsSuccess(true)
			}
		} finally {
			setIsSubmitting(false)
		}
	}

	return (
		<>
			<button className="modal-close" onClick={hideModal} disabled={isSubmitting} />
			<h3 className="modal-title">{t('common:appName')}</h3>
			<p className="modal-footnote">{t('pages:users:modalPasswordChangeText')}</p>

			{!isSuccess ? (
				<>
					<form className="form" onSubmit={handleSubmit(onSubmit)}>
						<div className="form-item">
							<Input
								type={'password'}
								name={'newPassword'}
								currentValue={watch('newPassword')}
								placeholder={t('pages:users:fieldNewPassword')}
								isError={!!errors.newPassword}
								isDisabled={isSubmitting}
								reference={register({
									required: true,
									minLength: 8,
									maxLength: 255,
									pattern: {
										value: REGEX_PASSWORD,
										message: 'fieldPatternPassword'
									}
								})}
								rules={{
									minLength: 8,
									maxLength: 255
								}}
								errors={errors.newPassword}
							/>
						</div>

						<div className="form-item">
							<Input
								type={'password'}
								name={'repeatPassword'}
								currentValue={watch('repeatPassword')}
								placeholder={t('pages:users:fieldRepeatPassword')}
								isError={!!errors.repeatPassword}
								isDisabled={isSubmitting}
								errors={errors.repeatPassword}
								reference={register({
									validate: {
										passwordMatches: value => value === getValues('newPassword')
									}
								})}
							/>
						</div>

						<div className="form-controls form-controls--right">
							<Button type={'secondary'} htmlType={'button'} onClick={hideModal} isDisabled={isSubmitting}>
								{t('common:actionCancel')}
							</Button>

							<Button type={'primary'} htmlType={'submit'} isDisabled={isSubmitting || !isDirty}>
								{t('pages:users:actionSave')}
							</Button>
						</div>
					</form>
				</>
			) : (
				<>
					<p>{t('pages:users:modalPasswordChangeDoneText')}</p>
					<div className="button-row button-row--right">
						<Button type={'primary'} onClick={hideModal} className={'button-ok'}>
							{t('common:actionOk')}
						</Button>
					</div>
				</>
			)}
		</>
	)
}

export default ModalChangePassword
