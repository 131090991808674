import React, { useEffect, useState } from 'react'
import Button from '../../../../components/UI/Button/Button'
import { useTranslation } from 'react-i18next'
import { modalConfirmShowHandler } from '../../../../store/actionCreators/UI/modalConfirm'
import { notificationAddHandler } from '../../../../store/actionCreators/UI/notification'
import { connect, ConnectedProps } from 'react-redux'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import AccordionLicensePackage from './components/AccordionLicensePackage'
import { ILicensePackage, ILicensePackageUpdate } from '../../../../interfaces/cabinet/licensePackage'
import GroupLicensePackage from './components/GroupLicensePackage'
import GroupLicensePackageItem from './components/GroupLicensePackageItem'
import InputDatePicker from '../../../../components/UI/InputDatePicker/InputDatePicker'
import { TDatePickerDate } from '../../../../interfaces/helpers'
import {
	ACTIVE,
	BLOCK,
	DATE_FORMAT_API_SHORT,
	declensionString,
	getDate,
	getPackageStatusName,
	NEW
} from '../../../../helpers/helpers'
import { IProduct } from '../../../../interfaces/cabinet/products'
import Select from 'react-select'
import { selectProps } from '../../../../components/UI/Select/helpers'
import { IVersion } from '../../../../interfaces/cabinet/versions'
import DateRangeLicensePackage from './components/DateRangeLicensePackage'
import Checkbox from '../../../../components/UI/Checkbox/Checkbox'
import MultiselectLicensePackage from './components/MultiselectLicensePackage'
import { IHardwareKey } from '../../../../interfaces/cabinet/hardwareKeys'
import Autocomplete from '../../../../components/UI/Autocomplete/Autocomplete'
import dayjs from 'dayjs'
import InputMask from '../../../../components/UI/InputMask/InputMask'

type TProps = ConnectedProps<typeof connector> & {
	hideModal: () => void
	onConfirm: (data: ILicensePackageUpdate) => Promise<any>
	onRemove: (id: string) => Promise<any>
	onBlock: (id: string) => Promise<any>
	onUnblock: (id: string) => Promise<any>
	licensePackage: ILicensePackage
	products: Array<IProduct>
	versions: Array<IVersion>
	hardwareKeys: Array<IHardwareKey>
}

interface IFormGroupUrgent {
	id: string
	versionId: string
	versionName?: string
	// dateFrom: string
	// dateTo: string
	dates: Array<string>
	count: string
	productId?: string
	productName?: string
	type?: string
	termless?: boolean
}

interface IFormGroupUrgentRequest extends Omit<IFormGroupUrgent, 'dates'> {
	dateFrom: string
	dateTo: string
}

interface IFormGroupTermless {
	id: string
	versionId: string
	versionName?: string
	count: string
	productId?: string
	productName?: string
	type?: string
	termless?: boolean
}

interface IFormGroupTermlessRequest extends Omit<IFormGroupTermless, 'dates'> {
	// dateFrom: string
	// dateTo: string
}

interface IFormGroupHardware {
	id: string
	versionId: string
	versionName?: string
	// dateFrom: string
	// dateTo: string
	dates: Array<string>
	termless: boolean
	hardwareKeys: Array<string>
	productId?: string
	productName?: string
	type?: string
}

interface IFormGroupHardwareRequest extends Omit<IFormGroupHardware, 'dates'> {
	dateFrom?: string
	dateTo?: string
}

interface IFormGroupNet {
	id: string
	versionId: string
	versionName?: string
	// dateFrom: string
	// dateTo: string
	dates: Array<string>
	hardwareKeys: Array<string>
	count: string
	termless: boolean
	productId?: string
	productName?: string
	type?: string
}

interface IFormGroupNetRequest extends Omit<IFormGroupNet, 'dates'> {
	dateFrom?: string
	dateTo?: string
}

interface IFormFields {
	contractDate: string
	state: string
	productId: string
	urgent: Array<IFormGroupUrgent>
	termless: Array<IFormGroupTermless>
	hardware: Array<IFormGroupHardware>
	net: Array<IFormGroupNet>
}

const ModalEditLicensePackage = ({
	licensePackage,
	products,
	versions,
	hardwareKeys,
	showModalConfirm,
	onConfirm,
	onBlock,
	onUnblock,
	onRemove,
	hideModal,
	notificationAdd
}: TProps) => {
	const { t } = useTranslation()

	const getDeclensionTitle = (number: number): string =>
		number > 0
			? `${number} ${declensionString(
					t('pages:licensePackages:declension1'),
					t('pages:licensePackages:declension2'),
					t('pages:licensePackages:declension3'),
					number
			  )}`
			: t('common:no')

	const isEditable = licensePackage.state === NEW

	/*
	 * Срочные - type: student && termless: false
	 * */
	const urgentDefaults: Array<IFormGroupUrgent> =
		licensePackage.licenseGroupList
			.filter(item => item.type === 'student' && !item.termless)
			.map(item => {
				return {
					id: item.id,
					versionId: item.versionId,
					// dateFrom: item.dateFrom,
					// dateTo: item.dateTo,
					dates: [item.dateFrom || '', item.dateTo || ''],
					count: `${item.count}`
				}
			}) || []

	/*
	 * Бессрочные - type: teacher && termless: true
	 * */
	const termlessDefaults: Array<IFormGroupTermless> =
		licensePackage.licenseGroupList
			.filter(item => item.type === 'teacher' && item.termless)
			.map(item => {
				return {
					id: item.id,
					versionId: item.versionId,
					count: `${item.count}`
				}
			}) || []

	/*
	 * Аппаратные - type: hardware && count < 2
	 * */
	const hardwareDefaults: Array<IFormGroupHardware> =
		licensePackage.licenseGroupList
			.filter(item => item.type === 'hardware' && item.count < 2)
			.map(item => {
				return {
					id: item.id,
					versionId: item.versionId,
					// dateFrom: item.dateFrom,
					// dateTo: item.dateTo,
					dates: [item.dateFrom || '', item.dateTo || ''],
					termless: item.termless,
					hardwareKeys: item.hardwareKeys
				}
			}) || []

	/*
	 * Сетевые - type: hardware && count >= 2
	 * */
	const netDefaults =
		licensePackage.licenseGroupList
			.filter(item => item.type === 'hardware' && item.count >= 2)
			.map(item => {
				return {
					id: item.id,
					versionId: item.versionId,
					// dateFrom: item.dateFrom,
					// dateTo: item.dateTo,
					dates: [item.dateFrom || '', item.dateTo || ''],
					hardwareKeys: item.hardwareKeys,
					count: `${item.count}`,
					termless: item.termless
				}
			}) || []

	const { register, errors, handleSubmit, watch, formState, control, setValue } = useForm<IFormFields>({
		defaultValues: {
			contractDate: licensePackage.contractDate,
			state: licensePackage.state,
			productId: licensePackage.licenseGroupList[0].productId,
			urgent: urgentDefaults,
			termless: termlessDefaults,
			hardware: hardwareDefaults,
			net: netDefaults
		}
	})

	const { fields: fieldsUrgent, append: appendUrgent, remove: removeUrgent } = useFieldArray<
		IFormGroupUrgent,
		'groupId'
	>({
		control,
		name: 'urgent',
		keyName: 'groupId'
	})

	const { fields: fieldsTermless, append: appendTermless, remove: removeTermless } = useFieldArray<
		IFormGroupTermless,
		'groupId'
	>({
		control,
		name: 'termless',
		keyName: 'groupId'
	})

	const { fields: fieldsHardware, append: appendHardware, remove: removeHardware } = useFieldArray<
		IFormGroupHardware,
		'groupId'
	>({
		control,
		name: 'hardware',
		keyName: 'groupId'
	})

	const { fields: fieldsNet, append: appendNet, remove: removeNet } = useFieldArray<IFormGroupNet, 'groupId'>({
		control,
		name: 'net',
		keyName: 'groupId'
	})

	const [contractDate, setContractDate] = useState<TDatePickerDate>(
		licensePackage.contractDate ? dayjs(licensePackage.contractDate).toDate() : null
	)

	const [currentVersions, setCurrentVersions] = useState<IVersion[]>(
		versions.filter(item => item.product.id === licensePackage.licenseGroupList[0].productId) || []
	)

	const { isDirty } = formState
	const [isSubmitting, setIsSubmitting] = useState(false)

	const cancelHandler = () => {
		if (isDirty) {
			showModalConfirm({
				type: 'cancel',
				onConfirm: hideModal
			})
		} else {
			hideModal()
		}
	}

	const onSubmit = async (data: IFormFields) => {
		setIsSubmitting(true)

		try {
			const product: IProduct = products.filter(el => el.id === data.productId)[0]

			let urgent: IFormGroupUrgentRequest[] = []
			let termless: IFormGroupTermlessRequest[] = []
			let hardware: IFormGroupHardwareRequest[] = []
			let net: IFormGroupNetRequest[] = []

			// URGENT
			if (data.urgent) {
				urgent = data.urgent.map(item => {
					return {
						// ...item,
						id: item.id || '',
						dateFrom: item.dates ? item.dates[0] : '',
						dateTo: item.dates ? item.dates[1] : '',
						count: item.count,
						versionId: item.versionId,
						versionName: versions.filter(el => el.id === item.versionId)[0].name,
						productId: product.id,
						productName: product.name,
						termless: false,
						type: 'student'
					}
				})
			}

			// TERMLESS
			if (data.termless) {
				termless = data.termless.map(item => {
					return {
						...item,
						id: item.id || '',
						versionName: versions.filter(el => el.id === item.versionId)[0].name,
						productId: product.id,
						productName: product.name,
						termless: true,
						type: 'teacher'
					}
				})
			}

			// HARDWARE
			if (data.hardware) {
				hardware = data.hardware.map(item => {
					return {
						// ...item,
						id: item.id || '',
						dateFrom: item.termless && item.dates ? item.dates[0] : '',
						dateTo: item.termless && item.dates ? item.dates[1] : '',
						versionId: item.versionId,
						hardwareKeys: item.hardwareKeys,
						versionName: versions.filter(el => el.id === item.versionId)[0].name,
						productId: product.id,
						productName: product.name,
						termless: !item.termless,
						type: 'hardware'
					}
				})
			}

			// NET
			if (data.net) {
				net = data.net.map(item => {
					return {
						// ...item,
						id: item.id || '',
						dateFrom: item.termless && item.dates ? item.dates[0] : '',
						dateTo: item.termless && item.dates ? item.dates[1] : '',
						count: item.count,
						versionId: item.versionId,
						hardwareKeys: item.hardwareKeys,
						versionName: versions.filter(el => el.id === item.versionId)[0].name,
						productId: product.id,
						productName: product.name,
						termless: !item.termless,
						type: 'hardware'
					}
				})
			}

			const result: ILicensePackageUpdate = {
				id: licensePackage.id,
				state: licensePackage.state,
				accountId: licensePackage.accountId,
				contractDate: data.contractDate,
				licenseGroupList: [...urgent, ...termless, ...hardware, ...net]
			}

			const response = await onConfirm(result)

			if (response?.status === 200) {
				hideModal()
			}
		} finally {
			setIsSubmitting(false)
		}
	}

	const onRemoveHandler = async () => {
		showModalConfirm({
			type: 'remove',
			elementText: t('pages:licensePackages:modalRemoveText'),
			onConfirm: async () => {
				try {
					await onRemove(licensePackage.id)
					hideModal()
				} finally {
				}
			}
		})
	}

	const onBlockHandler = async () => {
		showModalConfirm({
			type: 'lock',
			elementText: t('pages:licensePackages:modalBlockText'),
			onConfirm: async () => {
				try {
					await onBlock(licensePackage.id)
					hideModal()
				} finally {
				}
			}
		})
	}

	const onUnblockHandler = async () => {
		showModalConfirm({
			type: 'unlock',
			elementText: t('pages:licensePackages:modalUnblockText'),
			onConfirm: async () => {
				try {
					await onUnblock(licensePackage.id)
					hideModal()
				} finally {
				}
			}
		})
	}

	const addGroupUrgent = () => {
		if (watch('productId')) {
			appendUrgent({
				id: '',
				versionId: '',
				// dateFrom: '',
				// dateTo: '',
				// dates: [],
				count: '0'
			})
		} else {
			notificationAdd(t('pages:licensePackages:notificationSelectProduct'), 'warning')
		}
	}

	const removeGroupUrgent = (idx: number) => {
		removeUrgent(idx)
	}

	const addGroupTermless = () => {
		if (watch('productId')) {
			appendTermless({
				id: '',
				versionId: '',
				count: '0'
			})
		} else {
			notificationAdd(t('pages:licensePackages:notificationSelectProduct'), 'warning')
		}
	}

	const removeGroupTermless = (idx: number) => removeTermless(idx)

	const addGroupHardware = () => {
		if (watch('productId')) {
			appendHardware({
				id: '',
				versionId: ''
				// dateFrom: '',
				// dateTo: '',
				// dates: []
				// termless: false,
				// hardwareKeys: []
			})
		} else {
			notificationAdd(t('pages:licensePackages:notificationSelectProduct'), 'warning')
		}
	}

	const removeGroupHardware = (idx: number) => removeHardware(idx)

	const addGroupNet = () => {
		if (watch('productId')) {
			appendNet({
				id: '',
				versionId: '',
				// dateFrom: '',
				// dateTo: '',
				// dates: [],
				// hardwareKeys: [],
				count: '2'
				// termless: false
			})
		} else {
			notificationAdd(t('pages:licensePackages:notificationSelectProduct'), 'warning')
		}
	}

	const removeGroupNet = (idx: number) => removeNet(idx)

	useEffect(() => {
		setValue('productId', licensePackage.licenseGroupList[0].productId)
	}, [setValue, licensePackage.licenseGroupList])

	return (
		<>
			<button className="modal-close" onClick={cancelHandler} disabled={isSubmitting} />

			<h3 className="modal-title">{t('common:appName')}</h3>
			<p className="modal-footnote">{t('pages:licensePackages:modalUpdateText')}</p>

			<form className="form" onSubmit={handleSubmit(onSubmit)}>
				<div className="form-item">
					{t('pages:licensePackages:fieldState')}: {t(getPackageStatusName(licensePackage.state))}
				</div>

				<div className="form-item">
					<Controller
						render={({ name, onChange }) => (
							<InputDatePicker
								name={name}
								placeholderText={t('pages:licensePackages:fieldContractDate')}
								selected={contractDate}
								onChange={(date: TDatePickerDate) => {
									setContractDate(date)
									onChange(getDate(date, DATE_FORMAT_API_SHORT))
								}}
								errors={errors.contractDate}
								isError={!!errors.contractDate}
								isDisabled={isSubmitting || !isEditable}
							/>
						)}
						control={control}
						name={'contractDate'}
						rules={{
							required: true
						}}
						defaultValue={getDate(contractDate, DATE_FORMAT_API_SHORT) || ''}
					/>
				</div>

				<div className="form-item">
					<Controller
						render={({ onChange, name }) => (
							<Select
								name={name}
								defaultValue={
									products
										.filter(item => item.id === licensePackage.licenseGroupList[0].productId)
										.map(item => {
											return {
												label: item.name,
												value: item.id
											}
										})[0]
								}
								options={products.map(item => {
									return {
										label: item.name,
										value: item.id
									}
								})}
								placeholder={t('pages:licensePackages:fieldProduct')}
								isDisabled={
									isSubmitting ||
									fieldsUrgent.length > 0 ||
									fieldsTermless.length > 0 ||
									fieldsHardware.length > 0 ||
									fieldsNet.length > 0 ||
									!isEditable
								}
								isError={!!errors.productId}
								errors={errors.productId}
								onChange={option => {
									if (option) {
										onChange(option.value)

										setCurrentVersions(versions.filter(item => item.product.id === option.value) || [])
									}
								}}
								{...selectProps}
							/>
						)}
						name={'productId'}
						control={control}
						rules={{
							required: true
						}}
						defaultValue={licensePackage.licenseGroupList[0].productId}
					/>
				</div>

				<div className={`form-license-pack ${!watch('productId') ? `form-license-pack--disabled` : ``}`}>
					<AccordionLicensePackage
						title={t('pages:licensePackages:urgentGroup')}
						info={getDeclensionTitle(fieldsUrgent.length)}
						isDisabled={!isEditable && !fieldsUrgent.length}
					>
						<GroupLicensePackage onAdd={addGroupUrgent} isDisabled={!isEditable}>
							{fieldsUrgent.length > 0 &&
								fieldsUrgent.map((item, idx) => (
									<GroupLicensePackageItem
										key={item.groupId}
										title={t('pages:licensePackages:groupTitle', {
											number: idx + 1
										})}
										onRemove={() => removeGroupUrgent(idx)}
										isDisabled={!isEditable}
									>
										<input
											type="hidden"
											name={`urgent[${idx}].id`}
											defaultValue={fieldsUrgent?.[idx]?.id || ''}
											ref={register()}
										/>

										<div className="form-item">
											<Controller
												render={({ onChange, name }) => (
													<Select
														name={name}
														defaultValue={
															currentVersions
																.filter(item => item.id === fieldsUrgent?.[idx]?.versionId)
																.map(item => {
																	return {
																		label: item.name,
																		value: item.id
																	}
																})[0] || undefined
														}
														options={currentVersions.map(item => {
															return {
																label: item.name,
																value: item.id
															}
														})}
														placeholder={t('pages:licensePackages:fieldVersion')}
														isDisabled={isSubmitting || !isEditable}
														isError={!!errors.urgent?.[idx]?.versionId}
														errors={errors.urgent?.[idx]?.versionId}
														onChange={option => option && onChange(option.value)}
														{...selectProps}
													/>
												)}
												name={`urgent[${idx}].versionId`}
												control={control}
												rules={{
													required: true
												}}
												defaultValue={fieldsUrgent?.[idx]?.versionId || ''}
											/>
										</div>

										<div className="form-row">
											<Controller
												render={({ onChange }) => (
													<DateRangeLicensePackage
														isDisabled={isSubmitting || !isEditable}
														errors={errors?.urgent?.[idx]?.dates}
														onChange={onChange}
														defaultValue={fieldsUrgent?.[idx]?.dates}
													/>
												)}
												name={`urgent[${idx}].dates`}
												control={control}
												rules={{
													required: true,
													validate: {
														dateRange: values => {
															if (values[0] && values[1]) return true
															return false
														}
													}
												}}
												defaultValue={fieldsUrgent?.[idx]?.dates || []}
											/>
										</div>

										<div className="form-row">
											<div className="form-col">
												<div className="form-item">
													<Controller
														render={({ onChange }) => (
															<InputMask
																defaultValue={fieldsUrgent?.[idx]?.count || '0'}
																type={'text'}
																name={`urgent[${idx}].count`}
																placeholder={t('pages:licensePackages:fieldCount')}
																isDisabled={isSubmitting || !isEditable}
																isError={!!errors?.urgent?.[idx]?.count}
																min={0}
																max={9999}
																errors={errors?.urgent?.[idx]?.count}
																maskProps={{
																	mask: Number,
																	min: 0,
																	max: 9999
																}}
																onChange={onChange}
															/>
														)}
														name={`urgent[${idx}].count`}
														control={control}
														rules={{
															required: true,
															min: 0,
															max: 9999
														}}
														defaultValue={fieldsUrgent?.[idx]?.count || '0'}
													/>
												</div>
											</div>
										</div>
									</GroupLicensePackageItem>
								))}
						</GroupLicensePackage>
					</AccordionLicensePackage>

					<AccordionLicensePackage
						title={t('pages:licensePackages:termlessGroup')}
						info={getDeclensionTitle(fieldsTermless.length)}
						isDisabled={!isEditable && !fieldsTermless.length}
					>
						<GroupLicensePackage onAdd={addGroupTermless} isDisabled={!isEditable}>
							{fieldsTermless.length > 0 &&
								fieldsTermless.map((item, idx) => (
									<GroupLicensePackageItem
										key={item.groupId}
										title={t('pages:licensePackages:groupTitle', {
											number: idx + 1
										})}
										onRemove={() => removeGroupTermless(idx)}
										isDisabled={!isEditable}
									>
										<input
											type="hidden"
											name={`termless[${idx}].id`}
											defaultValue={fieldsTermless?.[idx]?.id || ''}
											ref={register()}
										/>

										<div className="form-item">
											<Controller
												render={({ onChange, name }) => (
													<Select
														name={name}
														defaultValue={
															currentVersions
																.filter(item => item.id === fieldsTermless?.[idx]?.versionId)
																.map(item => {
																	return {
																		label: item.name,
																		value: item.id
																	}
																})[0] || undefined
														}
														options={currentVersions.map(item => {
															return {
																label: item.name,
																value: item.id
															}
														})}
														placeholder={t('pages:licensePackages:fieldVersion')}
														isDisabled={isSubmitting || !isEditable}
														isError={!!errors.termless?.[idx]?.versionId}
														errors={errors.termless?.[idx]?.versionId}
														onChange={option => option && onChange(option.value)}
														{...selectProps}
													/>
												)}
												name={`termless[${idx}].versionId`}
												control={control}
												rules={{
													required: true
												}}
												defaultValue={fieldsTermless?.[idx]?.versionId || ''}
											/>
										</div>

										<div className="form-row">
											<div className="form-col">
												<div className="form-item">
													<Controller
														render={({ onChange }) => (
															<InputMask
																defaultValue={fieldsTermless?.[idx]?.count || '0'}
																type={'text'}
																name={`termless[${idx}].count`}
																placeholder={t('pages:licensePackages:fieldCount')}
																isDisabled={isSubmitting || !isEditable}
																isError={!!errors?.termless?.[idx]?.count}
																min={0}
																max={9999}
																errors={errors?.termless?.[idx]?.count}
																maskProps={{
																	mask: Number,
																	min: 0,
																	max: 9999
																}}
																onChange={onChange}
															/>
														)}
														name={`termless[${idx}].count`}
														control={control}
														rules={{
															required: true,
															min: 0,
															max: 9999
														}}
														defaultValue={fieldsTermless?.[idx]?.count || '0'}
													/>
												</div>
											</div>
										</div>
									</GroupLicensePackageItem>
								))}
						</GroupLicensePackage>
					</AccordionLicensePackage>

					<AccordionLicensePackage
						title={t('pages:licensePackages:hardwareGroup')}
						info={getDeclensionTitle(fieldsHardware.length)}
						isDisabled={!isEditable && !fieldsHardware.length}
					>
						<GroupLicensePackage onAdd={addGroupHardware} isDisabled={!isEditable}>
							{fieldsHardware.length > 0 &&
								fieldsHardware.map((item, idx) => (
									<GroupLicensePackageItem
										key={item.groupId}
										title={t('pages:licensePackages:groupTitle', {
											number: idx + 1
										})}
										onRemove={() => removeGroupHardware(idx)}
										isDisabled={!isEditable}
									>
										<input
											type="hidden"
											name={`hardware[${idx}].id`}
											defaultValue={fieldsHardware?.[idx]?.id || ''}
											ref={register()}
										/>

										<div className="form-item">
											<Controller
												render={({ onChange, name }) => (
													<Select
														name={name}
														defaultValue={
															currentVersions
																.filter(item => item.id === fieldsHardware?.[idx]?.versionId)
																.map(item => {
																	return {
																		label: item.name,
																		value: item.id
																	}
																})[0] || undefined
														}
														options={currentVersions.map(item => {
															return {
																label: item.name,
																value: item.id
															}
														})}
														placeholder={t('pages:licensePackages:fieldVersion')}
														isDisabled={isSubmitting || !isEditable}
														isError={!!errors.hardware?.[idx]?.versionId}
														errors={errors.hardware?.[idx]?.versionId}
														onChange={option => option && onChange(option.value)}
														{...selectProps}
													/>
												)}
												name={`hardware[${idx}].versionId`}
												control={control}
												rules={{
													required: true
												}}
												defaultValue={fieldsHardware?.[idx]?.versionId || ''}
											/>
										</div>

										<Controller
											render={({ onChange }) => (
												<MultiselectLicensePackage
													data={hardwareKeys}
													onChange={values => onChange(values.length > 0 ? values : '')}
													isDisabled={isSubmitting || !isEditable}
													isError={!!errors.hardware?.[idx]?.hardwareKeys}
													errors={errors.hardware?.[idx]?.hardwareKeys}
													defaultValue={fieldsHardware?.[idx]?.hardwareKeys || []}
												/>
											)}
											name={`hardware[${idx}].hardwareKeys`}
											control={control}
											rules={{
												required: true
											}}
											defaultValue={
												typeof fieldsHardware[idx].hardwareKeys === 'undefined'
													? ''
													: fieldsHardware?.[idx]?.hardwareKeys
											}
										/>

										<div className="form-row">
											<div className="form-col">
												<div className="form-item form-item--checkbox-center">
													<Checkbox
														name={`hardware[${idx}].termless`}
														reference={register()}
														isDisabled={isSubmitting || !isEditable}
														isError={!!errors?.hardware?.[idx]?.termless}
														errors={errors?.hardware?.[idx]?.termless}
														defaultChecked={
															typeof fieldsHardware[idx].termless === 'undefined'
																? false
																: !fieldsHardware?.[idx]?.termless
														}
													>
														{t('pages:licensePackages:fieldTermless')}
													</Checkbox>
												</div>
											</div>
										</div>

										{watch(`hardware[${idx}].termless`) && (
											<div className="form-row">
												<Controller
													render={({ onChange }) => (
														<DateRangeLicensePackage
															isDisabled={isSubmitting || !isEditable}
															errors={errors?.hardware?.[idx]?.dates}
															onChange={onChange}
															defaultValue={fieldsHardware?.[idx]?.dates}
														/>
													)}
													name={`hardware[${idx}].dates`}
													control={control}
													rules={{
														// required: watch(`hardware[${idx}].termless`),
														validate: {
															dateRange: values => {
																if (watch(`hardware[${idx}].termless`) && (!values[0] || !values[1])) return false
																return true
															}
															// dateRange: values => {
															// 	if (!watch(`hardware[${idx}].termless`)) return true
															// 	if (values[0] && values[1]) return true
															// 	return false
															// }
														}
													}}
													defaultValue={fieldsHardware?.[idx]?.dates || ''}
												/>
											</div>
										)}
									</GroupLicensePackageItem>
								))}
						</GroupLicensePackage>
					</AccordionLicensePackage>

					<AccordionLicensePackage
						title={t('pages:licensePackages:netGroup')}
						info={getDeclensionTitle(fieldsNet.length)}
						isDisabled={!isEditable && !fieldsNet.length}
					>
						<GroupLicensePackage onAdd={addGroupNet} isDisabled={!isEditable}>
							{fieldsNet.length > 0 &&
								fieldsNet.map((item, idx) => (
									<GroupLicensePackageItem
										key={item.groupId}
										title={t('pages:licensePackages:groupTitle', {
											number: idx + 1
										})}
										onRemove={() => removeGroupNet(idx)}
										isDisabled={!isEditable}
									>
										<input
											type="hidden"
											name={`net[${idx}].id`}
											defaultValue={fieldsNet?.[idx]?.id || ''}
											ref={register()}
										/>

										<div className="form-item">
											<Controller
												render={({ onChange, name }) => (
													<Select
														name={name}
														defaultValue={
															currentVersions
																.filter(item => item.id === fieldsNet?.[idx]?.versionId)
																.map(item => {
																	return {
																		label: item.name,
																		value: item.id
																	}
																})[0] || undefined
														}
														options={currentVersions.map(item => {
															return {
																label: item.name,
																value: item.id
															}
														})}
														placeholder={t('pages:licensePackages:fieldVersion')}
														isDisabled={isSubmitting || !isEditable}
														isError={!!errors.net?.[idx]?.versionId}
														errors={errors.net?.[idx]?.versionId}
														onChange={option => option && onChange(option.value)}
														{...selectProps}
													/>
												)}
												name={`net[${idx}].versionId`}
												control={control}
												rules={{
													required: true
												}}
												defaultValue={fieldsNet?.[idx]?.versionId || ''}
											/>
										</div>

										<div className="form-item">
											<Controller
												render={({ onChange, name }) => (
													<Autocomplete
														data={hardwareKeys.map(item => {
															return {
																label: item.code,
																value: item.id
															}
														})}
														defaultValue={
															hardwareKeys.find(item => item.id === fieldsNet?.[idx]?.hardwareKeys?.[0])?.code || ''
														}
														name={name}
														placeholder={t('pages:licensePackages:fieldHardwareKeys')}
														isDisabled={isSubmitting || !isEditable}
														isError={!!errors.net?.[idx]?.hardwareKeys}
														errors={errors.net?.[idx]?.hardwareKeys}
														onChange={() => onChange('')}
														onSelect={value => onChange([value])}
														onClear={() => onChange('')}
													/>
												)}
												name={`net[${idx}].hardwareKeys`}
												control={control}
												rules={{
													required: true
												}}
												defaultValue={
													typeof fieldsNet[idx].hardwareKeys === 'undefined' ? '' : fieldsNet?.[idx]?.hardwareKeys
												}
											/>
										</div>

										<div className="form-row">
											<div className="form-col">
												<div className="form-item">
													<Controller
														render={({ onChange }) => (
															<InputMask
																defaultValue={fieldsNet?.[idx]?.count || '2'}
																type={'text'}
																name={`net[${idx}].count`}
																placeholder={t('pages:licensePackages:fieldCount')}
																isDisabled={isSubmitting || !isEditable}
																isError={!!errors?.net?.[idx]?.count}
																min={2}
																max={9999}
																errors={errors?.net?.[idx]?.count}
																maskProps={{
																	mask: Number,
																	min: 2,
																	max: 9999
																}}
																onChange={onChange}
															/>
														)}
														name={`net[${idx}].count`}
														control={control}
														rules={{
															required: true,
															min: 2,
															max: 9999
														}}
														defaultValue={fieldsNet?.[idx]?.count || '0'}
													/>
												</div>
											</div>

											<div className="form-col">
												<div className="form-item form-item--checkbox-center">
													<Checkbox
														name={`net[${idx}].termless`}
														reference={register()}
														isDisabled={isSubmitting || !isEditable}
														isError={!!errors?.net?.[idx]?.termless}
														errors={errors?.net?.[idx]?.termless}
														defaultChecked={
															typeof fieldsNet[idx].termless === 'undefined' ? false : !fieldsNet?.[idx]?.termless
														}
													>
														{t('pages:licensePackages:fieldTermless')}
													</Checkbox>
												</div>
											</div>
										</div>

										{watch(`net[${idx}].termless`) && (
											<div className="form-row">
												<Controller
													render={({ onChange }) => (
														<DateRangeLicensePackage
															isDisabled={isSubmitting || !isEditable || !watch(`net[${idx}].termless`)}
															errors={errors?.net?.[idx]?.dates}
															onChange={onChange}
															defaultValue={fieldsNet?.[idx]?.dates}
														/>
													)}
													name={`net[${idx}].dates`}
													control={control}
													rules={{
														// required: watch(`net[${idx}].termless`),
														validate: {
															// dateRange: values => {
															// 	if (!watch(`net[${idx}].termless`)) return true
															// 	if (values[0] && values[1]) return true
															// 	return false
															// }
															dateRange: values => {
																if (watch(`net[${idx}].termless`) && (!values[0] || !values[1])) return false
																return true
															}
														}
													}}
													defaultValue={fieldsNet?.[idx]?.dates || ''}
												/>
											</div>
										)}
									</GroupLicensePackageItem>
								))}
						</GroupLicensePackage>
					</AccordionLicensePackage>
				</div>

				<div className="form-controls form-controls--justify">
					{isEditable && (
						<Button type={'secondary'} htmlType={'button'} isDisabled={isSubmitting} onClick={onRemoveHandler}>
							{t('common:actionRemove')}
						</Button>
					)}

					{licensePackage.state === BLOCK && (
						<Button type={'secondary'} htmlType={'button'} isDisabled={isSubmitting} onClick={onUnblockHandler}>
							{t('common:actionUnlock')}
						</Button>
					)}

					{licensePackage.state === ACTIVE && (
						<Button type={'secondary'} htmlType={'button'} isDisabled={isSubmitting} onClick={onBlockHandler}>
							{t('common:actionLock')}
						</Button>
					)}

					<Button type={'secondary'} htmlType={'button'} onClick={cancelHandler} isDisabled={isSubmitting}>
						{t('common:actionCancel')}
					</Button>

					{isEditable && (
						<Button
							type={'secondary'}
							htmlType={'submit'}
							isDisabled={
								isSubmitting ||
								!isDirty ||
								(!fieldsUrgent.length && !fieldsTermless.length && !fieldsHardware.length && !fieldsNet.length)
							}
						>
							{t('common:actionSave')}
						</Button>
					)}
				</div>
			</form>
		</>
	)
}

const mapDispatch = {
	showModalConfirm: modalConfirmShowHandler,
	notificationAdd: notificationAddHandler
}

const connector = connect(null, mapDispatch)

export default connector(ModalEditLicensePackage)
