import React, { useState } from 'react'
import InputError from '../InputError/InputError'

interface IProps {
	name: string
	placeholder?: string
	currentValue?: string
	isError?: boolean
	isRequired?: boolean
	isDisabled?: boolean
	onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
	reference?: any
	defaultValue?: string
	errors?: any
	rules?: {
		minLength?: number
		maxLength?: number
	}
	className?: string
}

const Textarea = ({
	name,
	placeholder,
	currentValue,
	isError,
	isRequired,
	isDisabled,
	onChange,
	reference,
	defaultValue,
	errors,
	rules,
	className
}: IProps) => {
	const [localValue, setLocalValue] = useState(currentValue || defaultValue)

	const classNames = ['field']

	if (className) classNames.push(className)
	if (isError) classNames.push('field--error')
	if (isRequired) classNames.push('field--required')
	if (isDisabled) classNames.push('field--disabled')
	if (currentValue || defaultValue || localValue) classNames.push('field--notempty')

	const onChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setLocalValue(e.currentTarget.value)

		if (typeof onChange === 'function') onChange(e)
	}

	return (
		<>
			<label className={classNames.join(' ')}>
				<textarea
					className="field-input"
					name={name}
					ref={reference}
					onChange={onChangeHandler}
					disabled={isDisabled}
					defaultValue={defaultValue}
				/>
				{placeholder && <span className="field-label">{placeholder}</span>}
			</label>

			{isError && <InputError errors={errors} rules={rules} />}
		</>
	)
}

export default Textarea
