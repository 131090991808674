import axios from 'axios'
import { API_CABINET_PATH, APIServices } from '../../helpers/api'
import { setErrorHandler } from '../../store/actionCreators/error'
import {
	IOrgSetting,
	IOrgSettingAPIRequest,
	IOrgSettingOrganizationAPIRequest,
	IOrgSettingUpdate
} from '../../interfaces/cabinet/orgSettings'
import { APIResponse, AppThunk } from '../../interfaces/helpers'
import { serviceHandlerErrorOptions } from '../helpers'

export function orgSettingGetAllHandler(
	params: IOrgSettingAPIRequest,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<APIResponse<IOrgSetting[]>> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<APIResponse<IOrgSetting[]>>({
				method: 'GET',
				url: `${API_CABINET_PATH}/orgSetting`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params
			})
			return response
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}

export function orgSettingOrganizationGetAllHandler(
	params: IOrgSettingOrganizationAPIRequest,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IOrgSetting[]> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IOrgSetting[]>({
				method: 'GET',
				url: `${API_CABINET_PATH}/orgSetting/listOrgSettings`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params
			})
			return response
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}

export function updateOrgSettingByIdHandler(
	id: string,
	accountId: string,
	data: IOrgSettingUpdate,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IOrgSetting> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IOrgSetting>({
				method: 'PUT',
				url: `${API_CABINET_PATH}/orgSetting/${id}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id,
					accountId
				},
				data
			})
			return response
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}
