import React, { FC, useCallback, useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import Pagetitle from '../../../components/UI/Pagetitle/Pagetitle'
import SearchField from '../../../components/UI/SearchField/SearchField'
import Pagination from '../../../components/UI/Pagination/Pagination'
import { modalShowHandler } from '../../../store/actionCreators/UI/modal'
import { IPagination, TSortColumnsTable, TStatus } from '../../../interfaces/helpers'
import { INFINITE_NUMBER, REGEX_V4 } from '../../../helpers/helpers'
import {
    IOrgSetting,
    IOrgSettingAPIRequest,
    IOrgSettingUpdate,
    TOrgSettingSortFields
} from '../../../interfaces/cabinet/orgSettings'
import { orgSettingGetAllHandler, updateOrgSettingByIdHandler } from '../../../services/cabinet/orgSettings'
import NoResults from '../../../components/UI/NoResults/NoResults'
import Loading from '../../../components/UI/Loading/Loading'
import { selectProps, TOnChange } from '../../../components/UI/Select/helpers'
import { toggleLoaderHandler } from '../../../store/actionCreators/UI/loader'
import Error from '../../Error/Error'
import { versionGetAllByAccountIdHandler } from '../../../services/cabinet/versions'
import { IVersion } from '../../../interfaces/cabinet/versions'
import ModalEditSetting from './ModalEditOrgSetting'

interface IOrgSettingsProps {
    organizationId: string
    accountId: string
    crumbs?: Array<{
        to: string
        title: string
        onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
    }>
}

const OrgSettingsList: FC<IOrgSettingsProps & ConnectedProps<typeof connector>> = ({
    organizationId,
    accountId,
    crumbs,
    getVersionsByAccountId,
    getSettings,
    updateSetting,
    showModal,
    toggleLoader
}) => {
    const { t } = useTranslation()
    const [status, setStatus] = useState<TStatus>('loading')
    const [table, setTable] = useState<IOrgSetting[]>([])
    const [currentVersion, setCurrentVersion] = useState<IVersion>()
    const [pagination, setPagination] = useState<IPagination>({
        pageNo: 0,
        totalPages: 0,
        totalCount: 0
    })

    const [filterSettings, setFilterSettings] = useState<Omit<IOrgSettingAPIRequest, 'versionId'>>({
        filter: '',
        sortField: 'Key',
        desc: false,
        pageSize: INFINITE_NUMBER,
        pageNo: 0,
        accountId,
        showOverridableOnly: true,
    })

    const [versions, setVersions] = useState<IVersion[]>()

    const searchHandle = (value: string) => {
        setFilterSettings({
            ...filterSettings,
            filter: value,
            pageNo: 0
        })
    }

    const [sortColumns, setSortColumns] = useState<TSortColumnsTable>({
        Key: {
            desc: false,
            classNames: ['table-sort', 'table-sort--top']
        },
        Value: {
            desc: true,
            classNames: ['table-sort']
        },
        Description: {
            desc: true,
            classNames: ['table-sort']
        }
    })

    const paginationHandle = (pageNo: number) => {
        setFilterSettings({
            ...filterSettings,
            pageNo
        })
    }

    const sortHandle = (sortField: TOrgSettingSortFields) => {
        const updatedSortColumns = { ...sortColumns }

        Object.keys(updatedSortColumns).forEach((key: string) => {
            if (key !== sortField) {
                updatedSortColumns[key] = {
                    desc: true,
                    classNames: ['table-sort']
                }
            } else {
                updatedSortColumns[key].desc = !updatedSortColumns[key].desc
                updatedSortColumns[key].classNames = [
                    'table-sort',
                    updatedSortColumns[key].desc ? 'table-sort--bottom' : 'table-sort--top'
                ]
            }
        })

        setFilterSettings({
            ...filterSettings,
            sortField,
            desc: updatedSortColumns[sortField].desc
        })

        setSortColumns(updatedSortColumns)
    }

    const updateHandler = (item: IOrgSetting) => {
        if (!accountId) {
            return
        }

        showModal({
            type: 'description',
            Component: ModalEditSetting,
            ComponentProps: {
                item,
                onConfirm: async (data: IOrgSettingUpdate) => {
                    toggleLoader(true)

                    try {
                        const response = await updateSetting(item.id, accountId, data)

                        if (response?.status === 200) {
                            paginationHandle(0)
                        }

                        return response
                    } finally {
                        toggleLoader(false)
                    }
                }
            }
        })
    }

    const selectHandler: TOnChange = useCallback(option => {
        if (option) {
            const version = versions?.find(item => item.id === option.value)

            if (version) {
                setCurrentVersion(version)
            }
        }
    }, [versions]);

    useEffect(() => {
        const fetchData = async () => {
            if (!accountId.match(new RegExp(REGEX_V4))) {
                setStatus('error')
            } else {
                setFilterSettings(prev => ({ ...prev, accountId }))
                getVersionsByAccountId(accountId)
                    .then(ver => {
                        setVersions(ver.data)
                        setStatus('ready')
                    })
                    .catch(() => {
                        setStatus('error')
                    })
            }
        }

        fetchData()
    }, [getVersionsByAccountId, accountId])

    useEffect(() => {
        if (versions && versions.length > 0) {
            setCurrentVersion(versions[0])
        }
    }, [versions])

    useEffect(() => {
        if (currentVersion) {
            getSettings({ ...filterSettings, versionId: currentVersion.id }).then(response => {
                if (response?.status === 200) {
                    const { data, pageNo, totalPages, totalCount } = response.data

                    setTable(data)

                    setPagination({
                        pageNo,
                        totalPages,
                        totalCount
                    })
                }
            })
        }
    }, [getSettings, filterSettings, currentVersion])

    if (status === 'loading') return <Loading />
    if (status === 'error') return <Error />

    return (
        <>
            <Pagetitle
                title={t('pages:orgSettings:title')}
                crumbs={crumbs}
            />

            <div className="filters">
                <div className="filters-item filters-item--search filters-item--small">
                    <SearchField
                        onSubmit={searchHandle}
                        placeholder={t('pages:orgSettings:searchField')}
                        isDisabled={!currentVersion}
                    />
                </div>

                <div className="filters-item filters-item--select">
                    <Select
                        name={'version'}
                        onChange={selectHandler}
                        options={versions?.map(item => {
                            return {
                                label: `${item.name} (${item.product.name})`,
                                value: item.id
                            }
                        })}
                        defaultValue={
                            versions?.map(item => {
                                return {
                                    label: `${item.name} (${item.product.name})`,
                                    value: item.id
                                }
                            })[0]
                        }
                        placeholder={t('pages:orgSettings:version')}
                        isDisabled={!versions?.length}
                        {...selectProps}
                    />
                </div>
            </div>

            {table.length > 0 ? (
                <div className="pagination-wrapper">
                    <div className="table">
                        <table>
                            <colgroup>
                                <col width="30%" />
                                <col width="30%" />
                                <col width="40%" />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th onClick={() => sortHandle('Key')}>
                                        <div className={sortColumns.Key.classNames.join(' ')}>{t('pages:orgSettings:tableHeadingKey')}</div>
                                    </th>
                                    <th onClick={() => sortHandle('Value')}>
                                        <div className={sortColumns.Value.classNames.join(' ')}>
                                            {t('pages:orgSettings:tableHeadingValue')}
                                        </div>
                                    </th>
                                    <th onClick={() => sortHandle('Description')}>
                                        <div className={sortColumns.Description.classNames.join(' ')}>
                                            {t('pages:orgSettings:tableHeadingDescription')}
                                        </div>
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {table.map(item => {
                                    return (
                                        <tr key={item.id}>
                                            <td>
                                                <div className="table-break">
                                                    <button className="button-link" type="button" onClick={() => updateHandler(item)}>
                                                        {item.key}
                                                    </button>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="table-break">{item.value}</div>
                                            </td>
                                            <td>{item?.description || '-'}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>

                    <Pagination onClick={paginationHandle} {...pagination} />
                </div>
            ) : (
                <NoResults />
            )}
        </>
    )
}

const mapDispatch = {
    getVersionsByAccountId: versionGetAllByAccountIdHandler,
    getSettings: orgSettingGetAllHandler,
    updateSetting: updateOrgSettingByIdHandler,
    showModal: modalShowHandler,
    toggleLoader: toggleLoaderHandler
}

const connector = connect(undefined, mapDispatch)

export default connector(OrgSettingsList)
