import React, { useEffect, useState } from 'react'
import Pagetitle from '../../../components/UI/Pagetitle/Pagetitle'
import Button from '../../../components/UI/Button/Button'
import Pagination from '../../../components/UI/Pagination/Pagination'
import Loading from '../../../components/UI/Loading/Loading'
import { modalShowHandler } from '../../../store/actionCreators/UI/modal'
import { connect, ConnectedProps } from 'react-redux'
import { Link, useLocation, useParams } from 'react-router-dom'
import {
	IVersion,
	IVersionAPIRequest,
	IVersionCreate,
	IVersionUpdate,
	TVersionSortFields
} from '../../../interfaces/cabinet/versions'
import {
	connectVersionWithBuildsHandler,
	createVersionHandler,
	deleteVersionByIdHandler,
	updateVersionByIdHandler,
	versionGetAllHandler
} from '../../../services/cabinet/versions'
import SearchField from '../../../components/UI/SearchField/SearchField'
import ModalEditVersion from './ModalEditVersion'
import { IProduct } from '../../../interfaces/cabinet/products'
import { productGetAllHandler } from '../../../services/cabinet/products'
import { DEFAULT_PAGE_SIZE, INFINITE_NUMBER } from '../../../helpers/helpers'
import ModalAddVersion from './ModalAddVersion'
import { IPagination, TSortColumnsTable, TStatus } from '../../../interfaces/helpers'
import NoResults from '../../../components/UI/NoResults/NoResults'
import { useTranslation } from 'react-i18next'
import { toggleLoaderHandler } from '../../../store/actionCreators/UI/loader'
import ModalBuilds from './ModalBuilds/ModalBuilds'
import ModalFeatures from './ModalFeatures/ModalFeatures'
import { connectFeatureWithVersionHandler } from '../../../services/cabinet/features'

const Versions = ({
	getProducts,
	getVersions,
	createVersion,
	updateVersion,
	connectVersionWithBuilds,
	connectVersionWithFeatures,
	removeVersion,
	showModal,
	toggleLoader
}: ConnectedProps<typeof connector>) => {
	const { t } = useTranslation()
	const { productId } = useParams()
	const { pathname } = useLocation()
	const [filteredProduct, setFilteredProduct] = useState<IProduct>()
	const [status, setStatus] = useState<TStatus>('loading')
	const [products, setProducts] = useState<IProduct[]>([])
	const [table, setTable] = useState<IVersion[]>([])
	const [pagination, setPagination] = useState<IPagination>({
		pageNo: 0,
		totalPages: 0,
		totalCount: 0
	})

	const [filterVersions, setFilterVersions] = useState<IVersionAPIRequest>({
		filter: '',
		sortField: 'Name',
		desc: false,
		pageSize: DEFAULT_PAGE_SIZE,
		pageNo: 0,
		productId
	})

	const searchHandle = (value: string) => {
		setFilterVersions({
			...filterVersions,
			filter: value,
			pageNo: 0
		})
	}

	const [sortColumns, setSortColumns] = useState<TSortColumnsTable>({
		Name: {
			desc: false,
			classNames: ['table-sort', 'table-sort--top']
		},
		ProductName: {
			desc: true,
			classNames: ['table-sort']
		},
		Description: {
			desc: true,
			classNames: ['table-sort']
		}
	})

	const paginationHandle = (pageNo: number) => {
		setFilterVersions({
			...filterVersions,
			pageNo
		})
	}

	const sortHandle = (sortField: TVersionSortFields) => {
		const updatedSortColumns = { ...sortColumns }

		Object.keys(updatedSortColumns).forEach((key: string) => {
			if (key !== sortField) {
				updatedSortColumns[key] = {
					desc: true,
					classNames: ['table-sort']
				}
			} else {
				updatedSortColumns[key].desc = !updatedSortColumns[key].desc
				updatedSortColumns[key].classNames = [
					'table-sort',
					updatedSortColumns[key].desc ? 'table-sort--bottom' : 'table-sort--top'
				]
			}
		})

		setFilterVersions({
			...filterVersions,
			sortField,
			desc: updatedSortColumns[sortField].desc
		})

		setSortColumns(updatedSortColumns)
	}

	const createHandler = () => {
		showModal({
			type: 'description',
			Component: ModalAddVersion,
			ComponentProps: {
				products,
				onConfirm: async (data: IVersionCreate) => {
					toggleLoader(true)

					try {
						const response = await createVersion(data)

						if (response?.status === 200) {
							paginationHandle(0)
						}

						return response
					} finally {
						toggleLoader(false)
					}
				}
			}
		})
	}

	const updateHandler = (item: IVersion) => {
		showModal({
			type: 'description',
			Component: ModalEditVersion,
			ComponentProps: {
				item,
				products,
				onConfirm: async (data: IVersionUpdate) => {
					toggleLoader(true)

					try {
						const response = await updateVersion(item.id, data)

						if (response?.status === 200) {
							paginationHandle(0)
						}

						return response
					} finally {
						toggleLoader(false)
					}
				},
				onRemove: async (id: string) => {
					toggleLoader(true)

					try {
						const response = await removeVersion(id)

						if (response?.status === 200) {
							paginationHandle(0)
						}

						return response
					} finally {
						toggleLoader(false)
					}
				}
			}
		})
	}

	const updateBuildsHandler = (version: IVersion) => {
		showModal({
			type: 'update',
			Component: ModalBuilds,
			ComponentProps: {
				version,
				onConfirm: async (data: { buildIds: string[] }) => {
					toggleLoader(true)

					//TODO: Дублируются версии при сохранении, исправят на беке
					try {
						const response = await connectVersionWithBuilds(version.id, data.buildIds)

						if (response?.status === 200) {
							paginationHandle(0)
						}

						return response
					} finally {
						toggleLoader(false)
					}
				}
			}
		})
	}

	const updateFeaturesHandler = (version: IVersion) => {
		showModal({
			type: 'update',
			Component: ModalFeatures,
			ComponentProps: {
				version,
				onConfirm: async (data: { featuresIds: string[] }) => {
					toggleLoader(true)

					try {
						const response = await connectVersionWithFeatures(version.id, data.featuresIds)

						if (response?.status === 200) {
							paginationHandle(0)
						}

						return response
					} finally {
						toggleLoader(false)
					}
				}
			}
		})
	}

	useEffect(() => {
		const filter = { ...filterVersions, productId: productId || '' }

		getVersions(filter).then(response => {
			if (response?.status === 200) {
				const { data, pageNo, totalPages, totalCount } = response.data

				setTable(data)

				setPagination({
					pageNo,
					totalPages,
					totalCount
				})

				if (!productId) {
					setFilteredProduct(undefined)
				}

				setStatus('ready')
			}
		})
	}, [getVersions, filterVersions, pathname, productId])

	useEffect(() => {
		getProducts({
			desc: false,
			sortField: 'Name',
			pageNo: 0,
			pageSize: INFINITE_NUMBER
		}).then(response => {
			if (response?.status === 200) {
				setProducts(response.data.data)
			}
		})
	}, [getProducts])

	useEffect(() => {
		if (productId) {
			setFilteredProduct(products.filter(item => item.id === productId)[0])
		}
	}, [products, productId])

	if (status === 'loading') return <Loading />

	return (
		<>
			<Pagetitle title={`${t('pages:versions:title')} ${filteredProduct?.name || ''}`} />

			<div className="filters">
				<div className="filters-item filters-item--search">
					<SearchField onSubmit={searchHandle} placeholder={t('pages:versions:searchField')} />
				</div>

				<div className="filters-item filters-item--buttons">
					<div className="button-row">
						<Button type={'primary'} htmlType={'button'} className="button--large" onClick={createHandler}>
							{t('pages:versions:addNew')}
						</Button>
					</div>
				</div>
			</div>

			{table.length > 0 ? (
				<div className="pagination-wrapper">
					<div className="table">
						<table>
							<colgroup>
								<col />
								<col width="18%" />
								<col />
								<col width="12%" />
								<col width="12%" />
								<col width="12%" />
							</colgroup>
							<thead>
								<tr>
									<th onClick={() => sortHandle('Name')}>
										<div className={sortColumns.Name.classNames.join(' ')}>{t('pages:versions:tableHeadingName')}</div>
									</th>
									<th onClick={() => sortHandle('ProductName')}>
										<div className={sortColumns.ProductName.classNames.join(' ')}>
											{t('pages:versions:tableHeadingProductName')}
										</div>
									</th>
									<th onClick={() => sortHandle('Description')}>
										<div className={sortColumns.Description.classNames.join(' ')}>
											{t('pages:versions:tableHeadingDescription')}
										</div>
									</th>
									<th>
										<div>{t('pages:versions:tableHeadingBuilds')}</div>
									</th>
									<th>
										<div>{t('pages:versions:tableHeadingSettings')}</div>
									</th>
									<th>
										<div>{t('pages:versions:tableHeadingFunctions')}</div>
									</th>
								</tr>
							</thead>

							<tbody>
								{table.map(item => {
									return (
										<tr key={item.id}>
											<td>
												<button className="button-link" type="button" onClick={() => updateHandler(item)}>
													{item.name}
												</button>
											</td>

											<td>{item.product.name}</td>

											<td>{item.description}</td>

											<td>
												<button className="button-link" type="button" onClick={() => updateBuildsHandler(item)}>
													{t('pages:versions:tableHeadingBuilds')}
												</button>
											</td>

											<td>
												<Link to={`/versions/${item.id}/settings`}>{t('pages:versions:tableHeadingSettings')}</Link>
											</td>

											<td>
												<button className="button-link" type="button" onClick={() => updateFeaturesHandler(item)}>
													{t('pages:versions:tableHeadingFunctions')}
												</button>
											</td>
										</tr>
									)
								})}
							</tbody>
						</table>
					</div>

					<Pagination onClick={paginationHandle} {...pagination} />
				</div>
			) : (
				<NoResults />
			)}
		</>
	)
}

const mapDispatch = {
	getProducts: productGetAllHandler,
	getVersions: versionGetAllHandler,
	createVersion: createVersionHandler,
	updateVersion: updateVersionByIdHandler,
	connectVersionWithBuilds: connectVersionWithBuildsHandler,
	connectVersionWithFeatures: connectFeatureWithVersionHandler,
	removeVersion: deleteVersionByIdHandler,
	showModal: modalShowHandler,
	toggleLoader: toggleLoaderHandler
}

const connector = connect(null, mapDispatch)

export default connector(Versions)
