import React from 'react'
import { FormProvider, SubmitHandler, UseFormMethods } from 'react-hook-form'
import Textarea from '../UI/Textarea/Textarea'
import InputFile from '../UI/InputFile/InputFile'
import Button from '../UI/Button/Button'
import { ICommentsFormInputs } from '../../interfaces/UI/support/commentsForm'
import { useTranslation } from 'react-i18next'
import { INPUT_FILE_ACCEPTED_IMAGES } from '../../helpers/helpers'

type TProps = {
	onSubmit: SubmitHandler<ICommentsFormInputs>
	methods: UseFormMethods<ICommentsFormInputs>
	isSubmitting: boolean
}

const CommentsForm = ({ onSubmit, methods, isSubmitting }: TProps) => {
	const { t } = useTranslation()

	return (
		<div className="support-comments-form">
			<FormProvider {...methods}>
				<form className="form" onSubmit={methods.handleSubmit(onSubmit)}>
					<div className="form-item">
						<div className="form-headnote">{t('pages:support:commentFormMessage')}</div>

						<Textarea
							name={'message'}
							reference={methods.register({
								required: true,
								maxLength: 5000
							})}
							currentValue={methods.watch('message')}
							isDisabled={isSubmitting}
							isError={!!methods.errors.message}
							errors={methods.errors.message}
							rules={{
								maxLength: 5000
							}}
						/>
					</div>

					<div className="form-item">
						<InputFile
							name={'attaches'}
							errors={methods.errors.attaches}
							isError={!!methods.errors.attaches}
							isDisabled={isSubmitting}
							placeholder={t('pages:support:commentFormAttach')}
							accept={INPUT_FILE_ACCEPTED_IMAGES}
							multiple={true}
						/>
					</div>

					<div className="form-controls form-controls--right">
						<Button type={'primary'} htmlType={'submit'} isDisabled={isSubmitting}>
							{t('pages:support:commentFormSubmit')}
						</Button>
					</div>
				</form>
			</FormProvider>
		</div>
	)
}

export default CommentsForm
