import { uniq } from 'lodash'
import { IUser, IUserAccount } from '../interfaces/passport/user'

interface IRoles {
    [key: string]: {
        bodyClassName: string
        sidebarClassName: string
    }
}

export const CLOUD_ADMIN = 'CLOUD_ADMIN'
export const ORG_ADMIN = 'ORG_ADMIN'
export const SUPPORT = 'SUPPORT'
export const USER = 'USER'
export const NONE = 'NONE'

export const roles: IRoles = {
    [CLOUD_ADMIN]: {
        bodyClassName: 'page-admin-wz',
        sidebarClassName: 'sidebar--admin-wz'
    },
    [ORG_ADMIN]: {
        bodyClassName: 'page-admin-org',
        sidebarClassName: 'sidebar--admin-org'
    },
    [SUPPORT]: {
        bodyClassName: 'page-support',
        sidebarClassName: 'sidebar--support'
    },
    [USER]: {
        bodyClassName: 'page-user',
        sidebarClassName: 'sidebar--user'
    }
}

export const BLOCKED = 'BLOCKED'
export const DELETED = 'DELETED'
export const ACTIVATED = 'ACTIVATED'
export const CONFIRMED = 'CONFIRMED'

export function getUserRoleName(user: IUser, currentAccountId?: string) {
    const currentAccount = currentAccountId ?
        user.accounts?.find(acc => acc.accountId === currentAccountId) :
        user.accounts?.[0];
    switch (user.role) {
        case CLOUD_ADMIN:
            return 'common:roleAdminCloud'

        case ORG_ADMIN:
            return 'common:roleAdminOrganization'

        case SUPPORT:
            return 'common:roleSupport'

        case USER:
            if (currentAccount) {
                return currentAccount.student ? 'common:roleStudent' : 'common:roleTeacher'
            }
            return user.accounts ? uniq(user.accounts?.map(acc => acc.student ? 'common:roleStudent' : 'common:roleTeacher')).join(",\n") : ''

        default:
            return ''
    }
}

export function getAccountRoleName(account: IUserAccount) {
    return account.student ? 'common:roleStudent' : 'common:roleTeacher'
}
