import React, { useState } from 'react'
import Button from '../../../components/UI/Button/Button'
import Checkbox from '../../../components/UI/Checkbox/Checkbox'
import Input from '../../../components/UI/Input/Input'
import { useForm } from 'react-hook-form'
import { REGEX_EMAIL } from '../../../helpers/helpers'
import { useTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { sendMarketingForm } from '../../../services/passport/marketing'

type FormFields = {
	name: string
	email: string
	privacy: boolean
	policy: boolean
}

const Demo = ({ sendMarketingForm }: ConnectedProps<typeof connector>) => {
	const { t } = useTranslation()

	const {
		register,
		errors,
		watch,
		handleSubmit,
		formState: { isSubmitting }
	} = useForm<FormFields>({
		defaultValues: {
			name: '',
			email: '',
			privacy: true,
			policy: true
		}
	})

	const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false)

	const onFormSubmitHandler = async (data: FormFields) => {
		setIsSubmitSuccessful(false)

		const response = await sendMarketingForm({
			source: 'DEMO',
			petitionerFullName: data.name,
			email: data.email
		})

		if (response?.status === 200 && response.data?.success) {
			setIsSubmitSuccessful(true)
		}
	}

	return (
		<div className="login" style={{ maxWidth: '60rem' }}>
			{isSubmitSuccessful ? (
				<div
					style={{
						textAlign: 'center',
						padding: '0 1.5rem'
					}}
				>
					<h2>{t('pages:forms:successTitle')}</h2>
					<p>{t('pages:forms:successDescription')}</p>
				</div>
			) : (
				<>
					<div
						style={{
							textAlign: 'center',
							padding: '0 1.5rem'
						}}
					>
						<h2>{t('pages:forms:demoTitle')}</h2>
						<p>
							{t('pages:forms:demoDescription')}
							<br />
							<br />
						</p>
					</div>

					<form className="form" onSubmit={handleSubmit(onFormSubmitHandler)} style={{ maxWidth: 'none' }}>
						<div className="form-item">
							<Input
								currentValue={watch('name')}
								type={'text'}
								name={'name'}
								placeholder={t('pages:forms:fieldName')}
								isDisabled={isSubmitting}
								isRequired
								isError={!!errors.name}
								reference={register({
									required: true
								})}
								errors={errors.name}
							/>
						</div>

						<div className="form-item">
							<Input
								currentValue={watch('email')}
								type={'text'}
								name={'email'}
								placeholder={t('pages:forms:fieldEmail')}
								isDisabled={isSubmitting}
								isRequired
								isError={!!errors.email}
								reference={register({
									required: true,
									minLength: 6,
									pattern: REGEX_EMAIL
								})}
								rules={{
									minLength: 6
								}}
								errors={errors.email}
							/>
						</div>

						<div className="form-item">
							<Checkbox
								isDisabled={isSubmitting}
								reference={register({
									required: true
								})}
								isError={!!errors.privacy}
								errors={errors.privacy}
								name={'privacy'}
							>
								{t('pages:forms:fieldPrivacy')}{' '}
								<a href={t('pages:forms:privacyLink')} target="_blank" rel="noopener noreferrer">
									{t('pages:forms:fieldPrivacyLink')}
								</a>
							</Checkbox>
						</div>

						<div className="form-item">
							<Checkbox
								isDisabled={isSubmitting}
								reference={register({
									required: true
								})}
								isError={!!errors.policy}
								errors={errors.policy}
								name={'policy'}
							>
								{t('pages:forms:fieldPolicy')}{' '}
								<a href={t('pages:forms:policyLink')} target="_blank" rel="noopener noreferrer">
									{t('pages:forms:fieldPolicyLink')}
								</a>
							</Checkbox>
						</div>

						<div className="form-item">
							<Button type={'primary'}>{t('common:actionSend')}</Button>
						</div>
					</form>
				</>
			)}
		</div>
	)
}

const mapDispatch = {
	sendMarketingForm
}

const connector = connect(null, mapDispatch)

export default connector(Demo)
