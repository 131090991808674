import { RECOVERY_APPLY_RESET_PASSWORD, RECOVERY_INIT_RESET_PASSWORD } from '../../actions/passport/recovery'
import { RecoveryActionTypes } from '../../../interfaces/passport/actionCreators/recovery'

interface IInitialState {
	date: string
	email: string
}

const initialState: IInitialState = {
	date: '',
	email: ''
}

export default function RecoveryReducer(state = initialState, action: RecoveryActionTypes): IInitialState {
	switch (action.type) {
		case RECOVERY_INIT_RESET_PASSWORD:
			return {
				...state,
				...action.payload
			}

		case RECOVERY_APPLY_RESET_PASSWORD:
			return {
				...state,
				date: '',
				email: ''
			}

		default:
			return state
	}
}
