import React, { useEffect, useState } from 'react'
import Pagetitle from '../../../components/UI/Pagetitle/Pagetitle'
import Pagination from '../../../components/UI/Pagination/Pagination'
import Loading from '../../../components/UI/Loading/Loading'
import SearchField from '../../../components/UI/SearchField/SearchField'
import { connect, ConnectedProps } from 'react-redux'
import {
	createProductHandler,
	deleteProductByIdHandler,
	productGetAllHandler,
	updateProductByIdHandler
} from '../../../services/cabinet/products'
import {
	IProduct,
	IProductAPIRequest,
	IProductCreate,
	IProductUpdate,
	TProductSortFields
} from '../../../interfaces/cabinet/products'
import { Link } from 'react-router-dom'
import { modalShowHandler } from '../../../store/actionCreators/UI/modal'
import ModalEditProduct from './ModalEditProduct'
import Button from '../../../components/UI/Button/Button'
import ModalAddProduct from './ModalAddProduct'
import { IPagination, TSortColumnsTable, TStatus } from '../../../interfaces/helpers'
import { DEFAULT_PAGE_SIZE, truncateString } from '../../../helpers/helpers'
import NoResults from '../../../components/UI/NoResults/NoResults'
import { useTranslation } from 'react-i18next'
import { toggleLoaderHandler } from '../../../store/actionCreators/UI/loader'

const Products = ({
	getProducts,
	createProduct,
	updateProduct,
	removeProduct,
	showModal,
	toggleLoader
}: ConnectedProps<typeof connector>) => {
	const { t } = useTranslation()
	const [status, setStatus] = useState<TStatus>('loading')
	const [table, setTable] = useState<IProduct[]>([])
	const [pagination, setPagination] = useState<IPagination>({
		pageNo: 0,
		totalPages: 0,
		totalCount: 0
	})

	const [filterProducts, setFilterProducts] = useState<IProductAPIRequest>({
		filter: '',
		sortField: 'Name',
		desc: false,
		pageSize: DEFAULT_PAGE_SIZE,
		pageNo: 0
	})

	const searchHandle = (value: string) => {
		setFilterProducts({
			...filterProducts,
			filter: value,
			pageNo: 0
		})
	}

	const [sortColumns, setSortColumns] = useState<TSortColumnsTable>({
		Name: {
			desc: false,
			classNames: ['table-sort', 'table-sort--top']
		},
		Description: {
			desc: true,
			classNames: ['table-sort']
		}
	})

	const paginationHandle = (pageNo: number) => {
		setFilterProducts({
			...filterProducts,
			pageNo
		})
	}

	const sortHandle = (sortField: TProductSortFields) => {
		const updatedSortColumns = { ...sortColumns }

		Object.keys(updatedSortColumns).forEach((key: string) => {
			if (key !== sortField) {
				updatedSortColumns[key] = {
					desc: true,
					classNames: ['table-sort']
				}
			} else {
				updatedSortColumns[key].desc = !updatedSortColumns[key].desc
				updatedSortColumns[key].classNames = [
					'table-sort',
					updatedSortColumns[key].desc ? 'table-sort--bottom' : 'table-sort--top'
				]
			}
		})

		setFilterProducts({
			...filterProducts,
			sortField,
			desc: updatedSortColumns[sortField].desc
		})

		setSortColumns(updatedSortColumns)
	}

	const createHandler = () => {
		showModal({
			type: 'description',
			Component: ModalAddProduct,
			ComponentProps: {
				onConfirm: async (data: IProductCreate) => {
					toggleLoader(true)
					try {
						const response = await createProduct(data)

						if (response?.status === 200) {
							paginationHandle(0)
						}

						return response
					} finally {
						toggleLoader(false)
					}
				}
			}
		})
	}

	const updateHandler = (item: IProduct) => {
		showModal({
			type: 'description',
			Component: ModalEditProduct,
			ComponentProps: {
				item,
				onConfirm: async (data: IProductUpdate) => {
					toggleLoader(true)
					try {
						const response = await updateProduct(item.id, data)

						if (response?.status === 200) {
							paginationHandle(0)
						}

						return response
					} finally {
						toggleLoader(false)
					}
				},
				onRemove: async (id: string) => {
					toggleLoader(true)
					try {
						const response = await removeProduct(id)

						if (response?.status === 200) {
							paginationHandle(0)
						}

						return response
					} finally {
						toggleLoader(false)
					}
				}
			}
		})
	}

	useEffect(() => {
		getProducts(filterProducts).then(response => {
			if (response?.status === 200) {
				const { data, pageNo, totalPages, totalCount } = response.data

				setTable(data)

				setPagination({
					pageNo,
					totalPages,
					totalCount
				})

				setStatus('ready')
			}
		})
	}, [getProducts, filterProducts])

	if (status === 'loading') return <Loading />

	return (
		<>
			<Pagetitle title={t('pages:products:title')} />

			<div className="filters">
				<div className="filters-item filters-item--search">
					<SearchField onSubmit={searchHandle} placeholder={t('pages:products:searchField')} />
				</div>

				<div className="filters-item filters-item--buttons">
					<div className="button-row">
						<Button type={'primary'} className="button--large" htmlType={'button'} onClick={createHandler}>
							{t('pages:products:addNew')}
						</Button>
					</div>
				</div>
			</div>

			{table.length > 0 ? (
				<div className="pagination-wrapper">
					<div className="table">
						<table>
							<colgroup>
								<col width="26%" />
								<col />
								<col width="12%" />
								<col width="12%" />
								<col width="12%" />
							</colgroup>
							<thead>
								<tr>
									<th onClick={() => sortHandle('Name')}>
										<div className={sortColumns.Name.classNames.join(' ')}>{t('pages:products:tableHeadingName')}</div>
									</th>
									<th onClick={() => sortHandle('Description')}>
										<div className={sortColumns.Description.classNames.join(' ')}>
											{t('pages:products:tableHeadingDescription')}
										</div>
									</th>
									<th>
										<div>{t('pages:products:tableHeadingVersion')}</div>
									</th>
									<th>
										<div>{t('pages:products:tableHeadingBuilds')}</div>
									</th>
									<th>
										<div>{t('pages:products:tableHeadingFunctions')}</div>
									</th>
								</tr>
							</thead>

							<tbody>
								{table.map(item => {
									return (
										<tr key={item.id}>
											<td>
												<button className="button-link" type="button" onClick={() => updateHandler(item)}>
													{item.name}
												</button>
											</td>
											<td>{truncateString(item.description)}</td>
											<td>
												<Link to={`/versions/${item.id}`}>{t('pages:products:tableHeadingVersion')}</Link>
											</td>
											<td>
												<Link to={`/builds/${item.id}`}>{t('pages:products:tableHeadingBuilds')}</Link>
											</td>
											<td>
												<Link to={`/functions/${item.id}`}>{t('pages:products:tableHeadingFunctions')}</Link>
											</td>
										</tr>
									)
								})}
							</tbody>
						</table>
					</div>

					<Pagination onClick={paginationHandle} {...pagination} />
				</div>
			) : (
				<NoResults />
			)}
		</>
	)
}

const mapDispatch = {
	getProducts: productGetAllHandler,
	createProduct: createProductHandler,
	updateProduct: updateProductByIdHandler,
	removeProduct: deleteProductByIdHandler,
	showModal: modalShowHandler,
	toggleLoader: toggleLoaderHandler
}

const connector = connect(null, mapDispatch)

export default connector(Products)
