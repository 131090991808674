import React from 'react'
import { IOrganization } from '../../interfaces/passport/organizations'
import { useTranslation } from 'react-i18next'
import Contact from './Contact'

interface IProps {
	organization: IOrganization
}

const OrganizationInfo = ({ organization }: IProps) => {
	const { t } = useTranslation()

	return (
		<div className="info-list">
			{organization.fullName && (
				<div className="info-item">
					<div className="info-item-label">{t('pages:organizations:fieldFullName')}</div>
					<div className="info-item-content">{organization.fullName}</div>
				</div>
			)}

			{organization.name && (
				<div className="info-item">
					<div className="info-item-label">{t('pages:organizations:fieldName')}</div>
					<div className="info-item-content">{organization.name}</div>
				</div>
			)}

			{organization.inn && (
				<div className="info-item">
					<div className="info-item-label">{t('pages:organizations:fieldInn')}</div>
					<div className="info-item-content">{organization.inn}</div>
				</div>
			)}

			{organization.mainContact && (
				<div className="info-item">
					<div className="info-item-label">{t('pages:organizations:mainContactTitle')}</div>
					<div className="info-item-content">
						<Contact contact={organization.mainContact} />
					</div>
				</div>
			)}

			{organization.contacts && organization.contacts.length > 0 && (
				<div className="info-item">
					<div className="info-item-label">
						{organization.contacts.length > 1
							? t('pages:organizations:contactsTitle')
							: t('pages:organizations:contactTitle')}
					</div>

					<div className="info-item-other-list">
						{organization.contacts.map((item, idx) => {
							return (
								<div key={idx} className="info-item-content">
									<Contact contact={item} />
								</div>
							)
						})}
					</div>
				</div>
			)}
		</div>
	)
}

export default OrganizationInfo
