import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
	onAdd: () => void
	isDisabled?: boolean
	children: ReactNode
}

const GroupLicensePackage = ({ onAdd, children, isDisabled = false }: IProps) => {
	const { t } = useTranslation()

	return (
		<>
			<div className="form-license-pack__group">{children}</div>

			{!isDisabled && (
				<div className="form-license-pack__create" onClick={onAdd}>
					<span>{t('pages:licensePackages:addGroup')}</span>
				</div>
			)}
		</>
	)
}

export default GroupLicensePackage
