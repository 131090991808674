import React, { useEffect, useRef } from 'react'
import Button from '../../../components/UI/Button/Button'
import { connect, ConnectedProps } from 'react-redux'
import { IModalErrorProps } from '../../../interfaces/UI/modalError'
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock'
import { useTranslation } from 'react-i18next'
import { CSSTransition } from 'react-transition-group'
import './modal.css'
import dayjs from 'dayjs'
import { modalErrorHideHandler } from '../../../store/actionCreators/UI/modalError'
import { Link } from 'react-router-dom'

type TProps = ConnectedProps<typeof connector> & IModalErrorProps

const ModalError = ({ isOpened, hideModal, onConfirm, errorDetails }: TProps) => {
	const { t } = useTranslation()
	const modalRef = useRef(null)

	useEffect(() => {
		if (isOpened) {
			// @ts-ignore
			disableBodyScroll(modalRef.current, {
				reserveScrollBarGap: false
			})
		}

		return () => {
			if (isOpened) {
				setTimeout(() => {
					clearAllBodyScrollLocks()
				}, 250)
			}
		}
	}, [isOpened])

	const classNames = ['modal', 'modal--error']

	const onConfirmHandler = () => {
		if (typeof onConfirm === 'function') onConfirm()
		hideModal()
	}

	return (
		<CSSTransition in={isOpened} timeout={250} nodeRef={modalRef} classNames={'modal-animation'} unmountOnExit>
			<div ref={modalRef} className={classNames.join(' ')}>
				<div className="modal-container">
					<button className="modal-close" onClick={hideModal} />
					<h4>{t('common:appName')}</h4>

					{errorDetails?.datetime && (
						<div className={'modal-error-time'}>
							<small>{dayjs(errorDetails.datetime).format('DD.MM.YYYY HH:mm:ss')}</small>
						</div>
					)}

					<div className={'modal-error-description'}>
						<p dangerouslySetInnerHTML={{ __html: errorDetails.message }} />

						{errorDetails?.errorCode === 'USER_EXISTS' && errorDetails?.details && (
							<>
								{errorDetails?.objectName ? (
									<p>
										<Link
											to={`/organization/${errorDetails.objectName}/users`}
											onClick={hideModal}
										>
											{errorDetails.details}
										</Link>
									</p>
								) : (
									<p>{errorDetails.details}</p>
								)}
							</>
						)}

						{errorDetails?.id && (
							<p>
								<small>Идентификатор ошибки: {errorDetails.id}</small>
							</p>
						)}
					</div>

					<div className="button-row">
						<Button type={'primary'} htmlType={'button'} onClick={onConfirmHandler}>
							{t('common:actionOk')}
						</Button>
					</div>
				</div>
			</div>
		</CSSTransition>
	)
}

const mapDispatch = {
	hideModal: modalErrorHideHandler
}

const connector = connect(null, mapDispatch)

export default connector(ModalError)
