import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { IHardwareKey, IHardwareKeyCreate } from '../../../interfaces/cabinet/hardwareKeys'
import Input from '../../../components/UI/Input/Input'
import { HARDWARE_KEY_TYPES, REGEX_HARDWARE_KEY_CODE } from '../../../helpers/helpers'
import Button from '../../../components/UI/Button/Button'
import { modalConfirmShowHandler } from '../../../store/actionCreators/UI/modalConfirm'
import { connect, ConnectedProps } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { selectProps } from '../../../components/UI/Select/helpers'

type TProps = ConnectedProps<typeof connector> & {
	item: IHardwareKey
	onConfirm: (data: IHardwareKeyCreate) => Promise<any>
	hideModal: () => void
}

interface IFormInputs {
	code: string
	type: string
	account: string
}

const ModalEditHardwareKey = ({ item, hideModal, showModalConfirm, onConfirm }: TProps) => {
	const { t } = useTranslation()
	const { register, errors, handleSubmit, formState, watch, control } = useForm<IFormInputs>({
		defaultValues: {
			...item
		}
	})
	const { isDirty } = formState
	const [isSubmitting, setIsSubmitting] = useState(false)

	const cancelHandler = () => {
		if (isDirty) {
			showModalConfirm({
				type: 'cancel',
				onConfirm: hideModal
			})
		} else {
			hideModal()
		}
	}

	const onSubmit = async (data: IFormInputs) => {
		setIsSubmitting(true)

		try {
			const response = await onConfirm(data)

			if (response?.status === 200) {
				hideModal()
			}
		} finally {
			setIsSubmitting(false)
		}
	}

	return (
		<>
			<button className="modal-close" onClick={cancelHandler} disabled={isSubmitting} />

			<h3 className="modal-title">{t('common:appName')}</h3>

			<p className="modal-footnote">{t('pages:hardwareKeys:modalUpdateText')}</p>

			<form className="form" onSubmit={handleSubmit(onSubmit)}>
				<div className="form-item">
					<Input
						currentValue={watch('code')}
						type={'text'}
						name={'code'}
						placeholder={t('pages:hardwareKeys:fieldCode')}
						isDisabled={isSubmitting}
						isError={!!errors.code}
						isRequired={true}
						reference={register({
							required: true,
							pattern: REGEX_HARDWARE_KEY_CODE
						})}
						errors={errors.code}
					/>
				</div>

				<div className="form-item">
					<Controller
						render={({ onChange, name }) => (
							<Select
								name={name}
								defaultValue={{
									label: item.type,
									value: item.type
								}}
								options={HARDWARE_KEY_TYPES.map(item => {
									return {
										label: item,
										value: item
									}
								})}
								placeholder={t('pages:hardwareKeys:fieldType')}
								isDisabled={isSubmitting}
								isRequired={true}
								isError={!!errors.type}
								errors={errors.type}
								onChange={option => option && onChange(option.value)}
								{...selectProps}
							/>
						)}
						name={'type'}
						control={control}
						rules={{
							required: true
						}}
					/>
				</div>

				<div className="form-item">
					<Input
						currentValue={watch('account')}
						type={'text'}
						name={'account'}
						placeholder={t('pages:hardwareKeys:fieldAccount')}
						isDisabled={isSubmitting}
						isError={!!errors.account}
						isRequired={true}
						reference={register({
							required: true,
							maxLength: 255
						})}
						errors={errors.account}
						rules={{
							maxLength: 255
						}}
					/>
				</div>

				<div className="form-controls form-controls--justify">
					<Button type={'secondary'} htmlType={'button'} onClick={cancelHandler} isDisabled={isSubmitting}>
						{t('common:actionCancel')}
					</Button>

					<Button type={'primary'} htmlType={'submit'} isDisabled={isSubmitting || !isDirty}>
						{t('common:actionUpdate')}
					</Button>
				</div>
			</form>
		</>
	)
}

const mapDispatch = {
	showModalConfirm: modalConfirmShowHandler
}

const connector = connect(null, mapDispatch)

export default connector(ModalEditHardwareKey)
