import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ConnectedProps, connect } from 'react-redux'
import omit from 'lodash/omit'
import Select from 'react-select'
import { IPagination } from '../../../interfaces/helpers'
import UsersTable, { ITableUser } from './UsersTable'
import { DEFAULT_PAGE_SIZE, INFINITE_NUMBER } from '../../../helpers/helpers'
import { CLOUD_ADMIN, ORG_ADMIN, SUPPORT } from '../../../helpers/roles'
import { IUserAPIRequest, IUserCreate, IUserUpdate } from '../../../interfaces/passport/user'
import { TOnChange, selectProps } from '../../../components/UI/Select/helpers'
import ModalAddAdministrator from '../../AdminCloud/Organizations/Administrators/ModalAddAdministrator'
import ModalAddSystemUser from '../../AdminCloud/SystemUsers/ModalAddSystemUser'
import { AxiosResponse } from 'axios'
import ModalEditAdministrator from '../../AdminCloud/Organizations/Administrators/ModalEditAdministrator'
import ModalEditSystemUser from '../../AdminCloud/SystemUsers/ModalEditSystemUser'
import {
	createUserHandler,
	deleteUserDataByIdHandler,
	deleteUsersByIdsHandler,
	getAllUsersHandler,
	updateUserDataByIdHandler
} from '../../../services/passport/user'
import { modalShowHandler } from '../../../store/actionCreators/UI/modal'
import { modalConfirmShowHandler } from '../../../store/actionCreators/UI/modalConfirm'
import { modalErrorShowHandler } from '../../../store/actionCreators/UI/modalError'
import { notificationAddHandler } from '../../../store/actionCreators/UI/notification'
import { toggleLoaderHandler } from '../../../store/actionCreators/UI/loader'
import Pagetitle from '../../../components/UI/Pagetitle/Pagetitle'
import SearchField from '../../../components/UI/SearchField/SearchField'
import SelectLimit from '../../../components/UI/Select/SelectLimit'
import { IOrganization } from '../../../interfaces/passport/organizations'
import Button from '../../../components/UI/Button/Button'

type TUserFilterOptions = 'orgFilter'

interface ISystemUsersListProps {
	organizationId?: string
	organizations?: IOrganization[]
	crumbs?: Array<{
		to: string
		title: string
		onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
	}>
}

const SystemUsersList = ({
	organizationId,
	organizations,
	crumbs,
	getUsers,
	createUser,
	updateUser,
	deleteUser,
	deleteUsers,
	showModal,
	showModalConfirm,
	showModalError,
	notificationAdd,
	toggleLoader
}: ISystemUsersListProps & ConnectedProps<typeof connector>) => {
	const tableRef = useRef<{ reset: () => void }>(null)
	const { t } = useTranslation()
	const [isRemoveButtonDisabled, setIsRemoveButtonDisabled] = useState(true)
	const [table, setTable] = useState<ITableUser[]>([])
	const [pagination, setPagination] = useState<IPagination>({
		pageNo: 0,
		totalPages: 0,
		totalCount: 0
	})
	const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE)

	const [filterUsers, setFilterUsers] = useState<
		Omit<IUserAPIRequest, 'userSortField' | 'pageSize' | 'pageNo' | 'desc'>
	>({
		nameFilter: '',
		orgFilter: organizationId,
		roleFilter: organizationId ? ORG_ADMIN : `${ORG_ADMIN}, ${SUPPORT}, ${CLOUD_ADMIN}`
	})

	const resetTable = () => {
		tableRef.current?.reset()
	}

	const limitHandle: TOnChange = option => {
		if (!option) return

		setPageSize(parseInt(option.value) || INFINITE_NUMBER)
	}

	const onSelectHandle = (items: ITableUser[]) => {
		setTable(items)
		setIsRemoveButtonDisabled(items.length === 0)
	}

	const onTextFilterChanged = (value: string) => {
		// TODO: поиск по всем полям

		setFilterUsers({
			...filterUsers,
			nameFilter: value
		})
	}

	const onSelectFilterChanged: (param: TUserFilterOptions, isBool?: boolean) => TOnChange = (param, isBool = false) => {
		return option => {
			if (!option) return

			if (option.value) {
				setFilterUsers({
					...filterUsers,
					[param]: isBool ? option.value === '1' : (option.value as any)
				})
			} else {
				setFilterUsers({
					...omit(filterUsers, [param])
				})
			}
		}
	}

	const withLoader = async (action: () => Promise<any>) => {
		toggleLoader(true)
		try {
			await action()
		} finally {
			toggleLoader(false)
		}
	}

	const afterUserAction = (text: string, response: AxiosResponse<any>) => {
		if (response?.status === 200) {
			resetTable()
			notificationAdd(t(text), 'info')
		}
	}

	const submitCreateUser = async (user: IUserCreate) => {
		await withLoader(async () => {
			const response = await createUser(user)
			afterUserAction('pages:systemUsers:notificationCreate', response)
		})
	}
	const submitUpdateUser = async (id: string, user: IUserUpdate) => {
		await withLoader(async () => {
			const response = await updateUser(id, user)
			afterUserAction('pages:systemUsers:notificationUpdate', response)
		})
	}

	const submitDeleteUser = async (userId: string) => {
		await withLoader(async () => {
			const response = await deleteUser(userId)
			afterUserAction('pages:systemUsers:notificationRemove', response)
		})
	}

	const addUserHandle = () => {
		console.log("add")
		showModal({
			type: 'middle',
			Component: organizationId ? ModalAddAdministrator : ModalAddSystemUser,
			ComponentProps: {
				organization: organizationId ? { id: organizationId } : undefined,
				organizations,
				showModalConfirm,
				onConfirm: submitCreateUser
			}
		})
	}

	const updateUserHandler = (user: ITableUser) => {
		showModal({
			type: 'middle',
			Component: organizationId ? ModalEditAdministrator : ModalEditSystemUser,
			ComponentProps: {
				user: user.user,
				organizations,
				onConfirm: submitUpdateUser,
				onRemove: submitDeleteUser
			}
		})
	}

	const batchRemoveHandler = async () => {
		const selectedUsers = table.filter(item => item.selected)
		const selectedIds = selectedUsers.map(item => item.id)
		const selectedTexts = selectedUsers.map(item => `${item.lastName} ${item.firstName}`)

		showModalConfirm({
			type: 'remove',
			elementText: selectedTexts.join(', '),
			onConfirm: async () => {
				toggleLoader(true)

				try {
					const response = await deleteUsers(selectedIds)

					if (response?.status === 200) {
						const errorIds: Array<string> = response.data.filter(item => !item.result).map(item => item.id) || []
						const errorUsers: Array<string> =
							table.filter(item => errorIds.includes(item.id)).map(item => `${item.lastName} ${item.firstName}`) || []

						if (errorUsers.length > 0) {
							showModalError({
								errorDetails: {
									message: t(`pages:systemUsers:notificationBatchError`, {
										value: errorUsers.join(', ')
									}),
									id: response.data.map(item => item.id).join(', ')
								}
							})
						} else {
							notificationAdd(t(`pages:systemUsers:notificationRemoveDone`), 'info')
						}
					}
				} finally {
					resetTable()
					toggleLoader(false)
				}
			}
		})
	}

	useEffect(() => {
		if (!organizationId) return
		setIsRemoveButtonDisabled(true)
	}, [organizationId])

	return (
		<>
			<Pagetitle
				title={t(organizationId ? 'pages:organizations:menuAdministrators' : 'pages:systemUsers:title')}
				crumbs={crumbs}
			/>

			<div className="filters">
				<div className="filters-item filters-item--search filters-item--small">
					<SearchField onSubmit={onTextFilterChanged} placeholder={t('pages:systemUsers:searchField')} />
				</div>

				{!organizationId && !!organizations && (
					<div className="filters-item filters-item--select">
						<Select
							name={'organization'}
							onChange={onSelectFilterChanged('orgFilter')}
							options={[
								{
									label: t('common:selectDefaultValue'),
									value: ''
								},
								...organizations.map(item => {
									return {
										label: item.name,
										value: item.id
									}
								})
							]}
							defaultValue={{
								label: t('common:selectDefaultValue'),
								value: ''
							}}
							placeholder={t('pages:systemUsers:organization')}
							{...selectProps}
						/>
					</div>
				)}

				<div className="filters-item filters-item--view">
					<SelectLimit total={pagination.totalCount} onChange={limitHandle} />
				</div>

				<div className="filters-item filters-item--buttons">
					<div className="button-row">
						<Button type={'primary'} htmlType={'button'} onClick={addUserHandle}>
							{t('pages:systemUsers:addNew')}
						</Button>
					</div>
				</div>
			</div>

			<UsersTable
				ref={tableRef}
				columns={[
					{
						title: 'pages:systemUsers:tableHeadingName',
						fieldName: 'firstName',
						sortName: 'Name',
						mapValue: item => (
							<button className="button-link" type="button" onClick={() => updateUserHandler(item)}>
								{item.lastName} {item.firstName}
							</button>
						)
					},
					{
						width: '20%',
						title: 'pages:systemUsers:tableHeadingLogin',
						fieldName: 'email',
						sortName: 'Login'
					},
					{
						title: 'pages:systemUsers:tableHeadingRole',
						fieldName: 'roleName',
						sortName: 'Role'
					},
					{
						title: 'pages:systemUsers:tableHeadingOrganization',
						fieldName: 'organizationName',
						mapValue: item => item.organizationName || '-'
					}
				]}
				pageSize={pageSize}
				filter={filterUsers}
				selectable={true}
				getUsers={getUsers}
				onSelect={onSelectHandle}
				onLoaded={setPagination}
			>
				<div className="group-actions">
					<div className="group-actions-control">
						<Button
							type="secondary"
							htmlType={'button'}
							isDisabled={isRemoveButtonDisabled}
							onClick={batchRemoveHandler}
						>
							{t('common:actionRemove')}
						</Button>
					</div>
				</div>
			</UsersTable>
		</>
	)
}

const mapDispatch = {
	getUsers: getAllUsersHandler,
	createUser: createUserHandler,
	updateUser: updateUserDataByIdHandler,
	deleteUser: deleteUserDataByIdHandler,
	deleteUsers: deleteUsersByIdsHandler,
	showModal: modalShowHandler,
	showModalConfirm: modalConfirmShowHandler,
	showModalError: modalErrorShowHandler,
	notificationAdd: notificationAddHandler,
	toggleLoader: toggleLoaderHandler
}

const connector = connect(null, mapDispatch)

export default connector(SystemUsersList)
