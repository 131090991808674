import { NOTIFICATION_ADD, NOTIFICATION_REMOVE } from '../../actions/UI/notification'
import { INotification } from '../../../interfaces/UI/notification'
import { NotificationActionTypes } from '../../../interfaces/UI/actionCreators/notifications'

export interface IInitialState {
	notifications: INotification[]
}

const initialState: IInitialState = {
	notifications: []
}

export default function NotificationReducer(state = initialState, action: NotificationActionTypes): IInitialState {
	switch (action.type) {
		case NOTIFICATION_ADD:
			return {
				...state,
				notifications: [...state.notifications, action.payload]
			}

		case NOTIFICATION_REMOVE:
			const notifications = state.notifications.filter(item => item.id !== action.payload)

			return {
				...state,
				notifications
			}

		default:
			return state
	}
}
