import { notificationAddHandler } from './UI/notification'
import { modalErrorShowHandler } from './UI/modalError'
import { ThunkAction } from 'redux-thunk'
import { RootState } from '../reducers/rootReducer'
import { Action } from 'redux'
import i18next from 'i18next'
import { APIError, TServiceHandlerErrorOptions } from '../../interfaces/helpers'
import { APIServices } from '../../helpers/api'
import { IModalErrorDetails } from '../../interfaces/UI/modalError'
import { serviceHandlerErrorOptions } from '../../services/helpers'
import { blobToJson } from '../../helpers/helpers'

type TErrors = {
	[key in APIServices]: Array<string>
}

const errors: TErrors = {
	[APIServices.AUTH]: ['INVALID_GRANT'],
	[APIServices.PASSPORT]: [
		'USER_EXISTS',
		'ACTIVATION_ERROR',
		'SERVICE_ERROR',
		'VALIDATION_ERROR',
		'NOT_FOUND',
		'FORBIDDEN',
		'BAD_ARGUMENTS',
		'MAIL_SEND_ERROR',
		'DATA_INTEGRITY_ERROR'
	],
	[APIServices.CABINET]: [
		'SERVICE_ERROR',
		'VALIDATION_ERROR',
		'NOT_FOUND',
		'FORBIDDEN',
		'BAD_ARGUMENTS',
		'MAIL_SEND_ERROR',
		'DATA_INTEGRITY_ERROR',
		'PRODUCT_EXISTS',
		'VERSION_HAS_BUILD',
		'VERSION_EXISTS_WITH_NAME_AND_PRODUCT',
		'SETTINGS_EXISTS'
	],
	[APIServices.APPS]: [
		'TENANT_EXISTS',
		'SERVICE_ERROR',
		'VALIDATION_ERROR',
		'NOT_FOUND',
		'FORBIDDEN',
		'BAD_ARGUMENTS',
		'MAIL_SEND_ERROR',
		'DATA_INTEGRITY_ERROR'
	],
	[APIServices.REPORTS]: [
		'SERVICE_ERROR',
		'VALIDATION_ERROR',
		'NOT_FOUND',
		'FORBIDDEN',
		'BAD_ARGUMENTS',
		'MAIL_SEND_ERROR',
		'DATA_INTEGRITY_ERROR'
	],
	[APIServices.LICENSE]: [
		'SERVICE_ERROR',
		'VALIDATION_ERROR',
		'NOT_FOUND',
		'FORBIDDEN',
		'BAD_ARGUMENTS',
		'MAIL_SEND_ERROR',
		'DATA_INTEGRITY_ERROR'
	],
	[APIServices.SUPPORT]: [
		'TOPIC_EXISTS',
		'SERVICE_ERROR',
		'VALIDATION_ERROR',
		'NOT_FOUND',
		'FORBIDDEN',
		'BAD_ARGUMENTS',
		'MAIL_SEND_ERROR',
		'DATA_INTEGRITY_ERROR'
	]
}

export type ErrorData = APIError & {
	error: string
	error_description: string
}

export function setErrorHandler(
	error: any,
	service: APIServices,
	options: TServiceHandlerErrorOptions = {}
): ThunkAction<void, RootState, unknown, Action<string>> {
	return async dispatch => {
		options = { ...serviceHandlerErrorOptions, ...options }

		if (!options.show) return

		let data: ErrorData = error.response?.data

		// TODO: временно
		console.log(error.response)

		if (data instanceof Blob) {
			data = await blobToJson<ErrorData>(data)
		}

		const errorDetails: IModalErrorDetails = {
			message: error.message
		}

		if (data) {
			const errorCode = (data.errorCode || data.error || '').toUpperCase()

			errorDetails.message = data.details || ''
			errorDetails.id = data.id || ''
			errorDetails.datetime = data.datetime || ''
			errorDetails.objectName = data.objectName || ''
			errorDetails.errorCode = data.errorCode || ''
			errorDetails.details = data.details || ''

			// if (errorCode === 'INVALID_GRANT') {
			// 	type = 'notification'
			// }

			if (errors[service].includes(errorCode)) {
				const translateKey = `api_${service}_${errorCode}_message`

				if (i18next.getResource(i18next.languages[0], 'errors', translateKey)) {
					errorDetails.message = i18next.t(`errors:${translateKey}`)
				}

				// TODO: временно
				if (process.env.REACT_APP_MODE === 'development') {
					console.table({
						title: 'Error translate helper:',
						translateKey,
						message: i18next.getResource(i18next.languages[0], 'errors', translateKey)
					})
				}

				if (data.objectName) {
					const translateKeyDetailed = `api_${service}_${errorCode}_objects_${data.objectName}`

					if (i18next.getResource(i18next.languages[0], 'errors', translateKeyDetailed)) {
						errorDetails.message = i18next.t(`errors:${translateKeyDetailed}`)
					}

					// TODO: временно
					if (process.env.REACT_APP_MODE === 'development') {
						console.table({
							title: 'Error translate helper:',
							translateKeyDetailed,
							messageDetailed: i18next.getResource(i18next.languages[0], 'errors', translateKeyDetailed)
						})
					}
				}
			}
		}

		if (options.message) {
			errorDetails.message = options.message
		}

		if (options.type === 'notification') {
			dispatch(notificationAddHandler(errorDetails.message, 'warning'))
		}

		if (options.type === 'modal') {
			dispatch(
				modalErrorShowHandler({
					errorDetails
				})
			)
		}
	}
}
