import React, { useCallback, useEffect, useRef, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import Select from 'react-select'
import {
    getFacultyByIdHandler,
    getFacultyGroupsByIdHandler,
    getGradesByIdHandler
} from '../../../services/passport/organization'
import { IUserAccount } from '../../../interfaces/passport/user'
import { useTranslation } from 'react-i18next'
import { IFaculty, IFacultyGroup, IGrade } from '../../../interfaces/passport/organizations'
import { getAccountRoleName } from '../../../helpers/roles'
import { selectProps } from '../../../components/UI/Select/helpers'
import { TStatus } from '../../../interfaces/helpers'
import Loading from '../../../components/UI/Loading/Loading'

interface IFormInputs {
    faculty: string
    grade: string
    facultyGroup: string
}

export interface IAccountFormResult extends IFormInputs {
    organization: string;
    accountId: string;
}

interface IAccountFormProps {
    account: IUserAccount
    isSubmitting?: boolean
    onChange: (item: IAccountFormResult, isValid: boolean) => void
}

const AccountForm: React.FC<IAccountFormProps & ConnectedProps<typeof connector>> = ({
    account,
    getFaculties,
    getGrades,
    getGroups,
    isSubmitting,
    onChange
}) => {
    const { t } = useTranslation()
    const [status, setStatus] = useState<TStatus>('loading')
    const [faculties, setFaculties] = useState<IFaculty[]>([])
    const [facultiesGroups, setFacultiesGroups] = useState<IFacultyGroup[]>([])
    const [grades, setGrades] = useState<IGrade[]>([])
    const [accountData, setAccountData] = useState<IFormInputs>({
        faculty: account.faculty?.id || '',
        grade: account.grade?.id || '',
        facultyGroup: account.facultyGroup?.id || ''
    })
    const selectFacultyGroupRef = useRef<any>(null)

    const onChangeForm = (data: IFormInputs) => {
        onChange({ ...data, accountId: account.accountId, organization: account.organization.id }, !!data.faculty && !!data.facultyGroup && !!data.grade)
    }


    const changeFacultyHandle = useCallback((value: string) => {
        const organizationId = account.organization?.id

        if (!organizationId) {
            return
        }

        setFacultiesGroups([])
        selectFacultyGroupRef.current.select.clearValue()

        getGroups(organizationId, value).then(response => {
            if (response?.status === 200) {
                setFacultiesGroups(response.data)
            }
        })
    }, [getGroups, account])

    useEffect(() => {
        const organizationId = account.organization?.id

        if (!organizationId) {
            return
        }

        getFaculties(organizationId).then(response => {
            if (response?.status === 200) {
                setFaculties(response.data)

                if (accountData.faculty) {
                    getGroups(organizationId, accountData.faculty).then(response => {
                        if (response?.status === 200) {
                            setFacultiesGroups(response.data)
                        }
                    })
                }
            }
        })

        getGrades(organizationId).then(response => {
            if (response?.status === 200) {
                setGrades(response.data)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getFaculties, getGrades, account, getGroups])

    useEffect(() => {
        if (grades.length && faculties.length && facultiesGroups.length) {
            setStatus('ready')
        }
    }, [grades, faculties, facultiesGroups])


    if (status === 'loading' || isSubmitting) return <Loading />

    return (
        <div className="profile-account-edit">
            <div className="form-title">{`${t('pages:profile:accountInfo')} ${account.organization.name}`}</div>
            <p>
                {t('pages:profile:accountRole')} <strong>{t(getAccountRoleName(account))}</strong>
            </p>
            {!!account.student && (
                <div className="form-row">
                    <div className="form-col form-col-33">
                        <div className="form-item">
                            <Select
                                name="faculty"
                                defaultValue={
                                    faculties
                                        .filter(item => item.id === account.faculty?.id)
                                        .map(item => {
                                            return {
                                                label: item.name,
                                                value: item.id
                                            }
                                        })[0]
                                }
                                options={faculties.map(item => {
                                    return {
                                        label: item.name,
                                        value: item.id
                                    }
                                })}
                                placeholder={t('pages:users:fieldFaculty')}
                                isDisabled={!!isSubmitting || !account.student || !faculties.length}
                                isRequired={account.student}
                                isError={!accountData.faculty}
                                onChange={option => {
                                    if (option) {
                                        const data = { ...accountData, faculty: option.value, facultyGroup: '' };
                                        setAccountData(data)
                                        changeFacultyHandle(option.value)
                                        onChangeForm(data)
                                    }
                                }}
                                {...selectProps}
                            />
                        </div>
                    </div>

                    <div className="form-col form-col-33">
                        <div className="form-item">
                            <Select
                                name="group"
                                defaultValue={
                                    facultiesGroups
                                        .filter(item => item.id === account.facultyGroup?.id)
                                        .map(item => {
                                            return {
                                                label: item.name,
                                                value: item.id
                                            }
                                        })[0]
                                }
                                options={facultiesGroups.map(item => {
                                    return {
                                        label: item.name,
                                        value: item.id
                                    }
                                })}
                                placeholder={t('pages:profile:fieldFacultyGroup')}
                                isDisabled={!!isSubmitting || !account.student || !facultiesGroups.length}
                                isRequired={account.student}
                                isError={!accountData.facultyGroup}
                                onChange={option => {
                                    if (option) {
                                        const data = { ...accountData, facultyGroup: option.value };
                                        setAccountData(data)
                                        onChangeForm(data)
                                    }
                                }}
                                ref={selectFacultyGroupRef}
                                {...selectProps}
                            />
                        </div>
                    </div>

                    <div className="form-col form-col-33">
                        <div className="form-item">
                            <Select
                                name="grade"
                                defaultValue={
                                    grades
                                        .filter(item => item.id === account.grade?.id)
                                        .map(item => {
                                            return {
                                                label: item.name,
                                                value: item.id
                                            }
                                        })[0]
                                }
                                options={grades.map(item => {
                                    return {
                                        label: item.name,
                                        value: item.id
                                    }
                                })}
                                placeholder={t('pages:profile:fieldGrade')}
                                isDisabled={!!isSubmitting || !account.student || !grades.length}
                                isRequired={account.student}
                                isError={!accountData.grade}
                                onChange={option => {
                                    if (option) {
                                        const data = { ...accountData, grade: option.value };
                                        setAccountData(data)
                                        onChangeForm(data)
                                    }
                                }}
                                {...selectProps}
                            />
                        </div>
                    </div>
                </div>)}
        </div>
    );
}

const mapDispatch = {
    getFaculties: getFacultyByIdHandler,
    getGrades: getGradesByIdHandler,
    getGroups: getFacultyGroupsByIdHandler,
}

const connector = connect(null, mapDispatch)

export default connector(AccountForm)