import React, { useEffect, useState } from 'react'
import Pagetitle from '../../../components/UI/Pagetitle/Pagetitle'
import { IPagination, TSortColumnsList, TStatus } from '../../../interfaces/helpers'
import { useTranslation } from 'react-i18next'
import Loading from '../../../components/UI/Loading/Loading'
import Error from '../../Error/Error'
import { connect, ConnectedProps } from 'react-redux'
import NoResults from '../../../components/UI/NoResults/NoResults'
import {
	createSupportRequestEmployeeHandler,
	supportRequestEmployeeGetAllListOfAnyUserRequestsForOrgAdminHandler,
	supportRequestEmployeeGetAllListOfOrgUserRequestsForOrgAdminHandler
} from '../../../services/support/supportRequestEmployee'
import {
	ISupportRequestEmployeeAPIResponse,
	ISupportRequestEmployeeCreate,
	ISupportRequestEmployeeListOfUserRequestsForOrgAdminAPIRequest,
	TSupportRequestEmployeeSortFields
} from '../../../interfaces/support/supportRequestEmployee'
import {
	DATE_FORMAT_FULL,
	DEFAULT_PAGE_SIZE,
	getStatusName,
	INFINITE_NUMBER,
	stringToLocalization,
	SUPPORT_STATUSES
} from '../../../helpers/helpers'
import { selectProps, TOnChange } from '../../../components/UI/Select/helpers'
import Pagination from '../../../components/UI/Pagination/Pagination'
import SearchField from '../../../components/UI/SearchField/SearchField'
import SelectLimit from '../../../components/UI/Select/SelectLimit'
import Checkbox from '../../../components/UI/Checkbox/Checkbox'
import Select from 'react-select'
import dayjs from 'dayjs'
import { Link, useParams } from 'react-router-dom'
import Button from '../../../components/UI/Button/Button'
import { modalShowHandler } from '../../../store/actionCreators/UI/modal'
import ModalAddTicket from './ModalAddTicket'
import { requestTopicGetAllHandler } from '../../../services/support/requestTopic'
import { toggleLoaderHandler } from '../../../store/actionCreators/UI/loader'
import { getAllUsersHandler } from '../../../services/passport/user'
import { RootState } from '../../../store/reducers/rootReducer'

const Support = ({
	user,
	getAllTicketsOrg,
	getAllTicketsCloud,
	createSupportRequestEmployee,
	getUsers,
	getTopics,
	toggleLoader,
	showModal
}: ConnectedProps<typeof connector>) => {
	const [routes] = useState(['cloud', 'organization'])

	const { t } = useTranslation()
	const { type } = useParams()
	const [status, setStatus] = useState<TStatus>('loading')
	const [table, setTable] = useState<ISupportRequestEmployeeAPIResponse[]>([])

	const [pagination, setPagination] = useState<IPagination>({
		pageNo: 0,
		totalPages: 0,
		totalCount: 0
	})
	const [
		filterSupportRequestEmployee,
		setFilterSupportRequestEmployee
	] = useState<ISupportRequestEmployeeListOfUserRequestsForOrgAdminAPIRequest>({
		stateFilter: '',
		numOrTopicOrSubjectFilter: '',
		requestEmployeeSortField: 'CHANGE_DATE',
		withClosed: false,
		desc: true,
		pageSize: DEFAULT_PAGE_SIZE,
		pageNo: 0
	})

	const searchHandle = (value: string) => {
		setFilterSupportRequestEmployee({
			...filterSupportRequestEmployee,
			numOrTopicOrSubjectFilter: value,
			pageNo: 0
		})
	}

	const paginationHandle = (pageNo: number) => {
		setFilterSupportRequestEmployee({
			...filterSupportRequestEmployee,
			pageNo
		})
	}

	const limitHandle: TOnChange = option => {
		if (option) {
			setFilterSupportRequestEmployee({
				...filterSupportRequestEmployee,
				pageSize: parseInt(option.value) || INFINITE_NUMBER,
				pageNo: 0
			})
		}
	}

	const selectStateHandle: TOnChange = option => {
		if (option) {
			setFilterSupportRequestEmployee({
				...filterSupportRequestEmployee,
				stateFilter: option.value
			})
		}
	}

	const showArchivedHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFilterSupportRequestEmployee({
			...filterSupportRequestEmployee,
			withClosed: e.target.checked
		})
	}

	const [sortColumns, setSortColumns] = useState<TSortColumnsList>({
		CHANGE_DATE: {
			desc: true,
			classNames: ['list-item', 'list-item--down']
		},
		NUMBER: {
			desc: true,
			classNames: ['list-item']
		},
		CREATE_DATE: {
			desc: true,
			classNames: ['list-item']
		},
		TOPIC: {
			desc: true,
			classNames: ['list-item']
		},
		STATE: {
			desc: true,
			classNames: ['list-item']
		},
		PRIORITY: {
			desc: true,
			classNames: ['list-item']
		}
	})

	const sortHandle = (requestEmployeeSortField: TSupportRequestEmployeeSortFields) => {
		const updatedSortColumns = { ...sortColumns }

		Object.keys(updatedSortColumns).forEach((key: string) => {
			if (key !== requestEmployeeSortField) {
				updatedSortColumns[key] = {
					desc: true,
					classNames: ['list-item']
				}
			} else {
				updatedSortColumns[key].desc = !updatedSortColumns[key].desc
				updatedSortColumns[key].classNames = [
					'list-item',
					updatedSortColumns[key].desc ? 'list-item--down' : 'list-item--up'
				]
			}
		})

		setFilterSupportRequestEmployee({
			...filterSupportRequestEmployee,
			requestEmployeeSortField,
			desc: updatedSortColumns[requestEmployeeSortField].desc
		})

		setSortColumns(updatedSortColumns)
	}

	const createHandler = async () => {
		toggleLoader(true)

		try {
			const topics = await getTopics({
				topicSortField: 'NAME',
				desc: false,
				pageNo: 0,
				pageSize: INFINITE_NUMBER
			})

			const users = await getUsers({
				orgFilter: user.accounts?.[0]?.organization?.id,
				userSortField: 'Name',
				desc: false,
				pageNo: 0,
				pageSize: INFINITE_NUMBER
			})

			showModal({
				type: 'middle',
				Component: ModalAddTicket,
				ComponentProps: {
					user: user,
					topics: topics.data.data,
					users: users.data.data,
					onConfirm: async (data: ISupportRequestEmployeeCreate) => {
						toggleLoader(true)

						try {
							const response = await createSupportRequestEmployee(data)

							if (response?.status === 200) {
								paginationHandle(0)
							}

							return response
						} finally {
							toggleLoader(false)
						}
					}
				}
			})
		} finally {
			toggleLoader(false)
		}
	}

	useEffect(() => {
		if (!routes.includes(type)) {
			setStatus('error')
		} else {
			if (type === 'organization') {
				getAllTicketsOrg(filterSupportRequestEmployee).then(response => {
					if (response?.status === 200) {
						const { data, pageNo, totalPages, totalCount } = response.data

						setTable(data)

						setPagination({
							pageNo,
							totalPages,
							totalCount
						})

						setStatus('ready')
					}
				})
			}

			if (type === 'cloud') {
				getAllTicketsCloud(filterSupportRequestEmployee).then(response => {
					if (response?.status === 200) {
						const { data, pageNo, totalPages, totalCount } = response.data

						setTable(data)

						setPagination({
							pageNo,
							totalPages,
							totalCount
						})

						setStatus('ready')
					}
				})
			}
		}
	}, [getAllTicketsOrg, getAllTicketsCloud, filterSupportRequestEmployee, routes, type])

	if (status === 'loading') return <Loading />
	if (status === 'error') return <Error />

	return (
		<>
			<Pagetitle
				title={t(type === 'cloud' ? 'pages:support:crumbsSupportCloud' : 'pages:support:crumbsSupportOrg', {
					name: user.accounts?.[0]?.organization?.name
				})}
				crumbs={[
					{
						title: t('pages:support:titleAdmin'),
						to: '/support'
					}
				]}
			/>

			{type === 'cloud' && (
				<div className="button-row button-row--support">
					<Button type={'primary'} htmlType={'button'} onClick={createHandler}>
						{t('pages:support:addNew')}
					</Button>
				</div>
			)}

			<div className="filters">
				<div className="filters-item">
					<SearchField onSubmit={searchHandle} placeholder={t('pages:support:searchField')} />
				</div>

				<div className="filters-item filters-item--select-small">
					<Select
						name={'state'}
						onChange={selectStateHandle}
						options={[
							{
								label: t('common:selectDefaultValue'),
								value: ''
							},
							...SUPPORT_STATUSES.map(item => {
								return {
									label: t(getStatusName(item)),
									value: item
								}
							})
						]}
						defaultValue={{
							label: t('common:selectDefaultValue'),
							value: ''
						}}
						placeholder={t('pages:support:state')}
						{...selectProps}
					/>
				</div>

				<div className="filters-item filters-item--view">
					<SelectLimit total={pagination.totalCount} onChange={limitHandle} />
				</div>

				<div className="filters-item filters-item--checkbox">
					<Checkbox name={'withClosed'} onChange={showArchivedHandler}>
						{t('pages:support:withClosed')}
					</Checkbox>
				</div>
			</div>

			<div className="sorting">
				<div className="sorting-title">
					<strong>{t('pages:support:sortBy')}</strong>
				</div>

				<div className="sorting-list">
					<ul className="list">
						<li onClick={() => sortHandle('CREATE_DATE')} className={sortColumns.CREATE_DATE.classNames.join(' ')}>
							<span>{t('pages:support:sortByCreateDate')}</span>
						</li>
						<li onClick={() => sortHandle('CHANGE_DATE')} className={sortColumns.CHANGE_DATE.classNames.join(' ')}>
							<span>{t('pages:support:sortByDate')}</span>
						</li>
						<li onClick={() => sortHandle('NUMBER')} className={sortColumns.NUMBER.classNames.join(' ')}>
							<span>{t('pages:support:sortByNum')}</span>
						</li>
					</ul>
				</div>
			</div>

			{table.length > 0 ? (
				<div className="pagination-wrapper">
					<div className="support-list">
						{table.map(item => (
							<Link
								key={item.id}
								className={`support-item ${
									(!item.readDate && dayjs(item.updatedWhen).diff(dayjs(item.fromDate)) > 0) ||
									(item.readDate && dayjs(item.updatedWhen).diff(dayjs(item.readDate)) > 0)
										? 'support-item--update'
										: ''
								} `}
								to={`/support/${item.id}`}
							>
								<div className="support-item-holder">
									<div className="support-item-number">#{item.num}</div>
									<div className="support-item-content">
										<h4>{item.subject}</h4>
										<div className="support-item-data">
											<span>
												{t('pages:support:owner')}: {item.ownerName} {item.clientName && `(${item.clientName})`}
											</span>
											<span>
												{t('pages:support:fromDate')}: {dayjs(item.fromDate).format(DATE_FORMAT_FULL)}
											</span>
										</div>
										<div className="support-item-theme">
											{t(`common:topic_${stringToLocalization(item.topic.name)}`)}
										</div>
									</div>

									<div className="support-item-status">
										<span className={`task-status task-status--${item.state.toLowerCase()}`}>
											{t(getStatusName(item.state))}
										</span>
									</div>
								</div>
							</Link>
						))}
					</div>

					<Pagination onClick={paginationHandle} {...pagination} />
				</div>
			) : (
				<NoResults />
			)}
		</>
	)
}

const mapState = (state: RootState) => ({
	user: state.user.user
})

const mapDispatch = {
	getUsers: getAllUsersHandler,
	getTopics: requestTopicGetAllHandler,
	getAllTicketsCloud: supportRequestEmployeeGetAllListOfAnyUserRequestsForOrgAdminHandler,
	getAllTicketsOrg: supportRequestEmployeeGetAllListOfOrgUserRequestsForOrgAdminHandler,
	createSupportRequestEmployee: createSupportRequestEmployeeHandler,

	showModal: modalShowHandler,
	toggleLoader: toggleLoaderHandler
}

const connector = connect(mapState, mapDispatch)

export default connector(Support)
