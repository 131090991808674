import React, { useRef, useState } from 'react'
import Button from '../../../components/UI/Button/Button'
import { Controller, useForm } from 'react-hook-form'
import Input from '../../../components/UI/Input/Input'
import { REGEX_EMAIL, REGEX_PHONE } from '../../../helpers/helpers'
import { CLOUD_ADMIN, getUserRoleName, ORG_ADMIN, SUPPORT } from '../../../helpers/roles'
import { modalConfirmShowHandler } from '../../../store/actionCreators/UI/modalConfirm'
import { connect, ConnectedProps } from 'react-redux'
import { IUser, IUserUpdate, TUserRoles } from '../../../interfaces/passport/user'
import { IOrganization } from '../../../interfaces/passport/organizations'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { selectProps } from '../../../components/UI/Select/helpers'

type TProps = ConnectedProps<typeof connector> & {
	hideModal: () => void
	user: IUser
	organizations: Array<IOrganization>
	onConfirm: (id: string, data: IUserUpdate) => Promise<any>
	onRemove: (id: string) => Promise<any>
}

interface IFormFields {
	firstName: string
	lastName: string
	patronymic?: string
	email: string
	phone?: string
	role: TUserRoles
	organization?: string
}

const ModalEditSystemUser = ({ hideModal, user, organizations, onConfirm, showModalConfirm, onRemove }: TProps) => {
	const { t } = useTranslation()
	const { register, watch, errors, handleSubmit, formState, control } = useForm<IFormFields>({
		defaultValues: {
			firstName: user.firstName,
			lastName: user.lastName,
			patronymic: user.patronymic,
			email: user.email,
			phone: user.phone,
			role: user.role,
			organization: user.accounts?.[0]?.organization?.id || ''
		}
	})
	const { isDirty } = formState
	const [isSubmitting, setIsSubmitting] = useState(false)

	const selectOrgainzationRef = useRef<any>(null)

	const onSubmit = async (data: IFormFields) => {
		setIsSubmitting(true)

		try {
			if (data.role !== ORG_ADMIN) data.organization = ''
			const organizationId = data.organization
			const result = { ...data, organization: undefined, accounts: [{ organization: organizationId }] }

			const response = await onConfirm(user.id, result)

			if (response?.status === 200) {
				hideModal()
			}
		} finally {
			setIsSubmitting(false)
		}
	}

	const cancelHandler = () => {
		if (isDirty) {
			showModalConfirm({
				type: 'cancel',
				onConfirm: hideModal
			})
		} else {
			hideModal()
		}
	}

	const removeHandler = () => {
		showModalConfirm({
			type: 'remove',
			elementText: `${user.firstName} ${user.lastName}`,
			onConfirm: async () => {
				try {
					const response = await onRemove(user.id)

					if (response?.status === 200) {
						hideModal()
					}
				} finally {
				}
			}
		})
	}

	return (
		<>
			<button className="modal-close" onClick={cancelHandler} disabled={isSubmitting} />

			<h3 className="modal-title">{t('common:appName')}</h3>

			<p className="modal-footnote">{t('pages:systemUsers:modalUpdateText')}</p>

			<form className="form" onSubmit={handleSubmit(onSubmit)}>
				<div className="form-item">
					<Input
						currentValue={watch('lastName')}
						type={'text'}
						name={'lastName'}
						placeholder={t('pages:systemUsers:fieldLastName')}
						isRequired={true}
						isDisabled={isSubmitting}
						isError={!!errors.lastName}
						reference={register({
							required: true,
							maxLength: 255
						})}
						rules={{
							maxLength: 255
						}}
						errors={errors.lastName}
					/>
				</div>

				<div className="form-item">
					<Input
						currentValue={watch('firstName')}
						type={'text'}
						name={'firstName'}
						placeholder={t('pages:systemUsers:fieldFirstName')}
						isRequired={true}
						isDisabled={isSubmitting}
						isError={!!errors.firstName}
						reference={register({
							required: true,
							maxLength: 255
						})}
						rules={{
							maxLength: 255
						}}
						errors={errors.firstName}
					/>
				</div>

				<div className="form-item">
					<Input
						currentValue={watch('patronymic')}
						type={'text'}
						name={'patronymic'}
						placeholder={t('pages:systemUsers:fieldPatronymic')}
						isDisabled={isSubmitting}
						isError={!!errors.patronymic}
						reference={register({
							maxLength: 255
						})}
						rules={{
							maxLength: 255
						}}
						errors={errors.patronymic}
					/>
				</div>

				<div className="form-row">
					<div className="form-col">
						<div className="form-item">
							<Input
								currentValue={watch('email')}
								type={'email'}
								name={'email'}
								placeholder={t('pages:systemUsers:fieldEmail')}
								isRequired={true}
								isDisabled={isSubmitting}
								isError={!!errors.email}
								reference={register({
									required: true,
									minLength: 6,
									maxLength: 255,
									pattern: REGEX_EMAIL
								})}
								rules={{
									minLength: 6,
									maxLength: 255
								}}
								errors={errors.email}
							/>
						</div>
					</div>

					<div className="form-col">
						<div className="form-item">
							<Input
								currentValue={watch('phone')}
								type={'tel'}
								name={'phone'}
								placeholder={t('pages:systemUsers:fieldPhone')}
								isDisabled={isSubmitting}
								isError={!!errors.phone}
								reference={register({
									maxLength: 255,
									pattern: REGEX_PHONE
								})}
								rules={{
									maxLength: 255
								}}
								errors={errors.phone}
							/>
						</div>
					</div>
				</div>

				<div className="form-item">
					<Controller
						render={({ onChange, name }) => (
							<Select
								name={name}
								defaultValue={{
									label: t(getUserRoleName(user)),
									value: user.role
								}}
								options={[
									{
										label: t('common:roleAdminCloud'),
										value: CLOUD_ADMIN
									},
									{
										label: t('common:roleSupport'),
										value: SUPPORT
									},
									{
										label: t('common:roleAdminOrganization'),
										value: ORG_ADMIN
									}
								]}
								placeholder={t('pages:systemUsers:fieldRole')}
								isDisabled={isSubmitting}
								isRequired={true}
								isError={!!errors.role}
								errors={errors.role}
								onChange={option => {
									if (option) {
										onChange(option.value)
										if (watch('role') !== ORG_ADMIN) {
											selectOrgainzationRef.current.select.clearValue()
										}
									}
								}}
								{...selectProps}
							/>
						)}
						name={'role'}
						control={control}
						rules={{
							required: true
						}}
					/>
				</div>

				<div className="form-item">
					<Controller
						render={({ onChange, name }) => (
							<Select
								name={name}
								defaultValue={
									organizations
										.filter(el => el.id === user?.accounts?.[0]?.organization?.id)
										.map(el => {
											return {
												label: el.name,
												value: el.id
											}
										})[0]
								}
								options={organizations.map(item => {
									return {
										label: item.name,
										value: item.id
									}
								})}
								placeholder={t('pages:systemUsers:fieldOrganization')}
								isDisabled={watch('role') !== ORG_ADMIN || isSubmitting}
								isRequired={watch('role') === ORG_ADMIN}
								isError={!!errors.organization}
								errors={errors.organization}
								onChange={option => option && onChange(option.value)}
								ref={selectOrgainzationRef}
								{...selectProps}
							/>
						)}
						name={'organization'}
						control={control}
						rules={{
							required: watch('role') === ORG_ADMIN
						}}
					/>
				</div>

				<div className="form-controls form-controls--justify">
					<Button type={'secondary'} htmlType={'button'} onClick={removeHandler} isDisabled={isSubmitting}>
						{t('common:actionRemove')}
					</Button>

					<Button type={'secondary'} htmlType={'button'} onClick={cancelHandler} isDisabled={isSubmitting}>
						{t('common:actionCancel')}
					</Button>

					<Button type={'primary'} htmlType={'submit'} isDisabled={isSubmitting}>
						{t('common:actionSave')}
					</Button>
				</div>
			</form>
		</>
	)
}

const mapDispatch = {
	showModalConfirm: modalConfirmShowHandler
}

const connector = connect(null, mapDispatch)

export default connector(ModalEditSystemUser)
