import React, { useEffect, useState } from 'react'
import {
	createOrganizationContactByIdHandler,
	deleteOrganizationContactByIdHandler,
	getOrganizationByIdHandler,
	updateOrganizationContactByIdHandler
} from '../../../services/passport/organization'
import { connect, ConnectedProps } from 'react-redux'
import Pagetitle from '../../../components/UI/Pagetitle/Pagetitle'
import Button from '../../../components/UI/Button/Button'
import { modalShowHandler } from '../../../store/actionCreators/UI/modal'
import { toggleLoaderHandler } from '../../../store/actionCreators/UI/loader'
import Loading from '../../../components/UI/Loading/Loading'
import { sleep } from '../../../helpers/api'
import { RootState } from '../../../store/reducers/rootReducer'
import { useTranslation } from 'react-i18next'
import { TStatus } from '../../../interfaces/helpers'
import Error from '../../Error/Error'
import OrganizationInfo from '../../../components/OrganizationInfo/OrganizationInfo'
import ModalEditOrganizationInfo, {
	IOrganizationUpdateCustom
} from '../../../components/OrganizationInfo/ModalEditOrganizationInfo'
import { updateOrganizationById } from '../../../store/actionCreators/passport/organization'

const Organization = ({
	user,
	organization,
	getOrganization,
	updateOrganization,
	createContact,
	updateContact,
	removeContact,
	showModal,
	toggleLoader
}: ConnectedProps<typeof connector>) => {
	const { t } = useTranslation()
	const [status, setStatus] = useState<TStatus>('loading')

	const updateHandle = () => {
		const organizationId = user.accounts?.[0]?.organization.id

		if (!organizationId) {
			return
		}

		showModal({
			type: 'large-form',
			Component: ModalEditOrganizationInfo,
			ComponentProps: {
				type: 'update',
				user,
				organization,
				onConfirm: async (data: IOrganizationUpdateCustom) => {
					toggleLoader(true)

					try {
						const {
							organization: updatedOrganization,
							mainContact,
							newContacts,
							removedContacts,
							updatedContacts
						} = data

						const updateData = []

						// Update organization
						if (updatedOrganization) {
							updateOrganization(updatedOrganization)
						}

						// Update main contact
						if (mainContact) {
							updateData.push(await sleep(), await updateContact(organizationId, mainContact))
						}

						// Update current contacts
						if (updatedContacts.length > 0) {
							for (let i = 0; i < updatedContacts.length; i++) {
								updateData.push(await sleep(), await updateContact(organizationId, updatedContacts[i]))
							}
						}

						// Add new contacts
						if (newContacts.length > 0) {
							for (let i = 0; i < newContacts.length; i++) {
								updateData.push(await sleep(), await createContact(organizationId, newContacts[i]))
							}
						}

						// Remove contacts
						if (removedContacts.length > 0) {
							for (let i = 0; i < removedContacts.length; i++) {
								updateData.push(await sleep(), await removeContact(organizationId, removedContacts[i]))
							}
						}

						const responseAll = await Promise.all(updateData)

						return responseAll.filter((res: any) => res)
					} finally {
						toggleLoader(false)
					}
				}
			}
		})
	}

	useEffect(() => {
		const organizationId = user.accounts?.[0]?.organization.id

		if (!organizationId) {
			return
		}

		getOrganization(organizationId).then(response => {
			if (response?.status === 200) {
				setStatus('ready')
			} else {
				setStatus('error')
			}
		})
	}, [getOrganization, user.accounts])

	if (status === 'loading') return <Loading />
	if (status === 'error') return <Error />

	return (
		<>
			<Pagetitle title={organization.name} />

			<OrganizationInfo organization={organization} />

			<div className="button-row button-row--options">
				<Button type={'primary'} htmlType={'button'} onClick={updateHandle}>
					{t('common:actionEdit')}
				</Button>
			</div>
		</>
	)
}

const mapState = (state: RootState) => ({
	organization: state.organization.organization,
	user: state.user.user
})

const mapDispatch = {
	getOrganization: getOrganizationByIdHandler,
	updateOrganization: updateOrganizationById,

	createContact: createOrganizationContactByIdHandler,
	updateContact: updateOrganizationContactByIdHandler,
	removeContact: deleteOrganizationContactByIdHandler,

	showModal: modalShowHandler,
	toggleLoader: toggleLoaderHandler
}

const connector = connect(mapState, mapDispatch)

export default connector(Organization)
