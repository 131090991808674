import { APIResponse, AppThunk } from '../../interfaces/helpers'
import {
	IBatchActionsUsersResponse,
	IUploadUserData,
	IUploadUsers,
	IUser,
	IUserAccountUpdate,
	IUserActivationRequest,
	IUserActivationResponse,
	IUserAPIRequest,
	IUserCreate,
	IUserExportAPIRequest,
	IUserSelfUpdate,
	IUserUpdate
} from '../../interfaces/passport/user'
import axios, { AxiosError } from 'axios'
import { API_PASSPORT_PATH, APIServices } from '../../helpers/api'
import { ErrorData, setErrorHandler } from '../../store/actionCreators/error'
import { setProgress } from '../../store/actionCreators/app'
import { setUserData } from '../../store/actionCreators/passport/user'
import { serviceHandlerErrorOptions } from '../helpers'

export function getUserDataHandler(errorOptions = serviceHandlerErrorOptions): AppThunk<IUser> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IUser>({
				method: 'GET',
				url: `${API_PASSPORT_PATH}/users/self`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens?.access_token}`
				}
			})

			localStorage.setItem('user', JSON.stringify(response.data))
			dispatch(setUserData(response.data))
			return response
		} catch (ex) {
			const error: AxiosError<ErrorData> = ex as AxiosError<ErrorData>;
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response as any
		}
	}
}

export function setUserDataHandler(data: IUserSelfUpdate, errorOptions = serviceHandlerErrorOptions): AppThunk<IUser> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IUser>({
				method: 'POST',
				url: `${API_PASSPORT_PATH}/users/self`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				data
			})
			localStorage.setItem('user', JSON.stringify(response.data))
			dispatch(setUserData(response.data))
			return response
		} catch (ex) {
			const error: AxiosError<ErrorData> = ex as AxiosError<ErrorData>;
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response as any
		}
	}
}

export function getUserDataByIdHandler(userId: string, errorOptions = serviceHandlerErrorOptions): AppThunk<IUser> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IUser>({
				method: 'GET',
				url: `${API_PASSPORT_PATH}/users/${userId}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id: userId
				}
			})
			return response
		} catch (ex) {
			const error: AxiosError<ErrorData> = ex as AxiosError<ErrorData>;
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response as any
		}
	}
}

export function getUserDataByMailHandler(email: string, errorOptions = serviceHandlerErrorOptions): AppThunk<IUser> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IUser>({
				method: 'GET',
				url: `${API_PASSPORT_PATH}/users/byMail`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					email
				}
			})
			return response
		} catch (ex) {
			const error: AxiosError<ErrorData> = ex as AxiosError<ErrorData>;
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response as any
		}
	}
}

export function attachUserToOrganizationHandler(
	userId: string,
	data: IUserAccountUpdate,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IUser> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IUser>({
				method: 'POST',
				url: `${API_PASSPORT_PATH}/users/${userId}/attach`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id: userId
				},
				data
			})
			return response
		} catch (ex) {
			const error: AxiosError<ErrorData> = ex as AxiosError<ErrorData>;
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response as any
		}
	}
}

export function updateUserDataByIdHandler(
	userId: string,
	data: IUserUpdate,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IUser> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IUser>({
				method: 'PUT',
				url: `${API_PASSPORT_PATH}/users/${userId}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id: userId
				},
				data
			})
			return response
		} catch (ex) {
			const error: AxiosError<ErrorData> = ex as AxiosError<ErrorData>;
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response as any
		}
	}
}

export function deleteUserDataByIdHandler(userId: string, errorOptions = serviceHandlerErrorOptions): AppThunk {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request({
				method: 'DELETE',
				url: `${API_PASSPORT_PATH}/users/${userId}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id: userId
				}
			})
			return response
		} catch (ex) {
			const error: AxiosError<ErrorData> = ex as AxiosError<ErrorData>;
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response as any
		}
	}
}

export function userConfirmEmailHandler(code: string, errorOptions = serviceHandlerErrorOptions): AppThunk {
	return async dispatch => {
		try {
			const response = await axios({
				method: 'POST',
				url: `${API_PASSPORT_PATH}/users/public/confirmEmail`,
				auth: {
					username: `${process.env.REACT_APP_AUTH_CLIENT_ID}`,
					password: `${process.env.REACT_APP_AUTH_CLIENT_SECRET}`
				},
				data: {
					code
				}
			})
			return response
		} catch (ex) {
			const error: AxiosError<ErrorData> = ex as AxiosError<ErrorData>;
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response as any
		}
	}
}

export function getAllUsersHandler(
	params: IUserAPIRequest,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<APIResponse<IUser[]>> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<APIResponse<IUser[]>>({
				method: 'GET',
				url: `${API_PASSPORT_PATH}/users`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params
			})
			return response
		} catch (ex) {
			const error: AxiosError<ErrorData> = ex as AxiosError<ErrorData>;
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response as any
		}
	}
}

export function getAllUsersByAccountsHandler(
	params: IUserAPIRequest,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<APIResponse<IUser[]>> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<APIResponse<IUser[]>>({
				method: 'GET',
				url: `${API_PASSPORT_PATH}/users`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params
			})
			if (response.status !== 200) return response

			const data = response.data;
			const splittedData: IUser[] = data.data.reduce<IUser[]>((result, item) => {
				item.accounts?.forEach(acc => {
					result.push({
						...item,
						accounts: [acc]
					})
				})
				return result
			}, []);
			return { ...response, data: { ...data, data: splittedData } }
		} catch (ex) {
			const error: AxiosError<ErrorData> = ex as AxiosError<ErrorData>;
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response as any
		}
	}
}

export function createUserHandler(data: IUserCreate, errorOptions = serviceHandlerErrorOptions): AppThunk<IUser> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IUser>({
				method: 'POST',
				url: `${API_PASSPORT_PATH}/users`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				data
			})
			return response
		} catch (ex) {
			const error: AxiosError<ErrorData> = ex as AxiosError<ErrorData>;
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response as any
		}
	}
}

export function uploadUsersHandler(
	data: IUploadUserData,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IUploadUsers> {
	return async (dispatch, getState) => {
		const formData = new FormData()
		formData.append('data', data.file[0])

		try {
			const response = await axios.request<IUploadUsers>({
				method: 'POST',
				url: `${API_PASSPORT_PATH}/users/upload`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`,
					'Content-Type': 'multipart/form-data'
				},
				params: {
					students: data.students,
					licenseGroupId: data.licenseGroupId,
					organizationId: data.organizationId || ''
				},
				data: formData,
				onUploadProgress: progress => {
					const value = Math.round((progress.loaded * 100) / progress.total)
					dispatch(setProgress(value))
				}
			})
			dispatch(setProgress(0))
			return response
		} catch (ex) {
			const error: AxiosError<ErrorData> = ex as AxiosError<ErrorData>;
			dispatch(setProgress(0))
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response as any
		}
	}
}

export function uploadUsersStatusHandler(
	id: string,
	showResults: boolean = false,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IUploadUsers> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IUploadUsers>({
				method: 'get',
				url: `${API_PASSPORT_PATH}/users/upload/${id}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id,
					showResults
				}
			})
			return response
		} catch (ex) {
			const error: AxiosError<ErrorData> = ex as AxiosError<ErrorData>;
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response as any
		}
	}
}

export function exportUsersHandler(
	params: IUserExportAPIRequest,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<BlobPart> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<BlobPart>({
				method: 'GET',
				url: `${API_PASSPORT_PATH}/users/export/${params.fileName}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				responseType: 'blob',
				params
			})
			return response
		} catch (ex) {
			const error: AxiosError<ErrorData> = ex as AxiosError<ErrorData>;
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response as any
		}
	}
}

export function lockUsersByIdsHandler(
	ids: Array<string>,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IBatchActionsUsersResponse[]> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IBatchActionsUsersResponse[]>({
				method: 'POST',
				url: `${API_PASSPORT_PATH}/users/lock`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				data: ids
			})
			return response
		} catch (ex) {
			const error: AxiosError<ErrorData> = ex as AxiosError<ErrorData>;
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response as any
		}
	}
}

export function lockUsersInOrgByIdsHandler(
	ids: Array<string>,
	organizationId: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IBatchActionsUsersResponse[]> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IBatchActionsUsersResponse[]>({
				method: 'POST',
				url: `${API_PASSPORT_PATH}/users/organization/${organizationId}/lock`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				data: ids
			})
			return response
		} catch (ex) {
			const error: AxiosError<ErrorData> = ex as AxiosError<ErrorData>;
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response as any
		}
	}
}

export function unlockUsersByIdsHandler(
	ids: Array<string>,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IBatchActionsUsersResponse[]> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IBatchActionsUsersResponse[]>({
				method: 'POST',
				url: `${API_PASSPORT_PATH}/users/unlock`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				data: ids
			})
			return response
		} catch (ex) {
			const error: AxiosError<ErrorData> = ex as AxiosError<ErrorData>;
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response as any
		}
	}
}

export function deleteUsersByIdsHandler(
	ids: Array<string>,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IBatchActionsUsersResponse[]> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IBatchActionsUsersResponse[]>({
				method: 'DELETE',
				url: `${API_PASSPORT_PATH}/users`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				data: ids
			})
			return response
		} catch (ex) {
			const error: AxiosError<ErrorData> = ex as AxiosError<ErrorData>;
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response as any
		}
	}
}

export function removeFromOrgByIdsHandler(
	ids: Array<string>,
	organizationId: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IBatchActionsUsersResponse[]> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IBatchActionsUsersResponse[]>({
				method: 'DELETE',
				url: `${API_PASSPORT_PATH}/users/organization/${organizationId}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				data: ids
			})
			return response
		} catch (ex) {
			const error: AxiosError<ErrorData> = ex as AxiosError<ErrorData>;
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response as any
		}
	}
}

export function getUsersByIdsHandler(ids: Array<string>, errorOptions = serviceHandlerErrorOptions): AppThunk<IUser[]> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IUser[]>({
				method: 'POST',
				url: `${API_PASSPORT_PATH}/users/names`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				data: ids
			})
			return response
		} catch (ex) {
			const error: AxiosError<ErrorData> = ex as AxiosError<ErrorData>;
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response as any
		}
	}
}

export function getUserActivationDataByCodeHandler(
	code: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IUserActivationResponse> {
	return async dispatch => {
		try {
			const response = await axios.request<IUserActivationResponse>({
				method: 'GET',
				url: `${API_PASSPORT_PATH}/users/public/activate`,
				params: {
					code
				}
			})
			return response
		} catch (ex) {
			const error: AxiosError<ErrorData> = ex as AxiosError<ErrorData>;
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response as any
		}
	}
}

export function userActivationHandler(
	data: IUserActivationRequest,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IUser> {
	return async dispatch => {
		try {
			const response = await axios.request<IUser>({
				method: 'POST',
				url: `${API_PASSPORT_PATH}/users/public/activate`,
				data
			})
			return response
		} catch (ex) {
			const error: AxiosError<ErrorData> = ex as AxiosError<ErrorData>;
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response as any
		}
	}
}

export function resendActivationEmailHandler(code: string, errorOptions = serviceHandlerErrorOptions): AppThunk {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request({
				method: 'POST',
				url: `${API_PASSPORT_PATH}/users/resendActivationEmail`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					code
				}
			})
			return response
		} catch (ex) {
			const error: AxiosError<ErrorData> = ex as AxiosError<ErrorData>;
			dispatch(setErrorHandler(error, APIServices.PASSPORT, errorOptions))
			return error.response as any
		}
	}
}
