import React, { useEffect, useMemo, useState } from 'react'
import Pagetitle from '../../../../components/UI/Pagetitle/Pagetitle'
import SearchField from '../../../../components/UI/SearchField/SearchField'
import { useHistory, useParams } from 'react-router-dom'
import Button from '../../../../components/UI/Button/Button'
import Textarea from '../../../../components/UI/Textarea/Textarea'
import { Controller, useForm } from 'react-hook-form'
import { connect, ConnectedProps } from 'react-redux'
import { buildGetAllHandler } from '../../../../services/cabinet/builds'
import { DATE_FORMAT_API, getDate, INFINITE_NUMBER, REGEX_V4 } from '../../../../helpers/helpers'
import { IVersion } from '../../../../interfaces/cabinet/versions'
import { versionGetAllHandler } from '../../../../services/cabinet/versions'
import dayjs from 'dayjs'
import { tenantGetAllHandler } from '../../../../services/apps/tenants'
import { IProductUpdate } from '../../../../interfaces/apps/productUpdates'
import {
	deleteProductUpdateByIdHandler,
	getProductUpdateByIdHandler,
	updateProductUpdateByIdHandler
} from '../../../../services/apps/productUpdate'
import { notificationAddHandler } from '../../../../store/actionCreators/UI/notification'
import Error from '../../../Error/Error'
import Loading from '../../../../components/UI/Loading/Loading'
import { IBuildCustom, ITenantCustom } from '../ProductUpdates'
import MultiselectBuilds from '../components/MultiselectBuilds'
import MultiselectTenants from '../components/MultiselectTenants'
import { modalConfirmShowHandler } from '../../../../store/actionCreators/UI/modalConfirm'
import { TDatePickerDate, TStatus } from '../../../../interfaces/helpers'
import InputDatePicker from '../../../../components/UI/InputDatePicker/InputDatePicker'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { selectProps } from '../../../../components/UI/Select/helpers'
import { toggleLoaderHandler } from '../../../../store/actionCreators/UI/loader'

interface IProductUpdateCustom extends IProductUpdate {
	tenants: Array<ITenantCustom>
}

interface IFormInputs {
	versionId: string
	description: string
	startTime: string
}

const Edit = ({
	getVersions,
	getBuilds,
	getTenants,
	getProductUpdate,
	updateProductUpdate,
	deleteProductUpdate,
	showModalConfirm,
	notificationAdd,
	toggleLoader
}: ConnectedProps<typeof connector>) => {
	const { t } = useTranslation()
	const { productUpdateId } = useParams()
	const [status, setStatus] = useState<TStatus>('loading')
	const [productUpdate, setProductUpdate] = useState<IProductUpdateCustom>()

	const [startTime, setStartTime] = useState<TDatePickerDate>(null)
	const history = useHistory()

	const { register, errors, handleSubmit, watch, control, formState } = useForm<IFormInputs>()
	const { isDirty } = formState

	const [isSubmitting, setIsSubmitting] = useState(false)

	const [versions, setVersions] = useState<IVersion[]>([])
	const [versionId, setVersionId] = useState<string>('')
	const [versionName, setVersionName] = useState<string>('')

	const [builds, setBuilds] = useState<IBuildCustom[]>([])
	const [buildsList, setBuildsList] = useState<IBuildCustom[]>([])
	const [buildsFilterList, setBuildsFilterList] = useState<IBuildCustom[]>([])
	const [buildsSelectedList, setBuildsSelectedList] = useState<IBuildCustom[]>([])
	const [isAddBuildsDisabled, setIsAddBuildsDisabled] = useState(true)
	const [isRemoveBuildsDisabled, setIsRemoveBuildsDisabled] = useState(true)

	const [tenants, setTenants] = useState<ITenantCustom[]>([])
	const [tenantsList, setTenantsList] = useState<ITenantCustom[]>([])
	const [tenantsSelectedList, setTenantsSelectedList] = useState<ITenantCustom[]>([])
	const [isAddTenantsDisabled, setIsAddTenantsDisabled] = useState(true)
	const [isRemoveTenantsDisabled, setIsRemoveTenantsDisabled] = useState(true)

	const isSubmitDisabled = useMemo(() => {
		const tenantsSelected = tenantsSelectedList.filter(t => !t.disabled)

		return isSubmitting || !buildsSelectedList.length || !tenantsSelected.length
	}, [buildsSelectedList.length, isSubmitting, tenantsSelectedList])

	const cancelHandler = () => {
		if (isDirty) {
			showModalConfirm({
				type: 'cancel',
				onConfirm: () => {
					history.replace('/product-updates')
				}
			})
		} else {
			history.replace('/product-updates')
		}
	}

	const versionSelectHandler = (id: string) => {
		const list = builds
			.filter(item => item.versions.some(v => v.id === id))
			.map(item => {
				return {
					...item,
					selected: false
				}
			})

		const versionName = versions.filter(item => item.id === id)[0].name

		setBuildsList(list)
		setBuildsFilterList(list)
		setVersionId(id)
		setVersionName(versionName)
	}

	const searchHandler = (value: string) => {
		const selected = buildsSelectedList.map(item => item.platform)
		const list = buildsFilterList
			.filter(item => !selected.includes(item.platform))
			.filter(item => item.platform.toLowerCase().includes(value.toLowerCase()))
			.map(item => {
				return {
					...item,
					selected: false
				}
			})

		setBuildsList(list)
	}

	const buildsListChangeHandler = (item: IBuildCustom, selected: boolean) => {
		item.selected = selected
		const index = buildsList.findIndex(el => el.id === item.id)
		const updatedList = [...buildsList]
		updatedList[index] = item
		setBuildsList(updatedList)

		setIsAddBuildsDisabled(updatedList.filter(el => el.selected).length === 0)
	}

	const buildsSelectedListChangeHandler = (item: IBuildCustom, selected: boolean) => {
		item.selected = selected
		const index = buildsSelectedList.findIndex(el => el.id === item.id)
		const updatedList = [...buildsSelectedList]
		updatedList[index] = item
		setBuildsSelectedList(updatedList)

		setIsRemoveBuildsDisabled(updatedList.filter(el => el.selected).length === 0)
	}

	const tenantsListChangeHandler = (item: ITenantCustom, selected: boolean) => {
		item.selected = selected
		const index = tenantsList.findIndex(el => el.id === item.id)
		const updatedList = [...tenantsList]
		updatedList[index] = item
		setTenantsList(updatedList)

		setIsAddTenantsDisabled(updatedList.filter(el => el.selected).length === 0)
	}

	const tenantsSelectedListChangeHandler = (item: ITenantCustom, selected: boolean) => {
		item.selected = selected
		const index = tenantsSelectedList.findIndex(el => el.id === item.id)
		const updatedList = [...tenantsSelectedList]
		updatedList[index] = item
		setTenantsSelectedList(updatedList)

		setIsRemoveTenantsDisabled(updatedList.filter(el => el.selected).length === 0)
	}

	const addBuildHandler = () => {
		const list = buildsList.filter(el => el.selected)
		const updatedList = buildsList.filter(el => !el.selected)

		setBuildsSelectedList([
			...buildsSelectedList,
			...list.map(item => {
				return {
					...item,
					selected: false
				}
			})
		])
		setBuildsList(updatedList)
		setIsAddBuildsDisabled(true)
	}

	const removeBuildHandler = () => {
		const list = buildsSelectedList.filter(el => el.selected)
		const updatedList = buildsSelectedList.filter(el => !el.selected)

		setBuildsList([
			...buildsList,
			...list.map(item => {
				return {
					...item,
					selected: false
				}
			})
		])
		setBuildsSelectedList(updatedList)
		setIsRemoveBuildsDisabled(true)
	}

	const addTenantHandler = () => {
		const list = tenantsList.filter(el => el.selected)
		const updatedList = tenantsList.filter(el => !el.selected)

		setTenantsSelectedList([
			...tenantsSelectedList,
			...list.map(item => {
				return {
					...item,
					selected: false
				}
			})
		])
		setTenantsList(updatedList)
		setIsAddTenantsDisabled(true)
	}

	const removeTenantHandler = () => {
		const list = tenantsSelectedList.filter(el => el.selected)
		const updatedList = tenantsSelectedList.filter(el => !el.selected)

		setTenantsList([
			...tenantsList,
			...list.map(item => {
				return {
					...item,
					selected: false
				}
			})
		])
		setTenantsSelectedList(updatedList)
		setIsRemoveTenantsDisabled(true)
	}

	const removeHandler = () => {
		showModalConfirm({
			type: 'remove',
			elementText: productUpdate?.versionName,
			onConfirm: () => {
				deleteProductUpdate(productUpdateId).then(response => {
					if (response?.status === 200) {
						notificationAdd(t('pages:productUpdates:notificationRemove'), 'info')
						history.replace('/product-updates')
					}
				})
			}
		})
	}

	const onSubmit = async (data: IFormInputs) => {
		toggleLoader(true)
		setIsSubmitting(true)

		try {
			const startTime = dayjs(data.startTime).format(DATE_FORMAT_API)

			const productUpdateKeys = buildsSelectedList.map(item => {
				const product = versions.filter(version => item.versions.some(v => v.id === version.id))[0]

				return {
					key: {
						product: product.product.name,
						platform: item.platform,
						buildVersionCode: item.buildVersionCode
					},
					buildId: item.id
				}
			})

			const response = await updateProductUpdate(productUpdateId, {
				...data,
				versionName,
				versionId,
				startTime,
				productUpdateKeys,
				tenants: tenantsSelectedList.filter(t => !t.disabled)
			})

			if (response?.status === 200) {
				notificationAdd(t('pages:productUpdates:notificationUpdate'), 'info')
				setIsSubmitting(false)
				toggleLoader(false)
				history.replace('/product-updates')
			}
		} finally {
			setIsSubmitting(false)
			toggleLoader(false)
		}
	}

	useEffect(() => {
		if (!productUpdateId.match(new RegExp(REGEX_V4))) {
			setStatus('error')
		} else {
			getProductUpdate(productUpdateId).then(response => {
				if (response?.status === 200) {
					const tenants: Array<ITenantCustom> = response.data.tenants.map(item => {
						return {
							...item,
							selected: true,
							disabled: false
						}
					})

					setProductUpdate({ ...response.data, tenants })
					setStartTime(dayjs(response.data?.startTime).toDate())

					setStatus('ready')
				} else {
					setStatus('error')
				}
			})

			getVersions({
				desc: false,
				pageSize: INFINITE_NUMBER,
				pageNo: 0
			}).then(response => {
				if (response?.status === 200) {
					setVersions(response.data.data)
				}
			})

			getBuilds({
				desc: false,
				pageSize: INFINITE_NUMBER,
				pageNo: 0
			}).then(response => {
				if (response?.status === 200) {
					const { data } = response.data

					const builds = data.map(item => {
						return {
							...item,
							selected: false
						}
					})

					setBuilds(builds)
				}
			})

			getTenants({
				desc: false,
				pageSize: INFINITE_NUMBER,
				pageNo: 0
			}).then(response => {
				if (response?.status === 200) {
					const { data } = response.data

					const tenants = data.map(item => {
						return {
							...item,
							selected: false,
							disabled: false
						}
					})

					setTenants(tenants)
					setTenantsList(tenants)
				}
			})
		}
	}, [productUpdateId, getProductUpdate, getVersions, getBuilds, getTenants])

	useEffect(() => {
		if (productUpdate) {
			const list = builds
				.filter(item => item.versions.some(v => v.id === productUpdate.versionId))
				.map(item => {
					item.selected = false
					return item
				})

			setBuildsList(list)
			setBuildsFilterList(list)
			setVersionId(productUpdate.versionId)
			setVersionName(productUpdate.versionName)

			// Set Builds
			const buildsIds = productUpdate.productUpdateKeys.map(item => item.buildId)

			setBuildsList(list.filter(item => !buildsIds.includes(item.id)))
			setBuildsSelectedList(builds.filter(item => buildsIds.includes(item.id)))

			// Set tenants
			const tenantsIds = productUpdate.tenants.map(item => item.id)

			setTenantsList(tenants.filter(item => !tenantsIds.includes(item.id)))

			setTenantsSelectedList(
				productUpdate.tenants.map(item => {
					item.selected = false
					return item
				})
			)
		}
	}, [productUpdate, builds, tenants])

	if (status === 'loading') return <Loading />
	if (status === 'error') return <Error />

	return (
		<>
			<Pagetitle
				title={t('pages:productUpdates:titleUpdate')}
				crumbs={[
					{
						title: t('pages:productUpdates:title'),
						to: '/product-updates'
					}
				]}
			>
				<p>{t('pages:productUpdates:textUpdate')}</p>
			</Pagetitle>

			<form className="form form--update" onSubmit={handleSubmit(onSubmit)}>
				<div className="form-row">
					<div className="form-col form-col-39">
						<div className="form-item">
							<Controller
								render={({ onChange, name }) => (
									<Select
										name={name}
										defaultValue={
											versions
												.filter(el => el.id === productUpdate?.versionId)
												.map(el => {
													return {
														label: el.name,
														value: el.id
													}
												})[0]
										}
										options={versions.map(item => {
											return {
												label: item.name,
												value: item.id
											}
										})}
										placeholder={t('pages:productUpdates:fieldVersion')}
										isDisabled={isSubmitting || buildsSelectedList.length > 0}
										isRequired={true}
										isError={!!errors.versionId}
										errors={errors.versionId}
										onChange={option => {
											if (option) {
												onChange(option.value)
												versionSelectHandler(option.value)
											}
										}}
										{...selectProps}
									/>
								)}
								defaultValue={productUpdate?.versionId}
								name={'versionId'}
								control={control}
								rules={{
									required: true
								}}
							/>
						</div>

						<div className="form-item">
							<SearchField
								onSubmit={searchHandler}
								placeholder={t('pages:productUpdates:fieldSearch')}
								isDisabled={isSubmitting}
							/>
						</div>
					</div>
				</div>

				<div className="form-row">
					<div className="form-col form-col-39">
						<MultiselectBuilds
							title={t('pages:productUpdates:listBuilds')}
							list={buildsList}
							onChange={buildsListChangeHandler}
						/>
					</div>

					<div className="form-col form-col-22">
						<div className="multiselect-controls">
							<Button
								type={'secondary'}
								htmlType={'button'}
								isDisabled={!buildsList.length || isAddBuildsDisabled}
								onClick={addBuildHandler}
							>
								<span dangerouslySetInnerHTML={{ __html: t('common:actionMoveAdd') }} />
							</Button>
							<Button
								type={'secondary'}
								htmlType={'button'}
								isDisabled={!buildsSelectedList.length || isRemoveBuildsDisabled}
								onClick={removeBuildHandler}
							>
								<span dangerouslySetInnerHTML={{ __html: t('common:actionMoveRemove') }} />
							</Button>
						</div>
					</div>

					<div className="form-col form-col-39">
						<MultiselectBuilds
							title={t('pages:productUpdates:listSelectedBuilds')}
							list={buildsSelectedList}
							onChange={buildsSelectedListChangeHandler}
						/>
					</div>
				</div>

				<hr className="dashed-separator" />

				<div className="form-item">
					<Textarea
						currentValue={watch('description')}
						name={'description'}
						placeholder={t('pages:productUpdates:fieldDescription')}
						className={'field--textarea-middle'}
						reference={register({
							maxLength: 5000
						})}
						isError={!!errors.description}
						errors={errors.description}
						rules={{
							maxLength: 5000
						}}
						defaultValue={productUpdate?.description}
					/>
				</div>

				<div className="form-item">
					<Controller
						render={({ name, onChange }) => (
							<InputDatePicker
								name={name}
								placeholderText={t('pages:productUpdates:fieldStartTime')}
								selected={startTime}
								minDate={
									new Date() < dayjs(productUpdate?.startTime).toDate()
										? new Date()
										: dayjs(productUpdate?.startTime).toDate()
								}
								onChange={(date: TDatePickerDate) => {
									setStartTime(date)
									onChange(getDate(date))
								}}
								errors={errors.startTime}
								isError={!!errors.startTime}
								isDisabled={isSubmitting}
							/>
						)}
						control={control}
						name={'startTime'}
						defaultValue={startTime}
						rules={{
							required: true
						}}
					/>
				</div>

				<div className="form-row">
					<div className="form-col form-col-39">
						<MultiselectTenants
							title={t('pages:productUpdates:listTenants')}
							list={tenantsList}
							onChange={tenantsListChangeHandler}
							name={'tenants'}
						/>
					</div>

					<div className="form-col form-col-22">
						<div className="multiselect-controls">
							<Button
								type={'secondary'}
								htmlType={'button'}
								isDisabled={!tenantsList.length || isAddTenantsDisabled}
								onClick={addTenantHandler}
							>
								<span dangerouslySetInnerHTML={{ __html: t('common:actionMoveAdd') }} />
							</Button>
							<Button
								type={'secondary'}
								htmlType={'button'}
								isDisabled={!tenantsSelectedList.length || isRemoveTenantsDisabled}
								onClick={removeTenantHandler}
							>
								<span dangerouslySetInnerHTML={{ __html: t('common:actionMoveRemove') }} />
							</Button>
						</div>
					</div>

					<div className="form-col form-col-39">
						<MultiselectTenants
							title={t('pages:productUpdates:listSelectedTenants')}
							list={tenantsSelectedList}
							onChange={tenantsSelectedListChangeHandler}
							name={'tenants-selected'}
						/>
					</div>
				</div>

				<div className="form-controls">
					<Button type={'secondary'} htmlType={'button'} isDisabled={isSubmitting} onClick={removeHandler}>
						{t('common:actionRemove')}
					</Button>

					<Button type={'secondary'} htmlType={'button'} onClick={cancelHandler} isDisabled={isSubmitting}>
						{t('common:actionCancel')}
					</Button>

					<Button type={'primary'} htmlType={'submit'} isDisabled={isSubmitDisabled}>
						{t('common:actionSave')}
					</Button>
				</div>
			</form>
		</>
	)
}

const mapDispatch = {
	getBuilds: buildGetAllHandler,
	getVersions: versionGetAllHandler,
	getTenants: tenantGetAllHandler,
	getProductUpdate: getProductUpdateByIdHandler,
	updateProductUpdate: updateProductUpdateByIdHandler,
	deleteProductUpdate: deleteProductUpdateByIdHandler,
	showModalConfirm: modalConfirmShowHandler,
	notificationAdd: notificationAddHandler,
	toggleLoader: toggleLoaderHandler
}

const connector = connect(null, mapDispatch)

export default connector(Edit)
