import { IVersion } from '../../../../interfaces/cabinet/versions'
import React, { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { modalConfirmShowHandler } from '../../../../store/actionCreators/UI/modalConfirm'

import Loading from '../../../../components/UI/Loading/Loading'
import Button from '../../../../components/UI/Button/Button'
import { MultiselectList } from '../../../../components/UI/MultiselectList/MultiselectList'
import { featureGetAllHandler, getFeaturesByVersionHandler } from '../../../../services/cabinet/features'

type Props = ConnectedProps<typeof connector> & {
	version: IVersion
	onConfirm: (data: FormFields) => Promise<any>
	hideModal: () => void
}

type FormFields = {
	featuresIds: string[]
}

type IFeatureWithSelect = {
	id: string
	name: string
	selected: boolean
}

const ModalFeatures = ({
	version,
	hideModal,
	showModalConfirm,
	onConfirm,
	getFeatures,
	getFeaturesByVersion
}: Props) => {
	const { t } = useTranslation()

	const [loading, setLoading] = useState(true)
	const [features, setFeatures] = useState<IFeatureWithSelect[]>([])
	const [leftFeatures, setLeftFeatures] = useState<IFeatureWithSelect[]>([])
	const [rightFeatures, setRightFeatures] = useState<IFeatureWithSelect[]>([])

	const {
		setValue,
		handleSubmit,
		reset,
		control,
		formState: { errors, isSubmitting, isDirty }
	} = useForm<FormFields>()

	const onAddFeatures = () => {
		setRightFeatures(prev => [...prev, ...leftFeatures.filter(b => b.selected).map(b => ({ ...b, selected: false }))])
		setLeftFeatures(prev => prev.filter(b => !b.selected))
	}

	const onRemoveFeatures = () => {
		setLeftFeatures(prev => [...prev, ...rightFeatures.filter(b => b.selected).map(b => ({ ...b, selected: false }))])
		setRightFeatures(prev => prev.filter(b => !b.selected))
	}

	const onAddAllFeatures = () => {
		setRightFeatures(features.map(b => ({ ...b, selected: false })))
		setLeftFeatures([])
	}

	const onRemoveAllFeatures = () => {
		setLeftFeatures(features.map(b => ({ ...b, selected: false })))
		setRightFeatures([])
	}

	const cancelHandler = () => {
		if (isDirty) {
			showModalConfirm({
				type: 'cancel',
				onConfirm: hideModal
			})
		} else {
			hideModal()
		}
	}

	const onSubmitHandler = async (data: FormFields) => {
		await onConfirm(data)
		hideModal()
	}

	useEffect(() => {
		getFeatures({
			productId: version.product.id
		})
			.then(response => {
				if (response?.status !== 200) {
					setLoading(false)
					return
				}

				const productFeaturesList =
					response.data?.map(f => ({
						id: f.id,
						name: `${f.title} (${f.featureCode})`,
						selected: false
					})) || []

				setFeatures(productFeaturesList)

				getFeaturesByVersion(version.id).then(response => {
					if (response?.status !== 200) {
						setLoading(false)
						return
					}

					const featuresIds = response?.data?.map(b => b.id) || []

					const featuresList = response?.data?.map(f => ({
						id: f.id,
						name: `${f.title} (${f.featureCode})`,
						selected: false
					}))

					setLeftFeatures(productFeaturesList.filter(b => !featuresIds.includes(b.id)))
					setRightFeatures(featuresList)

					reset({
						featuresIds: featuresIds
					})
				})
			})
			.finally(() => {
				setLoading(false)
			})
	}, [getFeatures, getFeaturesByVersion, reset, version.id, version.product.id])

	useEffect(() => {
		setValue('featuresIds', rightFeatures.map(b => b.id) || [], {
			shouldDirty: true
		})
	}, [rightFeatures, setValue])

	return (
		<>
			<button className="modal-close" onClick={cancelHandler} disabled={isSubmitting} />
			<h3 className="modal-title">{t('common:appName')}</h3>
			<p className="modal-footnote">{t('pages:versions:modalFeaturesTitle')}</p>

			{loading ? (
				<Loading />
			) : (
				<>
					{features.length === 0 ? (
						<div>No available features</div>
					) : (
						<form className="form form--update" onSubmit={handleSubmit(onSubmitHandler)}>
							<div className="form-row">
								<div className="form-col form-col-39">
									<MultiselectList
										label={t('pages:versions:listFeatures')}
										searchLabel={t('pages:versions:listSearch')}
										list={leftFeatures}
										isDisabled={isSubmitting}
										onChange={(feature, selected) => {
											const updatedFeatures = leftFeatures.map(item => ({
												...item,
												selected: item.id === feature.id ? selected : item.selected
											}))

											setLeftFeatures(updatedFeatures)
										}}
									/>
								</div>

								<div className="form-col form-col-22 multiselect-col">
									<div className="multiselect-controls">
										<div className="multiselect-controls-item">
											<Button
												type={'secondary'}
												htmlType={'button'}
												onClick={onAddAllFeatures}
												isDisabled={!leftFeatures.length}
											>
												<span dangerouslySetInnerHTML={{ __html: t('common:actionMoveAddAll') }} />
											</Button>

											<Button
												type={'secondary'}
												htmlType={'button'}
												onClick={onAddFeatures}
												isDisabled={!leftFeatures.filter(b => b.selected).length}
											>
												<span dangerouslySetInnerHTML={{ __html: t('common:actionMoveAdd') }} />
											</Button>
										</div>

										<div className="multiselect-controls-item">
											<Button
												type={'secondary'}
												htmlType={'button'}
												onClick={onRemoveFeatures}
												isDisabled={!rightFeatures.filter(b => b.selected).length}
											>
												<span dangerouslySetInnerHTML={{ __html: t('common:actionMoveRemove') }} />
											</Button>

											<Button
												type={'secondary'}
												htmlType={'button'}
												onClick={onRemoveAllFeatures}
												isDisabled={!rightFeatures.length}
											>
												<span dangerouslySetInnerHTML={{ __html: t('common:actionMoveRemoveAll') }} />
											</Button>
										</div>
									</div>
								</div>

								<div className="form-col form-col-39">
									<Controller
										name="featuresIds"
										control={control}
										rules={{
											required: true
										}}
										defaultValue={''}
										render={() => (
											<MultiselectList
												label={t('pages:versions:listSelectedFeatures')}
												searchLabel={t('pages:versions:listSearch')}
												isDisabled={isSubmitting}
												isRequired
												isError={!!errors.featuresIds}
												list={rightFeatures}
												onChange={(feature, selected) => {
													const updatedFeatures = rightFeatures.map(item => ({
														...item,
														selected: item.id === feature.id ? selected : item.selected
													}))

													setRightFeatures(updatedFeatures)
												}}
											/>
										)}
									/>
								</div>
							</div>

							<div className="form-controls form-controls--right">
								<Button type={'secondary'} htmlType={'button'} onClick={cancelHandler} isDisabled={isSubmitting}>
									{t('common:actionCancel')}
								</Button>

								<Button type={'primary'} htmlType={'submit'} isDisabled={isSubmitting}>
									{t('common:actionSave')}
								</Button>
							</div>
						</form>
					)}
				</>
			)}
		</>
	)
}

const mapDispatch = {
	getFeatures: featureGetAllHandler,
	getFeaturesByVersion: getFeaturesByVersionHandler,
	showModalConfirm: modalConfirmShowHandler
}

const connector = connect(null, mapDispatch)

export default connector(ModalFeatures)
