import {
	ORGANIZATION_CLEAR_CURRENT,
	ORGANIZATION_CREATE_ADDITIONAL_CONTACT_BY_ID,
	ORGANIZATION_DELETE_ADDITIONAL_CONTACT_BY_ID,
	ORGANIZATION_GET_BY_ID,
	ORGANIZATION_UPDATE_BY_ID,
	ORGANIZATION_UPDATE_CONTACT_BY_ID,
	ORGANIZATION_UPDATE_CURRENT
} from '../../actions/passport/organization'
import { IOrganization } from '../../../interfaces/passport/organizations'
import { OrganizationActionTypes } from '../../../interfaces/passport/actionCreators/organizations'
import { DEFAULT_LOCALE } from '../../../helpers/helpers'

interface IInitialState {
	organization: IOrganization
}

const initialState: IInitialState = {
	organization: {
		id: '',
		name: '',
		fullName: '',
		inn: '',
		archived: false,
		mainContact: {
			id: '',
			name: '',
			email: ''
		},
		contacts: [],
		locale: DEFAULT_LOCALE,
		region: '',
		country: {
			id: '',
			name: '',
			createdDate: ''
		},
		profile: '',
		accountId: '',
		accountType: 'B2B'
	}
}

export default function OrganizationReducer(state = initialState, action: OrganizationActionTypes): IInitialState {
	switch (action.type) {
		case ORGANIZATION_CLEAR_CURRENT:
			return {
				...state,
				organization: initialState.organization
			}

		case ORGANIZATION_UPDATE_CURRENT:
			return {
				...state,
				organization: action.payload
			}

		case ORGANIZATION_UPDATE_BY_ID:
			return {
				...state,
				organization: {
					...state.organization,
					...action.payload
				}
			}

		case ORGANIZATION_CREATE_ADDITIONAL_CONTACT_BY_ID:
			const createContacts = [...(state.organization.contacts || [])]
			createContacts.push(action.payload)

			return {
				...state,
				organization: {
					...state.organization,
					contacts: createContacts
				}
			}

		case ORGANIZATION_UPDATE_CONTACT_BY_ID:
			if (state.organization?.mainContact?.id === action.payload.id) {
				return {
					...state,
					organization: {
						...state.organization,
						mainContact: action.payload
					}
				}
			}

			if (state.organization?.contacts) {
				const updateContacts = [...state.organization.contacts]
				const updateIndex = updateContacts.findIndex(el => el.id === action.payload.id)
				updateContacts[updateIndex] = action.payload

				return {
					...state,
					organization: {
						...state.organization,
						contacts: updateContacts
					}
				}
			}

			return state

		case ORGANIZATION_DELETE_ADDITIONAL_CONTACT_BY_ID:
			const deleteContacts = [...(state.organization?.contacts || [])]
			const deleteIndex = deleteContacts.findIndex(el => el.id === action.payload)
			deleteContacts.splice(deleteIndex, 1)

			return {
				...state,
				organization: {
					...state.organization,
					contacts: deleteContacts
				}
			}

		case ORGANIZATION_GET_BY_ID:
			return {
				...state,
				organization: action.payload
			}

		default:
			return state
	}
}
