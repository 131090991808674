import React, { useEffect, useState } from 'react'
import Pagetitle from '../../../components/UI/Pagetitle/Pagetitle'
import { connect, ConnectedProps } from 'react-redux'
import {
	ITenant,
	ITenantAPIRequest,
	ITenantCreate,
	ITenantUpdate,
	TTenantSortFields
} from '../../../interfaces/apps/tenants'
import {
	createTenantHandler,
	deleteTenantByIdHandler,
	tenantGetAllHandler,
	updateTenantByIdHandler
} from '../../../services/apps/tenants'
import { modalShowHandler } from '../../../store/actionCreators/UI/modal'
import { modalConfirmShowHandler } from '../../../store/actionCreators/UI/modalConfirm'
import { IPagination, TSortColumnsTable, TStatus } from '../../../interfaces/helpers'
import { DEFAULT_PAGE_SIZE, INFINITE_NUMBER } from '../../../helpers/helpers'
import Button from '../../../components/UI/Button/Button'
import Pagination from '../../../components/UI/Pagination/Pagination'
import Loading from '../../../components/UI/Loading/Loading'
import ModalAddTenant from './ModalAddTenant'
import { IOrganization } from '../../../interfaces/passport/organizations'
import { getAllOrganizationsHandler } from '../../../services/passport/organization'
import { notificationAddHandler } from '../../../store/actionCreators/UI/notification'
import ModalEditTenant from './ModalEditTenant'
import NoResults from '../../../components/UI/NoResults/NoResults'
import { useTranslation } from 'react-i18next'
import { toggleLoaderHandler } from '../../../store/actionCreators/UI/loader'

const Tenants = ({
	getOrganizations,
	getTenants,
	createTenant,
	updateTenant,
	deleteTenant,
	showModal,
	toggleLoader,
	notificationAdd
}: ConnectedProps<typeof connector>) => {
	const { t } = useTranslation()
	const [status, setStatus] = useState<TStatus>('loading')
	const [organizations, setOrganizations] = useState<IOrganization[]>([])
	const [table, setTable] = useState<ITenant[]>([])
	const [pagination, setPagination] = useState<IPagination>({
		pageNo: 0,
		totalPages: 0,
		totalCount: 0
	})

	const [filterTenants, setFilterTenants] = useState<ITenantAPIRequest>({
		// filter: '',
		sortField: '',
		desc: false,
		pageSize: DEFAULT_PAGE_SIZE,
		pageNo: 0
	})

	const [sortColumns, setSortColumns] = useState<TSortColumnsTable>({
		Name: {
			desc: false,
			classNames: ['table-sort', 'table-sort--top']
		}
	})

	const paginationHandle = (pageNo: number) => {
		setFilterTenants({
			...filterTenants,
			pageNo
		})
	}

	const sortHandle = (sortField: TTenantSortFields) => {
		const updatedSortColumns = { ...sortColumns }

		Object.keys(updatedSortColumns).forEach((key: string) => {
			if (key !== sortField) {
				updatedSortColumns[key] = {
					desc: true,
					classNames: ['table-sort']
				}
			} else {
				updatedSortColumns[key].desc = !updatedSortColumns[key].desc
				updatedSortColumns[key].classNames = [
					'table-sort',
					updatedSortColumns[key].desc ? 'table-sort--bottom' : 'table-sort--top'
				]
			}
		})

		setFilterTenants({
			...filterTenants,
			sortField,
			desc: updatedSortColumns[sortField].desc
		})

		setSortColumns(updatedSortColumns)
	}

	const createHandler = () => {
		showModal({
			type: 'update',
			Component: ModalAddTenant,
			ComponentProps: {
				organizations,
				onConfirm: async (data: ITenantCreate) => {
					toggleLoader(true)

					try {
						const response = await createTenant(data)

						if (response?.status === 200) {
							paginationHandle(0)
							notificationAdd(t('pages:tenants:notificationCreate'), 'info')
						}

						return response
					} finally {
						toggleLoader(false)
					}
				}
			}
		})
	}

	const updateHandler = (item: ITenant) => {
		showModal({
			type: 'update',
			Component: ModalEditTenant,
			ComponentProps: {
				tenant: item,
				organizations,
				onConfirm: async (data: ITenantUpdate) => {
					toggleLoader(true)

					try {
						const response = await updateTenant(item.id, data)

						if (response?.status === 200) {
							paginationHandle(0)
							notificationAdd(t('pages:tenants:notificationUpdate'), 'info')
						}

						return response
					} finally {
						toggleLoader(false)
					}
				},
				onRemove: async (id: string) => {
					toggleLoader(true)

					try {
						const response = await deleteTenant(id)

						if (response?.status === 200) {
							paginationHandle(0)
							notificationAdd(t('pages:tenants:notificationRemove'), 'info')
						}

						return response
					} finally {
						toggleLoader(false)
					}
				}
			}
		})
	}

	useEffect(() => {
		getTenants(filterTenants).then(response => {
			if (response?.status === 200) {
				const { data, pageNo, totalPages, totalCount } = response.data

				setTable(data)

				setPagination({
					pageNo,
					totalPages,
					totalCount
				})

				setStatus('ready')
			}
		})
	}, [getTenants, filterTenants])

	useEffect(() => {
		getOrganizations({
			desc: false,
			pageSize: INFINITE_NUMBER,
			pageNo: 0
		}).then(response => {
			if (response?.status === 200) {
				setOrganizations(response.data.data)
			}
		})
	}, [getOrganizations])

	if (status === 'loading') return <Loading />

	return (
		<>
			<Pagetitle title={t('pages:tenants:title')} />

			<div className="filters">
				<div className="filters-item filters-item--buttons">
					<div className="button-row">
						<Button type={'primary'} htmlType={'button'} onClick={createHandler}>
							{t('pages:tenants:addNew')}
						</Button>
					</div>
				</div>
			</div>

			{table.length > 0 ? (
				<div className="pagination-wrapper">
					<div className="table">
						<table>
							<colgroup>
								<col width="25%" />
								<col />
							</colgroup>
							<thead>
								<tr>
									<th onClick={() => sortHandle('Name')}>
										<div className={sortColumns.Name.classNames.join(' ')}>{t('pages:tenants:tableHeaderName')}</div>
									</th>
									<th>
										<div>{t('pages:tenants:tableHeaderOrganizations')}</div>
									</th>
								</tr>
							</thead>

							<tbody>
								{table.map(item => {
									return (
										<tr key={item.id}>
											<td>
												<button className="button-link" type="button" onClick={() => updateHandler(item)}>
													{item.name}
												</button>
											</td>
											<td>{item.organizations.map(el => el.organizationName).join('; ')}</td>
										</tr>
									)
								})}
							</tbody>
						</table>
					</div>

					<Pagination onClick={paginationHandle} {...pagination} />
				</div>
			) : (
				<NoResults />
			)}
		</>
	)
}

const mapDispatch = {
	getOrganizations: getAllOrganizationsHandler,
	getTenants: tenantGetAllHandler,
	createTenant: createTenantHandler,
	updateTenant: updateTenantByIdHandler,
	deleteTenant: deleteTenantByIdHandler,

	showModal: modalShowHandler,
	showModalConfirm: modalConfirmShowHandler,
	notificationAdd: notificationAddHandler,
	toggleLoader: toggleLoaderHandler
}

const connector = connect(null, mapDispatch)

export default connector(Tenants)
