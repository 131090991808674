import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { connect, ConnectedProps } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { TStatus } from '../../../../../interfaces/helpers'
import { REGEX_V4 } from '../../../../../helpers/helpers'
import Loading from '../../../../../components/UI/Loading/Loading'
import { RootState } from '../../../../../store/reducers/rootReducer'
import { getOrganizationByIdHandler } from '../../../../../services/passport/organization'
import Error from '../../../../Error/Error'
import DistributivesList from '../../../../Common/Distributives/Distributives'

const Distributives = ({ organization, getOrganization }: ConnectedProps<typeof connector>) => {
    const { t } = useTranslation()
    const { organizationId, licensePackageId } = useParams<{ organizationId: string, licensePackageId: string }>()
    const [status, setStatus] = useState<TStatus>('loading')

    useEffect(() => {
        if (!organizationId.match(new RegExp(REGEX_V4))) {
            setStatus('error')
        } else if (!organization.id) {
            getOrganization(organizationId).then(response => {
                if (response?.status === 200) {
                    setStatus('ready')
                } else {
                    setStatus('error')
                }
            })
        } else {
            setStatus('ready')
        }
    }, [getOrganization, organizationId, organization])

    if (status === 'error') return <Error />
    if (status === 'loading') return <Loading />

    return (
        <DistributivesList
            accountId={organization.accountId}
            licensePackageId={licensePackageId}
            crumbs={[
                {
                    to: `/organization/${organizationId}/`,
                    title: organization.name
                },
                {
                    title: t('pages:licensePackages:title'),
                    to: `/organization/${organizationId}/license-packages`
                }
            ]}
        />
    )
}

const mapState = (state: RootState) => ({
    organization: state.organization.organization
})

const mapDispatch = {
    getOrganization: getOrganizationByIdHandler
}

const connector = connect(mapState, mapDispatch)

export default connector(Distributives)
