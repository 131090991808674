import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
	title: string
	onRemove: () => void
	isDisabled?: boolean
	children: ReactNode
}

const GroupLicensePackageItem = ({ title, onRemove, children, isDisabled = false }: IProps) => {
	const { t } = useTranslation()

	return (
		<div className="form-license-pack__group-item">
			<div className="form-license-pack__group-item-heading">
				<span>{title}</span>
				{!isDisabled && (
					<div className="form-license-pack__group-item-delete" onClick={onRemove}>
						{t('pages:licensePackages:removeGroup')}
					</div>
				)}
			</div>
			<div className="form-license-pack__group-item-body">{children}</div>
		</div>
	)
}

export default GroupLicensePackageItem
