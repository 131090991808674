import React, { useEffect, useRef } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { modalHideHandler } from '../../../store/actionCreators/UI/modal'
import { IModalProps } from '../../../interfaces/UI/modal'
import { CSSTransition } from 'react-transition-group'
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock'
import './modal.css'

type TProps = ConnectedProps<typeof connector> & IModalProps

const Modal = ({ isOpened, Component, ComponentProps, type, hideModal }: TProps) => {
	const modalRef = useRef(null)

	useEffect(() => {
		if (isOpened) {
			// @ts-ignore
			disableBodyScroll(modalRef.current, {
				reserveScrollBarGap: false
			})
		}

		return () => {
			if (isOpened) {
				setTimeout(() => {
					clearAllBodyScrollLocks()
				}, 250)
			}
		}
	}, [isOpened])

	const classNames = ['modal']
	if (type) classNames.push(`modal--${type}`)

	return (
		<CSSTransition in={isOpened} timeout={250} nodeRef={modalRef} classNames={'modal-animation'} unmountOnExit>
			<div ref={modalRef} className={classNames.join(' ')}>
				<div className="modal-container">
					{Component ? <Component {...ComponentProps} hideModal={hideModal} /> : ''}
				</div>
			</div>
		</CSSTransition>
	)
}

const mapDispatch = {
	hideModal: modalHideHandler
}

const connector = connect(null, mapDispatch)

export default connector(Modal)
