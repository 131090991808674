import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import Input from '../../../components/UI/Input/Input'
import Button from '../../../components/UI/Button/Button'
import { REGEX_PASSWORD } from '../../../helpers/helpers'
import { IUser, IUserSelfUpdate } from '../../../interfaces/passport/user'
import { useTranslation } from 'react-i18next'
import { APIError } from '../../../interfaces/helpers'

type TProps = {
	user: IUser
	hideModal: () => void
	onConfirm: (data: IUserSelfUpdate) => Promise<any>
}

interface IFormInputs {
	currentPassword: string
	newPassword: string
	repeatPassword: string
}

function ModalChangePassword({ hideModal, user, onConfirm }: TProps) {
	const { t } = useTranslation()
	const { register, watch, errors, handleSubmit, formState, setError, getValues } = useForm<IFormInputs>()
	const { isDirty } = formState
	const [isSubmitting, setIsSubmitting] = useState(false)

	const [isSuccess, setIsSuccess] = useState(false)

	const onSubmit = async (data: IFormInputs) => {
		setIsSubmitting(true)

		try {
			const response = await onConfirm({
				role: user.role,
				email: user.email,
				lastName: user.lastName,
				firstName: user.firstName,
				patronymic: user.patronymic,
				currentPassword: data.currentPassword,
				newPassword: data.newPassword
			})

			if (response?.status === 200) {
				setIsSuccess(true)
			} else {
				const { validationErrors } = (response.data as APIError) || []

				if (validationErrors && validationErrors.length) {
					validationErrors.forEach(error => {
						const { fieldName } = error

						setError(fieldName as keyof IFormInputs, {
							type: fieldName
						})
					})
				}
			}
		} finally {
			setIsSubmitting(false)
		}
	}

	return (
		<>
			<button className="modal-close" onClick={hideModal} disabled={isSubmitting} />

			<h3 className="login-title">{t('common:appName')}</h3>
			{!isSuccess ? (
				<>
					<p className="login-footnote">{t('pages:profile:modalChangePasswordText')}</p>

					<form className="form" onSubmit={handleSubmit(onSubmit)}>
						<div className="form-item">
							<Input
								type={'password'}
								name={'currentPassword'}
								currentValue={watch('currentPassword')}
								placeholder={t('pages:profile:fieldCurrentPassword')}
								isError={!!errors.currentPassword}
								isDisabled={isSubmitting}
								reference={register({
									required: true,
									minLength: 8,
									maxLength: 255,
									pattern: {
										value: REGEX_PASSWORD,
										message: 'fieldPatternPassword'
									}
								})}
								rules={{
									minLength: 8,
									maxLength: 255
								}}
								errors={errors.currentPassword}
							/>
						</div>

						<div className="form-item">
							<Input
								type={'password'}
								name={'newPassword'}
								currentValue={watch('newPassword')}
								placeholder={t('pages:profile:fieldNewPassword')}
								isError={!!errors.newPassword}
								isDisabled={isSubmitting}
								reference={register({
									required: true,
									minLength: 8,
									maxLength: 255,
									pattern: {
										value: REGEX_PASSWORD,
										message: 'fieldPatternPassword'
									}
								})}
								rules={{
									minLength: 8,
									maxLength: 255
								}}
								errors={errors.newPassword}
							/>
						</div>

						<div className="form-item">
							<Input
								type={'password'}
								name={'repeatPassword'}
								currentValue={watch('repeatPassword')}
								placeholder={t('pages:profile:fieldRepeatPassword')}
								isError={!!errors.repeatPassword}
								isDisabled={isSubmitting}
								reference={register({
									validate: {
										passwordMatches: value => value === getValues('newPassword')
									}
								})}
								errors={errors.repeatPassword}
							/>
						</div>

						<div className="form-controls form-controls--right">
							<Button type={'secondary'} onClick={hideModal} htmlType={'button'} isDisabled={isSubmitting}>
								{t('common:actionCancel')}
							</Button>

							<Button type={'primary'} htmlType={'submit'} isDisabled={isSubmitting || !isDirty}>
								{t('common:actionSave')}
							</Button>
						</div>
					</form>
				</>
			) : (
				<>
					<p className="login-footnote">{t('pages:profile:modalChangePasswordDoneFootnoteText')}</p>
					<p>{t('pages:profile:modalChangePasswordDoneText')}</p>
					<div className="button-row button-row--right">
						<Button type={'primary'} onClick={hideModal} className={'button-ok'}>
							{t('common:actionOk')}
						</Button>
					</div>
				</>
			)}
		</>
	)
}

export default ModalChangePassword
