import axios from 'axios'
import { setErrorHandler } from '../../store/actionCreators/error'
import { API_CABINET_PATH, API_LICENSE_PATH, APIServices } from '../../helpers/api'
import {
	ILicense,
	ILicenseAPIRequest,
	ISelfLicense,
	TUploadLicenseHardwareKeys
} from '../../interfaces/cabinet/license'
import { APIResponse, AppThunk } from '../../interfaces/helpers'
import { serviceHandlerErrorOptions } from '../helpers'
import { setProgress } from '../../store/actionCreators/app'

export function licenseGetAllHandler(
	data: ILicenseAPIRequest,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<APIResponse<ILicense[]>> {
	return async (dispatch, getState) => {
		try {
			return await axios.request<APIResponse<ILicense[]>>({
				method: 'GET',
				url: `${API_CABINET_PATH}/license`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				data
			})
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}

export function getUserLicensesHandler(errorOptions = serviceHandlerErrorOptions): AppThunk<ISelfLicense[]> {
	return async (dispatch, getState) => {
		try {
			return await axios.request<ISelfLicense[]>({
				method: 'GET',
				url: `${API_CABINET_PATH}/userLicense`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				}
			})
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}

export function uploadLicenseHardwareKeyHandler(
	buildId: string,
	data: File[],
	errorOptions = serviceHandlerErrorOptions
): AppThunk<TUploadLicenseHardwareKeys> {
	return async (dispatch, getState) => {
		const formData = new FormData()
		formData.append('data', data[0])

		try {
			const response = await axios.request<TUploadLicenseHardwareKeys>({
				method: 'POST',
				url: `${API_LICENSE_PATH}/license/licenseFile/batchImport/${buildId}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`,
					'Content-Type': `multipart/form-data`
				},
				data: formData,
				onUploadProgress: progress => {
					const value = Math.round((progress.loaded * 100) / progress.total)
					dispatch(setProgress(value))
				}
			})
			dispatch(setProgress(0))
			return response
		} catch (error) {
			dispatch(setProgress(0))
			dispatch(setErrorHandler(error, APIServices.LICENSE, errorOptions))
			return error.response
		}
	}
}
