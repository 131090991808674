import React, { useState } from 'react'
import SearchField from '../SearchField/SearchField'

type BaseMultiselectItem = {
	id: string
	name: string
	selected: boolean
}

interface IMultiselectProps<Item extends BaseMultiselectItem> {
	list: Item[]
	onChange: (item: Item, selected: boolean) => void
	isError?: boolean
	isRequired?: boolean
	isDisabled?: boolean
	label?: string
	searchLabel?: string
	withSearch?: boolean
	variant?: 'short'
}

export const MultiselectList = <Item extends BaseMultiselectItem>({
	list,
	isError,
	isDisabled,
	isRequired,
	onChange,
	searchLabel,
	label,
	withSearch = true,
	variant
}: IMultiselectProps<Item>) => {
	const classNames = ['multiselect']

	if (isError) classNames.push('multiselect--error')
	if (isDisabled) classNames.push('multiselect--disabled')
	if (isRequired) classNames.push('multiselect--required')
	if (variant) classNames.push(`multiselect--${variant}`)

	const [versionInput, setVersionInput] = useState('')

	return (
		<div className={classNames.join(' ')}>
			{label ? <div className="multiselect-label">{label}</div> : null}

			{withSearch ? (
				<div className="multiselect-search">
					<SearchField
						placeholder={searchLabel}
						isDisabled={isDisabled}
						onChange={setVersionInput}
						onSubmit={setVersionInput}
					/>
				</div>
			) : null}

			<ul className="list">
				{list
					.filter(v => v.name.toLowerCase().includes(versionInput.toLowerCase()))
					.map(item => (
						<li className="list-item" key={item.id}>
							<label>
								<input
									disabled={isDisabled}
									type="checkbox"
									onChange={event => onChange(item, event.target.checked)}
									checked={item.selected}
								/>
								<span>{item.name}</span>
							</label>
						</li>
					))}
			</ul>
		</div>
	)
}
