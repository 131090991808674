import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_SET_AUTHORIZED } from '../../actions/passport/auth'
import { ITokens } from '../../../interfaces/passport/auth'
import { AuthActionTypes } from '../../../interfaces/passport/actionCreators/auth'

export function logout(): AuthActionTypes {
	return {
		type: AUTH_LOGOUT
	}
}

export function login(tokens: ITokens): AuthActionTypes {
	return {
		type: AUTH_LOGIN,
		payload: tokens
	}
}

export function setIsAuthorized(isAuthorized: boolean): AuthActionTypes {
	return {
		type: AUTH_SET_AUTHORIZED,
		payload: isAuthorized
	}
}
