import React, { useEffect, useRef, Fragment } from 'react'
import Button from '../../../components/UI/Button/Button'
import { connect, ConnectedProps } from 'react-redux'
import { IModalLogDetailsKeys, IModalLogDetailsUsers, IModalLogProps } from '../../../interfaces/UI/modalLog'
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock'
import { useTranslation } from 'react-i18next'
import { CSSTransition } from 'react-transition-group'
import './modal.css'
import { modalLogHideHandler } from '../../../store/actionCreators/UI/modalLog'
import { notificationAddHandler } from '../../../store/actionCreators/UI/notification'

type TProps = ConnectedProps<typeof connector> & IModalLogProps

const ModalLog = ({ isOpened, hideModal, onConfirm, details, notificationAdd, message }: TProps) => {
	const { t } = useTranslation()
	const modalRef = useRef(null)

	const copyToClipboard = () => {
		const value = JSON.stringify(details)
		const textarea = document.createElement('textarea')
		textarea.value = value
		document.body.appendChild(textarea)
		textarea.select()
		document.execCommand('copy')
		document.body.removeChild(textarea)

		notificationAdd(t('pages:hardwareKeys:notificationCopy'), 'info')
	}

	useEffect(() => {
		if (isOpened) {
			// @ts-ignore
			disableBodyScroll(modalRef.current, {
				reserveScrollBarGap: false
			})
		}

		return () => {
			if (isOpened) {
				setTimeout(() => {
					clearAllBodyScrollLocks()
				}, 250)
			}
		}
	}, [isOpened])

	const classNames = ['modal', 'modal--large']

	const onConfirmHandler = () => {
		if (typeof onConfirm === 'function') onConfirm()
		hideModal()
	}

	let listUsers
	let listKeys

	if (Array.isArray(details)) {
		listUsers = details as Array<IModalLogDetailsUsers>
	} else {
		listKeys = details as IModalLogDetailsKeys
	}

	return (
		<CSSTransition in={isOpened} timeout={250} nodeRef={modalRef} classNames={'modal-animation'} unmountOnExit>
			<div ref={modalRef} className={classNames.join(' ')}>
				<div className="modal-container">
					<button className="modal-close" onClick={onConfirmHandler} />

					<h3 className="modal-title">{t('common:appName')}</h3>

					<p className="modal-footnote">{message || t('pages:hardwareKeys:modalLogText')}</p>

					<div className="modal-log">
						{listUsers
							?.filter(item => !item.ok)
							?.map((item, idx) => {
								return (
									<div className="modal-log-item" key={idx}>
										<div className="modal-log-error">
											{item?.rownum && <strong>Строка {item?.rownum ?? '-'}.</strong>}
											{item?.values &&
												Object.keys(item?.values)?.map(key => {
													return (
														<div key={key}>
															{key}: {item.values[key]}
														</div>
													)
												})}
										</div>
										{item?.message && <div className="modal-log-info">{item?.message}</div>}
									</div>
								)
							})}

						{listKeys?.errors.map((item, idx) => {
							if (Array.isArray(item)) {
								return (
									<Fragment key={idx}>
										{item.map((row, rowIdx) => {
											return (
												<div key={rowIdx} className="modal-log-item">
													<div className="modal-log-info">{row.message}</div>
												</div>
											)
										})}
									</Fragment>
								)
							} else {
								return (
									<div key={idx} className="modal-log-item">
										<div className="modal-log-info">{item}</div>
									</div>
								)
							}
						})}
					</div>

					<div className="button-row button-row--right">
						<Button type={'secondary'} htmlType={'button'} onClick={copyToClipboard}>
							{t('pages:hardwareKeys:copyButton')}
						</Button>

						<Button type={'primary'} htmlType={'button'} onClick={onConfirmHandler}>
							{t('common:actionClose')}
						</Button>
					</div>
				</div>
			</div>
		</CSSTransition>
	)
}

const mapDispatch = {
	hideModal: modalLogHideHandler,
	notificationAdd: notificationAddHandler
}

const connector = connect(null, mapDispatch)

export default connector(ModalLog)
