import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ConnectedProps, connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { REGEX_V4 } from '../../../../helpers/helpers'
import Error from '../../../Error/Error'
import Loading from '../../../../components/UI/Loading/Loading'
import { getOrganizationByIdHandler } from '../../../../services/passport/organization'
import { TStatus } from '../../../../interfaces/helpers'
import { RootState } from '../../../../store/reducers/rootReducer'
import UsersEditor from '../../../Common/Users/UsersEditor'

const UserAdd = ({ organization, getOrganization }: ConnectedProps<typeof connector>) => {
	const { t } = useTranslation()
	const { organizationId } = useParams<{ organizationId: string }>()
	const [status, setStatus] = useState<TStatus>('loading')

	useEffect(() => {
		if (!organizationId.match(new RegExp(REGEX_V4))) {
			setStatus('error')
		} else if (!organization.id) {
			getOrganization(organizationId).then(response => {
				if (response?.status === 200) {
					setStatus('ready')
				} else {
					setStatus('error')
				}
			})
		} else {
			setStatus('ready')
		}
	}, [getOrganization, organizationId, organization])

	if (status === 'error') return <Error />
	if (status === 'loading') return <Loading />

	return (
		<UsersEditor
			organizationId={organizationId}
			accountId={organization.accountId}
			parentRoute={`/organization/${organizationId}`}
			crumbs={[
				{
					title: organization?.name || '',
					to: `/organization/${organizationId}/`
				},
				{
					title: t('pages:users:title'),
					to: `/organization/${organizationId}/users`
				}
			]}
		/>
	)
}

const mapState = (state: RootState) => ({
	organization: state.organization.organization
})

const mapDispatch = {
	getOrganization: getOrganizationByIdHandler
}

const connector = connect(mapState, mapDispatch)

export default connector(UserAdd)
