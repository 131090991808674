import { LOADER_HIDE, LOADER_SHOW } from '../../actions/UI/loader'
import { LoaderActionTypes } from '../../../interfaces/UI/actionCreators/loader'
import { ThunkAction } from 'redux-thunk'
import { RootState } from '../../reducers/rootReducer'
import { Action } from 'redux'

export function showLoader(): LoaderActionTypes {
	return {
		type: LOADER_SHOW
	}
}

export function hideLoader(): LoaderActionTypes {
	return {
		type: LOADER_HIDE
	}
}

export function toggleLoaderHandler(show: boolean): ThunkAction<void, RootState, unknown, Action<string>> {
	return dispatch => {
		if (show) {
			dispatch(showLoader())
		} else {
			dispatch(hideLoader())
		}
	}
}
