import React from 'react'
import { ITenantOrganization } from '../../../../interfaces/apps/tenants'

export interface IMultiselectItem extends ITenantOrganization {
	selected: boolean
}

interface IMultiselectProps {
	title: string
	list: Array<IMultiselectItem>
	onChange: (item: IMultiselectItem, selected: boolean) => void
}

const Multiselect = ({ title, list, onChange }: IMultiselectProps) => {
	const name = title.charAt(0)

	return (
		<div className="multiselect">
			<div className="multiselect-label">
				<span>{title}</span>
			</div>
			<ul className="list">
				{list.map((item, idx) => {
					return (
						<li className="list-item" key={item.organizationId}>
							<label>
								<input
									type="checkbox"
									name={`${name}-${idx}`}
									onChange={event => onChange(item, event.target.checked)}
									checked={item.selected}
								/>
								<span>{item.organizationName}</span>
							</label>
						</li>
					)
				})}
			</ul>
		</div>
	)
}

export default Multiselect
