import React, { useEffect, useRef } from 'react'
import Button from '../../../components/UI/Button/Button'
import { connect, ConnectedProps } from 'react-redux'
import { modalConfirmHideHandler } from '../../../store/actionCreators/UI/modalConfirm'
import { IModalConfirmProps, TModalConfirm } from '../../../interfaces/UI/modalConfirm'
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock'
import { useTranslation } from 'react-i18next'
import { CSSTransition } from 'react-transition-group'
import './modal.css'

type TProps = ConnectedProps<typeof connector> & IModalConfirmProps

type ITypes = {
	[key in TModalConfirm]: {
		text: string
		button: string
	}
}

const types: ITypes = {
	cancel: {
		text: 'common:confirmCancel',
		button: 'common:actionYes'
	},
	update: {
		text: 'common:confirmCancel',
		button: 'common:actionUpdate'
	},
	add: {
		text: 'common:confirmAdd',
		button: 'common:actionAdd'
	},
	remove: {
		text: 'common:confirmRemove',
		button: 'common:actionRemove'
	},
	lock: {
		text: 'common:confirmLock',
		button: 'common:actionLock'
	},
	unlock: {
		text: 'common:confirmUnlock',
		button: 'common:actionUnlock'
	},
	error: {
		text: '',
		button: 'common:actionOk'
	},
	custom: {
		text: '',
		button: 'common:actionYes'
	}
}

const ModalConfirm = ({
	isOpened,
	hideModal,
	onConfirm,
	onCancel,
	type,
	questionText,
	elementText,
	buttonText
}: TProps) => {
	const { t } = useTranslation()
	const modalRef = useRef(null)

	useEffect(() => {
		if (isOpened) {
			// @ts-ignore
			disableBodyScroll(modalRef.current, {
				reserveScrollBarGap: false
			})
		}

		return () => {
			if (isOpened) {
				setTimeout(() => {
					clearAllBodyScrollLocks()
				}, 250)
			}
		}
	}, [isOpened])

	const classNames = ['modal']

	if (type === 'error') classNames.push('modal--large')

	const onCancelHandler = () => {
		if (typeof onCancel === 'function') onCancel()
		hideModal()
	}

	const onConfirmHandler = () => {
		onConfirm()
		hideModal()
	}

	return (
		<CSSTransition in={isOpened} timeout={250} nodeRef={modalRef} classNames={'modal-animation'} unmountOnExit>
			<div ref={modalRef} className={classNames.join(' ')}>
				<div className="modal-container">
					<button className="modal-close" onClick={onCancelHandler} />
					<h4>{t('common:appName')}</h4>

					<p dangerouslySetInnerHTML={{ __html: questionText || t(types[type].text) }} />

					{elementText && (
						<p>
							<strong>
								"<span dangerouslySetInnerHTML={{ __html: elementText }} />"
							</strong>
							?
						</p>
					)}

					<div className="button-row">
						{type === 'error' ? (
							<Button type={'primary'} htmlType={'button'} onClick={onCancelHandler}>
								{buttonText || t(types[type].button)}
							</Button>
						) : (
							<>
								<Button type={'secondary'} htmlType={'button'} onClick={onCancelHandler}>
									{t('common:actionCancel')}
								</Button>

								<Button type={'primary'} htmlType={'button'} onClick={onConfirmHandler}>
									{buttonText || t(types[type].button)}
								</Button>
							</>
						)}
					</div>
				</div>
			</div>
		</CSSTransition>
	)
}

const mapDispatch = {
	hideModal: modalConfirmHideHandler
}

const connector = connect(null, mapDispatch)

export default connector(ModalConfirm)
