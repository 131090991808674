import LayoutAuth from '../hoc/LayoutAuth'
import Login from '../pages/Authentication/Login/Login'
import { CLOUD_ADMIN, NONE, ORG_ADMIN, SUPPORT, USER } from '../helpers/roles'
import Layout from '../hoc/Layout'
import HomeUser from '../pages/User/Home/Home'
import HomeAdminCloud from '../pages/AdminCloud/Organizations/Organizations'
import HomeAdminOrg from '../pages/AdminOrganization/Organization/Organization'
import Activate from '../pages/Authentication/Activate/Activate'
import Recovery from '../pages/Authentication/Recovery/Recovery'
import Reset from '../pages/Authentication/Reset/Reset'
import Profile from '../pages/Common/Profile/Profile'
import { IRoutes } from './routes'
import Confirm from '../pages/Authentication/Confirm/Confirm'
import Orders from '../pages/Common/Orders/Orders'
import SupportUser from '../pages/User/Support/Support'
import SupportAdminCloud from '../pages/AdminCloud/Support/Support'
import SupportMenuAdminOrg from '../pages/AdminOrganization/Support/Menu'
import SupportTicketUser from '../pages/User/Support/Ticket/Ticket'
import SupportTicketAdminCloud from '../pages/AdminCloud/Support/Ticket/Ticket'
import SupportTicketAdminOrg from '../pages/AdminOrganization/Support/Ticket/Ticket'
import Policy from '../pages/Content/Policy/Policy'
import Consent from '../pages/Content/Consent/Consent'
import LicenseAgreement from '../pages/Content/LicenseAgreement/LicenseAgreement'
import PolicyNew from '../pages/Content/Policy/PolicyNew'
import ConsentNew from '../pages/Content/Consent/ConsentNew'
import LicenseAgreementNew from '../pages/Content/LicenseAgreement/LicenseAgreementNew'
import Demo from '../pages/Content/Demo/Demo'
import Price from '../pages/Content/Price/Price'

export const commonRoutes: IRoutes[] = [
	{
		path: '/',
		roles: {
			[NONE]: {
				layout: LayoutAuth,
				component: Login
			},
			[USER]: {
				layout: Layout,
				component: HomeUser
			},
			[CLOUD_ADMIN]: {
				layout: Layout,
				component: HomeAdminCloud
			},
			[ORG_ADMIN]: {
				layout: Layout,
				component: HomeAdminOrg
			}
		}
	},
	{
		path: '/activate',
		roles: {
			[NONE]: {
				layout: LayoutAuth,
				component: Activate
			},
			[USER]: {
				layout: LayoutAuth,
				component: Activate
			},
			[CLOUD_ADMIN]: {
				layout: LayoutAuth,
				component: Activate
			},
			[ORG_ADMIN]: {
				layout: LayoutAuth,
				component: Activate
			},
			[SUPPORT]: {
				layout: LayoutAuth,
				component: Activate
			}
		}
	},
	{
		path: '/activate/:code',
		roles: {
			[NONE]: {
				layout: LayoutAuth,
				component: Activate
			},
			[USER]: {
				layout: LayoutAuth,
				component: Activate
			},
			[CLOUD_ADMIN]: {
				layout: LayoutAuth,
				component: Activate
			},
			[ORG_ADMIN]: {
				layout: LayoutAuth,
				component: Activate
			},
			[SUPPORT]: {
				layout: LayoutAuth,
				component: Activate
			}
		}
	},
	{
		path: '/recovery',
		roles: {
			[NONE]: {
				layout: LayoutAuth,
				component: Recovery
			},
			[USER]: {
				layout: LayoutAuth,
				component: Recovery
			},
			[CLOUD_ADMIN]: {
				layout: LayoutAuth,
				component: Recovery
			},
			[ORG_ADMIN]: {
				layout: LayoutAuth,
				component: Recovery
			},
			[SUPPORT]: {
				layout: LayoutAuth,
				component: Recovery
			}
		}
	},
	{
		path: '/reset',
		roles: {
			[NONE]: {
				layout: LayoutAuth,
				component: Reset
			},
			[USER]: {
				layout: LayoutAuth,
				component: Reset
			},
			[CLOUD_ADMIN]: {
				layout: LayoutAuth,
				component: Reset
			},
			[ORG_ADMIN]: {
				layout: LayoutAuth,
				component: Reset
			},
			[SUPPORT]: {
				layout: LayoutAuth,
				component: Reset
			}
		}
	},
	{
		path: '/reset/:code',
		roles: {
			[NONE]: {
				layout: LayoutAuth,
				component: Reset
			},
			[USER]: {
				layout: LayoutAuth,
				component: Reset
			},
			[CLOUD_ADMIN]: {
				layout: LayoutAuth,
				component: Reset
			},
			[ORG_ADMIN]: {
				layout: LayoutAuth,
				component: Reset
			},
			[SUPPORT]: {
				layout: LayoutAuth,
				component: Reset
			}
		}
	},
	{
		path: '/confirm',
		roles: {
			[NONE]: {
				layout: LayoutAuth,
				component: Confirm
			},
			[USER]: {
				layout: LayoutAuth,
				component: Confirm
			},
			[CLOUD_ADMIN]: {
				layout: LayoutAuth,
				component: Confirm
			},
			[ORG_ADMIN]: {
				layout: LayoutAuth,
				component: Confirm
			},
			[SUPPORT]: {
				layout: LayoutAuth,
				component: Confirm
			}
		}
	},
	{
		path: '/confirm/:code',
		roles: {
			[NONE]: {
				layout: LayoutAuth,
				component: Confirm
			},
			[USER]: {
				layout: LayoutAuth,
				component: Confirm
			},
			[CLOUD_ADMIN]: {
				layout: LayoutAuth,
				component: Confirm
			},
			[ORG_ADMIN]: {
				layout: LayoutAuth,
				component: Confirm
			},
			[SUPPORT]: {
				layout: LayoutAuth,
				component: Confirm
			}
		}
	},
	{
		path: '/profile',
		roles: {
			[USER]: {
				layout: Layout,
				component: Profile
			},
			[CLOUD_ADMIN]: {
				layout: Layout,
				component: Profile
			},
			[ORG_ADMIN]: {
				layout: Layout,
				component: Profile
			},
			[SUPPORT]: {
				layout: Layout,
				component: Profile
			}
		}
	},
	{
		path: '/orders',
		roles: {
			[CLOUD_ADMIN]: {
				layout: Layout,
				component: Orders
			},
			[ORG_ADMIN]: {
				layout: Layout,
				component: Orders
			}
		}
	},
	{
		path: '/support',
		roles: {
			[USER]: {
				layout: Layout,
				component: SupportUser
			},
			[CLOUD_ADMIN]: {
				layout: Layout,
				component: SupportAdminCloud
			},
			[ORG_ADMIN]: {
				layout: Layout,
				component: SupportMenuAdminOrg
			}
		}
	},
	{
		path: '/support/:ticketId',
		roles: {
			[USER]: {
				layout: Layout,
				component: SupportTicketUser
			},
			[CLOUD_ADMIN]: {
				layout: Layout,
				component: SupportTicketAdminCloud
			},
			[ORG_ADMIN]: {
				layout: Layout,
				component: SupportTicketAdminOrg
			}
		}
	},
	{
		path: '/policy',
		roles: {
			[NONE]: {
				layout: LayoutAuth,
				component: Policy
			},
			[USER]: {
				layout: LayoutAuth,
				component: Policy
			},
			[CLOUD_ADMIN]: {
				layout: LayoutAuth,
				component: Policy
			},
			[ORG_ADMIN]: {
				layout: LayoutAuth,
				component: Policy
			},
			[SUPPORT]: {
				layout: LayoutAuth,
				component: Policy
			}
		}
	},
	{
		path: '/policy-new',
		roles: {
			[NONE]: {
				layout: LayoutAuth,
				component: PolicyNew
			},
			[USER]: {
				layout: LayoutAuth,
				component: PolicyNew
			},
			[CLOUD_ADMIN]: {
				layout: LayoutAuth,
				component: PolicyNew
			},
			[ORG_ADMIN]: {
				layout: LayoutAuth,
				component: PolicyNew
			},
			[SUPPORT]: {
				layout: LayoutAuth,
				component: PolicyNew
			}
		}
	},
	{
		path: '/consent',
		roles: {
			[NONE]: {
				layout: LayoutAuth,
				component: Consent
			},
			[USER]: {
				layout: LayoutAuth,
				component: Consent
			},
			[CLOUD_ADMIN]: {
				layout: LayoutAuth,
				component: Consent
			},
			[ORG_ADMIN]: {
				layout: LayoutAuth,
				component: Consent
			},
			[SUPPORT]: {
				layout: LayoutAuth,
				component: Consent
			}
		}
	},
	{
		path: '/consent-new',
		roles: {
			[NONE]: {
				layout: LayoutAuth,
				component: ConsentNew
			},
			[USER]: {
				layout: LayoutAuth,
				component: ConsentNew
			},
			[CLOUD_ADMIN]: {
				layout: LayoutAuth,
				component: ConsentNew
			},
			[ORG_ADMIN]: {
				layout: LayoutAuth,
				component: ConsentNew
			},
			[SUPPORT]: {
				layout: LayoutAuth,
				component: ConsentNew
			}
		}
	},
	{
		path: '/license-agreement',
		roles: {
			[NONE]: {
				layout: LayoutAuth,
				component: LicenseAgreement
			},
			[USER]: {
				layout: LayoutAuth,
				component: LicenseAgreement
			},
			[CLOUD_ADMIN]: {
				layout: LayoutAuth,
				component: LicenseAgreement
			},
			[ORG_ADMIN]: {
				layout: LayoutAuth,
				component: LicenseAgreement
			},
			[SUPPORT]: {
				layout: LayoutAuth,
				component: LicenseAgreement
			}
		}
	},
	{
		path: '/license-agreement-new',
		roles: {
			[NONE]: {
				layout: LayoutAuth,
				component: LicenseAgreementNew
			},
			[USER]: {
				layout: LayoutAuth,
				component: LicenseAgreementNew
			},
			[CLOUD_ADMIN]: {
				layout: LayoutAuth,
				component: LicenseAgreementNew
			},
			[ORG_ADMIN]: {
				layout: LayoutAuth,
				component: LicenseAgreementNew
			},
			[SUPPORT]: {
				layout: LayoutAuth,
				component: LicenseAgreementNew
			}
		}
	},
	{
		path: '/demo',
		roles: {
			[NONE]: {
				layout: LayoutAuth,
				component: Demo
			},
			[USER]: {
				layout: LayoutAuth,
				component: Demo
			},
			[CLOUD_ADMIN]: {
				layout: LayoutAuth,
				component: Demo
			},
			[ORG_ADMIN]: {
				layout: LayoutAuth,
				component: Demo
			},
			[SUPPORT]: {
				layout: LayoutAuth,
				component: Demo
			}
		}
	},
	{
		path: '/price',
		roles: {
			[NONE]: {
				layout: LayoutAuth,
				component: Price
			},
			[USER]: {
				layout: LayoutAuth,
				component: Demo
			},
			[CLOUD_ADMIN]: {
				layout: LayoutAuth,
				component: Price
			},
			[ORG_ADMIN]: {
				layout: LayoutAuth,
				component: Price
			},
			[SUPPORT]: {
				layout: LayoutAuth,
				component: Price
			}
		}
	}
]
