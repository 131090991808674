import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { RootState } from '../../../store/reducers/rootReducer'
import Error from '../../Error/Error'
import OrgSettingsList from '../../Common/Settings/OrgSettings'

const OrgSettings = ({ user }: ConnectedProps<typeof connector>) => {
    const account = user.accounts?.[0]

    if (!account) return <Error />

    return <OrgSettingsList organizationId={account.organization.id} accountId={account.accountId} />
}

const mapState = (state: RootState) => ({
    user: state.user.user
})

const connector = connect(mapState)

export default connector(OrgSettings)
