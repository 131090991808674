import { connect, ConnectedProps } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { TStatus } from '../../../../interfaces/helpers'
import { REGEX_V4 } from '../../../../helpers/helpers'
import Loading from '../../../../components/UI/Loading/Loading'
import { useParams } from 'react-router-dom'
import { RootState } from '../../../../store/reducers/rootReducer'
import { getOrganizationByIdHandler } from '../../../../services/passport/organization'
import Error from '../../../Error/Error'
import UsersList from '../../../Common/Users/Users'

const Users = ({ organization, getOrganization }: ConnectedProps<typeof connector>) => {
	const { organizationId } = useParams<{ organizationId: string }>()
	const [status, setStatus] = useState<TStatus>('loading')

	useEffect(() => {
		if (!organizationId.match(new RegExp(REGEX_V4))) {
			setStatus('error')
		} else if (!organization.id) {
			getOrganization(organizationId).then(response => {
				if (response?.status === 200) {
					setStatus('ready')
				} else {
					setStatus('error')
				}
			})
		} else {
			setStatus('ready')
		}
	}, [getOrganization, organizationId, organization])

	if (status === 'error') return <Error />
	if (status === 'loading') return <Loading />

	return (
		<UsersList
			organizationId={organizationId}
			accountId={organization.accountId}
			parentRoute={`/organization/${organizationId}`}
			crumbs={[
				{
					to: `/organization/${organizationId}/`,
					title: organization.name
				}
			]}
		/>
	)
}

const mapState = (state: RootState) => ({
	organization: state.organization.organization
})

const mapDispatch = {
	getOrganization: getOrganizationByIdHandler
}

const connector = connect(mapState, mapDispatch)

export default connector(Users)
