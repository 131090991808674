import { USER_CLEAR_DATA, USER_GET_DATA, USER_SET_DATA } from '../../actions/passport/user'
import { IUser } from '../../../interfaces/passport/user'
import { UserActionTypes } from '../../../interfaces/passport/actionCreators/users'

export function getUserData(user: IUser): UserActionTypes {
	return {
		type: USER_GET_DATA,
		payload: user
	}
}

export function setUserData(user: IUser): UserActionTypes {
	return {
		type: USER_SET_DATA,
		payload: user
	}
}

export function clearUserData(): UserActionTypes {
	return {
		type: USER_CLEAR_DATA
	}
}
