import { commonRoutes } from './common'
import { userRoutes } from './user'
import { adminOrgRoutes } from './adminOrg'
import { adminCloudRoutes } from './adminCloud'

export interface IRoutes {
	path: string
	roles: {
		[key: string]: {
			layout: any
			component: any
		}
	}
}

export const routes: IRoutes[] = [...commonRoutes, ...userRoutes, ...adminOrgRoutes, ...adminCloudRoutes]
