import { MODAL_CONFIRM_HIDE, MODAL_CONFIRM_SHOW } from '../../actions/UI/modalConfirm'
import { IModalConfirmProps } from '../../../interfaces/UI/modalConfirm'
import { ModalConfirmActionTypes } from '../../../interfaces/UI/actionCreators/modalConfirm'
import { ThunkAction } from 'redux-thunk'
import { RootState } from '../../reducers/rootReducer'
import { Action } from 'redux'

export function modalConfirmShow(data: IModalConfirmProps): ModalConfirmActionTypes {
	return {
		type: MODAL_CONFIRM_SHOW,
		payload: data
	}
}

export function modalConfirmHide(): ModalConfirmActionTypes {
	return {
		type: MODAL_CONFIRM_HIDE
	}
}

export function modalConfirmShowHandler(
	data: IModalConfirmProps
): ThunkAction<void, RootState, unknown, Action<string>> {
	return dispatch => dispatch(modalConfirmShow(data))
}

export function modalConfirmHideHandler(): ThunkAction<void, RootState, unknown, Action<string>> {
	return dispatch => dispatch(modalConfirmHide())
}
