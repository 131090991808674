import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { EMAIL_ADMIN } from '../../helpers/helpers'

export default function Error() {
	const { t } = useTranslation()

	return (
		<div className="error-page">
			<h1>{t('pages:error404:title')}</h1>
			<p
				dangerouslySetInnerHTML={{
					__html: t('pages:error404:text', {
						email: EMAIL_ADMIN
					})
				}}
			/>

			<Link className="button button--primary" to="/">
				{t('pages:error404:button')}
			</Link>
		</div>
	)
}
