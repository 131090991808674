import { connect, ConnectedProps } from 'react-redux'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { TStatus } from '../../../../../interfaces/helpers'
import { useParams, useRouteMatch } from 'react-router-dom'
import { REGEX_V4 } from '../../../../../helpers/helpers'
import Loading from '../../../../../components/UI/Loading/Loading'
import Error from '../../../../Error/Error'
import { RootState } from '../../../../../store/reducers/rootReducer'
import { getOrganizationByIdHandler } from '../../../../../services/passport/organization'
import UsersList from '../../../../Common/Users/Users'

const Users = ({ organization, getOrganization }: ConnectedProps<typeof connector>) => {
	const { t } = useTranslation()
	const { organizationId, licensePackageId } = useParams<{ organizationId: string; licensePackageId: string }>()
	const [status, setStatus] = useState<TStatus>('loading')
	const { path } = useRouteMatch()
	const type = path.split('/').reverse()[0]

	useEffect(() => {
		if (!organizationId.match(new RegExp(REGEX_V4))) {
			setStatus('error')
		} else if (!organization.id) {
			getOrganization(organizationId).then(response => {
				if (response?.status === 200) {
					setStatus('ready')
				} else {
					setStatus('error')
				}
			})
		} else {
			setStatus('ready')
		}
	}, [getOrganization, organizationId, organization])

	if (status === 'error') return <Error />
	if (status === 'loading') return <Loading />

	return (
		<UsersList
			organizationId={organizationId}
			accountId={organization.accountId}
			licensePackageId={licensePackageId}
			userLicenseType={type === 'termless' ? 'TERMLESS' : 'LIMITED'}
			title={t(`pages:licensePackages:${type === 'termless' ? 'titleUserTermless' : 'titleUserUrgent'}`)}
			parentRoute={`/organization/${organizationId}`}
			crumbs={[
				{
					to: `/organization/${organizationId}/`,
					title: organization.name
				},
				{
					title: t('pages:licensePackages:title'),
					to: `/organization/${organizationId}/license-packages`
				}
			]}
		/>
	)
}

const mapProps = (state: RootState) => ({
	organization: state.organization.organization
})

const mapDispatch = {
	getOrganization: getOrganizationByIdHandler
}

const connector = connect(mapProps, mapDispatch)

export default connector(Users)
