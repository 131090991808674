import React, { ReactNode } from 'react'
import InputError from '../InputError/InputError'

interface IProps {
	name: string
	isChecked?: boolean
	isDisabled?: boolean
	reference?: any
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
	isError?: boolean
	errors?: any
	children: ReactNode
	defaultChecked?: boolean
}

const Checkbox = ({
	name,
	isChecked,
	isDisabled,
	reference,
	onChange,
	isError,
	errors,
	children,
	defaultChecked
}: IProps) => {
	return (
		<>
			<label className="checkbox">
				<input
					type="checkbox"
					name={name}
					checked={isChecked}
					disabled={isDisabled}
					ref={reference}
					defaultChecked={defaultChecked}
					onChange={onChange}
				/>
				<span>{children}</span>
			</label>

			{isError && <InputError errors={errors} />}
		</>
	)
}

export default Checkbox
