import React, { useEffect, useState } from 'react'
import {
	ISetting,
	ISettingAPIRequest,
	ISettingCreate,
	ISettingUpdate,
	TSettingSortFields
} from '../../../../interfaces/cabinet/settings'
import {
	createSettingHandler,
	deleteSettingByIdHandler,
	settingGetAllHandler,
	updateSettingByIdHandler
} from '../../../../services/cabinet/settings'
import { modalShowHandler } from '../../../../store/actionCreators/UI/modal'
import { connect, ConnectedProps } from 'react-redux'
import Pagination from '../../../../components/UI/Pagination/Pagination'
import SearchField from '../../../../components/UI/SearchField/SearchField'
import Pagetitle from '../../../../components/UI/Pagetitle/Pagetitle'
import { INFINITE_NUMBER, REGEX_V4 } from '../../../../helpers/helpers'
import { IPagination, TSortColumnsTable, TStatus } from '../../../../interfaces/helpers'
import { getVersionByIdHandler } from '../../../../services/cabinet/versions'
import { IVersion } from '../../../../interfaces/cabinet/versions'
import { useParams } from 'react-router-dom'
import Error from '../../../Error/Error'
import Loading from '../../../../components/UI/Loading/Loading'
import { notificationAddHandler } from '../../../../store/actionCreators/UI/notification'
import NoResults from '../../../../components/UI/NoResults/NoResults'
import { useTranslation } from 'react-i18next'
import { toggleLoaderHandler } from '../../../../store/actionCreators/UI/loader'
import ModalAddSetting from './ModalAddSetting'
import ModalEditSetting from './ModalEditSetting'
import Button from '../../../../components/UI/Button/Button'

const Settings = ({
	getVersion,
	getSettings,
	createSetting,
	updateSetting,
	deleteSetting,
	showModal,
	notificationAdd,
	toggleLoader
}: ConnectedProps<typeof connector>) => {
	const { t } = useTranslation()
	const [status, setStatus] = useState<TStatus>('loading')
	const { versionId } = useParams()
	const [table, setTable] = useState<ISetting[]>([])
	const [currentVersion, setCurrentVersion] = useState<IVersion>()
	const [pagination, setPagination] = useState<IPagination>({
		pageNo: 0,
		totalPages: 0,
		totalCount: 0
	})

	const [filterSettings, setFilterSettings] = useState<ISettingAPIRequest>({
		filter: '',
		sortField: 'Key',
		desc: false,
		pageSize: INFINITE_NUMBER,
		pageNo: 0,
		versionId
	})

	const searchHandle = (value: string) => {
		setFilterSettings({
			...filterSettings,
			filter: value,
			pageNo: 0
		})
	}

	const [sortColumns, setSortColumns] = useState<TSortColumnsTable>({
		Key: {
			desc: false,
			classNames: ['table-sort', 'table-sort--top']
		},
		Value: {
			desc: true,
			classNames: ['table-sort']
		},
		Description: {
			desc: true,
			classNames: ['table-sort']
		}
	})

	const paginationHandle = (pageNo: number) => {
		setFilterSettings({
			...filterSettings,
			pageNo
		})
	}

	// const limitHandle: TOnChange = option => {
	// 	if (option) {
	// 		setFilterSettings({
	// 			...filterSettings,
	// 			pageSize: parseInt(option.value) || INFINITE_NUMBER,
	// 			pageNo: 0
	// 		})
	// 	}
	// }

	const sortHandle = (sortField: TSettingSortFields) => {
		const updatedSortColumns = { ...sortColumns }

		Object.keys(updatedSortColumns).forEach((key: string) => {
			if (key !== sortField) {
				updatedSortColumns[key] = {
					desc: true,
					classNames: ['table-sort']
				}
			} else {
				updatedSortColumns[key].desc = !updatedSortColumns[key].desc
				updatedSortColumns[key].classNames = [
					'table-sort',
					updatedSortColumns[key].desc ? 'table-sort--bottom' : 'table-sort--top'
				]
			}
		})

		setFilterSettings({
			...filterSettings,
			sortField,
			desc: updatedSortColumns[sortField].desc
		})

		setSortColumns(updatedSortColumns)
	}

	const createHandler = () => {
		showModal({
			Component: ModalAddSetting,
			ComponentProps: {
				versionId,
				onConfirm: async (data: ISettingCreate) => {
					toggleLoader(true)

					try {
						const response = await createSetting(data)

						if (response?.status === 200) {
							paginationHandle(0)
							notificationAdd(t('pages:settings:notificationCreate'), 'info')
						}

						return response
					} finally {
						toggleLoader(false)
					}
				}
			}
		})
	}

	const updateHandler = (item: ISetting) => {
		showModal({
			type: 'description',
			Component: ModalEditSetting,
			ComponentProps: {
				versionId,
				item,
				onConfirm: async (data: ISettingUpdate) => {
					toggleLoader(true)

					try {
						const response = await updateSetting(item.id, data)

						if (response?.status === 200) {
							paginationHandle(0)
							notificationAdd(t('pages:settings:notificationUpdate'), 'info')
						}

						return response
					} finally {
						toggleLoader(false)
					}
				},
				onRemove: async (id: string) => {
					toggleLoader(true)

					try {
						const response = await deleteSetting(id)

						if (response?.status === 200) {
							paginationHandle(0)
							notificationAdd(t('pages:settings:notificationRemove'), 'info')
						}

						return response
					} finally {
						toggleLoader(false)
					}
				}
			}
		})
	}

	useEffect(() => {
		if (!versionId.match(new RegExp(REGEX_V4))) {
			setStatus('error')
		} else {
			getVersion(versionId).then(response => {
				if (response?.status === 200) {
					setCurrentVersion(response.data)
				}
			})
		}
	}, [getVersion, versionId])

	useEffect(() => {
		if (currentVersion) {
			getSettings(filterSettings).then(response => {
				if (response?.status === 200) {
					const { data, pageNo, totalPages, totalCount } = response.data

					setTable(data)

					setPagination({
						pageNo,
						totalPages,
						totalCount
					})

					setStatus('ready')
				}
			})
		}
	}, [getSettings, filterSettings, currentVersion])

	if (status === 'loading') return <Loading />
	if (status === 'error') return <Error />

	return (
		<>
			<Pagetitle
				title={t('pages:settings:titleVersionSettings', {
					value: `${currentVersion?.product.name}. ${currentVersion?.name}`
				})}
				crumbs={[
					{
						to: `/versions/`,
						title: t('pages:settings:titleVersion')
					}
				]}
			/>

			<div className="filters">
				<div className="filters-item filters-item--search filters-item--small">
					<SearchField onSubmit={searchHandle} placeholder={t('pages:settings:searchField')} />
				</div>

				{/*<div className="filters-item filters-item--view">*/}
				{/*	<SelectLimit total={pagination.totalCount} onChange={limitHandle} isDisabled={!currentVersion} />*/}
				{/*</div>*/}

				<div className="filters-item filters-item--buttons">
					<div className="button-row">
						<Button type={'primary'} htmlType={'button'} onClick={createHandler}>
							{t('pages:settings:addNew')}
						</Button>
					</div>
				</div>
			</div>

			{table.length > 0 ? (
				<div className="pagination-wrapper">
					<div className="table">
						<table>
							<colgroup>
								<col />
								<col />
								<col />
								<col />
							</colgroup>
							<thead>
								<tr>
									<th onClick={() => sortHandle('Key')}>
										<div className={sortColumns.Key.classNames.join(' ')}>{t('pages:settings:tableHeadingKey')}</div>
									</th>
									<th onClick={() => sortHandle('Value')}>
										<div className={sortColumns.Value.classNames.join(' ')}>
											{t('pages:settings:tableHeadingValue')}
										</div>
									</th>
									<th onClick={() => sortHandle('Description')}>
										<div className={sortColumns.Description.classNames.join(' ')}>
											{t('pages:settings:tableHeadingDescription')}
										</div>
									</th>
									<th>
										<div>{t('pages:settings:tableHeadingOverride')}</div>
									</th>
								</tr>
							</thead>

							<tbody>
								{table.map(item => {
									return (
										<tr key={item.id}>
											<td>
												<button className="button-link" type="button" onClick={() => updateHandler(item)}>
													{item.key}
												</button>
											</td>
											<td>
												<div className="table-break">{item.value}</div>
											</td>
											<td>{item?.description}</td>
											<td>{item.override ? t('common:yes') : t('common:no')}</td>
										</tr>
									)
								})}
							</tbody>
						</table>
					</div>

					<Pagination onClick={paginationHandle} {...pagination} />
				</div>
			) : (
				<NoResults />
			)}
		</>
	)
}

const mapDispatch = {
	getVersion: getVersionByIdHandler,
	getSettings: settingGetAllHandler,
	createSetting: createSettingHandler,
	updateSetting: updateSettingByIdHandler,
	deleteSetting: deleteSettingByIdHandler,
	showModal: modalShowHandler,
	notificationAdd: notificationAddHandler,
	toggleLoader: toggleLoaderHandler
}

const connector = connect(null, mapDispatch)

export default connector(Settings)
