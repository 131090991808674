import { IRoutes } from './routes'
import { CLOUD_ADMIN } from '../helpers/roles'
import Layout from '../hoc/Layout'
import Menu from '../pages/AdminCloud/Organizations/Menu/Menu'
import Info from '../pages/AdminCloud/Organizations/Info/Info'
import Faculties from '../pages/AdminCloud/Organizations/Faculties/Faculties'
import Orders from '../pages/Common/Orders/Orders'
import LicensePackages from '../pages/AdminCloud/Organizations/LicensePackages/LicensePackages'
import LicensePackagesUsers from '../pages/AdminCloud/Organizations/LicensePackages/Users/Users'
import Users from '../pages/AdminCloud/Organizations/Users/Users'
import UserEdit from '../pages/AdminCloud/Organizations/Users/UserEdit'
import UserAdd from '../pages/AdminCloud/Organizations/Users/UserAdd'
import Settings from '../pages/AdminCloud/Organizations/Settings/Settings'
import Administrators from '../pages/AdminCloud/Organizations/Administrators/Administrators'
import Notifications from '../pages/AdminCloud/Notifications/Notifications'
import Reports from '../pages/AdminCloud/Reports/Reports'
import Products from '../pages/AdminCloud/Products/Products'
import Versions from '../pages/AdminCloud/Versions/Versions'
import VersionsSettings from '../pages/AdminCloud/Versions/Settings/Settings'
import Builds from '../pages/AdminCloud/Builds/Builds'
import HardwareKeys from '../pages/AdminCloud/HardwareKeys/HardwareKeys'
import SystemUsers from '../pages/AdminCloud/SystemUsers/SystemUsers'
import Tenants from '../pages/AdminCloud/Tenants/Tenants'
import ProductUpdates from '../pages/AdminCloud/ProductUpdates/ProductUpdates'
import ProductUpdatesAdd from '../pages/AdminCloud/ProductUpdates/Add/Add'
import ProductUpdatesEdit from '../pages/AdminCloud/ProductUpdates/Edit/Edit'
import Distributives from '../pages/AdminCloud/Organizations/LicensePackages/Distributives/Distributives'
import Functions from '../pages/AdminCloud/Features/Features'

export const adminCloudRoutes: IRoutes[] = [
	{
		path: '/organization/:organizationId/',
		roles: {
			[CLOUD_ADMIN]: {
				layout: Layout,
				component: Menu
			}
		}
	},
	{
		path: '/organization/:organizationId/info',
		roles: {
			[CLOUD_ADMIN]: {
				layout: Layout,
				component: Info
			}
		}
	},
	{
		path: '/organization/:organizationId/faculties',
		roles: {
			[CLOUD_ADMIN]: {
				layout: Layout,
				component: Faculties
			}
		}
	},
	{
		path: '/organization/:organizationId/license-package-requests',
		roles: {
			[CLOUD_ADMIN]: {
				layout: Layout,
				component: Orders
			}
		}
	},
	{
		path: '/organization/:organizationId/license-packages',
		roles: {
			[CLOUD_ADMIN]: {
				layout: Layout,
				component: LicensePackages
			}
		}
	},
	{
		path: '/organization/:organizationId/license-packages/:licensePackageId/termless',
		roles: {
			[CLOUD_ADMIN]: {
				layout: Layout,
				component: LicensePackagesUsers
			}
		}
	},
	{
		path: '/organization/:organizationId/license-packages/:licensePackageId/urgent',
		roles: {
			[CLOUD_ADMIN]: {
				layout: Layout,
				component: LicensePackagesUsers
			}
		}
	},
	{
		path: '/organization/:organizationId/license-packages/:licensePackageId/hardware',
		roles: {
			[CLOUD_ADMIN]: {
				layout: Layout,
				component: Distributives
			}
		}
	},
	{
		path: '/organization/:organizationId/users',
		roles: {
			[CLOUD_ADMIN]: {
				layout: Layout,
				component: Users
			}
		}
	},
	{
		path: '/organization/:organizationId/users/add',
		roles: {
			[CLOUD_ADMIN]: {
				layout: Layout,
				component: UserAdd
			}
		}
	},
	{
		path: '/organization/:organizationId/users/:userId',
		roles: {
			[CLOUD_ADMIN]: {
				layout: Layout,
				component: UserEdit
			}
		}
	},
	{
		path: '/organization/:organizationId/settings',
		roles: {
			[CLOUD_ADMIN]: {
				layout: Layout,
				component: Settings
			}
		}
	},
	{
		path: '/organization/:organizationId/administrators',
		roles: {
			[CLOUD_ADMIN]: {
				layout: Layout,
				component: Administrators
			}
		}
	},
	{
		path: '/notifications',
		roles: {
			[CLOUD_ADMIN]: {
				layout: Layout,
				component: Notifications
			}
		}
	},
	{
		path: '/reports',
		roles: {
			[CLOUD_ADMIN]: {
				layout: Layout,
				component: Reports
			}
		}
	},
	{
		path: '/products',
		roles: {
			[CLOUD_ADMIN]: {
				layout: Layout,
				component: Products
			}
		}
	},
	{
		path: '/functions',
		roles: {
			[CLOUD_ADMIN]: {
				layout: Layout,
				component: Functions
			}
		}
	},
	{
		path: '/functions/:productId',
		roles: {
			[CLOUD_ADMIN]: {
				layout: Layout,
				component: Functions
			}
		}
	},
	{
		path: '/versions',
		roles: {
			[CLOUD_ADMIN]: {
				layout: Layout,
				component: Versions
			}
		}
	},
	{
		path: '/versions/:productId',
		roles: {
			[CLOUD_ADMIN]: {
				layout: Layout,
				component: Versions
			}
		}
	},
	{
		path: '/versions/:versionId/settings',
		roles: {
			[CLOUD_ADMIN]: {
				layout: Layout,
				component: VersionsSettings
			}
		}
	},
	{
		path: '/builds',
		roles: {
			[CLOUD_ADMIN]: {
				layout: Layout,
				component: Builds
			}
		}
	},
	{
		path: '/builds/:productId',
		roles: {
			[CLOUD_ADMIN]: {
				layout: Layout,
				component: Builds
			}
		}
	},
	{
		path: '/hardware-keys',
		roles: {
			[CLOUD_ADMIN]: {
				layout: Layout,
				component: HardwareKeys
			}
		}
	},
	{
		path: '/system-users',
		roles: {
			[CLOUD_ADMIN]: {
				layout: Layout,
				component: SystemUsers
			}
		}
	},
	{
		path: '/tenants',
		roles: {
			[CLOUD_ADMIN]: {
				layout: Layout,
				component: Tenants
			}
		}
	},
	{
		path: '/product-updates',
		roles: {
			[CLOUD_ADMIN]: {
				layout: Layout,
				component: ProductUpdates
			}
		}
	},
	{
		path: '/product-updates/add',
		roles: {
			[CLOUD_ADMIN]: {
				layout: Layout,
				component: ProductUpdatesAdd
			}
		}
	},
	{
		path: '/product-updates/:productUpdateId',
		roles: {
			[CLOUD_ADMIN]: {
				layout: Layout,
				component: ProductUpdatesEdit
			}
		}
	}
]
