import { IRoutes } from './routes'
import { ORG_ADMIN } from '../helpers/roles'
import Layout from '../hoc/Layout'
import Faculties from '../pages/AdminOrganization/Faculties/Faculties'
import Users from '../pages/AdminOrganization/Users/Users'
import UserAdd from '../pages/AdminOrganization/Users/UserAdd'
import UserEdit from '../pages/AdminOrganization/Users/UserEdit'
import Distributives from '../pages/AdminOrganization/Distributives/Distributives'
import LicensePackages from '../pages/AdminOrganization/LicensePackages/LicensePackages'
import LicensePackagesUsers from '../pages/AdminOrganization/LicensePackages/Users/Users'
import OrgSettings from '../pages/AdminOrganization/OrgSettings/OrgSettings'
import Support from '../pages/AdminOrganization/Support/Support'

export const adminOrgRoutes: IRoutes[] = [
	{
		path: '/support/type/:type',
		roles: {
			[ORG_ADMIN]: {
				layout: Layout,
				component: Support
			}
		}
	},
	{
		path: '/faculties',
		roles: {
			[ORG_ADMIN]: {
				layout: Layout,
				component: Faculties
			}
		}
	},
	{
		path: '/users',
		roles: {
			[ORG_ADMIN]: {
				layout: Layout,
				component: Users
			}
		}
	},
	{
		path: '/users/add',
		roles: {
			[ORG_ADMIN]: {
				layout: Layout,
				component: UserAdd
			}
		}
	},
	{
		path: '/users/:userId',
		roles: {
			[ORG_ADMIN]: {
				layout: Layout,
				component: UserEdit
			}
		}
	},
	{
		path: '/distributives',
		roles: {
			[ORG_ADMIN]: {
				layout: Layout,
				component: Distributives
			}
		}
	},
	{
		path: '/distributives/:licensePackageId',
		roles: {
			[ORG_ADMIN]: {
				layout: Layout,
				component: Distributives
			}
		}
	},
	{
		path: '/license-packages',
		roles: {
			[ORG_ADMIN]: {
				layout: Layout,
				component: LicensePackages
			}
		}
	},
	{
		path: '/license-packages/:licensePackageId/:type',
		roles: {
			[ORG_ADMIN]: {
				layout: Layout,
				component: LicensePackagesUsers
			}
		}
	},
	{
		path: '/settings',
		roles: {
			[ORG_ADMIN]: {
				layout: Layout,
				component: OrgSettings
			}
		}
	}
]
