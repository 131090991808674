import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { ISetting, ISettingUpdate } from '../../../../interfaces/cabinet/settings'
import { useTranslation } from 'react-i18next'
import { INFINITE_NUMBER } from '../../../../helpers/helpers'
import Select from 'react-select'
import { selectProps } from '../../../../components/UI/Select/helpers'
import {
	deleteSettingByIdHandler,
	settingGetAllHandler,
	updateSettingByIdHandler
} from '../../../../services/cabinet/settings'
import { connect, ConnectedProps } from 'react-redux'
import { versionGetAllHandler } from '../../../../services/cabinet/versions'
import { IVersion } from '../../../../interfaces/cabinet/versions'
import Loading from '../../../../components/UI/Loading/Loading'
import UpdateSettingsForm from './UpdateSettingsForm'
import { notificationAddHandler } from '../../../../store/actionCreators/UI/notification'

interface ISettingEditable extends ISetting {
	selected: boolean
}

const VersionSettings = ({
	getSettings,
	updateSetting,
	deleteSetting,
	getVersions,
	notificationAdd
}: ConnectedProps<typeof connector>) => {
	const { t } = useTranslation()

	const [versions, setVersions] = useState<IVersion[]>([])
	const [currentVersionId, setCurrentVersionId] = useState<string | null>(null)
	const [settings, setSettings] = useState<ISettingEditable[] | null>(null)
	const [isSettingsLoading, setIsSettingsLoading] = useState<boolean>(false)

	const fetchSettings = useCallback(async () => {
		if (!currentVersionId) return

		const response = await getSettings({
			filter: '',
			sortField: 'Key',
			desc: false,
			pageSize: INFINITE_NUMBER,
			pageNo: 0,
			versionId: currentVersionId
		})

		if (response?.status === 200) {
			const settingsEditable = response.data.data.map(item => ({
				...item,
				selected: false
			}))

			setSettings(settingsEditable)
		}
	}, [currentVersionId, getSettings])

	const toggleHandler = (item: ISettingEditable) => {
		if (!settings) return

		const updatedSettings = settings.map(setting => ({
			...setting,
			selected: setting.id === item.id ? !setting.selected : false
		}))

		setSettings(updatedSettings)
	}

	const onUpdateSettingHandler = async (id: string, data: ISettingUpdate) => {
		if (!currentVersionId) return

		try {
			const response = await updateSetting(id, data)

			if (response?.status === 200) {
				await fetchSettings()

				notificationAdd(t('pages:settings:notificationUpdate'), 'info')
			}

			return response
		} catch (e) {
			console.log(e)
		}
	}

	const onRemoveHandler = async (id: string) => {
		try {
			const response = await deleteSetting(id)

			if (response?.status === 200) {
				await fetchSettings()

				notificationAdd(t('pages:settings:notificationRemove'), 'info')
			}

			return response
		} catch (e) {
			console.log(e)
		}
	}

	useEffect(() => {
		;(async () => {
			const versionsResponse = await getVersions({
				desc: true,
				pageSize: INFINITE_NUMBER,
				pageNo: 0
			})

			if (versionsResponse?.status === 200) {
				setVersions(versionsResponse.data.data)
			}
		})()
	}, [getVersions])

	useEffect(() => {
		;(async () => {
			if (currentVersionId) {
				setIsSettingsLoading(true)

				await fetchSettings()

				setIsSettingsLoading(false)
			}
		})()
	}, [currentVersionId, fetchSettings])

	return (
		<div className="form-group">
			<div className="form-group-title">
				<span>{t('pages:organizations:versionTitle')}</span>
			</div>

			<div className="form-item">
				<Select
					options={versions?.map(item => {
						return {
							label: item.name,
							value: item.id
						}
					})}
					placeholder={t('pages:organizations:fieldVersion')}
					onChange={option => option && setCurrentVersionId(option.value)}
					{...selectProps}
				/>
			</div>

			<div className="form-item">
				{isSettingsLoading ? (
					<Loading />
				) : (
					<>
						{!settings ? (
							<div style={{ textAlign: 'center' }}>{t('pages:organizations:labelSelectVersion')}</div>
						) : (
							<>
								{!settings.length ? (
									<div style={{ textAlign: 'center' }}>{t('pages:organizations:labelNoSettings')}</div>
								) : (
									<div className="table">
										<table style={{ width: '100%' }}>
											<colgroup>
												<col width="50%" />
												<col width="50%" />
											</colgroup>
											<thead>
												<tr>
													<th>
														<div>{t('pages:settings:tableHeadingKey')}</div>
													</th>
													<th>
														<div>{t('pages:settings:tableHeadingValue')}</div>
													</th>
												</tr>
											</thead>
											<tbody>
												{settings?.map(item => (
													<Fragment key={item.id}>
														<tr onClick={() => toggleHandler(item)} style={{ cursor: 'pointer' }}>
															<td>{item.key}</td>
															<td>
																<div className="table-break">{item.value}</div>
															</td>
														</tr>

														{item.selected && (
															<tr>
																<td colSpan={2}>
																	<UpdateSettingsForm
																		versionId={currentVersionId}
																		setting={item}
																		onSubmit={onUpdateSettingHandler}
																		onRemove={onRemoveHandler}
																	/>
																</td>
															</tr>
														)}
													</Fragment>
												))}
											</tbody>
										</table>
									</div>
								)}
							</>
						)}
					</>
				)}
			</div>
		</div>
	)
}

const mapDispatch = {
	getVersions: versionGetAllHandler,
	getSettings: settingGetAllHandler,
	updateSetting: updateSettingByIdHandler,
	deleteSetting: deleteSettingByIdHandler,
	notificationAdd: notificationAddHandler
}

const connector = connect(null, mapDispatch)

export default connector(VersionSettings)
