import axios from 'axios'
import { API_PASSPORT_PATH, APIServices } from '../../helpers/api'
import { setErrorHandler } from '../../store/actionCreators/error'
import { INotification, INotificationAPIRequest, INotificationCreate } from '../../interfaces/passport/notifications'
import { APIResponse, AppThunk } from '../../interfaces/helpers'
import { serviceHandlerErrorOptions } from '../helpers'

export function notificationGetAllHandler(
	params: INotificationAPIRequest,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<APIResponse<INotification[]>> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<APIResponse<INotification[]>>({
				method: 'GET',
				url: `${API_PASSPORT_PATH}/notifications`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params
			})
			return response
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}

export function createNotificationHandler(
	data: INotificationCreate,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<INotification> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<INotification>({
				method: 'POST',
				url: `${API_PASSPORT_PATH}/notifications`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				data
			})
			return response
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}

export function getNotificationByIdHandler(
	id: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<INotification> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<INotification>({
				method: 'GET',
				url: `${API_PASSPORT_PATH}/notifications/${id}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id
				}
			})
			return response
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}
