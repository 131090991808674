import React, { FC, useState } from 'react'
import { ISetting, ISettingUpdate } from '../../../../interfaces/cabinet/settings'
import { useForm } from 'react-hook-form'
import Input from '../../../../components/UI/Input/Input'
import Checkbox from '../../../../components/UI/Checkbox/Checkbox'
import Button from '../../../../components/UI/Button/Button'
import { useTranslation } from 'react-i18next'

interface Props {
	versionId: string | null
	setting: ISetting
	onSubmit: (id: string, data: ISettingUpdate) => void
	onRemove: (id: string) => void
}

interface IFormFields {
	key: string
	value: string
	description?: string
	override: boolean
}

const UpdateSettingsForm: FC<Props> = ({ onSubmit, onRemove, setting, versionId }) => {
	const { t } = useTranslation()

	const { register, handleSubmit, formState, watch, errors } = useForm<IFormFields>({
		defaultValues: {
			key: setting.key,
			value: setting.value,
			description: setting.description,
			override: setting.override
		}
	})
	const { isDirty } = formState
	const [isSubmitting, setIsSubmitting] = useState(false)

	const onSubmitHandler = async (data: IFormFields) => {
		if (!versionId) return

		setIsSubmitting(true)

		try {
			await onSubmit(setting.id, {
				...setting,
				...data,
				versionId
			})
		} finally {
			setIsSubmitting(false)
		}
	}

	const removeHandler = async () => {
		setIsSubmitting(true)

		await onRemove(setting.id)

		setIsSubmitting(false)
	}

	return (
		<div className="form">
			<div className="form-item">
				<Input
					currentValue={watch('key')}
					type={'text'}
					name={'key'}
					placeholder={t('pages:settings:fieldKey')}
					isDisabled={isSubmitting}
					isError={!!errors.key}
					isRequired={true}
					reference={register({
						required: true
					})}
					errors={errors.key}
				/>
			</div>

			<div className="form-item">
				<Input
					currentValue={watch('value')}
					type={'text'}
					name={'value'}
					placeholder={t('pages:settings:fieldValue')}
					isDisabled={isSubmitting}
					isError={!!errors.value}
					isRequired={true}
					reference={register({
						required: true
					})}
					errors={errors.value}
				/>
			</div>

			<div className="form-item">
				<Input
					currentValue={watch('description')}
					type={'text'}
					name={'description'}
					placeholder={t('pages:settings:fieldDescription')}
					isDisabled={isSubmitting}
					isError={!!errors.description}
					reference={register()}
					errors={errors.description}
				/>
			</div>

			<div className="form-item form-item--checkbox">
				<Checkbox
					name={'override'}
					isDisabled={isSubmitting}
					reference={register}
					errors={errors.override}
					isError={!!errors.override}
				>
					{t('pages:settings:fieldOverride')}
				</Checkbox>
			</div>

			<div className="form-controls form-controls--justify">
				<Button type={'secondary'} htmlType={'button'} onClick={removeHandler} isDisabled={isSubmitting}>
					{t('common:actionRemove')}
				</Button>

				<Button
					type={'primary'}
					htmlType={'button'}
					onClick={() => handleSubmit(onSubmitHandler)()}
					isDisabled={isSubmitting || !isDirty}
				>
					{t('common:actionSave')}
				</Button>
			</div>
		</div>
	)
}

export default UpdateSettingsForm
