import { AppThunk } from '../../interfaces/helpers'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { API_CABINET_PATH, APIServices } from '../../helpers/api'
import { ErrorData, setErrorHandler } from '../../store/actionCreators/error'
import { IActivationCode, IActivationCodeListAPIRequest, IActivationCodeListResponse } from '../../interfaces/cabinet/activationCode'
import { serviceHandlerErrorOptions } from '../helpers'

export function createActivationCodeByLicenseGroupIdHandler(
	licenseGroupId: string,
	userId: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IActivationCode> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IActivationCode>({
				method: 'POST',
				url: `${API_CABINET_PATH}/activationCode/licenseGroup/${licenseGroupId}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					licenseGroupId
				},
				data: {
					userId
				}
			})
			return response
		} catch (ex) {
			const error: AxiosError<ErrorData> = ex as AxiosError<ErrorData>;
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response as any
		}
	}
}

export function createActivationCodeByLicenseGroupIdsHandler(
	licenseGroupIds: string[],
	userId: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IActivationCode[]> {
	return async (dispatch, getState) => {
		const emptyResult = Promise.resolve<AxiosResponse<IActivationCode[]>>({ data: [], status: 200, statusText: "", headers: {}, config: {} });
		if (!userId || licenseGroupIds.length === 0) return emptyResult

		const response = Promise.all(licenseGroupIds.map(item => dispatch(createActivationCodeByLicenseGroupIdHandler(item, userId))))
			.then(response => response.filter(item => item && item.status === 200))
			.then((response: Array<AxiosResponse<IActivationCode>>) => {
				return response.reduce<AxiosResponse<IActivationCode[]>>((acc, item) => {
					acc.data.push(item.data);
					return acc;
				}, { data: [], status: 200, statusText: "", headers: {}, config: {} });
			})
		return response;
	}
}

export function getActivationCodeByUserIdHandler(
	params: IActivationCodeListAPIRequest,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IActivationCodeListResponse[]> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IActivationCodeListResponse[]>({
				method: 'GET',
				url: `${API_CABINET_PATH}/activationCode/list`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params
			})
			return response
		} catch (ex) {
			const error: AxiosError<ErrorData> = ex as AxiosError<ErrorData>;
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response as any
		}
	}
}

export function getActivationCodeByUserIdsHandler(
	userIds: string[],
	accountId?: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IActivationCodeListResponse[]> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IActivationCodeListResponse[]>({
				method: 'POST',
				url: `${API_CABINET_PATH}/activationCode/list`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: accountId ? { accountId } : undefined,
				data: userIds
			})
			return response
		} catch (ex) {
			const error: AxiosError<ErrorData> = ex as AxiosError<ErrorData>;
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response as any
		}
	}
}

export function deleteActivationCodeByIdHandler(id: string, errorOptions = serviceHandlerErrorOptions): AppThunk {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request({
				method: 'DELETE',
				url: `${API_CABINET_PATH}/activationCode/${id}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id
				}
			})
			return response
		} catch (ex) {
			const error: AxiosError<ErrorData> = ex as AxiosError<ErrorData>;
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response as any
		}
	}
}

export function activateActivationCodeHandler(code: string, errorOptions = serviceHandlerErrorOptions): AppThunk {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request({
				method: 'POST',
				url: `${API_CABINET_PATH}/activationCode/activate`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					activationCode: code
				}
			})
			return response
		} catch (ex) {
			const error: AxiosError<ErrorData> = ex as AxiosError<ErrorData>;
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response as any
		}
	}
}
