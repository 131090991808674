import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface IProps {
	children: ReactNode
}

export default function LayoutAuth({ children }: IProps) {
	const { t } = useTranslation()

	return (
		<div className="wrapper page-login">
			<div className="header">
				<div className="container">
					<div className="header-logotype">
						<Link className="logotype" to={'/'}>
							{t('common:appName')}
						</Link>
					</div>
				</div>
			</div>

			<div className="main">
				<div className="container">{children}</div>
			</div>

			<footer className="footer">
				<div className="container">
					<div className="footer-links">
						<a href={t('common:footerLink1')} className="footer-link" target="_blank" rel="noopener noreferrer">
							{t('common:footerLinkText1')}
						</a>
						<a href={t('common:footerLink2')} className="footer-link" target="_blank" rel="noopener noreferrer">
							{t('common:footerLinkText2')}
						</a>
						<a href={t('common:footerLink3')} className="footer-link" target="_blank" rel="noopener noreferrer">
							{t('common:footerLinkText3')}
						</a>
					</div>
					<div className="footer-text" dangerouslySetInnerHTML={{ __html: t('common:footerText1') }} />
				</div>
			</footer>
		</div>
	)
}
