import React, { useEffect, useState } from 'react'
import Pagetitle from '../../../components/UI/Pagetitle/Pagetitle'
import Pagination from '../../../components/UI/Pagination/Pagination'
import Loading from '../../../components/UI/Loading/Loading'
import { IPagination, TDatePickerDate, TSortColumnsTable, TStatus } from '../../../interfaces/helpers'
import { DATE_FORMAT, DATE_FORMAT_API, DEFAULT_PAGE_SIZE } from '../../../helpers/helpers'
import { connect, ConnectedProps } from 'react-redux'
import {
	IProductUpdate,
	IProductUpdateAPIRequest,
	TProductUpdateSortFields
} from '../../../interfaces/apps/productUpdates'
import { productUpdateGetAllHandler } from '../../../services/apps/productUpdate'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { IBuild } from '../../../interfaces/cabinet/builds'
import { ITenant } from '../../../interfaces/apps/tenants'
import SearchField from '../../../components/UI/SearchField/SearchField'
import InputDatePicker from '../../../components/UI/InputDatePicker/InputDatePicker'
import NoResults from '../../../components/UI/NoResults/NoResults'
import { useTranslation } from 'react-i18next'

export interface IBuildCustom extends IBuild {
	selected: boolean
}

export interface ITenantCustom extends ITenant {
	selected: boolean
	disabled: boolean
}

const ProductUpdates = ({ getProductUpdates }: ConnectedProps<typeof connector>) => {
	const { t } = useTranslation()
	const [status, setStatus] = useState<TStatus>('loading')
	const [table, setTable] = useState<IProductUpdate[]>([])
	const [pagination, setPagination] = useState<IPagination>({
		pageNo: 0,
		totalPages: 0,
		totalCount: 0
	})

	const [filterProductUpdates, setFilterProductUpdates] = useState<IProductUpdateAPIRequest>({
		filter: '',
		startTimeFrom: '',
		startTimeTo: '',
		sortField: 'VersionName',
		desc: false,
		pageSize: DEFAULT_PAGE_SIZE,
		pageNo: 0
	})

	const [sortColumns, setSortColumns] = useState<TSortColumnsTable>({
		VersionName: {
			desc: false,
			classNames: ['table-sort', 'table-sort--top']
		},
		StartTime: {
			desc: true,
			classNames: ['table-sort']
		}
	})

	const paginationHandle = (pageNo: number) => {
		setFilterProductUpdates({
			...filterProductUpdates,
			pageNo
		})
	}

	const sortHandle = (sortField: TProductUpdateSortFields) => {
		const updatedSortColumns = { ...sortColumns }

		Object.keys(updatedSortColumns).forEach((key: string) => {
			if (key !== sortField) {
				updatedSortColumns[key] = {
					desc: true,
					classNames: ['table-sort']
				}
			} else {
				updatedSortColumns[key].desc = !updatedSortColumns[key].desc
				updatedSortColumns[key].classNames = [
					'table-sort',
					updatedSortColumns[key].desc ? 'table-sort--bottom' : 'table-sort--top'
				]
			}
		})

		setFilterProductUpdates({
			...filterProductUpdates,
			sortField,
			desc: updatedSortColumns[sortField].desc
		})

		setSortColumns(updatedSortColumns)
	}

	const searchHandle = (value: string) => {
		setFilterProductUpdates({
			...filterProductUpdates,
			filter: value,
			pageNo: 0
		})
	}

	const [startTimeFrom, setStartTimeFrom] = useState<TDatePickerDate>(null)
	const [minStartTimeFrom] = useState<TDatePickerDate>(null)
	const [maxStartTimeFrom, setMaxStartTimeFrom] = useState<TDatePickerDate>(null)
	const [startTimeTo, setStartTimeTo] = useState<TDatePickerDate>(null)
	const [minStartTimeTo, setMinStartTimeTo] = useState<TDatePickerDate>(null)
	const [maxStartTimeTo] = useState<TDatePickerDate>(null)

	const startTimeFromHandler = (date: TDatePickerDate) => {
		setStartTimeFrom(date)
		setMinStartTimeTo(date)

		setFilterProductUpdates({
			...filterProductUpdates,
			startTimeFrom: (date && dayjs(date).format(DATE_FORMAT_API)) || ''
		})
	}

	const startTimeToHandler = (date: TDatePickerDate) => {
		setStartTimeTo(date)
		setMaxStartTimeFrom(date)

		setFilterProductUpdates({
			...filterProductUpdates,
			startTimeTo: (date && dayjs(date).format(DATE_FORMAT_API)) || ''
		})
	}

	useEffect(() => {
		getProductUpdates(filterProductUpdates).then(response => {
			if (response?.status === 200) {
				const { data, pageNo, totalPages, totalCount } = response.data

				setTable(data)

				setPagination({
					pageNo,
					totalPages,
					totalCount
				})
			}

			setStatus('ready')
		})
	}, [getProductUpdates, filterProductUpdates])

	if (status === 'loading') return <Loading />

	return (
		<>
			<Pagetitle title={t('pages:productUpdates:title')} />

			<div className="filters">
				<div className="filters-item filters-item--buttons filters-item--buttons-fullrow">
					<div className="button-row">
						<Link className="button button--primary button--large" to={'/product-updates/add'}>
							{t('pages:productUpdates:addNew')}
						</Link>
					</div>
				</div>

				<div className="filters-item">
					<SearchField onSubmit={searchHandle} placeholder={t('pages:productUpdates:sortField')} />
				</div>

				<div className="filters-item filters-item--calendar-inline">
					<InputDatePicker
						placeholderText={t('pages:productUpdates:startTimeFrom')}
						name={'startTimeFrom'}
						selected={startTimeFrom}
						minDate={minStartTimeFrom}
						maxDate={maxStartTimeFrom}
						onChange={startTimeFromHandler}
					/>

					<span className="filters-item-delimiter">—</span>

					<InputDatePicker
						placeholderText={t('pages:productUpdates:startTimeTo')}
						name={'startTimeTo'}
						selected={startTimeTo}
						minDate={minStartTimeTo}
						maxDate={maxStartTimeTo}
						onChange={startTimeToHandler}
					/>
				</div>
			</div>

			{table.length > 0 ? (
				<div className="pagination-wrapper">
					<div className="table">
						<table>
							<colgroup>
								<col />
								<col />
								<col width="15%" />
								<col width="22%" />
							</colgroup>
							<thead>
								<tr>
									<th onClick={() => sortHandle('VersionName')}>
										<div className={sortColumns.VersionName.classNames.join(' ')}>
											{t('pages:productUpdates:tableHeadingVersionName')}
										</div>
									</th>
									<th>
										<div>{t('pages:productUpdates:tableHeadingBuilds')}</div>
									</th>
									<th onClick={() => sortHandle('StartTime')}>
										<div className={sortColumns.StartTime.classNames.join(' ')}>
											{t('pages:productUpdates:tableHeadingStartTime')}
										</div>
									</th>
									<th>
										<div>{t('pages:productUpdates:tableHeadingTenant')}</div>
									</th>
								</tr>
							</thead>
							<tbody>
								{table.map(item => {
									return (
										<tr key={item.id}>
											<td>
												<Link to={`/product-updates/${item.id}`}>{item.versionName}</Link>
											</td>
											<td>
												<ul className="list list--nomargin">
													{item.productUpdateKeys.map(item => (
														<li className="list-item" key={item.id}>
															<div className="table-break">
																{item.key.platform} {item.key.product} {item.key.buildVersionCode}
															</div>
														</li>
													))}
												</ul>
											</td>
											<td>{item.startTime ? dayjs(item.startTime).format(DATE_FORMAT) : '-'}</td>
											<td>{item.tenants.map(item => item.name).join('; ')}</td>
										</tr>
									)
								})}
							</tbody>
						</table>
					</div>

					<Pagination onClick={paginationHandle} {...pagination} />
				</div>
			) : (
				<NoResults />
			)}
		</>
	)
}

const mapDispatch = {
	getProductUpdates: productUpdateGetAllHandler
}

const connector = connect(null, mapDispatch)

export default connector(ProductUpdates)
