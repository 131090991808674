import React, { useEffect, useState } from 'react'
import Pagetitle from '../../../components/UI/Pagetitle/Pagetitle'
import Button from '../../../components/UI/Button/Button'
import { IPagination, TSortColumnsList, TStatus } from '../../../interfaces/helpers'
import { useTranslation } from 'react-i18next'
import Loading from '../../../components/UI/Loading/Loading'
import Error from '../../Error/Error'
import { modalShowHandler } from '../../../store/actionCreators/UI/modal'
import { connect, ConnectedProps } from 'react-redux'
import { toggleLoaderHandler } from '../../../store/actionCreators/UI/loader'
import {
	createSupportRequestUserHandler,
	supportRequestUserListFilteredByStatusAndNumOrTopicGetAllHandler
} from '../../../services/support/supportRequestUser'
import {
	DATE_FORMAT_FULL,
	DEFAULT_PAGE_SIZE,
	getStatusName,
	INFINITE_NUMBER,
	stringToLocalization,
	SUPPORT_STATUSES
} from '../../../helpers/helpers'
import {
	ISupportRequestUserAPIResponse,
	ISupportRequestUserCreate,
	ISupportRequestUserListFilteredByStatusAndNumOrTopicAPIRequest,
	TSupportRequestUserSortFields
} from '../../../interfaces/support/supportRequestUser'
import ModalAddTicket from './ModalAddTicket'
import { requestTopicGetAllHandler } from '../../../services/support/requestTopic'
import { IRequestTopic } from '../../../interfaces/support/requestTopic'
import dayjs from 'dayjs'
import Pagination from '../../../components/UI/Pagination/Pagination'
import SearchField from '../../../components/UI/SearchField/SearchField'
import { selectProps, TOnChange } from '../../../components/UI/Select/helpers'
import SelectLimit from '../../../components/UI/Select/SelectLimit'
import Select from 'react-select'
import NoResults from '../../../components/UI/NoResults/NoResults'
import { Link } from 'react-router-dom'

const Support = ({
	getTopics,
	getAllSupportRequestUser,
	createSupportRequestUser,
	showModal,
	toggleLoader
}: ConnectedProps<typeof connector>) => {
	const { t } = useTranslation()
	const [status, setStatus] = useState<TStatus>('loading')
	const [topics, setTopics] = useState<IRequestTopic[]>([])
	const [table, setTable] = useState<ISupportRequestUserAPIResponse[]>([])
	const [pagination, setPagination] = useState<IPagination>({
		pageNo: 0,
		totalPages: 0,
		totalCount: 0
	})
	const [
		filterSupportRequestUser,
		setFilterSupportRequestUser
	] = useState<ISupportRequestUserListFilteredByStatusAndNumOrTopicAPIRequest>({
		statusFilter: '',
		numOrTopicOrSubjectFilter: '',
		requestUserSortField: 'CHANGE_DATE',
		desc: true,
		pageSize: DEFAULT_PAGE_SIZE,
		pageNo: 0
	})

	const searchHandle = (value: string) => {
		setFilterSupportRequestUser({
			...filterSupportRequestUser,
			numOrTopicOrSubjectFilter: value,
			pageNo: 0
		})
	}

	const paginationHandle = (pageNo: number) => {
		setFilterSupportRequestUser({
			...filterSupportRequestUser,
			pageNo
		})
	}

	const limitHandle: TOnChange = option => {
		if (option) {
			setFilterSupportRequestUser({
				...filterSupportRequestUser,
				pageSize: parseInt(option.value) || INFINITE_NUMBER,
				pageNo: 0
			})
		}
	}

	const selectHandle: TOnChange = option => {
		if (option) {
			setFilterSupportRequestUser({
				...filterSupportRequestUser,
				statusFilter: option.value
			})
		}
	}

	const [sortColumns, setSortColumns] = useState<TSortColumnsList>({
		CHANGE_DATE: {
			desc: true,
			classNames: ['list-item', 'list-item--down']
		},
		TOPIC: {
			desc: true,
			classNames: ['list-item']
		}
	})

	const sortHandle = (requestUserSortField: TSupportRequestUserSortFields) => {
		const updatedSortColumns = { ...sortColumns }

		Object.keys(updatedSortColumns).forEach((key: string) => {
			if (key !== requestUserSortField) {
				updatedSortColumns[key] = {
					desc: true,
					classNames: ['list-item']
				}
			} else {
				updatedSortColumns[key].desc = !updatedSortColumns[key].desc
				updatedSortColumns[key].classNames = [
					'list-item',
					updatedSortColumns[key].desc ? 'list-item--down' : 'list-item--up'
				]
			}
		})

		setFilterSupportRequestUser({
			...filterSupportRequestUser,
			requestUserSortField,
			desc: updatedSortColumns[requestUserSortField].desc
		})

		setSortColumns(updatedSortColumns)
	}

	const createHandler = () => {
		showModal({
			type: 'middle',
			Component: ModalAddTicket,
			ComponentProps: {
				topics,
				onConfirm: async (data: ISupportRequestUserCreate) => {
					toggleLoader(true)

					try {
						const response = await createSupportRequestUser(data)

						if (response?.status === 200) {
							paginationHandle(0)
						}

						return response
					} finally {
						toggleLoader(false)
					}
				}
			}
		})
	}

	useEffect(() => {
		getTopics({
			topicSortField: 'NAME',
			desc: false,
			pageNo: 0,
			pageSize: INFINITE_NUMBER
		}).then(response => {
			if (response?.status === 200) {
				setTopics(response.data.data)
			}
		})
	}, [getTopics])

	useEffect(() => {
		getAllSupportRequestUser(filterSupportRequestUser).then(response => {
			if (response?.status === 200) {
				const { data, pageNo, totalPages, totalCount, userHasRequests } = response.data

				setTable(data)

				setPagination({
					pageNo,
					totalPages,
					totalCount
				})

				if (userHasRequests) {
					setStatus('ready')
				} else {
					setStatus('empty')
				}
			}
		})
	}, [getAllSupportRequestUser, filterSupportRequestUser])

	if (status === 'loading') return <Loading />
	if (status === 'error') return <Error />

	return (
		<>
			<Pagetitle title={t('pages:support:title')} />

			{status === 'empty' ? (
				<div className="support-empty">
					<p>{t('pages:support:emptyText')}</p>

					<Button type={'primary'} htmlType={'button'} onClick={createHandler}>
						{t('pages:support:emptyButton')}
					</Button>
				</div>
			) : (
				<>
					<div className="button-row button-row--support">
						<Button type={'primary'} htmlType={'button'} onClick={createHandler}>
							{t('pages:support:addNew')}
						</Button>
					</div>

					<div className="filters filters--support">
						<div className="filters-item filters-item--search">
							<SearchField onSubmit={searchHandle} placeholder={t('pages:support:searchField')} />
						</div>

						<div className="filters-item filters-item--select">
							<Select
								name={'state'}
								onChange={selectHandle}
								options={[
									{
										label: t('common:selectDefaultValue'),
										value: ''
									},
									...SUPPORT_STATUSES.map(item => {
										return {
											label: t(getStatusName(item)),
											value: item
										}
									})
								]}
								defaultValue={{
									label: t('common:selectDefaultValue'),
									value: ''
								}}
								placeholder={t('pages:support:state')}
								{...selectProps}
							/>
						</div>

						<div className="filters-item filters-item--view">
							<SelectLimit total={pagination.totalCount} onChange={limitHandle} />
						</div>
					</div>

					<div className="sorting">
						<div className="sorting-title">
							<strong>{t('pages:support:sortBy')}</strong>
						</div>

						<div className="sorting-list">
							<ul className="list">
								<li onClick={() => sortHandle('CHANGE_DATE')} className={sortColumns.CHANGE_DATE.classNames.join(' ')}>
									<span>{t('pages:support:sortByDate')}</span>
								</li>
							</ul>
						</div>
					</div>

					{table.length > 0 ? (
						<div className="pagination-wrapper">
							<div className="support-list">
								{table.map(item => {
									return (
										<Link
											key={item.id}
											className={`support-item ${
												(!item.readDate && dayjs(item.updatedWhen).diff(dayjs(item.fromDate)) > 0) ||
												(item.readDate && dayjs(item.updatedWhen).diff(dayjs(item.readDate)) > 0)
													? 'support-item--update'
													: ''
											} `}
											to={`/support/${item.id}`}
										>
											<div className="support-item-holder">
												<div className="support-item-number">#{item.num}</div>
												<div className="support-item-content">
													<h4>{item.subject}</h4>
													<div className="support-item-theme">
														{t(`common:topic_${stringToLocalization(item.topic.name)}`)}
													</div>
													<div className="support-item-date">
														{t('pages:support:fromDate')}: {dayjs(item.fromDate).format(DATE_FORMAT_FULL)}
														{item.updatedWhen &&
															`, ${t('pages:support:updatedWhen')}: ${dayjs(item.updatedWhen).format(
																DATE_FORMAT_FULL
															)}`}
													</div>
												</div>
												<div className="support-item-status">
													<span className={`task-status task-status--${item.state.toLowerCase()}`}>
														{t(getStatusName(item.state))}
													</span>
												</div>
											</div>
										</Link>
									)
								})}
							</div>

							<Pagination onClick={paginationHandle} {...pagination} />
						</div>
					) : (
						<NoResults />
					)}
				</>
			)}
		</>
	)
}

const mapDispatch = {
	// getAllSupportRequestUser: supportRequestUserGetAllHandler,
	getAllSupportRequestUser: supportRequestUserListFilteredByStatusAndNumOrTopicGetAllHandler,
	createSupportRequestUser: createSupportRequestUserHandler,
	getTopics: requestTopicGetAllHandler,

	showModal: modalShowHandler,
	toggleLoader: toggleLoaderHandler
}

const connector = connect(null, mapDispatch)

export default connector(Support)
