import React, { useEffect, useState } from 'react'
import { modalConfirmShowHandler } from '../../../store/actionCreators/UI/modalConfirm'
import { connect, ConnectedProps } from 'react-redux'
import { ITenant, ITenantCreate } from '../../../interfaces/apps/tenants'
import { useForm } from 'react-hook-form'
import Button from '../../../components/UI/Button/Button'
import Input from '../../../components/UI/Input/Input'
import { IOrganization } from '../../../interfaces/passport/organizations'
import SearchField from '../../../components/UI/SearchField/SearchField'
import Multiselect, { IMultiselectItem } from './components/Multiselect'
import { useTranslation } from 'react-i18next'

type TProps = ConnectedProps<typeof connector> & {
	tenant: ITenant
	organizations: Array<IOrganization>
	onConfirm: (data: ITenantCreate) => Promise<any>
	onRemove: (id: string) => Promise<any>
	hideModal: () => void
}

interface IFormInputs {
	name: string
}

const ModalEditTenant = ({ tenant, organizations, hideModal, showModalConfirm, onConfirm, onRemove }: TProps) => {
	const { t } = useTranslation()
	const { register, errors, handleSubmit, formState, watch } = useForm<IFormInputs>({
		defaultValues: {
			name: tenant.name
		}
	})

	const [orgList, setOrgList] = useState<IMultiselectItem[]>([])
	const [orgFilterList, setOrgFilterList] = useState<IMultiselectItem[]>([])
	const [orgSelectedList, setOrgSelectedList] = useState<IMultiselectItem[]>([])
	const [isAddDisabled, setIsAddDisabled] = useState(true)
	const [isRemoveDisabled, setIsRemoveDisabled] = useState(true)
	const [isFormChanged, setIsFormChanged] = useState(false)

	const { isDirty } = formState
	const [isSubmitting, setIsSubmitting] = useState(false)

	const cancelHandler = () => {
		if (isDirty || isFormChanged) {
			showModalConfirm({
				type: 'cancel',
				onConfirm: hideModal
			})
		} else {
			hideModal()
		}
	}

	const searchHandle = (value: string) => {
		const selected = orgSelectedList.map(item => item.organizationName)
		const list = orgFilterList
			.filter(item => !selected.includes(item.organizationName))
			.filter(item => item.organizationName.toLowerCase().includes(value.toLowerCase()))
			.map(item => {
				item.selected = false
				return item
			})

		setOrgList(list)
	}

	const orgListChangeHandler = (item: IMultiselectItem, selected: boolean) => {
		item.selected = selected
		const index = orgList.findIndex(el => el.organizationId === item.organizationId)
		const updatedList = [...orgList]
		updatedList[index] = item

		setOrgList(updatedList)

		setIsAddDisabled(updatedList.filter(el => el.selected).length === 0)
	}

	const orgSelectedListChangeHandler = (item: IMultiselectItem, selected: boolean) => {
		item.selected = selected
		const index = orgSelectedList.findIndex(el => el.organizationId === item.organizationId)
		const updatedList = [...orgSelectedList]

		updatedList[index] = item

		setOrgSelectedList(updatedList)

		setIsRemoveDisabled(updatedList.filter(el => el.selected).length === 0)
	}

	const addAllHandler = () => {
		setOrgSelectedList([
			...orgSelectedList,
			...orgList.map(item => {
				item.selected = false
				return item
			})
		])

		setOrgList([])
		setIsAddDisabled(true)
		setIsFormChanged(true)
	}

	const removeAllHandler = () => {
		setOrgList([
			...orgList,
			...orgSelectedList.map(item => {
				item.selected = false
				return item
			})
		])

		setOrgSelectedList([])
		setIsRemoveDisabled(true)
		setIsFormChanged(true)
	}

	const addHandler = () => {
		const list = orgList.filter(el => el.selected)
		const updatedList = orgList.filter(el => !el.selected)

		setOrgSelectedList([
			...orgSelectedList,
			...list.map(item => {
				item.selected = false
				return item
			})
		])
		setOrgList(updatedList)
		setIsAddDisabled(true)
		setIsFormChanged(true)
	}

	const removeHandler = () => {
		const list = orgSelectedList.filter(el => el.selected)
		const updatedList = orgSelectedList.filter(el => !el.selected)

		setOrgList([
			...orgList,
			...list.map(item => {
				item.selected = false
				return item
			})
		])
		setOrgSelectedList(updatedList)
		setIsRemoveDisabled(true)
		setIsFormChanged(true)
	}

	const onSubmit = async (data: IFormInputs) => {
		setIsSubmitting(true)

		try {
			const result = {
				...data,
				id: tenant.id,
				organizations: orgSelectedList.map(item => {
					return {
						id: item.id,
						organizationId: item.organizationId,
						organizationName: item.organizationName
					}
				})
			}

			const response = await onConfirm(result)

			if (response?.status === 200) {
				hideModal()
			}
		} finally {
			setIsSubmitting(false)
		}
	}

	const deleteHandler = () => {
		showModalConfirm({
			type: 'remove',
			elementText: tenant.name,
			onConfirm: async () => {
				try {
					const response = await onRemove(tenant.id)

					if (response?.status === 200) {
						hideModal()
					}
				} finally {
				}
			}
		})
	}

	useEffect(() => {
		const list = tenant.organizations.map(item => {
			return {
				selected: false,
				id: item.id,
				organizationId: item.organizationId,
				organizationName: item.organizationName
			}
		})

		setOrgSelectedList(list)
	}, [tenant.organizations])

	useEffect(() => {
		const list = organizations.map(item => {
			return {
				selected: false,
				organizationId: item.id,
				organizationName: item.name
			}
		})

		const selectedIds = tenant.organizations.map(item => item.organizationId)

		const orgList = list.filter(item => !selectedIds.includes(item.organizationId))

		setOrgList(orgList)
		setOrgFilterList(list)
	}, [organizations, tenant.organizations])

	return (
		<>
			<button className="modal-close" onClick={cancelHandler} disabled={isSubmitting} />

			<h3 className="modal-title">{t('common:appName')}</h3>

			<p className="modal-footnote">{t('pages:tenants:modalUpdateText')}</p>

			<form className="form form--update" onSubmit={handleSubmit(onSubmit)}>
				<div className="form-row">
					<div className="form-col form-col-39">
						<SearchField onSubmit={searchHandle} placeholder={t('pages:tenants:fieldSearch')} />
					</div>

					<div className="form-col form-col-22" />

					<div className="form-col form-col-39">
						<Input
							currentValue={watch('name')}
							type={'text'}
							name={'name'}
							placeholder={t('pages:tenants:fieldName')}
							isDisabled={isSubmitting}
							isError={!!errors.name}
							isRequired={true}
							reference={register({
								required: true,
								maxLength: 255
							})}
							errors={errors.name}
							rules={{
								maxLength: 255
							}}
						/>
					</div>
				</div>

				<div className="form-row">
					<div className="form-col form-col-39">
						<Multiselect title={t('pages:tenants:listOrganizations')} list={orgList} onChange={orgListChangeHandler} />
					</div>

					<div className="form-col form-col-22">
						<div className="multiselect-controls">
							<div className="multiselect-controls-item">
								<Button type={'secondary'} htmlType={'button'} onClick={addAllHandler} isDisabled={!orgList.length}>
									<span dangerouslySetInnerHTML={{ __html: t('common:actionMoveAddAll') }} />
								</Button>
								<Button type={'secondary'} htmlType={'button'} onClick={addHandler} isDisabled={isAddDisabled}>
									<span dangerouslySetInnerHTML={{ __html: t('common:actionMoveAdd') }} />
								</Button>
							</div>

							<div className="multiselect-controls-item">
								<Button type={'secondary'} htmlType={'button'} onClick={removeHandler} isDisabled={isRemoveDisabled}>
									<span dangerouslySetInnerHTML={{ __html: t('common:actionMoveRemove') }} />
								</Button>
								<Button
									type={'secondary'}
									htmlType={'button'}
									onClick={removeAllHandler}
									isDisabled={!orgSelectedList.length}
								>
									<span dangerouslySetInnerHTML={{ __html: t('common:actionMoveRemoveAll') }} />
								</Button>
							</div>
						</div>
					</div>

					<div className="form-col form-col-39">
						<Multiselect
							title={t('pages:tenants:listSelectedOrganizations')}
							list={orgSelectedList}
							onChange={orgSelectedListChangeHandler}
						/>
					</div>
				</div>

				<div className="form-controls form-controls--right">
					<Button type={'secondary'} htmlType={'button'} onClick={deleteHandler} isDisabled={isSubmitting}>
						{t('common:actionRemove')}
					</Button>

					<Button type={'secondary'} htmlType={'button'} onClick={cancelHandler} isDisabled={isSubmitting}>
						{t('common:actionCancel')}
					</Button>

					<Button
						type={'primary'}
						htmlType={'submit'}
						isDisabled={isSubmitting || !orgSelectedList.length || (!isDirty && !isFormChanged)}
					>
						{t('common:actionSave')}
					</Button>
				</div>
			</form>
		</>
	)
}

const mapDispatch = {
	showModalConfirm: modalConfirmShowHandler
}

const connector = connect(null, mapDispatch)

export default connector(ModalEditTenant)
