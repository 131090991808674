import React from 'react'
import Button from '../../../components/UI/Button/Button'
import dayjs from 'dayjs'
import { DATE_FORMAT } from '../../../helpers/helpers'
import { useTranslation } from 'react-i18next'
import { ISelfLicense } from '../../../interfaces/cabinet/license'
import { connect, ConnectedProps } from 'react-redux'
import { modalConfirmShowHandler } from '../../../store/actionCreators/UI/modalConfirm'

type TProps = ConnectedProps<typeof connector> & {
	item: ISelfLicense
	hideModal: () => void
	onUnlinkHardware: () => Promise<any>
}

const ModalLicenseDetails = ({ hideModal, onUnlinkHardware, item, showModalConfirm }: TProps) => {
	const { t } = useTranslation()

	const {
		productName,
		productDescription,
		versionName,
		versionDescription,
		termless,
		dateFrom,
		dateTo,
		activationCode,
		activationDate,
		activePlatform,
		activeDevice,
		activeDownloadLink
	} = item

	const unlinkHardwareHandler = async () => {
		showModalConfirm({
			type: 'custom',
			buttonText: t('common:actionOk'),
			questionText: t('pages:home:modalUnlinkText'),
			onConfirm: async () => {
				const response = await onUnlinkHardware()

				if (response?.status === 200) {
					hideModal()
				}
			}
		})
	}

	return (
		<>
			<button className="modal-close" onClick={hideModal} />

			<h3 className="modal-title">{t('common:appName')}</h3>

			<p className="modal-footnote">{t('pages:home:modalIntro')}</p>

			<div className="user-license-desc">
				<div className="user-license-desc-row">
					<div className="user-license-desc-label">{t('pages:home:modalVersion')}:</div>
					<div className="user-license-desc-value">
						{productName}. {versionName}. {versionDescription}
						{productDescription && (
							<>
								<br />
								{productDescription}
							</>
						)}
					</div>
				</div>

				{activationCode && (
					<div className="user-license-desc-row">
						<div className="user-license-desc-label">{t('pages:home:modalCode')}:</div>
						<div className="user-license-desc-value">{activationCode}</div>
					</div>
				)}

				<div className="user-license-desc-row">
					<div className="user-license-desc-label">{t('pages:home:modalType')}:</div>
					<div className="user-license-desc-value">{termless ? t('pages:home:termless') : t('pages:home:urgent')}</div>
				</div>

				{activationDate && (
					<div className="user-license-desc-row">
						<div className="user-license-desc-label">{t('pages:home:modalActivationDate')}:</div>
						<div className="user-license-desc-value">{dayjs(activationDate).format(DATE_FORMAT)}</div>
					</div>
				)}

				{(dateFrom || dateTo) && (
					<div className="user-license-desc-row">
						<div className="user-license-desc-label">{t('pages:home:modalDates')}:</div>
						{dateFrom && dateTo && (
							<div className="user-license-desc-value">
								{dayjs(dateFrom).format(DATE_FORMAT)} - {dayjs(dateTo).format(DATE_FORMAT)}
							</div>
						)}

						{dateFrom && !dateTo && (
							<div className="user-license-desc-value">
								{t('common:from')}
								{dayjs(dateFrom).format(DATE_FORMAT)}
							</div>
						)}
						{!dateFrom && dateTo && (
							<div className="user-license-desc-value">
								{t('common:to')}
								{dayjs(dateTo).format(DATE_FORMAT)}
							</div>
						)}
					</div>
				)}

				{activeDevice && (
					<div className="user-license-desc-row">
						<div className="user-license-desc-label">{t('pages:home:modalDevice')}:</div>
						<div className="user-license-desc-value">
							<div className="user-license-desc-device">
								<span>
									{activeDevice}. {activePlatform}
								</span>
								{!termless && (
									<span>
										<button className={'button-link'} onClick={unlinkHardwareHandler}>
											{t('pages:home:modalUnlink')}
										</button>
									</span>
								)}
							</div>
						</div>
					</div>
				)}

				{activeDownloadLink && (
					<div className="user-license-desc-row">
						<div className="user-license-desc-label">{t('pages:home:modalDistributive')}:</div>
						<div className="user-license-desc-value">
							<a
								className="user-license-desc-download"
								href={activeDownloadLink}
								target="_blank"
								rel="noopener noreferrer"
							>
								{activeDownloadLink}
							</a>
						</div>
					</div>
				)}
			</div>
			<div className="button-row button-row--right">
				<Button type={'primary'} htmlType={'button'} onClick={hideModal}>
					{t('common:actionClose')}
				</Button>
			</div>
		</>
	)
}

const mapDispatch = {
	showModalConfirm: modalConfirmShowHandler
}

const connector = connect(null, mapDispatch)

export default connector(ModalLicenseDetails)
