import { MODAL_HIDE, MODAL_SHOW } from '../../actions/UI/modal'
import { IModalProps } from '../../../interfaces/UI/modal'
import { ModalActionTypes } from '../../../interfaces/UI/actionCreators/modal'
import { ThunkAction } from 'redux-thunk'
import { RootState } from '../../reducers/rootReducer'
import { Action } from 'redux'

export function modalShow(data: IModalProps): ModalActionTypes {
	return {
		type: MODAL_SHOW,
		payload: data
	}
}

export function modalHide(): ModalActionTypes {
	return {
		type: MODAL_HIDE
	}
}

export function modalShowHandler(data: IModalProps): ThunkAction<void, RootState, unknown, Action<string>> {
	return dispatch => dispatch(modalShow(data))
}

export function modalHideHandler(): ThunkAction<void, RootState, unknown, Action<string>> {
	return dispatch => dispatch(modalHide())
}
