import { IRoutes } from './routes'
import { USER } from '../helpers/roles'
import Layout from '../hoc/Layout'
import Faq from '../pages/User/Faq/Faq'

export const userRoutes: IRoutes[] = [
	{
		path: '/faq',
		roles: {
			[USER]: {
				layout: Layout,
				component: Faq
			}
		}
	}
]
