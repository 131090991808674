import React, { useState } from 'react'
import Button from '../../../components/UI/Button/Button'
import { Controller, useForm } from 'react-hook-form'
import { ILicensePackageRequestCreate } from '../../../interfaces/cabinet/licensePackageRequest'
import Textarea from '../../../components/UI/Textarea/Textarea'
import { modalConfirmShowHandler } from '../../../store/actionCreators/UI/modalConfirm'
import { connect, ConnectedProps } from 'react-redux'
import { DATE_FORMAT_API_SHORT, getDate } from '../../../helpers/helpers'
import { TDatePickerDate } from '../../../interfaces/helpers'
import InputDatePicker from '../../../components/UI/InputDatePicker/InputDatePicker'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import InputMask from '../../../components/UI/InputMask/InputMask'

type TProps = ConnectedProps<typeof connector> & {
	organization: {
		id: string
		name: string
		accountId: string
	}
	onConfirm: (data: ILicensePackageRequestCreate) => Promise<any>
	hideModal: () => void
}

interface IFormInputs {
	termlessCount: string
	urgentCount: string
	hardwareCount: string
	dateFrom: string
	dateTo: string
	status: string
	comment: string
}

const ModalAddOrderByOrgAdmin = ({ hideModal, showModalConfirm, onConfirm, organization }: TProps) => {
	const { t } = useTranslation()
	const { register, formState, watch, handleSubmit, errors, control, reset } = useForm<IFormInputs>({
		defaultValues: {
			termlessCount: '0',
			urgentCount: '0',
			hardwareCount: '0'
		}
	})
	const { isDirty } = formState
	const [isSubmitting, setIsSubmitting] = useState(false)

	const [dateFrom, setDateFrom] = useState<TDatePickerDate>(null)
	const [minDateFrom] = useState<TDatePickerDate>(new Date())
	const [maxDateFrom, setMaxDateFrom] = useState<TDatePickerDate>(null)
	const [dateTo, setDateTo] = useState<TDatePickerDate>(null)
	const [minDateTo, setMinDateTo] = useState<TDatePickerDate>(new Date())
	const [maxDateTo] = useState<TDatePickerDate>(null)

	const dateFromHandler = (date: TDatePickerDate) => {
		setDateFrom(date)
		setMinDateTo(date)
	}
	const dateToHandler = (date: TDatePickerDate) => {
		setDateTo(date)
		setMaxDateFrom(date)
	}

	const cancelHandler = () => {
		if (isDirty) {
			showModalConfirm({
				type: 'cancel',
				onConfirm: hideModal
			})
		} else {
			hideModal()
		}
	}

	const onSubmit = async (data: IFormInputs) => {
		setIsSubmitting(true)

		try {
			const response = await onConfirm({
				...data,
				dateFrom: (data.dateFrom && dayjs(data.dateFrom).format(DATE_FORMAT_API_SHORT)) || '',
				dateTo: (data.dateTo && dayjs(data.dateTo).format(DATE_FORMAT_API_SHORT)) || '',
				accountId: organization.accountId
			})

			if (response?.status === 200) {
				reset()
				hideModal()
			}
		} finally {
			setIsSubmitting(false)
		}
	}

	return (
		<>
			<button className="modal-close" onClick={cancelHandler} disabled={isSubmitting} />

			<h3 className="modal-title">{t('common:appName')}</h3>
			<p className="modal-footnote">{t('pages:licensePackagesRequest:modalNewText')}</p>

			<form className="form" onSubmit={handleSubmit(onSubmit)}>
				<div className="form-item">
					<Controller
						render={({ onChange }) => (
							<InputMask
								defaultValue={'0'}
								type={'text'}
								name={'termlessCount'}
								placeholder={t('pages:licensePackagesRequest:fieldTermlessCount')}
								isDisabled={isSubmitting}
								isError={!!errors.termlessCount}
								min={0}
								max={9999}
								errors={errors.termlessCount}
								maskProps={{
									mask: Number,
									min: 0,
									max: 9999
								}}
								onChange={onChange}
							/>
						)}
						name={'termlessCount'}
						control={control}
						rules={{
							min: 0,
							max: 9999
						}}
						defaultValue={'0'}
					/>
				</div>

				<div className="form-item">
					<Controller
						render={({ onChange }) => (
							<InputMask
								defaultValue={'0'}
								type={'text'}
								name={'urgentCount'}
								placeholder={t('pages:licensePackagesRequest:fieldUrgentCount')}
								isDisabled={isSubmitting}
								isError={!!errors.urgentCount}
								min={0}
								max={9999}
								errors={errors.urgentCount}
								maskProps={{
									mask: Number,
									min: 0,
									max: 9999
								}}
								onChange={onChange}
							/>
						)}
						name={'urgentCount'}
						control={control}
						rules={{
							min: 0,
							max: 9999
						}}
						defaultValue={'0'}
					/>
				</div>

				<div className="form-item">
					<Controller
						render={({ onChange }) => (
							<InputMask
								defaultValue={'0'}
								type={'text'}
								name={'hardwareCount'}
								placeholder={t('pages:licensePackagesRequest:fieldHardwareCount')}
								isDisabled={isSubmitting}
								isError={!!errors.hardwareCount}
								min={0}
								max={9999}
								errors={errors.hardwareCount}
								maskProps={{
									mask: Number,
									min: 0,
									max: 9999
								}}
								onChange={onChange}
							/>
						)}
						name={'hardwareCount'}
						control={control}
						rules={{
							min: 0,
							max: 9999
						}}
						defaultValue={'0'}
					/>
				</div>

				<div className="form-item form-item--calendar">
					<Controller
						render={({ name, onChange }) => (
							<InputDatePicker
								name={name}
								placeholderText={t('pages:licensePackagesRequest:fieldDateFrom')}
								selected={dateFrom}
								minDate={minDateFrom}
								maxDate={maxDateFrom}
								onChange={(date: TDatePickerDate) => {
									dateFromHandler(date)
									onChange(getDate(date, DATE_FORMAT_API_SHORT))
								}}
								errors={errors.dateFrom}
								isError={!!errors.dateFrom}
								isDisabled={isSubmitting}
							/>
						)}
						control={control}
						name={'dateFrom'}
						defaultValue={dateFrom}
					/>

					<span className="form-delimiter">—</span>

					<Controller
						render={({ name, onChange }) => (
							<InputDatePicker
								name={name}
								placeholderText={t('pages:licensePackagesRequest:fieldDateTo')}
								selected={dateTo}
								minDate={minDateTo}
								maxDate={maxDateTo}
								onChange={(date: TDatePickerDate) => {
									dateToHandler(date)
									onChange(getDate(date, DATE_FORMAT_API_SHORT))
								}}
								errors={errors.dateTo}
								isError={!!errors.dateTo}
								isDisabled={isSubmitting}
								isRequired={+watch('urgentCount') > 0}
							/>
						)}
						control={control}
						name={'dateTo'}
						defaultValue={dateTo}
						rules={{
							required: +watch('urgentCount') > 0
						}}
					/>
				</div>

				<div className="form-item">
					<Textarea
						currentValue={watch('comment')}
						name={'comment'}
						placeholder={t('pages:licensePackagesRequest:fieldComment')}
						isDisabled={isSubmitting}
						isError={!!errors.comment}
						reference={register({
							maxLength: 5000
						})}
						errors={errors.comment}
						rules={{
							maxLength: 5000
						}}
					/>
				</div>

				<div className="form-controls form-controls--right">
					<Button type={'secondary'} htmlType={'button'} onClick={cancelHandler} isDisabled={isSubmitting}>
						{t('common:actionCancel')}
					</Button>

					<Button type={'primary'} htmlType={'submit'} isDisabled={isSubmitting || !isDirty}>
						{t('pages:licensePackagesRequest:modalNewSave')}
					</Button>
				</div>
			</form>
		</>
	)
}

const mapDispatch = {
	showModalConfirm: modalConfirmShowHandler
}

const connector = connect(null, mapDispatch)

export default connector(ModalAddOrderByOrgAdmin)
