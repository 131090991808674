interface getLicencePackageRequestCountGet {
	type: 'LICENCE_PACKAGE_REQUEST_COUNT_GET'
	payload: number
}

export type ActionTypes = getLicencePackageRequestCountGet

export function getLicencePackageRequestCountGet(payload: number): ActionTypes {
	return {
		type: 'LICENCE_PACKAGE_REQUEST_COUNT_GET',
		payload
	}
}
