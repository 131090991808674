import React, { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Switch, Route, useHistory, useLocation } from 'react-router-dom'
import { RootState } from './store/reducers/rootReducer'
import { setDefaultLocale } from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import Layout from './hoc/Layout'
import LayoutAuth from './hoc/LayoutAuth'
import Login from './pages/Authentication/Login/Login'
import Error from './pages/Error/Error'
import Notification from './components/UI/Notification/Notification'
import Modal from './components/UI/Modal/Modal'
import ModalConfirm from './components/UI/Modal/ModalConfirm'
import Loader from './components/UI/Loader/Loader'
import ModalError from './components/UI/Modal/ModalError'
import { autoLoginHandler } from './services/passport/auth'
import { routes } from './routes/routes'
import Progress from './components/UI/Progress/Progress'
import ModalLog from './components/UI/Modal/ModalLog'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-image-lightbox/style.css'
import { DEFAULT_LOCALE, LOCALES_EXISTS } from './helpers/helpers'
import { TLocales } from './interfaces/helpers'

function App({
	user,
	autoLogin,
	isAuthorized,
	notifications,
	modal,
	modalConfirm,
	modalError,
	modalLog,
	loader,
	progress
}: ConnectedProps<typeof connector>) {
	const history = useHistory()
	const { state, pathname } = useLocation()
	const { i18n } = useTranslation()

	useEffect(() => {
		const searchParams = new URLSearchParams(history.location.search)
		const locale = searchParams.get('locale')?.toUpperCase() as TLocales

		if (locale) {
			if (LOCALES_EXISTS.includes(locale)) {
				i18n.changeLanguage(locale.toLowerCase())
			} else {
				i18n.changeLanguage(DEFAULT_LOCALE.toLowerCase())
			}
		}
	}, [history.location.search, i18n])

	useEffect(() => {
		setDefaultLocale(i18n.languages[0])
	}, [i18n.languages])

	useEffect(autoLogin, [])

	useEffect(() => {
		if (isAuthorized) {
			const { from } = state || {
				from: {
					pathname: pathname || '/'
				}
			}

			history.replace(from)
		}
	}, [isAuthorized, history, state, pathname])

	return (
		<>
			<Switch>
				{routes.map((route, idx) => (
					<Route
						key={idx}
						exact
						path={route.path}
						render={props => {
							const data = route.roles[isAuthorized ? user.role : 'NONE']

							if (data) {
								const { layout: PageLayout, component: Component } = data

								return (
									<PageLayout>
										<Component {...props} />
									</PageLayout>
								)
							} else {
								if (isAuthorized) {
									return (
										<Layout>
											<Error />
										</Layout>
									)
								} else {
									return (
										<LayoutAuth>
											<Login {...props} />
										</LayoutAuth>
									)
								}
							}
						}}
					/>
				))}

				<Route
					render={() => {
						if (isAuthorized) {
							return (
								<Layout>
									<Error />
								</Layout>
							)
						} else {
							return (
								<LayoutAuth>
									<Error />
								</LayoutAuth>
							)
						}
					}}
				/>
			</Switch>

			<Notification notifications={notifications} />
			<Modal {...modal} />
			<ModalConfirm {...modalConfirm} />
			<ModalError {...modalError} />
			<ModalLog {...modalLog} />
			<Loader {...loader} />
			<Progress value={progress} />
		</>
	)
}

const mapState = (state: RootState) => ({
	user: state.user.user,
	isAuthorized: state.auth.isAuthorized,
	notifications: state.notification.notifications,

	modal: state.modal,
	modalConfirm: state.modalConfirm,
	modalError: state.modalError,
	modalLog: state.modalLog,
	loader: state.loader,
	progress: state.app.progress
})

const mapDispatch = {
	autoLogin: autoLoginHandler
}

const connector = connect(mapState, mapDispatch)

export default connector(App)
