import axios from 'axios'
import { API_APPS_PATH, APIServices } from '../../helpers/api'
import { setErrorHandler } from '../../store/actionCreators/error'
import {
	ICheckProductAllowedTenants,
	ICheckProductAllowedTenantsAPIRequest,
	IProductUpdate,
	IProductUpdateAPIRequest,
	IProductUpdateCreate,
	IProductUpdateUpdate
} from '../../interfaces/apps/productUpdates'
import { APIResponse, AppThunk } from '../../interfaces/helpers'
import { serviceHandlerErrorOptions } from '../helpers'

export function productUpdateGetAllHandler(
	params: IProductUpdateAPIRequest,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<APIResponse<IProductUpdate[]>> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<APIResponse<IProductUpdate[]>>({
				method: 'GET',
				url: `${API_APPS_PATH}/productUpdates`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params
			})
			return response
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.APPS, errorOptions))
			return error.response
		}
	}
}

export function getProductUpdateByIdHandler(
	id: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IProductUpdate> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IProductUpdate>({
				method: 'GET',
				url: `${API_APPS_PATH}/productUpdates/${id}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id
				}
			})
			return response
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.APPS, errorOptions))
			return error.response
		}
	}
}

export function createProductUpdateHandler(
	data: IProductUpdateCreate,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IProductUpdate> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IProductUpdate>({
				method: 'POST',
				url: `${API_APPS_PATH}/productUpdates`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				data
			})
			return response
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.APPS, errorOptions))
			return error.response
		}
	}
}

export function updateProductUpdateByIdHandler(
	id: string,
	data: IProductUpdateUpdate,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IProductUpdate> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IProductUpdate>({
				method: 'PUT',
				url: `${API_APPS_PATH}/productUpdates/${id}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id
				},
				data
			})
			return response
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.APPS, errorOptions))
			return error.response
		}
	}
}

export function deleteProductUpdateByIdHandler(id: string, errorOptions = serviceHandlerErrorOptions): AppThunk {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request({
				method: 'DELETE',
				url: `${API_APPS_PATH}/productUpdates/${id}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id
				}
			})
			return response
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.APPS, errorOptions))
			return error.response
		}
	}
}

export function checkProductAllowedTenantsHandler(
	params: ICheckProductAllowedTenantsAPIRequest,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<ICheckProductAllowedTenants[]> {
	return async (dispatch, getState) => {
		try {
			const query = params.buildIds.map(buildId => `buildIds=${buildId}`).join('&')

			const response = await axios.request<ICheckProductAllowedTenants[]>({
				method: 'GET',
				url: `${API_APPS_PATH}/productUpdates/allowedTenants?${query}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				}
			})
			return response
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.APPS, errorOptions))
			return error.response
		}
	}
}
