import { serviceHandlerErrorOptions } from '../helpers'
import { AppThunk } from '../../interfaces/helpers'
import axios from 'axios'
import { API_SUPPORT_PATH, APIServices } from '../../helpers/api'
import { setErrorHandler } from '../../store/actionCreators/error'
import { IPriority } from '../../interfaces/support/priorities'

export function prioritiesGetAllHandler(errorOptions = serviceHandlerErrorOptions): AppThunk<IPriority[]> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IPriority[]>({
				method: 'GET',
				url: `${API_SUPPORT_PATH}/priorities`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				}
			})
			return response
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.SUPPORT, errorOptions))
			return error.response
		}
	}
}
