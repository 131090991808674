import axios from 'axios'
import { API_APPS_PATH, APIServices } from '../../helpers/api'
import { setErrorHandler } from '../../store/actionCreators/error'
import { ITenant, ITenantAPIRequest, ITenantCreate, ITenantUpdate } from '../../interfaces/apps/tenants'
import { APIResponse, AppThunk } from '../../interfaces/helpers'
import { serviceHandlerErrorOptions } from '../helpers'

export function tenantGetAllHandler(
	params: ITenantAPIRequest,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<APIResponse<ITenant[]>> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<APIResponse<ITenant[]>>({
				method: 'GET',
				url: `${API_APPS_PATH}/tenants`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params
			})
			return response
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.APPS, errorOptions))
			return error.response
		}
	}
}

export function createTenantHandler(data: ITenantCreate, errorOptions = serviceHandlerErrorOptions): AppThunk<ITenant> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<ITenant>({
				method: 'POST',
				url: `${API_APPS_PATH}/tenants`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				data
			})
			return response
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.APPS, errorOptions))
			return error.response
		}
	}
}

export function updateTenantByIdHandler(
	id: string,
	data: ITenantUpdate,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<ITenant> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<ITenant>({
				method: 'PUT',
				url: `${API_APPS_PATH}/tenants/${id}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id
				},
				data
			})
			return response
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.APPS, errorOptions))
			return error.response
		}
	}
}

export function deleteTenantByIdHandler(id: string, errorOptions = serviceHandlerErrorOptions): AppThunk {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request({
				method: 'DELETE',
				url: `${API_APPS_PATH}/tenants/${id}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id
				}
			})
			return response
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.APPS, errorOptions))
			return error.response
		}
	}
}
