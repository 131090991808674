import { MODAL_LOG_HIDE, MODAL_LOG_SHOW } from '../../actions/UI/modalLog'
import { ModalLogActionTypes } from '../../../interfaces/UI/actionCreators/modalLog'
import { IModalLogDetailsUsers, IModalLogDetailsKeys } from '../../../interfaces/UI/modalLog'

interface IInitialState {
	isOpened: boolean
	message?: string
	onConfirm?: () => void
	details: Array<IModalLogDetailsUsers> | IModalLogDetailsKeys
}

const initialState: IInitialState = {
	isOpened: false,
	details: [
		{
			message: '',
			newUser: false,
			rownum: 0,
			ok: false,
			values: {}

			// successCount: 0,
			// successIds: [],
			// errorCount: 0,
			// errors: []
		}
	]
}

export default function ModalLogReducer(state = initialState, action: ModalLogActionTypes): IInitialState {
	switch (action.type) {
		case MODAL_LOG_SHOW:
			return {
				...state,
				isOpened: true,
				message: action.payload.message,
				onConfirm: action.payload.onConfirm,
				details: action.payload.details
			}

		case MODAL_LOG_HIDE:
			return {
				...state,
				isOpened: false,
				message: undefined
			}

		default:
			return state
	}
}
