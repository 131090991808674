import React, { useState } from 'react'
import { ILicensePackageGroupList } from '../../interfaces/cabinet/licensePackage'
import dayjs from 'dayjs'
import { DATE_FORMAT } from '../../helpers/helpers'
import { useTranslation } from 'react-i18next'

interface IProps {
	item: ILicensePackageGroupList
	onRemove: () => void
}

const LicenseItemAdd = ({ item, onRemove }: IProps) => {
	const { t } = useTranslation()

	const [isOpened, setIsOpened] = useState(true)

	const classNames = ['form-license-item']

	if (!isOpened) {
		classNames.push('form-license-item--short')
	}

	return (
		<div className={classNames.join(' ')}>
			<div className="form-license-holder">
				<div className="form-license-title" onClick={() => setIsOpened(!isOpened)}>
					{item.productName}
				</div>
				<div className="form-license-params">
					<ul className="list">
						<li className="list-item">
							<div className="list-item-label">{t('pages:users:licenseFieldVersion')}:</div>
							<div className="list-item-value">{item.versionName}</div>
						</li>
						<li className="list-item">
							<div className="list-item-label">{t('pages:users:licenseFieldTime')}:</div>
							<div className="list-item-value">
								{item.termless ? (
									<>{t('pages:users:termless')}</>
								) : (
									<>
										{item.dateFrom && item.dateTo && (
											<>{`${dayjs(item.dateFrom).format(DATE_FORMAT)} - ${dayjs(item.dateTo).format(DATE_FORMAT)}`}</>
										)}
										{item.dateFrom && !item.dateTo && (
											<>
												{t('common:from')}
												{dayjs(item.dateFrom).format(DATE_FORMAT)}
											</>
										)}
										{!item.dateFrom && item.dateTo && (
											<>
												{t('common:to')}
												{dayjs(item.dateTo).format(DATE_FORMAT)}
											</>
										)}
										{!item.dateFrom && !item.dateTo && '-'}
									</>
								)}
							</div>
						</li>
						{/*<li className="list-item">*/}
						{/*	<div className="list-item-label">{t('pages:users:licenseFieldActivated')}:</div>*/}
						{/*	<div className="list-item-value">-</div>*/}
						{/*</li>*/}
					</ul>
				</div>
				<div className="form-license-toggler" onClick={() => setIsOpened(!isOpened)} />
			</div>
			<div className="form-license-delete">
				<button className="button-link" type="button" onClick={onRemove}>
					{t('common:actionRemove')}
				</button>
			</div>
		</div>
	)
}

export default LicenseItemAdd
