import React from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
	isOpened: boolean
}

export default function Loader({ isOpened }: IProps) {
	const { t } = useTranslation()

	return (
		<div
			className="loader"
			style={{
				display: isOpened ? 'block' : 'none'
			}}
		>
			<div className="loader-progress">{t('common:loading')}</div>
		</div>
	)
}
