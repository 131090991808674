import React, { useState } from 'react'
import { modalConfirmShowHandler } from '../../../../store/actionCreators/UI/modalConfirm'
import { connect, ConnectedProps } from 'react-redux'
import Button from '../../../../components/UI/Button/Button'
import { useForm } from 'react-hook-form'
import { ISetting, ISettingCreate } from '../../../../interfaces/cabinet/settings'
import Input from '../../../../components/UI/Input/Input'
import { useTranslation } from 'react-i18next'
import Checkbox from '../../../../components/UI/Checkbox/Checkbox'

type TProps = ConnectedProps<typeof connector> & {
	item: ISetting
	versionId: string
	hideModal: () => void
	onConfirm: (data: ISettingCreate) => Promise<any>
	onRemove: (id: string) => Promise<any>
}

interface IFormFields {
	key: string
	value: string
	description?: string
	override: boolean
}

const ModalEditSetting = ({ hideModal, showModalConfirm, onConfirm, item, versionId, onRemove }: TProps) => {
	const { t } = useTranslation()
	const { register, handleSubmit, formState, watch, errors } = useForm<IFormFields>({
		defaultValues: {
			key: item.key,
			value: item.value,
			description: item.description,
			override: item.override
		}
	})
	const { isDirty } = formState
	const [isSubmitting, setIsSubmitting] = useState(false)

	const cancelHandler = () => {
		if (isDirty) {
			showModalConfirm({
				type: 'cancel',
				onConfirm: hideModal
			})
		} else {
			hideModal()
		}
	}

	const onSubmit = async (data: IFormFields) => {
		setIsSubmitting(true)

		try {
			const response = await onConfirm({
				...item,
				...data,
				versionId
			})
			if (response?.status === 200) {
				hideModal()
			}
		} finally {
			setIsSubmitting(false)
		}
	}

	const removeHandler = () => {
		showModalConfirm({
			type: 'remove',
			elementText: item.key,
			onConfirm: async () => {
				try {
					const response = await onRemove(item.id)

					if (response?.status === 200) {
						hideModal()
					}
				} finally {
				}
			}
		})
	}

	return (
		<>
			<button className="modal-close" onClick={cancelHandler} disabled={isSubmitting} />

			<h3 className="modal-title">{t('common:appName')}</h3>

			<p className="modal-footnote">
				{t('pages:settings:modalUpdateText')} <strong>{item.key}</strong>
			</p>

			<form className="form" onSubmit={handleSubmit(onSubmit)}>
				<div className="form-item">
					<Input
						currentValue={watch('key')}
						type={'text'}
						name={'key'}
						placeholder={t('pages:settings:fieldKey')}
						isDisabled={isSubmitting}
						isError={!!errors.key}
						isRequired={true}
						reference={register({
							required: true
						})}
						errors={errors.key}
					/>
				</div>

				<div className="form-item">
					<Input
						currentValue={watch('value')}
						type={'text'}
						name={'value'}
						placeholder={t('pages:settings:fieldValue')}
						isDisabled={isSubmitting}
						isError={!!errors.value}
						isRequired={true}
						reference={register({
							required: true
						})}
						errors={errors.value}
					/>
				</div>

				<div className="form-item">
					<Input
						currentValue={watch('description')}
						type={'text'}
						name={'description'}
						placeholder={t('pages:settings:fieldDescription')}
						isDisabled={isSubmitting}
						isError={!!errors.description}
						reference={register()}
						errors={errors.description}
					/>
				</div>

				<div className="form-item form-item--checkbox">
					<Checkbox
						name={'override'}
						isDisabled={isSubmitting}
						reference={register}
						errors={errors.override}
						isError={!!errors.override}
					>
						{t('pages:settings:fieldOverride')}
					</Checkbox>
				</div>

				<div className="form-controls form-controls--justify">
					<Button type={'secondary'} htmlType={'button'} onClick={removeHandler} isDisabled={isSubmitting}>
						{t('common:actionRemove')}
					</Button>

					<Button type={'secondary'} htmlType={'button'} onClick={cancelHandler} isDisabled={isSubmitting}>
						{t('common:actionCancel')}
					</Button>

					<Button type={'primary'} htmlType={'submit'} isDisabled={isSubmitting || !isDirty}>
						{t('common:actionSave')}
					</Button>
				</div>
			</form>
		</>
	)
}

const mapDispatch = {
	showModalConfirm: modalConfirmShowHandler
}

const connector = connect(null, mapDispatch)

export default connector(ModalEditSetting)
