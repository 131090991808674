import React, { useState } from 'react'
import Button from '../UI/Button/Button'
import { useForm } from 'react-hook-form'
import Input from '../UI/Input/Input'
import { useTranslation } from 'react-i18next'
import { IMultiselectItem } from './Multiselect'
import { onlySpaces } from '../../helpers/validators'

interface IProps {
	hideModal: () => void
	onConfirm: (value: string) => Promise<any>
	items: Array<IMultiselectItem>
}

interface IFormInputs {
	name: string
}

const ModalAddRecord = ({ hideModal, onConfirm, items }: IProps) => {
	const { t } = useTranslation()

	const { register, errors, watch, handleSubmit, formState, setError } = useForm<IFormInputs>({
		defaultValues: {
			name: ''
		}
	})
	const { isDirty } = formState
	const [isSubmitting, setIsSubmitting] = useState(false)

	const existingItems = items.map(el => el.name.toLowerCase().trim())

	const onSubmit = async (data: IFormInputs) => {
		if (existingItems.includes(data.name.toLowerCase().trim())) {
			setError('name', {
				type: 'duplicateValue'
			})
			return
		}

		setIsSubmitting(true)

		try {
			const response = await onConfirm(data.name)

			if (response?.status === 200) {
				hideModal()
			}
		} finally {
			setIsSubmitting(false)
		}
	}

	return (
		<>
			<button className="modal-close" onClick={hideModal} disabled={isSubmitting} />

			<h3 className="modal-title">{t('common:appName')}</h3>
			<p className="modal-footnote">{t('pages:faculties:addNew')}</p>

			<form className="form" onSubmit={handleSubmit(onSubmit)}>
				<div className="form-item">
					<Input
						type={'text'}
						name={'name'}
						currentValue={watch('name')}
						isError={!!errors.name}
						reference={register({
							required: true,
							maxLength: 255,
							validate: {
								onlySpaces
							}
						})}
						rules={{
							maxLength: 255
						}}
						errors={errors.name}
					/>
				</div>

				<div className="form-controls form-controls--justify">
					<Button type={'secondary'} htmlType={'button'} onClick={hideModal}>
						{t('common:actionCancel')}
					</Button>

					<Button type={'primary'} htmlType={'submit'} isDisabled={isSubmitting || !isDirty}>
						{t('common:actionAdd')}
					</Button>
				</div>
			</form>
		</>
	)
}

export default ModalAddRecord
