import React, { useEffect, useRef, useState } from 'react'
import { IHardwareKey } from '../../../../../interfaces/cabinet/hardwareKeys'
import Autocomplete from '../../../../../components/UI/Autocomplete/Autocomplete'
import InputError from '../../../../../components/UI/InputError/InputError'
import { useTranslation } from 'react-i18next'

interface IHardwareKeyCustom extends IHardwareKey {
	selected: boolean
}

interface IProps {
	data: Array<IHardwareKey>
	defaultValue?: Array<string>
	onChange: (values: string[]) => void
	isDisabled?: boolean
	isError?: boolean
	errors?: any
}

const MultiselectLicensePackage = ({
	data,
	onChange,
	defaultValue = [],
	isDisabled = false,
	isError = false,
	errors
}: IProps) => {
	const { t } = useTranslation()

	const [keys, setKeys] = useState<IHardwareKeyCustom[]>(
		defaultValue
			? data
					.filter(item => defaultValue.includes(item.id))
					.map(item => {
						return {
							...item,
							selected: false
						}
					})
			: []
	)
	const [isAddDisabled, setIsAddDisabled] = useState(true)
	const [isRemoveDisabled, setIsRemoveDisabled] = useState(true)

	const [autocompleteValue, setAutocompleteValue] = useState('')
	const autocompleteRef = useRef<any>(null)

	const checkboxHandler = (id: string) => {
		const updated = [...keys]
		const index = updated.findIndex(item => item.id === id)
		updated[index].selected = !updated[index].selected

		setKeys(updated)
	}

	useEffect(() => {
		setIsAddDisabled(!autocompleteValue)
	}, [autocompleteValue])

	useEffect(() => {
		const selected = keys.filter(item => item.selected)

		setIsRemoveDisabled(!selected.length)
	}, [keys])

	const onAdd = () => {
		if (autocompleteValue) {
			const item = data.filter(item => item.id === autocompleteValue)[0] || []
			const updated = [
				...keys,
				{
					...item,
					selected: false
				}
			]

			autocompleteRef.current.autocompleteClear()

			setKeys(updated)

			onChange(updated.map(item => item.id) || [])
		}
	}

	const onRemove = () => {
		const updated = keys.filter(item => !item.selected)

		setKeys(updated)

		onChange(updated.map(item => item.id) || [])
	}

	return (
		<>
			<div className="form-item form-item--circle-control">
				<Autocomplete
					data={data.map(item => {
						return {
							label: item.code,
							value: item.id
						}
					})}
					excludes={keys.map(item => item.id)}
					onChange={() => setAutocompleteValue('')}
					onSelect={value => setAutocompleteValue(value)}
					onClear={() => setAutocompleteValue('')}
					placeholder={t('pages:licensePackages:fieldSearchKey')}
					ref={autocompleteRef}
					isDisabled={isDisabled}
				/>

				<button
					className="control-circle control-circle--plus"
					type="button"
					disabled={isAddDisabled || isDisabled}
					onClick={onAdd}
				>
					{t('pages:licensePackages:addKey')}
				</button>
			</div>

			<div className="form-item form-item--circle-control">
				<div className="multiselect">
					<ul className="list">
						{keys.map(item => {
							return (
								<li className="list-item" key={item.id}>
									<label>
										<input
											type="checkbox"
											checked={item.selected}
											onChange={() => checkboxHandler(item.id)}
											disabled={isDisabled}
										/>
										<span>{item.code}</span>
									</label>
								</li>
							)
						})}
					</ul>

					<div className="multiselect-label-small">{t('pages:licensePackages:selectedKeys')}</div>
				</div>

				<button
					className="control-circle control-circle--minus"
					type="button"
					disabled={isRemoveDisabled || isDisabled}
					onClick={onRemove}
				>
					{t('pages:licensePackages:removeKey')}
				</button>
			</div>

			{isError && <InputError errors={errors} />}
		</>
	)
}

export default MultiselectLicensePackage
