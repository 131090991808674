import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { modalConfirmShowHandler } from '../../../store/actionCreators/UI/modalConfirm'
import { connect, ConnectedProps } from 'react-redux'
import Button from '../../../components/UI/Button/Button'
import Input from '../../../components/UI/Input/Input'
import { IProduct } from '../../../interfaces/cabinet/products'
import { useTranslation } from 'react-i18next'
import { selectProps } from '../../../components/UI/Select/helpers'
import Select from 'react-select'
import { IFeatureCreate } from '../../../interfaces/cabinet/features'

type TProps = ConnectedProps<typeof connector> & {
	feature?: IFeatureCreate
	products: IProduct[]
	onRemove?: () => Promise<any>
	onConfirm: (data: IFeatureCreate) => Promise<any>
	hideModal: () => void
}

const ModalAddFeature = ({ products, feature, hideModal, showModalConfirm, onConfirm, onRemove }: TProps) => {
	const { t } = useTranslation()
	const { register, errors, handleSubmit, formState, watch, reset, control } = useForm<IFeatureCreate>({
		defaultValues: {
			productId: feature?.productId || '',
			featureCode: feature?.featureCode,
			title: feature?.title
		}
	})

	const { isDirty } = formState
	const [isSubmitting, setIsSubmitting] = useState(false)

	const cancelHandler = () => {
		if (isDirty) {
			showModalConfirm({
				type: 'cancel',
				onConfirm: hideModal
			})
		} else {
			hideModal()
		}
	}

	const onSubmit = async (data: IFeatureCreate) => {
		setIsSubmitting(true)

		try {
			const response = await onConfirm(data)

			if (response?.status === 200) {
				reset()
				hideModal()
			}
		} finally {
			setIsSubmitting(false)
		}
	}

	const removeHandler = () => {
		if (!onRemove || !feature) {
			return
		}

		showModalConfirm({
			type: 'remove',
			elementText: `${feature.title} ${feature.featureCode}`,
			onConfirm: async () => {
				try {
					await onRemove()
					hideModal()
				} finally {
				}
			}
		})
	}

	const product = products.find(p => p.id === feature?.productId)

	return (
		<>
			<button className="modal-close" onClick={cancelHandler} disabled={isSubmitting} />
			<h3 className="modal-title">{t('common:appName')}</h3>
			<p className="modal-footnote">
				{feature ? t('pages:features:modalUpdateText') : t('pages:functions:modalNewText')}
			</p>

			<form className="form" onSubmit={handleSubmit(onSubmit)}>
				<div className="form-item">
					<Controller
						render={({ onChange, name }) => (
							<Select
								name={name}
								options={products.map(item => ({
									label: item.name,
									value: item.id
								}))}
								value={
									product
										? {
												label: product.name,
												value: product.id
										  }
										: undefined
								}
								placeholder={t('pages:features:fieldProduct')}
								isDisabled={isSubmitting}
								isRequired={true}
								isError={!!errors.productId}
								errors={errors.productId}
								onChange={option => option && onChange(option.value)}
								{...selectProps}
							/>
						)}
						name={'productId'}
						control={control}
						rules={{
							required: true
						}}
					/>
				</div>

				<div className="form-item">
					<Input
						currentValue={watch('featureCode')}
						type={'text'}
						name={'featureCode'}
						placeholder={t('pages:features:fieldFunctionCode')}
						isDisabled={isSubmitting || !watch('productId')}
						isError={!!errors.featureCode}
						isRequired
						reference={register({
							required: true,
							maxLength: 255
						})}
						errors={errors.featureCode}
					/>
				</div>

				<div className="form-item">
					<Input
						currentValue={watch('title')}
						type={'text'}
						name={'title'}
						placeholder={t('pages:features:fieldFunctionName')}
						isDisabled={isSubmitting || !watch('productId')}
						isError={!!errors.title}
						isRequired
						reference={register({
							required: true,
							maxLength: 255
						})}
						errors={errors.title}
					/>
				</div>

				<div className="form-controls form-controls--justify">
					{feature ? (
						<Button type={'secondary'} htmlType={'button'} onClick={removeHandler} isDisabled={isSubmitting}>
							{t('common:actionRemove')}
						</Button>
					) : null}

					<Button type={'secondary'} htmlType={'button'} onClick={cancelHandler} isDisabled={isSubmitting}>
						{t('common:actionCancel')}
					</Button>

					<Button type={'primary'} htmlType={'submit'} isDisabled={isSubmitting || !isDirty}>
						{t('common:actionSave')}
					</Button>
				</div>
			</form>
		</>
	)
}

const mapDispatch = {
	showModalConfirm: modalConfirmShowHandler
}

const connector = connect(null, mapDispatch)

export default connector(ModalAddFeature)
