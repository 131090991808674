import { ActionTypes } from './licensePackageRequest.actions'

const initialState = {
	count: 0
}

export default function LicencePackageRequestReducer(state = initialState, action: ActionTypes) {
	switch (action.type) {
		case 'LICENCE_PACKAGE_REQUEST_COUNT_GET':
			return {
				...state,
				count: action.payload
			}

		default:
			return state
	}
}
