import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import Button from '../../../components/UI/Button/Button'
import Input from '../../../components/UI/Input/Input'
import { REGEX_EMAIL, REGEX_PASSWORD } from '../../../helpers/helpers'
import { IUser, IUserSelfUpdate } from '../../../interfaces/passport/user'
import { useTranslation } from 'react-i18next'

type TProps = {
	user: IUser
	hideModal: () => void
	onConfirm: (data: IUserSelfUpdate) => Promise<any>
}

interface IFormInputs {
	currentPassword: string
	email: string
}

function ModalChangeEmail({ user, hideModal, onConfirm }: TProps) {
	const { t } = useTranslation()
	const [isSuccess, setIsSuccess] = useState(false)
	const { register, watch, errors, handleSubmit, formState } = useForm<IFormInputs>({
		defaultValues: {
			currentPassword: '',
			email: ''
		}
	})
	const { isDirty } = formState
	const [isSubmitting, setIsSubmitting] = useState(false)

	const onSubmit = async (data: IFormInputs) => {
		setIsSubmitting(true)

		try {
			const response = await onConfirm({
				role: user.role,
				firstName: user.firstName,
				lastName: user.lastName,
				patronymic: user.patronymic,
				email: data.email,
				phone: user.phone,
				currentPassword: data.currentPassword
			})

			if (response?.status === 200) {
				setIsSuccess(true)
			}
		} finally {
			setIsSubmitting(false)
		}
	}

	return (
		<>
			<button className="modal-close" onClick={hideModal} disabled={isSubmitting} />

			<h3 className="login-title">{t('common:appName')}</h3>
			<p className="login-footnote">{t('pages:profile:modalChangeEmailText')}</p>

			{!isSuccess ? (
				<form className="form" onSubmit={handleSubmit(onSubmit)}>
					<div className="form-item">
						<Input
							type={'password'}
							name={'currentPassword'}
							currentValue={watch('currentPassword')}
							placeholder={t('pages:profile:fieldCurrentPasswordEmail')}
							isError={!!errors.currentPassword}
							isRequired={true}
							isDisabled={isSubmitting}
							reference={register({
								required: true,
								minLength: 8,
								maxLength: 255,
								pattern: {
									value: REGEX_PASSWORD,
									message: 'fieldPatternPassword'
								}
							})}
							rules={{
								minLength: 8,
								maxLength: 255
							}}
							errors={errors.currentPassword}
						/>
					</div>

					<div className="form-item">
						<Input
							type={'email'}
							name={'email'}
							currentValue={watch('email')}
							placeholder={t('pages:profile:fieldEmail')}
							isError={!!errors.email}
							isRequired={true}
							isDisabled={isSubmitting}
							reference={register({
								required: true,
								pattern: REGEX_EMAIL
							})}
							errors={errors.email}
						/>
					</div>

					<div className="form-warning">{t('pages:profile:modalChangeEmailWarningText')}</div>

					<div className="form-controls form-controls--right">
						<Button type={'secondary'} htmlType={'button'} onClick={hideModal} isDisabled={isSubmitting}>
							{t('common:actionCancel')}
						</Button>

						<Button type={'primary'} htmlType={'submit'} isDisabled={isSubmitting || !isDirty}>
							{t('pages:profile:actionSave')}
						</Button>
					</div>
				</form>
			) : (
				<>
					<p>{t('pages:profile:modalChangeEmailDoneText')}</p>
					<div className="button-row button-row--right">
						<Button type={'primary'} htmlType={'button'} onClick={hideModal} className={'button-ok'}>
							{t('common:actionOk')}
						</Button>
					</div>
				</>
			)}
		</>
	)
}

export default ModalChangeEmail
