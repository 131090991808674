import React from 'react'

interface IProps {
	value: number
}

const Progress = ({ value }: IProps) => {
	return (
		<>
			{value > 0 && (
				<div
					className="modal modal--progress"
					style={{
						zIndex: 999999
					}}
				>
					<div className="modal-container">
						<div className="form">
							<div className="form-progress">
								<div className="form-progress-line">
									<div style={{ width: `${value}%` }}></div>
								</div>
								<div className="form-progress-percent">{value}%</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default Progress
