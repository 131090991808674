import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { RootState } from '../../../store/reducers/rootReducer'
import LicensePackagesList from '../../Common/LicensePackages/LicensePackages'
import Error from '../../Error/Error'

const LicensePackages = ({ user }: ConnectedProps<typeof connector>) => {
    const account = user.accounts?.[0]

    if (!account) return <Error />

    return <LicensePackagesList accountId={account.accountId} hardwareLink="/distributives/{id}" />
}

const mapState = (state: RootState) => ({
    user: state.user.user
})

const connector = connect(mapState)

export default connector(LicensePackages)
