import React, { ReactNode, useEffect } from 'react'
import Header from '../components/Header/Header'
import Sidebar from '../components/Sidebar/Sidebar'
import { CLOUD_ADMIN, roles } from '../helpers/roles'
import { RootState } from '../store/reducers/rootReducer'
import { connect, ConnectedProps } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { clearCurrentOrganization } from '../store/actionCreators/passport/organization'
import { getNewAmountLicensePackageRequestHandler } from '../services/cabinet/licensePackageRequests'

type Props = ConnectedProps<typeof connector> & {
	children: ReactNode
}

const Layout = ({
	user,
	organization,
	children,
	clearCurrentOrganization,
	getNewAmountLicensePackageRequest
}: Props) => {
	const { role } = user
	const { pathname } = useLocation()

	useEffect(() => {
		if (role === CLOUD_ADMIN && !pathname.includes('organization')) {
			clearCurrentOrganization()
		}
	}, [pathname, clearCurrentOrganization, role])

	// TODO: uncomment
	// useEffect(() => {
	// 	if ([CLOUD_ADMIN, ORG_ADMIN].includes(role)) {
	// 		getNewAmountLicensePackageRequest({
	// 			status: 'NEW'
	// 		})
	// 	}
	// }, [getNewAmountLicensePackageRequest, role])

	return (
		<div className={`wrapper ${roles[role].bodyClassName}`}>
			<Header user={user} organization={organization} />

			<div className="main">
				<div className="container">
					<div className="content">
						<Sidebar user={user} />

						<div className="article">{children}</div>
					</div>
				</div>
			</div>
		</div>
	)
}

const mapState = (state: RootState) => ({
	organization: state.organization.organization,
	user: state.user.user
})

const mapDispatch = {
	clearCurrentOrganization: clearCurrentOrganization,
	getNewAmountLicensePackageRequest: getNewAmountLicensePackageRequestHandler
}

const connector = connect(mapState, mapDispatch)

export default connector(Layout)
