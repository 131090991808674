import React, { useState } from 'react'
import Pagetitle from '../../../components/UI/Pagetitle/Pagetitle'
import { useTranslation } from 'react-i18next'
import PageMenu, { IPageMenuItem } from '../../../components/PageMenu/PageMenu'

const Menu = () => {
	const { t } = useTranslation()
	const [menu] = useState<IPageMenuItem[]>([
		{
			title: 'pages:support:menuSupportCloud',
			path: '/support/type/cloud',
			icon: '/assets/img/support/icon-1.svg'
		},
		{
			title: 'pages:support:menuSupportOrg',
			path: '/support/type/organization',
			icon: '/assets/img/support/icon-2.svg'
		}
	])

	return (
		<>
			<Pagetitle title={t('pages:support:titleMenu')} />

			<PageMenu menu={menu} />
		</>
	)
}

export default Menu
