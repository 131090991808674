import { APP_SET_PROGRESS } from '../actions/app'
import { AppActionTypes } from '../../interfaces/app'

interface IInitialState {
	progress: number
}

const initialState: IInitialState = {
	progress: 0
}

export default function appReducer(state = initialState, action: AppActionTypes): IInitialState {
	switch (action.type) {
		case APP_SET_PROGRESS:
			return {
				...state,
				progress: action.payload
			}

		default:
			return state
	}
}
