import { LOADER_HIDE, LOADER_SHOW } from '../../actions/UI/loader'
import { LoaderActionTypes } from '../../../interfaces/UI/actionCreators/loader'

interface IInitialState {
	isOpened: boolean
}

const initialState: IInitialState = {
	isOpened: false
}

export default function LoaderReducer(state = initialState, action: LoaderActionTypes): IInitialState {
	switch (action.type) {
		case LOADER_SHOW:
			return {
				isOpened: true
			}

		case LOADER_HIDE:
			return {
				isOpened: false
			}

		default:
			return state
	}
}
