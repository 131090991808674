import React from 'react'
import Button from '../../../components/UI/Button/Button'
import { INotification } from '../../../interfaces/passport/notifications'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { formatUsername } from '../../../helpers/helpers'

interface IProps {
	item: INotification
	hideModal: () => void
}

const ModalViewNotification = ({ item, hideModal }: IProps) => {
	const { t } = useTranslation()

	const recipientsAdmins: Array<string> = item.recipients.reduce((acc: Array<string>, item) => {
		const contacts =
			item.adminContacts?.map(user => {
				const name = formatUsername({
					firstName: user.firstName,
					lastName: user.lastName,
					patronymic: user.patronymic
				})

				const email = user.email && ` (${user.email})`

				return `${name}${email}`
			}) || []

		return [...acc, ...contacts]
	}, [])

	const recipientsContacts: Array<string> = item.recipients.reduce((acc: Array<string>, item) => {
		const contacts =
			item.orgContacts?.map(user => {
				const name = user.name
				const email = user.orgMail && ` (${user.orgMail})`
				return `${name}${email}`
			}) || []

		return [...acc, ...contacts]
	}, [])

	const recipients = [...recipientsAdmins, ...recipientsContacts]

	return (
		<div>
			<button className="modal-close" onClick={hideModal} />

			<h3 className="modal-title">{t('common:appName')}</h3>
			<p className="modal-footnote">{t('pages:notifications:modalViewText')}</p>

			<div className="modal-description">
				<div className="modal-description-row">
					<div className="modal-description-label">{t('pages:notifications:modalDate')}</div>
					<div className="modal-description-value">{dayjs(item.sentTime).format('DD.MM.YYYY HH:mm')}</div>
				</div>

				<div className="modal-description-row">
					<div className="modal-description-label">{t('pages:notifications:modalRecipients')}</div>
					<div className="modal-description-value">
						<div className="modal-description-scroll">{recipients.join(', ')}</div>
					</div>
				</div>

				<div className="modal-description-row">
					<div className="modal-description-label">{t('pages:notifications:modalTopic')}</div>
					<div className="modal-description-value">
						<strong>{item.topic}</strong>
					</div>
				</div>

				<div className="modal-description-row">
					<div className="modal-description-label">{t('pages:notifications:modalMessage')}</div>
					<div className="modal-description-value">{item.message}</div>
				</div>
			</div>

			<div className="button-row button-row--right">
				<Button type={'primary'} htmlType={'button'} onClick={hideModal}>
					{t('common:actionClose')}
				</Button>
			</div>
		</div>
	)
}

export default ModalViewNotification
