import React, { useEffect, useState } from 'react'
import Pagetitle from '../../../components/UI/Pagetitle/Pagetitle'
import Button from '../../../components/UI/Button/Button'
import SelectLimit from '../../../components/UI/Select/SelectLimit'
import Pagination from '../../../components/UI/Pagination/Pagination'
import { IPagination, TDatePickerDate } from '../../../interfaces/helpers'
import {
	DATE_FORMAT,
	DATE_FORMAT_API_SHORT,
	DEFAULT_PAGE_SIZE,
	getStatusName,
	INFINITE_NUMBER,
	LICENSE_PACKAGE_REQUEST_STATUSES
} from '../../../helpers/helpers'
import {
	ILicensePackageRequest,
	ILicensePackageRequestAPIRequest,
	ILicensePackageRequestCreate,
	ILicensePackageRequestUpdate,
	TLicensePackageRequestStatus
} from '../../../interfaces/cabinet/licensePackageRequest'
import { modalShowHandler } from '../../../store/actionCreators/UI/modal'
import { notificationAddHandler } from '../../../store/actionCreators/UI/notification'
import { connect, ConnectedProps } from 'react-redux'
import {
	createLicensePackageRequestHandler,
	deleteLicensePackageRequestByIdHandler,
	getNewAmountLicensePackageRequestHandler,
	licensePackageRequestGetAllHandler,
	updateLicensePackageRequestByIdHandler
} from '../../../services/cabinet/licensePackageRequests'
import ModalAddOrderByOrgAdmin from './ModalAddOrderByOrgAdmin'
import ModalAddOrderByCloudAdmin from './ModalAddOrderByCloudAdmin'
import { IOrganization } from '../../../interfaces/passport/organizations'
import { getAllOrganizationsHandler, getOrganizationByIdHandler } from '../../../services/passport/organization'
import dayjs from 'dayjs'
import { CLOUD_ADMIN } from '../../../helpers/roles'
import { useParams } from 'react-router-dom'
import Loading from '../../../components/UI/Loading/Loading'
import ModalEditOrderByCloudAdmin from './ModalEditOrderByCloudAdmin'
import ModalEditOrderByOrgAdmin from './ModalEditOrderByOrgAdmin'
import SearchField from '../../../components/UI/SearchField/SearchField'
import InputDatePicker from '../../../components/UI/InputDatePicker/InputDatePicker'
import { RootState } from '../../../store/reducers/rootReducer'
import { useTranslation } from 'react-i18next'
import { IUser } from '../../../interfaces/passport/user'
import NoResults from '../../../components/UI/NoResults/NoResults'
import { selectProps, TOnChange } from '../../../components/UI/Select/helpers'
import Select from 'react-select'
import { toggleLoaderHandler } from '../../../store/actionCreators/UI/loader'

const isEditDisabled = (user: IUser, item: ILicensePackageRequest) => {
	return user.role !== CLOUD_ADMIN && item.status === 'PROCESSED'
}

const Orders = ({
	user,
	organization,
	getOrganization,
	getOrganizations,
	getLicensePackageRequests,
	createLicensePackageRequest,
	updateLicensePackageRequest,
	removeLicensePackageRequest,
	showModal,
	notificationAdd,
	toggleLoader,
	getNewAmountLicensePackageRequest
}: ConnectedProps<typeof connector>) => {
	const { t } = useTranslation()
	const { organizationId } = useParams()

	const [selectedOrganization, setSelectedOrganization] = useState(
		organization.id
			? {
					id: organization.id,
					name: organization.name,
					accountId: organization.accountId
			  }
			: user?.accounts?.[0]?.organization && user?.accounts?.[0]?.organization?.id
			? {
					id: user?.accounts?.[0]?.organization.id,
					name: user?.accounts?.[0]?.organization.name,
					accountId: user?.accounts?.[0]?.accountId
			  }
			: {
					id: '',
					name: '',
					accountId: ''
			  }
	)

	const [organizations, setOrganizations] = useState<IOrganization[]>([])

	const [table, setTable] = useState<ILicensePackageRequest[]>([])
	const [pagination, setPagination] = useState<IPagination>({
		pageNo: 0,
		totalPages: 0,
		totalCount: 0
	})

	const [filterLicensePackageRequests, setFilterLicensePackageRequests] = useState<ILicensePackageRequestAPIRequest>({
		accountId: selectedOrganization.accountId,
		dateTo: '',
		dateFrom: '',
		sortField: 'Date',
		status: '',
		desc: true,
		pageSize: DEFAULT_PAGE_SIZE,
		pageNo: 0
	})

	const paginationHandle = (pageNo: number) => {
		setFilterLicensePackageRequests({
			...filterLicensePackageRequests,
			pageNo
		})
	}

	const limitHandle: TOnChange = option => {
		if (option) {
			setFilterLicensePackageRequests({
				...filterLicensePackageRequests,
				pageSize: parseInt(option.value) || INFINITE_NUMBER,
				pageNo: 0
			})
		}
	}

	const statusHandle: TOnChange = option => {
		if (option) {
			setFilterLicensePackageRequests({
				...filterLicensePackageRequests,
				status: option.value as TLicensePackageRequestStatus
			})
		}
	}

	const searchHandle = (value: string) => {
		setFilterLicensePackageRequests({
			...filterLicensePackageRequests,
			organizationFilter: value,
			pageNo: 0
		})
	}

	const updateLicensePackageRequestAmount = () => {
		getNewAmountLicensePackageRequest({
			status: 'NEW'
		})
	}

	const [dateFrom, setDateFrom] = useState<TDatePickerDate>(null)
	const [minDateFrom] = useState<TDatePickerDate>(null)
	const [maxDateFrom, setMaxDateFrom] = useState<TDatePickerDate>(null)
	const [dateTo, setDateTo] = useState<TDatePickerDate>(null)
	const [minDateTo, setMinDateTo] = useState<TDatePickerDate>(null)
	const [maxDateTo] = useState<TDatePickerDate>(null)

	const dateFromHandler = (date: TDatePickerDate) => {
		setDateFrom(date)
		setMinDateTo(date)

		setFilterLicensePackageRequests({
			...filterLicensePackageRequests,
			dateFrom: (date && dayjs(date).format(DATE_FORMAT_API_SHORT)) || ''
		})
	}

	const dateToHandler = (date: TDatePickerDate) => {
		setDateTo(date)
		setMaxDateFrom(date)

		setFilterLicensePackageRequests({
			...filterLicensePackageRequests,
			dateTo: (date && dayjs(date).format(DATE_FORMAT_API_SHORT)) || ''
		})
	}

	const createHandler = () => {
		if (user.role === CLOUD_ADMIN) {
			showModal({
				type: 'middle',
				Component: ModalAddOrderByCloudAdmin,
				ComponentProps: {
					accountId: selectedOrganization.accountId,
					organizations,
					onConfirm: async (data: ILicensePackageRequestCreate) => {
						toggleLoader(true)

						try {
							const response = await createLicensePackageRequest(data)

							if (response?.status === 200) {
								paginationHandle(0)
								notificationAdd(t('pages:licensePackagesRequest:notificationCreate'), 'info')
								updateLicensePackageRequestAmount()
							}

							return response
						} finally {
							toggleLoader(false)
						}
					}
				}
			})
		} else {
			showModal({
				type: 'middle',
				Component: ModalAddOrderByOrgAdmin,
				ComponentProps: {
					organization: selectedOrganization,
					onConfirm: async (data: ILicensePackageRequestCreate) => {
						toggleLoader(true)

						try {
							const response = await createLicensePackageRequest(data)

							if (response?.status === 200) {
								paginationHandle(0)
								notificationAdd(t('pages:licensePackagesRequest:notificationCreate'), 'info')
								updateLicensePackageRequestAmount()
							}

							return response
						} finally {
							toggleLoader(false)
						}
					}
				}
			})
		}
	}

	const updateHandler = (item: ILicensePackageRequest) => {
		if (isEditDisabled(user, item)) return false

		if (user.role === CLOUD_ADMIN) {
			showModal({
				type: 'middle',
				Component: ModalEditOrderByCloudAdmin,
				ComponentProps: {
					item,
					accountId: selectedOrganization.accountId,
					organizations,
					onConfirm: async (data: ILicensePackageRequestUpdate) => {
						toggleLoader(true)

						try {
							const response = await updateLicensePackageRequest(item.id, data)

							if (response?.status === 200) {
								paginationHandle(0)
								notificationAdd(t('pages:licensePackagesRequest:notificationUpdate'), 'info')
								updateLicensePackageRequestAmount()
							}

							return response
						} finally {
							toggleLoader(false)
						}
					},
					onRemove: async (id: string) => {
						toggleLoader(true)

						try {
							const response = await removeLicensePackageRequest(id)

							if (response?.status === 200) {
								paginationHandle(0)
								notificationAdd(t('pages:licensePackagesRequest:notificationRemove'), 'info')
								updateLicensePackageRequestAmount()
							}

							return response
						} finally {
							toggleLoader(false)
						}
					}
				}
			})
		} else {
			showModal({
				type: 'middle',
				Component: ModalEditOrderByOrgAdmin,
				ComponentProps: {
					item,
					onConfirm: async (data: ILicensePackageRequestUpdate) => {
						toggleLoader(true)

						try {
							const response = await updateLicensePackageRequest(item.id, data)

							if (response?.status === 200) {
								paginationHandle(0)
								notificationAdd(t('pages:licensePackagesRequest:notificationUpdate'), 'info')
								updateLicensePackageRequestAmount()
							}

							return response
						} finally {
							toggleLoader(false)
						}
					}
				}
			})
		}
	}

	useEffect(() => {
		if (user.role === CLOUD_ADMIN) {
			getOrganizations({
				desc: false,
				pageNo: 0,
				pageSize: INFINITE_NUMBER
			}).then(response => {
				if (response?.status === 200) {
					setOrganizations(response.data.data)
				}
			})
		}

		if (user.role === CLOUD_ADMIN && organizationId) {
			getOrganization(organizationId).then(response => {
				if (response?.status === 200) {
					setSelectedOrganization({
						id: organizationId,
						name: response.data.name,
						accountId: response.data.accountId
					})
				}
			})
		}
	}, [getOrganization, getOrganizations, user.role, organizationId])

	useEffect(() => {
		getLicensePackageRequests(filterLicensePackageRequests).then(response => {
			if (response?.status === 200) {
				const { data, pageNo, totalPages, totalCount } = response.data

				setTable(data)

				setPagination({
					pageNo,
					totalPages,
					totalCount
				})
			}
		})
	}, [getLicensePackageRequests, filterLicensePackageRequests])

	if (user.role === CLOUD_ADMIN && !organizationId && !organizations.length) {
		return <Loading />
	}

	return (
		<>
			{organization?.id && user.role === CLOUD_ADMIN ? (
				<Pagetitle
					title={t('pages:licensePackagesRequest:title')}
					crumbs={[
						{
							title: organization.name,
							to: `/organization/${organization.id}/`
						}
					]}
				/>
			) : (
				<Pagetitle title={t('pages:licensePackagesRequest:title')} />
			)}

			<div className="button-row button-row--add">
				<Button type={'primary'} htmlType={'button'} onClick={createHandler}>
					{t('pages:licensePackagesRequest:addNew')}
				</Button>
			</div>

			<div className="filters filters--license-short">
				<div className="filters-item filters-item--calendar">
					<InputDatePicker
						placeholderText={t('pages:licensePackagesRequest:fieldDateFrom')}
						name={'dateFrom'}
						selected={dateFrom}
						minDate={minDateFrom}
						maxDate={maxDateFrom}
						onChange={dateFromHandler}
					/>

					<span className="filters-item-delimiter">—</span>

					<InputDatePicker
						placeholderText={t('pages:licensePackagesRequest:fieldDateTo')}
						name={'dateTo'}
						selected={dateTo}
						minDate={minDateTo}
						maxDate={maxDateTo}
						onChange={dateToHandler}
					/>
				</div>

				{user.role === CLOUD_ADMIN && !organizationId && (
					<div className="filters-item filters-item--search">
						<SearchField onSubmit={searchHandle} placeholder={t('pages:licensePackagesRequest:searchField')} />
					</div>
				)}

				<div className="filters-item filters-item--select">
					<Select
						name={'status'}
						onChange={statusHandle}
						options={[
							{
								label: t('common:selectDefaultValue'),
								value: ''
							},
							...LICENSE_PACKAGE_REQUEST_STATUSES.map(item => {
								return {
									label: t(getStatusName(item)),
									value: item
								}
							})
						]}
						defaultValue={{
							label: t('common:selectDefaultValue'),
							value: ''
						}}
						placeholder={t('pages:licensePackagesRequest:fieldStatus')}
						{...selectProps}
					/>
				</div>

				<div className="filters-item filters-item--view">
					<SelectLimit total={pagination.totalCount} onChange={limitHandle} />
				</div>
			</div>

			{table.length > 0 ? (
				<div className="pagination-wrapper">
					<div className="license-request">
						{table.map(item => {
							const organizationName = organizations.find(o => o.accountId === item.accountId)?.name

							return (
								<div
									key={item.id}
									className={`license-request-item ${
										isEditDisabled(user, item) ? 'license-request-item--nohover' : ''
									}`}
									onClick={() => updateHandler(item)}
								>
									<div className="license-request-info">
										{organizationName ? <h4 className="license-request-title">{organizationName}</h4> : null}

										<ul className="list">
											<li className="list-item">
												<div className="list-item-label">{t('pages:licensePackagesRequest:period')}</div>
												<div className="list-item-value">
													{t('pages:licensePackagesRequest:periodFrom', {
														value: (item.dateFrom && dayjs(item.dateFrom).format(DATE_FORMAT)) || '-'
													})}
													<br />
													{t('pages:licensePackagesRequest:periodTo', {
														value: (item.dateTo && dayjs(item.dateTo).format(DATE_FORMAT)) || '-'
													})}
												</div>
											</li>
											<li className="list-item">
												<div className="list-item-label">{t('pages:licensePackagesRequest:licenseCount')}</div>
												<div className="list-item-value">
													{t('pages:licensePackagesRequest:termless', {
														value: item.termlessCount || 0
													})}
													<br />
													{t('pages:licensePackagesRequest:urgent', {
														value: item.urgentCount || 0
													})}
													<br />
													{t('pages:licensePackagesRequest:hardware', {
														value: item.hardwareCount || 0
													})}
												</div>
											</li>
											<li className="list-item list-item--fullwidth">
												<div className="list-item-label">{t('pages:licensePackagesRequest:comment')}</div>
												<div className="list-item-value">{item.comment || '-'}</div>
											</li>
										</ul>
									</div>
									<div className="license-request-status">
										<span className={`task-status task-status--${item.status.toLowerCase()}`}>
											{t(getStatusName(item.status))}
										</span>
									</div>
								</div>
							)
						})}
					</div>

					<Pagination onClick={paginationHandle} {...pagination} />
				</div>
			) : (
				<NoResults />
			)}
		</>
	)
}

const mapState = (state: RootState) => ({
	user: state.user.user,
	organization: state.organization.organization
})

const mapDispatch = {
	getOrganization: getOrganizationByIdHandler,
	getOrganizations: getAllOrganizationsHandler,
	getLicensePackageRequests: licensePackageRequestGetAllHandler,
	createLicensePackageRequest: createLicensePackageRequestHandler,
	updateLicensePackageRequest: updateLicensePackageRequestByIdHandler,
	removeLicensePackageRequest: deleteLicensePackageRequestByIdHandler,
	showModal: modalShowHandler,
	notificationAdd: notificationAddHandler,
	toggleLoader: toggleLoaderHandler,
	getNewAmountLicensePackageRequest: getNewAmountLicensePackageRequestHandler
}

const connector = connect(mapState, mapDispatch)

export default connector(Orders)
