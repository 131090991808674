import React, { useEffect, useState } from 'react'
import { IOrganization } from '../../../interfaces/passport/organizations'
import Loading from '../../../components/UI/Loading/Loading'
import { getAllOrganizationsHandler } from '../../../services/passport/organization'
import { connect, ConnectedProps } from 'react-redux'
import { DATE_FORMAT, DATE_FORMAT_API_SHORT, getDate, INFINITE_NUMBER } from '../../../helpers/helpers'
import {
	reportsByOrganizationsHandler,
	reportsByOrganizationUsersHandler,
	reportsLicenseUsageUsageByOrganizationsHandler
} from '../../../services/reports/reports'
import FileSaver from 'file-saver'
import dayjs from 'dayjs'
import { toggleLoaderHandler } from '../../../store/actionCreators/UI/loader'
import { TStatus } from '../../../interfaces/helpers'
import ReportByOrganizations, { IFormOrganizationInputs } from './components/ReportByOrganizations'
import ReportByOrganizationUsers, { IFormOrganizationUsersInputs } from './components/ReportByOrganizationUsers'
import ReportLicenseUsageByOrganizations, {
	IFormLicenseUsageByOrganizationsInputs
} from './components/ReportLicenseUsageByOrganizations'

const Reports = ({
	getOrganizations,
	getReportsByOrganizationUsers,
	getReportsByOrganizations,
	getReportsLicenseUsageByOrganizations,
	toggleLoader
}: ConnectedProps<typeof connector>) => {
	const [status, setStatus] = useState<TStatus>('loading')
	const [organizations, setOrganizations] = useState<IOrganization[]>([])

	const onSubmitOrganizations = async (data: IFormOrganizationInputs) => {
		toggleLoader(true)

		try {
			const response = await getReportsByOrganizations({
				...data,
				unactivatedCount: parseInt(data.unactivatedCount),
				reportCreatedFrom: getDate(data.reportCreatedFrom, DATE_FORMAT_API_SHORT),
				reportCreatedTo: getDate(data.reportCreatedTo, DATE_FORMAT_API_SHORT),
				createdFrom: getDate(data.createdFrom),
				createdTo: getDate(data.createdTo),
				dateFrom: getDate(data.dateFrom, DATE_FORMAT_API_SHORT),
				dateTo: getDate(data.dateTo, DATE_FORMAT_API_SHORT)
			})

			if (response?.status === 200) {
				const file = new Blob([response.data])
				const fileName = `report_${dayjs(new Date()).format(DATE_FORMAT)}.xls`
				FileSaver.saveAs(file, fileName)
			}

			return response
		} finally {
			toggleLoader(false)
		}
	}

	const onSubmitOrganizationUsers = async (data: IFormOrganizationUsersInputs) => {
		toggleLoader(true)

		try {
			const response = await getReportsByOrganizationUsers({
				...data,
				reportCreatedFrom: getDate(data.reportCreatedFrom, DATE_FORMAT_API_SHORT),
				reportCreatedTo: getDate(data.reportCreatedTo, DATE_FORMAT_API_SHORT)
			})

			if (response?.status === 200) {
				const file = new Blob([response.data])
				const fileName = `report_${dayjs(new Date()).format(DATE_FORMAT)}.xls`
				FileSaver.saveAs(file, fileName)
			}

			return response
		} finally {
			toggleLoader(false)
		}
	}

	const onSubmitUsageByOrganizations = async (data: IFormLicenseUsageByOrganizationsInputs) => {
		toggleLoader(true)

		try {
			const response = await getReportsLicenseUsageByOrganizations({
				...data,
				reportCreatedFrom: getDate(data.reportCreatedFrom, DATE_FORMAT_API_SHORT),
				reportCreatedTo: getDate(data.reportCreatedTo, DATE_FORMAT_API_SHORT)
			})

			if (response?.status === 200) {
				const file = new Blob([response.data])
				const fileName = `report_${dayjs(new Date()).format(DATE_FORMAT)}.xls`
				FileSaver.saveAs(file, fileName)
			}

			return response
		} finally {
			toggleLoader(false)
		}
	}

	useEffect(() => {
		getOrganizations({
			desc: false,
			pageSize: INFINITE_NUMBER,
			pageNo: 0
		}).then(response => {
			if (response?.status === 200) {
				setOrganizations(response.data.data)

				setStatus('ready')
			}
		})
	}, [getOrganizations])

	if (status === 'loading') return <Loading />

	return (
		<>
			<ReportByOrganizations onSubmit={onSubmitOrganizations} organizations={organizations} />
			<ReportByOrganizationUsers onSubmit={onSubmitOrganizationUsers} organizations={organizations} />
			<ReportLicenseUsageByOrganizations onSubmit={onSubmitUsageByOrganizations} organizations={organizations} />
		</>
	)
}

const mapDispatch = {
	getOrganizations: getAllOrganizationsHandler,
	getReportsByOrganizations: reportsByOrganizationsHandler,
	getReportsByOrganizationUsers: reportsByOrganizationUsersHandler,
	getReportsLicenseUsageByOrganizations: reportsLicenseUsageUsageByOrganizationsHandler,
	toggleLoader: toggleLoaderHandler
}

const connector = connect(null, mapDispatch)

export default connector(Reports)
