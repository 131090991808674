import { APIResponse, AppThunk } from '../../interfaces/helpers'
import axios from 'axios'
import { API_SUPPORT_PATH, APIServices } from '../../helpers/api'
import { setErrorHandler } from '../../store/actionCreators/error'
import {
	IRequestTopic,
	IRequestTopicAPIRequest,
	IRequestTopicCreate,
	IRequestTopicUpdate
} from '../../interfaces/support/requestTopic'
import { serviceHandlerErrorOptions } from '../helpers'

export function requestTopicGetAllHandler(
	params: IRequestTopicAPIRequest,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<APIResponse<IRequestTopic[]>> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<APIResponse<IRequestTopic[]>>({
				method: 'GET',
				url: `${API_SUPPORT_PATH}/requestTopic/listFiltered`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params
			})
			return response
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.SUPPORT, errorOptions))
			return error.response
		}
	}
}

export function getRequestTopicByIdHandler(
	id: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IRequestTopic> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IRequestTopic>({
				method: 'GET',
				url: `${API_SUPPORT_PATH}/requestTopic/${id}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id
				}
			})
			return response
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.SUPPORT, errorOptions))
			return error.response
		}
	}
}

export function createRequestTopicHandler(
	data: IRequestTopicCreate,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IRequestTopic> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IRequestTopic>({
				method: 'POST',
				url: `${API_SUPPORT_PATH}/requestTopic`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				data
			})
			return response
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.SUPPORT, errorOptions))
			return error.response
		}
	}
}

export function updateRequestTopicByIdHandler(
	id: string,
	data: IRequestTopicUpdate,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IRequestTopic> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IRequestTopic>({
				method: 'PUT',
				url: `${API_SUPPORT_PATH}/requestTopic/${id}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id
				},
				data
			})
			return response
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.SUPPORT, errorOptions))
			return error.response
		}
	}
}

export function archiveRequestTopicByIdHandler(id: string, errorOptions = serviceHandlerErrorOptions): AppThunk {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request({
				method: 'POST',
				url: `${API_SUPPORT_PATH}/requestTopic/${id}/archive`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id
				}
			})
			return response
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.SUPPORT, errorOptions))
			return error.response
		}
	}
}
