import React from 'react'
import { useParams } from 'react-router-dom'
import { connect, ConnectedProps } from 'react-redux'
import Error from '../../../Error/Error'
import { useTranslation } from 'react-i18next'
import UsersList from '../../../Common/Users/Users'
import { RootState } from '../../../../store/reducers/rootReducer'

const Users = ({ user }: ConnectedProps<typeof connector>) => {
	const { t } = useTranslation()
	const { licensePackageId, type } = useParams<{ licensePackageId: string; type: 'urgent' | 'termless' }>()
	const account = user.accounts?.[0]

	if (!account) return <Error />

	return (
		<UsersList
			organizationId={account.organization.id}
			accountId={account.accountId}
			licensePackageId={licensePackageId}
			userLicenseType={type === 'termless' ? 'TERMLESS' : 'LIMITED'}
			title={t(`pages:licensePackages:${type === 'termless' ? 'titleUserTermless' : 'titleUserUrgent'}`)}
			crumbs={[
				{
					title: t('pages:licensePackages:title'),
					to: `/license-packages`
				}
			]}
		/>
	)
}

const mapState = (state: RootState) => ({
	user: state.user.user
})

const connector = connect(mapState)

export default connector(Users)
