import React, { useCallback, useEffect } from 'react'
import InputError from '../InputError/InputError'
import { useDropzone } from 'react-dropzone'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface IProps {
	name: string
	accept?: string
	isError?: boolean
	isRequired?: boolean
	isDisabled?: boolean
	reference?: any
	errors?: any
}

const DragAndDrop = ({ name, accept, errors, isError, isRequired, isDisabled }: IProps) => {
	const { t } = useTranslation()
	const { register, unregister, setValue, watch } = useFormContext()

	const files: File[] = watch(name)

	const onDrop = useCallback(
		droppedFiles => {
			setValue(name, droppedFiles, {
				shouldValidate: true,
				shouldDirty: true
			})
		},
		[setValue, name]
	)

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept,
		disabled: isDisabled,
		multiple: false
	})

	useEffect(() => {
		register(name, {
			required: isRequired
		})
		return () => {
			unregister(name)
		}
	}, [register, unregister, name, isRequired])

	return (
		<>
			<div {...getRootProps({ className: 'drag-and-drop' })} style={{ outline: 'none' }}>
				<input name={name} {...getInputProps()} />

				<div className="drag-and-drop-label">{t('common:DragAndDropText')}</div>

				<div className="drag-and-drop-file">
					{!!files?.length ? files.map(item => item.name).join(', ') : t('common:DragAndDropEmpty')}
				</div>

				<div className="button button--secondary">{t('common:DragAndDropButton')}</div>
			</div>

			{isError && <InputError errors={errors} />}
		</>
	)
}

export default DragAndDrop
