import { ActionMeta, components, ValueType } from 'react-select'
import React from 'react'
import InputError from '../InputError/InputError'
import i18n from 'i18next'

export type OptionType = {
	label: string
	value: string
}

export type TOnChange = (value: ValueType<OptionType, false>, actionMeta: ActionMeta<OptionType>) => void

const ControlComponent = (props: any) => {
	const { errors, isError, isRequired, placeholder, rules } = props.selectProps

	const classNames = ['pirogov-select']

	if (isError) classNames.push('pirogov-select--error')
	if (isRequired) classNames.push('pirogov-select--required')
	if (props.hasValue) classNames.push('pirogov-select--notempty')

	return (
		<div className={classNames.join(' ')}>
			<components.Control {...props} />
			{placeholder && <div className={'pirogov-select-placeholder'}>{placeholder}</div>}

			{isError && <InputError errors={errors} rules={rules} />}
		</div>
	)
}

export const selectProps = {
	className: 'pirogov-container',
	classNamePrefix: 'pirogov',
	components: {
		Control: ControlComponent
	},
	noOptionsMessage: () => i18n.t('common:selectNoOptions')
}
