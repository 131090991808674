import { IAuth, ITokens } from '../../interfaces/passport/auth'
import { AppThunk } from '../../interfaces/helpers'
import axios, { AxiosPromise } from 'axios'
import { API_AUTH_PATH, APIServices } from '../../helpers/api'
import { clearUserData, setUserData } from '../../store/actionCreators/passport/user'
import { setErrorHandler } from '../../store/actionCreators/error'
import { ThunkAction } from 'redux-thunk'
import { RootState } from '../../store/reducers/rootReducer'
import { Action } from 'redux'
import { clearCurrentOrganization } from '../../store/actionCreators/passport/organization'
import { login, logout, setIsAuthorized } from '../../store/actionCreators/passport/auth'
import { getUserDataHandler } from './user'
import { serviceHandlerErrorOptions } from '../helpers'

const REFRESH_TIME_OFFSET = 10000

export function loginHandler(data: IAuth, errorOptions = serviceHandlerErrorOptions): AppThunk<ITokens> {
	return async dispatch => {
		try {
			const params: IAuth & { grant_type: string } = {
				grant_type: 'password',
				username: data.username.trim(),
				password: data.password
			}

			if (data.c_id && data.c_val) {
				params.c_id = data.c_id
				params.c_val = data.c_val
			}

			const response = await axios.request<ITokens>({
				method: 'POST',
				url: `${API_AUTH_PATH}/auth/realms/pgcloud/protocol/openid-connect/token`,
				auth: {
					username: `${process.env.REACT_APP_AUTH_CLIENT_ID}`,
					password: `${process.env.REACT_APP_AUTH_CLIENT_SECRET}`
				},
				data: new URLSearchParams(params)
			})

			const expirationDate = new Date(new Date().getTime() + response.data.expires_in * 1000).getTime()
			const loginDate = new Date().getTime()
			localStorage.setItem('tokens', JSON.stringify(response.data))
			localStorage.setItem('expirationDate', expirationDate.toString())
			dispatch(login(response.data))
			dispatch(getUserDataHandler()).then(response => {
				if (response?.status === 200) {
					dispatch(setIsAuthorized(true))
				} else {
					dispatch(logoutHandler())
				}
			})
			setTimeout(() => {
				dispatch(refreshToken())
			}, expirationDate - loginDate - REFRESH_TIME_OFFSET)
			return response
		} catch (error) {
			dispatch(logoutHandler())
			dispatch(setErrorHandler(error, APIServices.AUTH, errorOptions))
			return error.response
		}
	}
}

export function logoutHandler(withRedirect = true): ThunkAction<void, RootState, unknown, Action<string>> {
	return dispatch => {
		localStorage.removeItem('tokens')
		localStorage.removeItem('user')
		localStorage.removeItem('expirationDate')

		dispatch(setIsAuthorized(false))
		dispatch(logout())
		dispatch(clearUserData())
		dispatch(clearCurrentOrganization())

		if (withRedirect) {
			window.history.replaceState(null, '', '/')
		}
	}
}

export function autoLoginHandler(): ThunkAction<void, RootState, unknown, Action<string>> {
	return dispatch => {
		const tokens = localStorage.getItem('tokens')
		const user = localStorage.getItem('user')

		if (!tokens || !user) {
			dispatch(logoutHandler(false))
		} else {
			const expirationDate = localStorage.getItem('expirationDate')
			const loginDate = new Date().getTime()

			if (!expirationDate) {
				dispatch(logoutHandler())
			} else {
				if (loginDate >= parseInt(expirationDate)) {
					dispatch(logoutHandler())
				} else {
					dispatch(login(JSON.parse(tokens)))
					dispatch(setUserData(JSON.parse(user)))
					dispatch(setIsAuthorized(true))

					setTimeout(() => {
						dispatch(refreshToken())
					}, parseInt(expirationDate) - loginDate - REFRESH_TIME_OFFSET)
				}
			}
		}
	}
}

function refreshToken(
	errorOptions = serviceHandlerErrorOptions
): ThunkAction<AxiosPromise<ITokens> | void, RootState, unknown, Action<string>> {
	return async dispatch => {
		const tokens = localStorage.getItem('tokens')

		if (tokens) {
			try {
				const response = await axios.request<ITokens>({
					method: 'POST',
					url: `${API_AUTH_PATH}/auth/realms/pgcloud/protocol/openid-connect/token`,
					data: new URLSearchParams({
						grant_type: 'refresh_token',
						client_id: `${process.env.REACT_APP_AUTH_CLIENT_ID}`,
						client_secret: `${process.env.REACT_APP_AUTH_CLIENT_SECRET}`,
						refresh_token: JSON.parse(tokens).refresh_token
					})
				})

				const expirationDate = new Date(new Date().getTime() + response.data.expires_in * 1000).getTime()
				const loginDate = new Date().getTime()

				localStorage.setItem('tokens', JSON.stringify(response.data))
				localStorage.setItem('expirationDate', expirationDate.toString())

				dispatch(login(response.data))

				setTimeout(() => {
					dispatch(refreshToken())
				}, expirationDate - loginDate - REFRESH_TIME_OFFSET)

				return response
			} catch (error) {
				dispatch(logoutHandler())
				dispatch(setErrorHandler(error, APIServices.AUTH, errorOptions))

				return error.response
			}
		} else {
			dispatch(logoutHandler())
		}
	}
}

export function autoLogoutHandler(expires_in: number): ThunkAction<void, RootState, unknown, Action<string>> {
	return dispatch => {
		setTimeout(() => {
			dispatch(logoutHandler())
		}, expires_in)
	}
}
