import React, { useEffect, useMemo, useState } from 'react'
import Pagetitle from '../../../components/UI/Pagetitle/Pagetitle'
import Loading from '../../../components/UI/Loading/Loading'
import SearchField from '../../../components/UI/SearchField/SearchField'
import { connect, ConnectedProps } from 'react-redux'
import { productGetAllHandler } from '../../../services/cabinet/products'
import { useParams } from 'react-router-dom'
import { modalShowHandler } from '../../../store/actionCreators/UI/modal'
import Button from '../../../components/UI/Button/Button'
import { TStatus } from '../../../interfaces/helpers'
import { INFINITE_NUMBER } from '../../../helpers/helpers'
import NoResults from '../../../components/UI/NoResults/NoResults'
import { useTranslation } from 'react-i18next'
import { toggleLoaderHandler } from '../../../store/actionCreators/UI/loader'
import {
	createFeatureHandler,
	deleteFeatureByIdHandler,
	featureGetAllHandler,
	updateFeatureHandler
} from '../../../services/cabinet/features'
import { IFeature, IFeatureAPIRequest, IFeatureCreate, IFeatureUpdate } from '../../../interfaces/cabinet/features'
import ModalAddFeature from './ModalAddFeature'
import { IProduct } from '../../../interfaces/cabinet/products'
import { notificationAddHandler } from '../../../store/actionCreators/UI/notification'

const Features = ({
	getProducts,
	getFeatures,
	showModal,
	createFeature,
	updateFeature,
	removeFeature,
	toggleLoader,
	notificationAdd
}: ConnectedProps<typeof connector>) => {
	const { t } = useTranslation()
	const { productId } = useParams()
	const [status, setStatus] = useState<TStatus>('loading')
	const [table, setTable] = useState<IFeature[]>([])
	const [products, setProducts] = useState<IProduct[]>([])

	const [filterFeatures, setFilterFeatures] = useState<IFeatureAPIRequest>({
		productId
	})

	const searchByCodeHandle = (value: string) => {
		setFilterFeatures({
			...filterFeatures,
			featureCode: value
		})
	}

	const searchByTitleHandle = (value: string) => {
		setFilterFeatures({
			...filterFeatures,
			title: value
		})
	}

	const createFeatureHandler = () => {
		showModal({
			type: 'description',
			Component: ModalAddFeature,
			ComponentProps: {
				products,
				onConfirm: async (data: IFeatureCreate) => {
					toggleLoader(true)

					try {
						const response = await createFeature(data)

						if (response?.status === 200) {
							setFilterFeatures({
								...filterFeatures,
								featureCode: undefined,
								title: undefined
							})

							notificationAdd(t('pages:features:notificationCreate'), 'info')
						}

						return response
					} finally {
						toggleLoader(false)
					}
				}
			}
		})
	}

	const updateFeatureHandler = (id: string, feature: IFeatureUpdate) => {
		showModal({
			type: 'description',
			Component: ModalAddFeature,
			ComponentProps: {
				products,
				feature,
				onConfirm: async (data: IFeatureUpdate) => {
					toggleLoader(true)

					try {
						const response = await updateFeature(id, data)

						if (response?.status === 200) {
							setFilterFeatures({
								...filterFeatures,
								featureCode: undefined,
								title: undefined
							})

							notificationAdd(t('pages:features:notificationUpdate'), 'info')
						}

						return response
					} finally {
						toggleLoader(false)
					}
				},
				onRemove: async () => {
					toggleLoader(true)

					try {
						const response = await removeFeature(id)

						if (response?.status === 200) {
							setFilterFeatures({
								...filterFeatures,
								featureCode: undefined,
								title: undefined
							})

							notificationAdd(t('pages:features:notificationRemove'), 'info')
						}

						return response
					} finally {
						toggleLoader(false)
					}
				}
			}
		})
	}

	useEffect(() => {
		getFeatures(filterFeatures).then(response => {
			if (response?.status === 200) {
				setTable(response.data)

				setStatus('ready')
			}
		})
	}, [getFeatures, filterFeatures])

	useEffect(() => {
		getProducts({
			desc: false,
			pageSize: INFINITE_NUMBER,
			pageNo: 0
		}).then(response => {
			if (response?.status === 200) {
				setProducts(response?.data?.data || [])
			}
		})
	}, [getProducts])

	const productTitle = useMemo(() => {
		if (productId) {
			return products.find(product => product.id === productId)?.name
		}

		return undefined
	}, [productId, products])

	if (status === 'loading') {
		return <Loading />
	}

	return (
		<>
			<Pagetitle title={`${t('pages:features:title')} ${productTitle ? `| ${productTitle}` : ''}`} />

			<div className="filters">
				<div className="filters-item filters-item--search-middle">
					<SearchField onSubmit={searchByCodeHandle} placeholder={t('pages:features:searchFieldCode')} />
				</div>

				<div className="filters-item filters-item--search-middle">
					<SearchField onSubmit={searchByTitleHandle} placeholder={t('pages:features:searchFieldName')} />
				</div>

				<div className="filters-item filters-item--buttons">
					<div className="button-row">
						<Button type={'primary'} className="button--large" htmlType={'button'} onClick={createFeatureHandler}>
							{t('pages:features:addNew')}
						</Button>
					</div>
				</div>
			</div>

			{table?.length > 0 ? (
				<div className="pagination-wrapper">
					<div className="table">
						<table>
							<thead>
								<tr>
									<th>
										<div>{t('pages:features:tableHeadingCode')}</div>
									</th>
									<th>
										<div>{t('pages:features:tableHeadingName')}</div>
									</th>
									<th>
										<div>{t('pages:features:tableHeadingProduct')}</div>
									</th>
								</tr>
							</thead>

							<tbody>
								{table?.map(item => {
									return (
										<tr key={item.id}>
											<td>
												<button
													className="button-link"
													type="button"
													onClick={() =>
														updateFeatureHandler(item.id, {
															featureCode: item.featureCode,
															productId: item.productId,
															title: item.title
														})
													}
												>
													{item.featureCode}
												</button>
											</td>
											<td>{item.title}</td>
											<td>{products?.find(product => product.id === item.productId)?.name || '-'}</td>
										</tr>
									)
								})}
							</tbody>
						</table>
					</div>
				</div>
			) : (
				<NoResults />
			)}
		</>
	)
}

const mapDispatch = {
	getProducts: productGetAllHandler,
	getFeatures: featureGetAllHandler,
	createFeature: createFeatureHandler,
	updateFeature: updateFeatureHandler,
	removeFeature: deleteFeatureByIdHandler,

	showModal: modalShowHandler,
	notificationAdd: notificationAddHandler,
	toggleLoader: toggleLoaderHandler
}

const connector = connect(null, mapDispatch)

export default connector(Features)
