import { MODAL_ERROR_HIDE, MODAL_ERROR_SHOW } from '../../actions/UI/modalError'
import { ModalErrorActionTypes } from '../../../interfaces/UI/actionCreators/modalError'
import { IModalErrorDetails } from '../../../interfaces/UI/modalError'

interface IInitialState {
	isOpened: boolean
	onConfirm?: () => void
	errorDetails: IModalErrorDetails
}

const initialState: IInitialState = {
	isOpened: false,
	errorDetails: {
		message: '',
		id: '',
		datetime: ''
	}
}

export default function ModalErrorReducer(state = initialState, action: ModalErrorActionTypes): IInitialState {
	switch (action.type) {
		case MODAL_ERROR_SHOW:
			return {
				...state,
				isOpened: true,
				onConfirm: action.payload.onConfirm,
				errorDetails: action.payload.errorDetails
			}

		case MODAL_ERROR_HIDE:
			return {
				...state,
				isOpened: false
			}

		default:
			return state
	}
}
