import React, { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useTranslation } from 'react-i18next'
import FileSaver from 'file-saver'
import Pagetitle from '../../../components/UI/Pagetitle/Pagetitle'
import { downloadLicenseFileHandler, licenseFileGetAllHandler } from '../../../services/license/license'
import { IPagination, TSortColumnsTable, TStatus } from '../../../interfaces/helpers'
import { DEFAULT_PAGE_SIZE } from '../../../helpers/helpers'
import { ILicenseFile, ILicenseFileAPIRequest, TLicenseFileSortFields } from '../../../interfaces/license/license'
import Loading from '../../../components/UI/Loading/Loading'
import NoResults from '../../../components/UI/NoResults/NoResults'
import Pagination from '../../../components/UI/Pagination/Pagination'
import Error from '../../Error/Error'

interface IDistributivesProps {
    accountId: string
    licensePackageId?: string
    crumbs?: Array<{
        to: string
        title: string
        onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
    }>
}

const DistributivesList = ({ accountId, licensePackageId, crumbs, getLicenseFiles, downloadLicenseFile }: IDistributivesProps & ConnectedProps<typeof connector>) => {
    const { t } = useTranslation()
    const [status, setStatus] = useState<TStatus>('loading')
    const [table, setTable] = useState<ILicenseFile[]>([])
    const [pagination, setPagination] = useState<IPagination>({
        pageNo: 0,
        totalPages: 0,
        totalCount: 0
    })

    const [filterLicenseFiles, setFilterLicenseFiles] = useState<Omit<ILicenseFileAPIRequest, 'accountId' | 'licensePackageId'>>({
        sortField: 'id',
        desc: true,
        pageSize: DEFAULT_PAGE_SIZE,
        pageNo: 0
    })

    const [sortColumns, setSortColumns] = useState<TSortColumnsTable>({
        id: {
            desc: true,
            classNames: ['table-sort']
        }
    })

    const paginationHandle = (pageNo: number) => {
        setFilterLicenseFiles({
            ...filterLicenseFiles,
            pageNo
        })
    }

    const sortHandle = (sortField: TLicenseFileSortFields) => {
        const updatedSortColumns = { ...sortColumns }

        Object.keys(updatedSortColumns).forEach((key: string) => {
            if (key !== sortField) {
                updatedSortColumns[key] = {
                    desc: false,
                    classNames: ['table-sort']
                }
            } else {
                updatedSortColumns[key].desc = !updatedSortColumns[key].desc
                updatedSortColumns[key].classNames = [
                    'table-sort',
                    updatedSortColumns[key].desc ? 'table-sort--bottom' : 'table-sort--top'
                ]
            }
        })

        setFilterLicenseFiles({
            ...filterLicenseFiles,
            sortField,
            desc: updatedSortColumns[sortField].desc
        })

        setSortColumns(updatedSortColumns)
    }

    const downloadLicenseFileHandler = async (url: string) => {
        const response = await downloadLicenseFile(url)

        if (response?.status === 200) {
            const file = new Blob([response.data])
            FileSaver.saveAs(file, `license.key`)
        }
    }

    useEffect(() => {
        if (!accountId) {
            setStatus('error')
            return
        }
        getLicenseFiles({ ...filterLicenseFiles, accountId, licensePackageId }).then(response => {
            if (response?.status === 200) {
                const { data, pageNo, totalPages, totalCount } = response.data

                setTable(data)

                setPagination({
                    pageNo,
                    totalPages,
                    totalCount
                })

                setStatus('ready')
            }
        })
    }, [getLicenseFiles, filterLicenseFiles, accountId, licensePackageId])

    if (status === 'loading') return <Loading />
    if (status === 'error') return <Error />

    return (
        <>
            <Pagetitle title={t('pages:distributives:title')} crumbs={crumbs} />

            {table.length > 0 ? (
                <>
                    <div className="pagination-wrapper">
                        <div className="table">
                            <table>
                                <colgroup>
                                    <col width="16.5%" />
                                    <col width="16.5%" />
                                    <col width="33.5%" />
                                    <col width="33.5%" />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th onClick={() => sortHandle('id')}>
                                            <div className={sortColumns.id.classNames.join(' ')}>
                                                {t('pages:distributives:tableHeadingID')}
                                            </div>
                                        </th>
                                        <th>
                                            <div>{t('pages:distributives:tableHeadingPlatform')}</div>
                                        </th>
                                        <th>
                                            <div>{t('pages:distributives:tableHeadingDistLink')}</div>
                                        </th>
                                        <th>
                                            <div>{t('pages:distributives:tableHeadingKeyLink')}</div>
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {table.map(item => {
                                        return (
                                            <tr key={item.tokenId}>
                                                <td>
                                                    <div className="table-break">{item.tokenId || '-'}</div>
                                                </td>

                                                <td>
                                                    <div className="table-nowrap" title={item.platform}>
                                                        {item.platform || '-'}
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className="table-nowrap">
                                                        {item.buildDownloadLink ? (
                                                            <a href={item.buildDownloadLink} target="_blank" rel="noopener noreferrer">
                                                                {t('common:actionDownload')}
                                                            </a>
                                                        ) : (
                                                            '-'
                                                        )}
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className="table-nowrap">
                                                        {item.licenseFileDownloadLink ? (
                                                            <button
                                                                className="button-link"
                                                                type="button"
                                                                onClick={() => downloadLicenseFileHandler(item.licenseFileDownloadLink)}
                                                            >
                                                                {t('common:actionDownload')}
                                                            </button>
                                                        ) : (
                                                            '-'
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>

                        <Pagination onClick={paginationHandle} {...pagination} />
                    </div>
                </>
            ) : (
                <NoResults />
            )}
        </>
    )
}

const mapDispatch = {
    getLicenseFiles: licenseFileGetAllHandler,
    downloadLicenseFile: downloadLicenseFileHandler
}

const connector = connect(null, mapDispatch)

export default connector(DistributivesList)
