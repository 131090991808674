import React, { useEffect, useMemo, useState } from 'react'
import Button from '../../../components/UI/Button/Button'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import {
	DEFAULT_LOCALE,
	getLocaleName,
	LOCALES,
	MAX_ORGANIZATION_CONTACTS,
	REGEX_EMAIL,
	REGEX_INN,
	REGEX_PHONE
} from '../../../helpers/helpers'
import { modalConfirmShowHandler } from '../../../store/actionCreators/UI/modalConfirm'
import { notificationAddHandler } from '../../../store/actionCreators/UI/notification'
import { connect, ConnectedProps } from 'react-redux'
import Input from '../../../components/UI/Input/Input'
import { IContactCreate, ICountry, IOrganizationCreate } from '../../../interfaces/passport/organizations'
import Textarea from '../../../components/UI/Textarea/Textarea'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { selectProps } from '../../../components/UI/Select/helpers'
import { ITenant } from '../../../interfaces/apps/tenants'
import VersionSettings from './components/VersionSettings'
import { getAllCountriesHandler } from '../../../services/passport/organization'
import { ORGANIZATION_ACCOUNT, ORGANIZATION_PROFILES, ORGANIZATION_REGIONS } from '../../../helpers/organization'

type TProps = ConnectedProps<typeof connector> & {
	tenants: ITenant[]
	hideModal: () => void
	onConfirm: (data: IOrganizationCreate & { tenantId: string }) => Promise<any>
}

interface IFormFields extends Omit<IOrganizationCreate, 'inn' | 'country'> {
	inn: string
	tenantId: string
	country: string
}

const ModalAddOrganization = ({
	hideModal,
	onConfirm,
	showModalConfirm,
	notificationAdd,
	tenants,
	getCountries
}: TProps) => {
	const { t } = useTranslation()

	const {
		register,
		errors,
		handleSubmit,
		watch,
		formState: { isDirty },
		control,
		reset
	} = useForm<IFormFields>({
		defaultValues: {
			fullName: '',
			name: '',
			inn: '',
			mainContact: {
				name: '',
				jobName: '',
				phone: '',
				email: ''
			},
			contacts: [],
			locale: DEFAULT_LOCALE,
			tenantId: '',
			profile: '',
			region: '',
			country: '',
			accountType: ORGANIZATION_ACCOUNT[0].value
		}
	})
	const { fields: contacts, append, remove } = useFieldArray<IContactCreate>({
		control,
		name: 'contacts'
	})

	const [isSubmitting, setIsSubmitting] = useState(false)

	const [countries, setCountries] = useState<ICountry[]>([])
	const countryOptions = useMemo(
		() =>
			countries.map(item => ({
				label: item.name,
				value: item.id
			})),
		[countries]
	)

	const cancelHandler = () => {
		if (isDirty) {
			showModalConfirm({
				type: 'cancel',
				onConfirm: hideModal
			})
		} else {
			hideModal()
		}
	}

	const onSubmit = async (data: IFormFields) => {
		setIsSubmitting(true)

		const country = countries.find(c => c.id === data.country)

		try {
			const response = await onConfirm({
				...data,
				inn: data.inn === '' ? null : data.inn,
				country: country!
			})

			if (response?.status === 200) {
				reset()
				hideModal()
			}
		} finally {
			setIsSubmitting(false)
		}
	}

	const addContactHandle = () => {
		if (contacts.length < MAX_ORGANIZATION_CONTACTS) {
			append({
				name: '',
				jobName: '',
				phone: '',
				email: ''
			})
		} else {
			notificationAdd(t('pages:organizations:notificationContactsLimit'), 'info')
		}
	}

	useEffect(() => {
		getCountries().then(response => {
			if (response?.status === 200) {
				setCountries(response.data)
			}
		})
	}, [getCountries])

	return (
		<>
			<button className="modal-close" onClick={cancelHandler} disabled={isSubmitting} />

			<h3 className="modal-title">{t('common:appName')}</h3>

			<p className="modal-footnote">{t('pages:organizations:modalNewText')}</p>

			<form className="form" onSubmit={handleSubmit(onSubmit)}>
				<div className="form-item">
					<Textarea
						currentValue={watch('fullName')}
						name={'fullName'}
						placeholder={t('pages:organizations:fieldFullName')}
						isDisabled={isSubmitting}
						isError={!!errors.fullName}
						isRequired={true}
						reference={register({
							required: true
						})}
						errors={errors.fullName}
					/>
				</div>

				<div className="form-item">
					<Input
						currentValue={watch('name')}
						type={'text'}
						name={'name'}
						placeholder={t('pages:organizations:fieldName')}
						isDisabled={isSubmitting}
						isError={!!errors.name}
						isRequired={true}
						reference={register({
							required: true
						})}
						errors={errors.name}
					/>
				</div>

				<div className="form-item">
					<Controller
						render={({ onChange, name }) => (
							<Select
								name={name}
								options={ORGANIZATION_PROFILES}
								placeholder={t('pages:organizations:fieldProfile')}
								isDisabled={isSubmitting}
								isRequired={true}
								isError={!!errors.profile}
								errors={errors.profile}
								onChange={option => option && onChange(option.value)}
								{...selectProps}
							/>
						)}
						name={'profile'}
						control={control}
						rules={{
							required: true
						}}
					/>
				</div>

				<div className="form-item">
					<Controller
						render={({ onChange, name }) => (
							<Select
								name={name}
								options={ORGANIZATION_REGIONS}
								placeholder={t('pages:organizations:fieldRegion')}
								isDisabled={isSubmitting}
								isRequired={true}
								isError={!!errors.region}
								errors={errors.region}
								onChange={option => option && onChange(option.value)}
								{...selectProps}
							/>
						)}
						name={'region'}
						control={control}
						rules={{
							required: true
						}}
					/>
				</div>

				<div className="form-item">
					<Controller
						render={({ onChange, name }) => (
							<Select
								name={name}
								options={countryOptions}
								placeholder={t('pages:organizations:fieldCountry')}
								isDisabled={isSubmitting}
								isRequired={true}
								isError={!!errors.country}
								errors={errors.country}
								onChange={option => option && onChange(option.value)}
								{...selectProps}
							/>
						)}
						name={'country'}
						control={control}
						rules={{
							required: true
						}}
					/>
				</div>

				<div className="form-item">
					<Input
						currentValue={watch('inn')}
						type={'text'}
						name={'inn'}
						placeholder={t('pages:organizations:fieldInn')}
						isDisabled={isSubmitting}
						isError={!!errors.inn}
						reference={register({
							pattern: REGEX_INN,
							minLength: 10,
							maxLength: 12
						})}
						rules={{
							minLength: 10,
							maxLength: 12
						}}
						errors={errors.inn}
					/>
				</div>

				<div className="form-item">
					<Controller
						render={({ onChange, name }) => (
							<Select
								name={name}
								options={LOCALES.map(item => {
									return {
										label: t(getLocaleName(item)),
										value: item
									}
								})}
								placeholder={t('pages:organizations:fieldLocale')}
								isDisabled={isSubmitting}
								isRequired={true}
								isError={!!errors.locale}
								errors={errors.locale}
								defaultValue={{
									label: t(getLocaleName(DEFAULT_LOCALE)),
									value: DEFAULT_LOCALE
								}}
								onChange={option => option && onChange(option.value)}
								{...selectProps}
							/>
						)}
						name={'locale'}
						control={control}
						rules={{
							required: true
						}}
					/>
				</div>

				<div className="form-item">
					<Controller
						render={({ onChange, name }) => (
							<Select
								name={name}
								options={tenants.map(item => {
									return {
										label: item.name,
										value: item.id
									}
								})}
								placeholder={t('pages:organizations:fieldTenant')}
								isDisabled={isSubmitting}
								isRequired={true}
								isError={!!errors.tenantId}
								errors={errors.tenantId}
								onChange={option => option && onChange(option.value)}
								{...selectProps}
							/>
						)}
						name={'tenantId'}
						control={control}
						rules={{
							required: true
						}}
					/>
				</div>

				<div className="form-item">
					<Controller
						render={({ onChange, name }) => (
							<Select
								name={name}
								options={ORGANIZATION_ACCOUNT}
								placeholder={t('pages:organizations:fieldAccountType')}
								isDisabled={isSubmitting}
								isRequired={true}
								isError={!!errors.profile}
								errors={errors.profile}
								defaultValue={ORGANIZATION_ACCOUNT[0]}
								onChange={option => option && onChange(option.value)}
								{...selectProps}
							/>
						)}
						name={'accountType'}
						control={control}
						rules={{
							required: true
						}}
					/>
				</div>

				<VersionSettings />

				<div className="form-group">
					<div className="form-group-title">
						<span>{t('pages:organizations:mainContactTitle')}</span>
					</div>
					<div className="form-item">
						<Input
							currentValue={watch('mainContact.name')}
							type={'text'}
							name={'mainContact.name'}
							placeholder={t('pages:organizations:fieldContactName')}
							isDisabled={isSubmitting}
							isError={!!errors.mainContact?.name}
							reference={register}
							errors={errors.mainContact?.name}
						/>
					</div>
					<div className="form-item">
						<Input
							currentValue={watch('mainContact.jobName')}
							type={'text'}
							name={'mainContact.jobName'}
							placeholder={t('pages:organizations:fieldJobName')}
							isDisabled={isSubmitting}
							isError={!!errors.mainContact?.jobName}
							reference={register}
						/>
					</div>
					<div className="form-row">
						<div className="form-col">
							<div className="form-item">
								<Input
									currentValue={watch('mainContact.phone')}
									type={'tel'}
									name={'mainContact.phone'}
									placeholder={t('pages:organizations:fieldPhone')}
									isDisabled={isSubmitting}
									isError={!!errors.mainContact?.phone}
									reference={register({
										maxLength: 255,
										pattern: REGEX_PHONE
									})}
									rules={{
										maxLength: 255
									}}
									errors={errors.mainContact?.phone}
								/>
							</div>
						</div>
						<div className="form-col">
							<div className="form-item">
								<Input
									currentValue={watch('mainContact.email')}
									type={'email'}
									name={'mainContact.email'}
									placeholder={t('pages:organizations:fieldEmail')}
									isDisabled={isSubmitting}
									isError={!!errors.mainContact?.email}
									reference={register({
										minLength: 6,
										maxLength: 255,
										pattern: REGEX_EMAIL
									})}
									rules={{
										minLength: 6,
										maxLength: 255
									}}
									errors={errors.mainContact?.email}
								/>
							</div>
						</div>
					</div>
				</div>

				{contacts.length > 0 &&
					contacts.map((item, idx: number) => {
						return (
							<div key={item.id} className="form-group">
								<div className="form-group-title">
									<span>{t('pages:organizations:contactTitle')}</span>

									<button type="button" onClick={() => remove(idx)}>
										{t('pages:organizations:actionRemove')}
									</button>
								</div>

								<div className="form-item">
									<Input
										currentValue={watch(`contacts[${idx}].name`)}
										type={'text'}
										name={`contacts[${idx}].name`}
										placeholder={t('pages:organizations:fieldContactName')}
										isDisabled={isSubmitting}
										isError={!!errors?.contacts?.[idx]?.name}
										isRequired={true}
										reference={register({
											required: true
										})}
										errors={errors?.contacts?.[idx]?.name}
									/>
								</div>

								<div className="form-item">
									<Input
										currentValue={watch(`contacts[${idx}].jobName`)}
										type={'text'}
										name={`contacts[${idx}].jobName`}
										placeholder={t('pages:organizations:fieldJobName')}
										isDisabled={isSubmitting}
										isError={!!errors?.contacts?.[idx]?.jobName}
										reference={register}
									/>
								</div>

								<div className="form-row">
									<div className="form-col">
										<div className="form-item">
											<Input
												currentValue={watch(`contacts[${idx}].phone`)}
												type={'tel'}
												name={`contacts[${idx}].phone`}
												placeholder={t('pages:organizations:fieldPhone')}
												isDisabled={isSubmitting}
												isError={!!errors?.contacts?.[idx]?.phone}
												reference={register({
													pattern: REGEX_PHONE
												})}
												errors={errors?.contacts?.[idx]?.phone}
											/>
										</div>
									</div>

									<div className="form-col">
										<div className="form-item">
											<Input
												currentValue={watch(`contacts[${idx}].email`)}
												type={'email'}
												name={`contacts[${idx}].email`}
												placeholder={t('pages:organizations:fieldEmail')}
												isDisabled={isSubmitting}
												isError={!!errors?.contacts?.[idx]?.email}
												reference={register({
													minLength: 6,
													maxLength: 255,
													pattern: REGEX_EMAIL
												})}
												rules={{
													minLength: 6,
													maxLength: 255
												}}
												errors={errors?.contacts?.[idx]?.email}
											/>
										</div>
									</div>
								</div>
							</div>
						)
					})}

				<div className="form-controls form-controls--justify form-controls--fit-buttons">
					<Button
						type={'secondary'}
						htmlType={'button'}
						className={'button--add-group'}
						onClick={addContactHandle}
						isDisabled={contacts.length >= MAX_ORGANIZATION_CONTACTS}
					>
						{t('pages:organizations:actionAdd')}
					</Button>

					<Button type={'secondary'} htmlType={'button'} onClick={cancelHandler} isDisabled={isSubmitting}>
						{t('common:actionCancel')}
					</Button>

					<Button type={'primary'} htmlType={'submit'} isDisabled={isSubmitting || !isDirty}>
						{t('common:actionSave')}
					</Button>
				</div>
			</form>
		</>
	)
}

const mapDispatch = {
	showModalConfirm: modalConfirmShowHandler,
	notificationAdd: notificationAddHandler,
	getCountries: getAllCountriesHandler
}

const connector = connect(null, mapDispatch)

export default connector(ModalAddOrganization)
