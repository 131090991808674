import React, { useEffect, useState } from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'
import Pagetitle from '../../../../components/UI/Pagetitle/Pagetitle'
import { connect, ConnectedProps } from 'react-redux'
import { getOrganizationByIdHandler } from '../../../../services/passport/organization'
import Error from '../../../Error/Error'
import Loading from '../../../../components/UI/Loading/Loading'
import { REGEX_V4 } from '../../../../helpers/helpers'
import { RootState } from '../../../../store/reducers/rootReducer'
import { useTranslation } from 'react-i18next'
import { TStatus } from '../../../../interfaces/helpers'
import PageMenu, { IPageMenuItem } from '../../../../components/PageMenu/PageMenu'

const Menu = ({ organization, getOrganization }: ConnectedProps<typeof connector>) => {
	const { t } = useTranslation()
	const { url } = useRouteMatch()
	const { organizationId } = useParams()
	const [status, setStatus] = useState<TStatus>('loading')
	const [menu] = useState<IPageMenuItem[]>([
		{
			title: 'pages:organizations:menuInfo',
			path: `${url}info`,
			icon: '/assets/img/organization/icon-1.svg'
		},
		{
			title: 'pages:organizations:menuFaculties',
			path: `${url}faculties`,
			icon: '/assets/img/organization/icon-2.svg'
		},
		{
			title: 'pages:organizations:menuOrders',
			path: `${url}license-package-requests`,
			icon: '/assets/img/organization/icon-3.svg'
		},
		{
			title: 'pages:organizations:menuLicences',
			path: `${url}license-packages`,
			icon: '/assets/img/organization/icon-4.svg'
		},
		{
			title: 'pages:organizations:menuUsers',
			path: `${url}users`,
			icon: '/assets/img/organization/icon-5.svg'
		},
		{
			title: 'pages:organizations:menuSettings',
			path: `${url}settings`,
			icon: '/assets/img/organization/icon-6.svg'
		},
		{
			title: 'pages:organizations:menuAdministrators',
			path: `${url}administrators`,
			icon: '/assets/img/organization/icon-7.svg'
		}
	])

	useEffect(() => {
		if (!organizationId.match(new RegExp(REGEX_V4))) {
			setStatus('error')
		} else {
			getOrganization(organizationId).then(response => {
				if (response?.status === 200) {
					setStatus('ready')
				} else {
					setStatus('error')
				}
			})
		}
	}, [getOrganization, organizationId])

	if (status === 'loading') return <Loading />
	if (status === 'error') return <Error />

	return (
		<>
			<Pagetitle title={organization?.name}>
				<p>{t('pages:organizations:menuText')}</p>
			</Pagetitle>

			<PageMenu menu={menu} />
		</>
	)
}

const mapState = (state: RootState) => ({
	organization: state.organization.organization
})

const mapDispatch = {
	getOrganization: getOrganizationByIdHandler
}

const connector = connect(mapState, mapDispatch)

export default connector(Menu)
