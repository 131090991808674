import React from 'react'
import Button from '../../../components/UI/Button/Button'
import { useTranslation } from 'react-i18next'
import { ISelfLicense } from '../../../interfaces/cabinet/license'
import { connect, ConnectedProps } from 'react-redux'
import { modalConfirmShowHandler } from '../../../store/actionCreators/UI/modalConfirm'
import dayjs from 'dayjs'
import { DATE_FORMAT, EMAIL_SUPPORT } from '../../../helpers/helpers'

type TProps = ConnectedProps<typeof connector> & {
	item: ISelfLicense
	hideModal: () => void
}

const ModalLicenseFutureDetails = ({ hideModal, item }: TProps) => {
	const { t } = useTranslation()

	return (
		<>
			<button className="modal-close" onClick={hideModal} />

			<h3 className="modal-title">{t('common:appName')}</h3>

			<p>
				<b>
					{t('pages:home:futureLicenseDate', {
						date: dayjs(item.dateFrom).format(DATE_FORMAT)
					})}
				</b>
			</p>
			<p>
				{t('pages:home:futureLicenseAuth', {
					productName: item.productName
				})}
			</p>
			<p
				dangerouslySetInnerHTML={{
					__html: t('pages:home:futureLicenseEmail', {
						email: EMAIL_SUPPORT
					})
				}}
			/>

			<div className="button-row button-row--right">
				<Button type={'primary'} htmlType={'button'} onClick={hideModal}>
					{t('common:actionClose')}
				</Button>
			</div>
		</>
	)
}

const mapDispatch = {
	showModalConfirm: modalConfirmShowHandler
}

const connector = connect(null, mapDispatch)

export default connector(ModalLicenseFutureDetails)
