import { SidebarActionTypes } from '../../../interfaces/UI/sidebar'
import { SIDEBAR_HIDE, SIDEBAR_SHOW } from '../../actions/UI/sidebar'

export function showSidebar(): SidebarActionTypes {
	return {
		type: SIDEBAR_SHOW
	}
}

export function hideSidebar(): SidebarActionTypes {
	return {
		type: SIDEBAR_HIDE
	}
}
