import React, { useEffect, useRef, useState } from 'react'
import Button from '../UI/Button/Button'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { IUploadUserData } from '../../interfaces/passport/user'
import DragAndDrop from '../UI/DragAndDrop/DragAndDrop'
import { useTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { ILicensePackage, ILicensePackageGroupList } from '../../interfaces/cabinet/licensePackage'
import dayjs from 'dayjs'
import { modalConfirmShowHandler } from '../../store/actionCreators/UI/modalConfirm'
import { selectProps } from '../UI/Select/helpers'
import Select from 'react-select'

interface IFormInputs {
	students: string
	licenseGroupId: string
	file: File[]
}

type TProps = ConnectedProps<typeof connector> & {
	hideModal: () => void
	onConfirm: (data: IUploadUserData) => Promise<any>
	licensePackages: Array<ILicensePackage>
}

const ModalUploadUsers = ({ hideModal, onConfirm, licensePackages, showModalConfirm }: TProps) => {
	const { t } = useTranslation()
	const methods = useForm<IFormInputs>({
		defaultValues: {
			students: '',
			licenseGroupId: ''
		}
	})
	const [isSubmitting, setIsSubmitting] = useState(false)

	const [licenseGroupsStudent, setLicenseGroupsStudent] = useState<ILicensePackageGroupList[]>([])
	const [licenseGroupsTeacher, setLicenseGroupsTeacher] = useState<ILicensePackageGroupList[]>([])

	const selectLicenseRef = useRef<any>(null)

	const cancelHandler = () => {
		if (methods.formState.isDirty) {
			showModalConfirm({
				type: 'cancel',
				onConfirm: hideModal
			})
		} else {
			hideModal()
		}
	}

	const onSubmit = async (data: IFormInputs) => {
		setIsSubmitting(true)

		try {
			await onConfirm({
				...data,
				students: !!+methods.watch('students')
			})
			hideModal()
		} finally {
			setIsSubmitting(false)
		}
	}

	useEffect(() => {
		const licenseGroupsData = licensePackages.reduce((acc: Array<ILicensePackageGroupList>, licensePackage) => {
			licensePackage.licenseGroupList.forEach(item => {
				acc.push(item)
			})

			return acc
		}, [])

		setLicenseGroupsStudent(
			licenseGroupsData
				.filter(item => item.type === 'student')
				.filter(item => {
					if (item.dateTo) {
						if (dayjs(item.dateTo).toDate() < dayjs().toDate()) {
							return false
						}
					}

					return true
				})
		)

		setLicenseGroupsTeacher(
			licenseGroupsData
				.filter(item => item.type === 'teacher' || item.type === 'student')
				.filter(item => {
					if (item.dateTo) {
						if (dayjs(item.dateTo).toDate() < dayjs().toDate()) {
							return false
						}
					}

					return true
				})
		)
	}, [licensePackages])

	return (
		<>
			<button className="modal-close" onClick={cancelHandler} disabled={isSubmitting} />

			<h3 className="modal-title">{t('common:appName')}</h3>
			<p className="modal-footnote">{t('pages:users:modalUploadText')}</p>

			<FormProvider {...methods}>
				<form className="form" encType="multipart/form-data" onSubmit={methods.handleSubmit(onSubmit)}>
					<div className="form-item">
						<Controller
							render={({ onChange, name }) => (
								<Select
									name={name}
									options={[
										{
											label: t('common:roleTeacher'),
											value: '0'
										},
										{
											label: t('common:roleStudent'),
											value: '1'
										}
									]}
									placeholder={t('pages:users:fieldStudents')}
									isDisabled={isSubmitting}
									isError={!!methods.errors.students}
									errors={methods.errors.students}
									onChange={option => {
										if (option) {
											onChange(option.value)
											selectLicenseRef.current?.select.clearValue()
											methods.setValue('licenseGroupId', '')
										}
									}}
									{...selectProps}
								/>
							)}
							name={'students'}
							control={methods.control}
							rules={{
								required: true
							}}
						/>
					</div>

					<div className="form-item">
						<Controller
							render={({ onChange, name }) => (
								<Select
									name={name}
									options={
										!!+methods.watch('students')
											? licenseGroupsStudent.map(item => {
													return {
														label: `${item.versionName}. ${item.productName} ${
															item.termless ? `(${t('pages:users:termless')})` : ''
														} (${item.count - item.generatedActivationCodesCount})`,
														value: item.id
													}
											  })
											: licenseGroupsTeacher.map(item => {
													return {
														label: `${item.versionName}. ${item.productName} ${
															item.termless ? `(${t('pages:users:termless')})` : ''
														} (${item.count - item.generatedActivationCodesCount})`,
														value: item.id
													}
											  })
									}
									placeholder={t('pages:users:fieldLicenseGroupId')}
									isDisabled={isSubmitting || !methods.watch('students')}
									isError={!!methods.errors.licenseGroupId}
									errors={methods.errors.licenseGroupId}
									onChange={option => option && onChange(option.value)}
									ref={selectLicenseRef}
									{...selectProps}
								/>
							)}
							name={'licenseGroupId'}
							control={methods.control}
							rules={{
								required: true
							}}
						/>
					</div>

					<DragAndDrop
						name={'file'}
						isDisabled={isSubmitting}
						isRequired={true}
						isError={!!methods.errors.file}
						errors={methods.errors.file}
						accept={
							'text/plain, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
						}
					/>

					<div className="form-controls form-controls--right">
						<Button type={'secondary'} htmlType={'button'} onClick={cancelHandler} isDisabled={isSubmitting}>
							{t('common:actionCancel')}
						</Button>

						<Button type={'primary'} htmlType={'submit'} isDisabled={isSubmitting}>
							{t('common:actionUpload')}
						</Button>
					</div>
				</form>
			</FormProvider>
		</>
	)
}

const mapDispatch = {
	showModalConfirm: modalConfirmShowHandler
}

const connector = connect(null, mapDispatch)

export default connector(ModalUploadUsers)
