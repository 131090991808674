import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { connect, ConnectedProps } from 'react-redux'
import Input from '../../../components/UI/Input/Input'
import { REGEX_EMAIL, REGEX_PASSWORD, REGEX_V4 } from '../../../helpers/helpers'
import Button from '../../../components/UI/Button/Button'
import { applyResetPasswordHandler } from '../../../services/passport/recovery'
import Error from '../../Error/Error'
import { TStatus } from '../../../interfaces/helpers'
import { useTranslation } from 'react-i18next'
import { toggleLoaderHandler } from '../../../store/actionCreators/UI/loader'
import Loading from '../../../components/UI/Loading/Loading'

interface IFormInputs {
	email: string
	newPassword: string
	repeatPassword: string
}

const Reset = ({ resetPassword, toggleLoader }: ConnectedProps<typeof connector>) => {
	const { t } = useTranslation()
	const [status, setStatus] = useState<TStatus>('loading')
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [isSuccess, setIsSuccess] = useState(false)
	const [resetCode, setResetCode] = useState('')
	const { code } = useParams()

	const { register, errors, handleSubmit, watch, getValues } = useForm<IFormInputs>()

	useEffect(() => {
		if (code.match(new RegExp(REGEX_V4))) {
			setStatus('ready')
			setResetCode(code)
		} else {
			setStatus('error')
		}
	}, [code])

	const onSubmit = async (data: IFormInputs) => {
		toggleLoader(true)
		setIsSubmitting(true)

		try {
			const response = await resetPassword({
				email: data.email,
				newPassword: data.newPassword,
				code: resetCode
			})

			if (response?.status === 200) {
				setIsSuccess(true)
			}
		} finally {
			setIsSubmitting(false)
			toggleLoader(false)
		}
	}

	if (status === 'error') return <Error />
	if (status === 'loading') return <Loading />

	return (
		<>
			<div className="login login--password">
				<h3 className="login-title">{t('common:appName')}</h3>
				<p className="login-footnote">{t('pages:reset:intro')}</p>

				{isSuccess && (
					<div className="login-message text-center">
						<p>
							<strong>{t('pages:reset:changed')}</strong>
						</p>

						<Link className="button button--primary" to={'/'}>
							{t('pages:reset:login')}
						</Link>
					</div>
				)}

				{!isSuccess && (
					<form className="form" onSubmit={handleSubmit(onSubmit)}>
						<div className="form-item">
							<Input
								type={'email'}
								name={'email'}
								currentValue={watch('email')}
								placeholder={t('pages:reset:fieldEmail')}
								isError={!!errors.email}
								isDisabled={isSubmitting}
								reference={register({
									required: true,
									minLength: 6,
									pattern: REGEX_EMAIL
								})}
								rules={{
									minLength: 6
								}}
								errors={errors.email}
							/>
						</div>

						<div className="form-item">
							<Input
								type={'password'}
								name={'newPassword'}
								currentValue={watch('newPassword')}
								placeholder={t('pages:reset:fieldNewPassword')}
								isError={!!errors.newPassword}
								isDisabled={isSubmitting}
								reference={register({
									required: true,
									minLength: 8,
									maxLength: 255,
									pattern: {
										value: REGEX_PASSWORD,
										message: 'fieldPatternPassword'
									}
								})}
								rules={{
									minLength: 8,
									maxLength: 255
								}}
								errors={errors.newPassword}
							/>
						</div>

						<div className="form-item">
							<Input
								type={'password'}
								name={'repeatPassword'}
								currentValue={watch('repeatPassword')}
								placeholder={t('pages:reset:fieldRepeatPassword')}
								isError={!!errors.repeatPassword}
								isDisabled={isSubmitting}
								reference={register({
									validate: {
										passwordMatches: value => value === getValues('newPassword')
									}
								})}
								errors={errors.repeatPassword}
							/>
						</div>

						<div className="form-controls form-controls--right">
							<Link className="button button--secondary" to={'/'}>
								{t('common:actionCancel')}
							</Link>

							<Button type={'primary'} htmlType={'submit'} isDisabled={isSubmitting}>
								{t('common:actionSave')}
							</Button>
						</div>
					</form>
				)}
			</div>
		</>
	)
}

const mapDispatch = {
	resetPassword: applyResetPasswordHandler,
	toggleLoader: toggleLoaderHandler
}

const connector = connect(null, mapDispatch)

export default connector(Reset)
