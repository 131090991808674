import React, { useCallback, useEffect } from 'react'
import InputError from '../InputError/InputError'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { useDropzone } from 'react-dropzone'
import { formatBytes, INPUT_FILE_LIMIT } from '../../../helpers/helpers'

interface IProps {
	name: string
	accept?: string
	isError?: boolean
	isRequired?: boolean
	isDisabled?: boolean
	reference?: any
	errors?: any
	placeholder?: string
	multiple?: boolean
	maxSize?: number
	minSize?: number
}

const InputFile = ({
	name,
	errors,
	isRequired,
	isError,
	isDisabled,
	placeholder,
	accept,
	multiple,
	maxSize,
	minSize
}: IProps) => {
	const { t } = useTranslation()

	const classNames = ['form-file']
	if (isError) classNames.push('form-file--error')
	if (isRequired) classNames.push('form-file--required')

	const { register, unregister, setValue, watch, getValues } = useFormContext()

	const files: File[] = watch(name)

	const onDrop = useCallback(
		droppedFiles => {
			const prevFiles = getValues(name) || []

			setValue(name, [...prevFiles, ...droppedFiles], {
				shouldValidate: true,
				shouldDirty: true
			})
		},
		[setValue, name, getValues]
	)

	const { getRootProps, getInputProps, fileRejections } = useDropzone({
		onDrop,
		accept,
		noDrag: true,
		disabled: isDisabled,
		multiple,
		minSize,
		maxSize: maxSize || INPUT_FILE_LIMIT
	})

	const onRemove = (idx: number) => {
		const list: File[] = getValues(name)

		setValue(
			name,
			list.filter((item, index) => index !== idx),
			{
				shouldValidate: true,
				shouldDirty: true
			}
		)
	}

	useEffect(() => {
		register(name, {
			required: isRequired
		})
		return () => {
			unregister(name)
		}
	}, [register, unregister, name, isRequired])

	return (
		<>
			<div className={classNames.join(' ')}>
				<div {...getRootProps({ className: 'field' })}>
					<input name={name} {...getInputProps()} />
					<div className="field-input" />
					<span className="field-label">{placeholder || t('common:InputFilePlaceholder')}</span>
					<div className="button button--secondary">{t('common:InputFileButton')}</div>
				</div>

				{!!files?.length && (
					<ul className="list">
						{files.map((file, idx) => {
							return (
								<li className="list-item" key={idx}>
									<button className="list-item-remove" type="button" onClick={() => onRemove(idx)} />
									<span>{file.name}</span>
								</li>
							)
						})}
					</ul>
				)}

				{!!fileRejections.length && (
					<ul className="list" style={{ color: '#b64758' }}>
						{fileRejections.map((file, idx) => {
							return (
								<li key={idx}>
									{t(`errors:file_${file.errors[0].code}`, {
										name: file.file.name,
										size: formatBytes(maxSize || INPUT_FILE_LIMIT),
										accept
									})}
								</li>
							)
						})}
					</ul>
				)}
			</div>

			{isError && <InputError errors={errors} />}
		</>
	)
}

export default InputFile
