import React, { forwardRef, useImperativeHandle, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import dayjs from 'dayjs'
import Pagetitle from '../../../components/UI/Pagetitle/Pagetitle'
import { licensePackageGetAllHandler } from '../../../services/cabinet/licensePackage'
import { IPagination, TDatePickerDate, TSortColumnsTable, TStatus } from '../../../interfaces/helpers'
import { DATE_FORMAT, DATE_FORMAT_API_SHORT, DEFAULT_PAGE_SIZE, INFINITE_NUMBER } from '../../../helpers/helpers'
import {
    ILicensePackageAPIRequest,
    ILicensePackageAPIResponse,
    TLicensePackageSortFields
} from '../../../interfaces/cabinet/licensePackage'
import Pagination from '../../../components/UI/Pagination/Pagination'
import SelectLimit from '../../../components/UI/Select/SelectLimit'
import InputDatePicker from '../../../components/UI/InputDatePicker/InputDatePicker'
import NoResults from '../../../components/UI/NoResults/NoResults'
import Loading from '../../../components/UI/Loading/Loading'
import { TOnChange } from '../../../components/UI/Select/helpers'
import Error from '../../Error/Error'
import Button from '../../../components/UI/Button/Button'

interface ILicensePackagesProps {
    accountId: string
    crumbs?: Array<{
        to: string
        title: string
        onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
    }>
    limitedLink?: string
    termlessLink?: string
    hardwareLink?: string
    onAddClick?: () => Promise<void>
    onChangeClick?: (id: string) => Promise<void>
}

const LicensePackagesList = forwardRef<unknown, ILicensePackagesProps & ConnectedProps<typeof connector>>(
    ({ accountId, crumbs, limitedLink, termlessLink, hardwareLink, onAddClick, onChangeClick, getLicensePackages }: ILicensePackagesProps & ConnectedProps<typeof connector>,
        ref
    ) => {
        useImperativeHandle(ref, () => ({
            reset() {
                if (pagination.pageNo === 0) {
                    setFilterLicensePackages({
                        ...filterLicensePackages,
                        pageSize: INFINITE_NUMBER,
                        pageNo: 0
                    })
                }
                paginationHandle(0)
            }
        }))
        const { t } = useTranslation()
        const { pathname } = useLocation<{ pathname: string }>()
        const [status, setStatus] = useState<TStatus>('loading')
        const [dateFilter, setDateFilter] = useState<TDatePickerDate>(null)

        const [table, setTable] = useState<ILicensePackageAPIResponse[]>([])
        const [pagination, setPagination] = useState<IPagination>({
            pageNo: 0,
            totalPages: 0,
            totalCount: 0
        })

        const [filterLicensePackages, setFilterLicensePackages] = useState<Omit<ILicensePackageAPIRequest, 'accountId'>>({
            hardwareKeyCodeFilter: '',
            dateFilter: '',
            sortField: 'Date',
            desc: true,
            pageSize: DEFAULT_PAGE_SIZE,
            pageNo: 0
        })

        const [sortColumns, setSortColumns] = useState<TSortColumnsTable>({
            Organization: {
                desc: true,
                classNames: ['table-sort']
            },
            Date: {
                desc: true,
                classNames: ['table-sort', 'table-sort--bottom']
            }
        })

        const paginationHandle = (pageNo: number) => {
            setFilterLicensePackages({
                ...filterLicensePackages,
                pageNo
            })
        }

        const limitHandle: TOnChange = option => {
            if (option) {
                setFilterLicensePackages({
                    ...filterLicensePackages,
                    pageSize: parseInt(option.value) || INFINITE_NUMBER,
                    pageNo: 0
                })
            }
        }

        const dateHandler = (date: TDatePickerDate) => {
            setDateFilter(date)

            setFilterLicensePackages({
                ...filterLicensePackages,
                dateFilter: (date && dayjs(date).format(DATE_FORMAT_API_SHORT)) || ''
            })
        }

        const sortHandle = (sortField: TLicensePackageSortFields) => {
            const updatedSortColumns = { ...sortColumns }

            Object.keys(updatedSortColumns).forEach((key: string) => {
                if (key !== sortField) {
                    updatedSortColumns[key] = {
                        desc: false,
                        classNames: ['table-sort']
                    }
                } else {
                    updatedSortColumns[key].desc = !updatedSortColumns[key].desc
                    updatedSortColumns[key].classNames = [
                        'table-sort',
                        updatedSortColumns[key].desc ? 'table-sort--bottom' : 'table-sort--top'
                    ]
                }
            })

            setFilterLicensePackages({
                ...filterLicensePackages,
                sortField,
                desc: updatedSortColumns[sortField].desc
            })

            setSortColumns(updatedSortColumns)
        }

        useEffect(() => {
            if (!accountId) {
                setStatus('error')
                return
            }
            getLicensePackages({ ...filterLicensePackages, accountId }).then(response => {
                if (response?.status === 200) {
                    const { data, pageNo, totalPages, totalCount } = response.data

                    setTable(data)

                    setPagination({
                        pageNo,
                        totalPages,
                        totalCount
                    })

                    setStatus('ready')
                }
            })
        }, [getLicensePackages, filterLicensePackages, accountId])

        if (status === 'loading') return <Loading />
        if (status === 'error') return <Error />

        return (
            <>
                <Pagetitle title={t('pages:licensePackages:title')} crumbs={crumbs} />

                <div className="filters">
                    <div className="filters-item filters-item--calendar-once">
                        <InputDatePicker
                            onChange={dateHandler}
                            placeholderText={t('pages:licensePackages:dateFilter')}
                            name={'dateFilter'}
                            selected={dateFilter}
                        />
                    </div>

                    <div className="filters-item filters-item--view">
                        <SelectLimit total={pagination.totalCount} onChange={limitHandle} />
                    </div>

                    {!!onAddClick && <div className="filters-item filters-item--buttons">
                        <div className="button-row">
                            <Button type={'primary'} htmlType={'button'} onClick={onAddClick}>
                                {t('pages:licensePackages:addNew')}
                            </Button>
                        </div>
                    </div>}

                </div>

                {table.length > 0 ? (
                    <div className="pagination-wrapper">
                        <div className="table">
                            <table>
                                <colgroup>
                                    <col width="16.5%" />
                                    <col width="25.75%" />
                                    <col width="19.25%" />
                                    <col width="19.25%" />
                                    <col width="19.25%" />
                                </colgroup>

                                <thead>
                                    <tr>
                                        <th onClick={() => sortHandle('Date')}>
                                            <div className={sortColumns.Date.classNames.join(' ')}>
                                                {t('pages:licensePackages:tableHeadingDate')}
                                            </div>
                                        </th>
                                        <th>
                                            <div>{t('pages:licensePackages:tableHeadingLicense')}</div>
                                        </th>
                                        <th>
                                            <div>{t('pages:licensePackages:tableHeadingTotal')}</div>
                                        </th>
                                        <th>
                                            <div>{t('pages:licensePackages:tableHeadingAvailable')}</div>
                                        </th>
                                        <th>
                                            <div>{t('pages:licensePackages:tableHeadingUnused')}</div>
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {table.map(item => {
                                        return (
                                            <tr key={item.id}>
                                                <td>
                                                    {!onChangeClick && dayjs(item.createdDate).format(DATE_FORMAT)}
                                                    {!!onChangeClick && <button className="button-link" type="button" onClick={() => onChangeClick(item.id)}>
                                                        {item.contractDate
                                                            ? dayjs(item.contractDate).format(DATE_FORMAT)
                                                            : dayjs(item.createdDate).format(DATE_FORMAT)}
                                                    </button>}
                                                </td>

                                                <td>
                                                    <ul className="list list--nowrap">
                                                        <li className="list-item">
                                                            {item.total?.termlessCount > 0 ? (
                                                                <Link to={termlessLink ? termlessLink.replace("{id}", item.id) : `${pathname}/${item.id}/termless`}>{t('pages:licensePackages:termless')}</Link>
                                                            ) : (
                                                                t('pages:licensePackages:termless')
                                                            )}
                                                        </li>

                                                        <li className="list-item">
                                                            {item.total?.urgentCount > 0 ? (
                                                                <Link to={limitedLink ? limitedLink.replace("{id}", item.id) : `${pathname}/${item.id}/urgent`}>{t('pages:licensePackages:urgent')}</Link>
                                                            ) : (
                                                                t('pages:licensePackages:urgent')
                                                            )}
                                                        </li>

                                                        <li className="list-item">
                                                            {item.total?.hardwareCount > 0 ? (
                                                                <Link to={hardwareLink ? hardwareLink.replace("{id}", item.id) : `${pathname}/${item.id}/hardware`}>{t('pages:licensePackages:hardware')}</Link>
                                                            ) : (
                                                                t('pages:licensePackages:hardware')
                                                            )}
                                                        </li>
                                                    </ul>
                                                </td>

                                                <td>
                                                    <ul className="list list--nowrap">
                                                        <li className="list-item">{item.total?.termlessCount || 0}</li>
                                                        <li className="list-item">{item.total?.urgentCount || 0}</li>
                                                        <li className="list-item">{item.total?.hardwareCount || 0}</li>
                                                    </ul>
                                                </td>

                                                <td>
                                                    <ul className="list list--nowrap">
                                                        <li className="list-item">{item.available?.termlessCount || 0}</li>
                                                        <li className="list-item">{item.available?.urgentCount || 0}</li>
                                                        <li className="list-item">{item.available?.hardwareCount || 0}</li>
                                                    </ul>
                                                </td>

                                                <td>
                                                    <ul className="list list--nowrap">
                                                        <li className="list-item">{item.unused?.termlessCount || 0}</li>
                                                        <li className="list-item">{item.unused?.urgentCount || 0}</li>
                                                        <li className="list-item">{item.unused?.hardwareCount || 0}</li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>

                        <Pagination onClick={paginationHandle} {...pagination} />
                    </div>
                ) : (
                    <NoResults />
                )}
            </>
        )
    }
)

const mapDispatch = {
    getLicensePackages: licensePackageGetAllHandler
}

const connector = connect(null, mapDispatch, null, { forwardRef: true })

export default connector(LicensePackagesList)
