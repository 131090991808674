import React, { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { getAllOrganizationsHandler } from '../../../services/passport/organization'
import { INFINITE_NUMBER } from '../../../helpers/helpers'
import Loading from '../../../components/UI/Loading/Loading'
import { IOrganization, IOrganizationAPIRequest } from '../../../interfaces/passport/organizations'
import { TStatus } from '../../../interfaces/helpers'
import Error from '../../Error/Error'
import SystemUsersList from '../../Common/Users/SystemUsers'

const SystemUsers = ({ getOrganizations }: ConnectedProps<typeof connector>) => {
	const [status, setStatus] = useState<TStatus>('loading')
	const [organizations, setOrganizations] = useState<IOrganization[]>([])

	const [filterOrganizations] = useState<IOrganizationAPIRequest>({
		desc: false,
		pageSize: INFINITE_NUMBER,
		pageNo: 0
	})

	useEffect(() => {
		getOrganizations(filterOrganizations).then(response => {
			if (response?.status === 200) {
				setOrganizations(response.data.data)
				setStatus('ready')
				return
			}
			setStatus('error')
		})
	}, [getOrganizations, filterOrganizations])

	if (status === 'error') return <Error />
	if (status === 'loading') return <Loading />

	return <SystemUsersList organizations={organizations} />
}

const mapDispatch = {
	getOrganizations: getAllOrganizationsHandler
}

const connector = connect(null, mapDispatch)

export default connector(SystemUsers)
