import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { ILicenseUnlinkResponse } from '../../interfaces/license/license'
import Button from '../UI/Button/Button'
import { declensionString } from '../../helpers/helpers'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

dayjs.extend(duration)

type TProps = ConnectedProps<typeof connector> & {
	details: ILicenseUnlinkResponse
	hideModal: () => void
}

const ModalLicenseUnlink = ({ hideModal, details }: TProps) => {
	const { t } = useTranslation()

	const nextAttemptDate = useMemo(() => {
		if (!details.nextAttemptDate) {
			return null
		}

		const diff = dayjs(details.nextAttemptDate).diff(dayjs())
		const duration = dayjs.duration(diff)

		return {
			hours: Math.floor(duration.asHours()),
			minutes: duration.minutes()
		}
	}, [details.nextAttemptDate])

	return (
		<>
			<button className="modal-close" onClick={hideModal} />

			<h3 className="modal-title">{t('common:appName')}</h3>

			{details.result === 'SUCCESS' && (
				<p
					dangerouslySetInnerHTML={{
						__html: t('common:modalLicenseUnlink_SUCCESS', {
							timesCount: `${details.deleteAttemptsLeft}&nbsp;${declensionString(
								t('common:times_1'),
								t('common:times_5'),
								t('common:times_2'),
								details?.deleteAttemptsLeft || 0
							)}`
						})
					}}
				/>
			)}

			{details.result === 'NO_ATTEMPTS_LEFT' && (
				<p
					dangerouslySetInnerHTML={{
						__html: t('common:modalLicenseUnlink_NO_ATTEMPTS_LEFT', {
							timeCountdown: `${nextAttemptDate?.hours}&nbsp;${declensionString(
								t('common:hours_1'),
								t('common:hours_5'),
								t('common:hours_2'),
								nextAttemptDate?.hours || 0
							)} ${nextAttemptDate?.minutes}&nbsp;${declensionString(
								t('common:minutes_1'),
								t('common:minutes_5'),
								t('common:minutes_2'),
								nextAttemptDate?.minutes || 0
							)}`
						})
					}}
				/>
			)}

			<div className="button-row button-row--right">
				<Button type={'primary'} htmlType={'button'} onClick={hideModal}>
					{t('common:actionClose')}
				</Button>
			</div>
		</>
	)
}

const connector = connect(null, {})

export default connector(ModalLicenseUnlink)
