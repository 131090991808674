import { AppThunk, IPagination } from '../../interfaces/helpers'
import axios from 'axios'
import { API_SUPPORT_PATH, APIServices } from '../../helpers/api'
import { setErrorHandler } from '../../store/actionCreators/error'
import {
	ISupportRequestUser,
	ISupportRequestUserAPIRequest,
	ISupportRequestUserAPIResponse,
	ISupportRequestUserCreate,
	ISupportRequestUserListFilteredByStatusAndNumOrTopicAPIRequest,
	ISupportRequestUserUpdate
} from '../../interfaces/support/supportRequestUser'
import { serviceHandlerErrorOptions } from '../helpers'
import {
	ISupportAttach,
	ISupportAttachCreate,
	ISupportComment,
	ISupportCommentCreate,
	ISupportCommentUpdate
} from '../../interfaces/support/common'

type APIResponseUserSupportList = IPagination & {
	data: ISupportRequestUserAPIResponse[]
	userHasRequests: boolean
}

export function supportRequestUserGetAllHandler(
	params: ISupportRequestUserAPIRequest,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<APIResponseUserSupportList> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<APIResponseUserSupportList>({
				method: 'GET',
				url: `${API_SUPPORT_PATH}/supportRequestUser/listFiltered`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params
			})
			return response
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.SUPPORT, errorOptions))
			return error.response
		}
	}
}

export function supportRequestUserListFilteredByStatusAndNumOrTopicGetAllHandler(
	params: ISupportRequestUserListFilteredByStatusAndNumOrTopicAPIRequest,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<APIResponseUserSupportList> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<APIResponseUserSupportList>({
				method: 'GET',
				url: `${API_SUPPORT_PATH}/supportRequestUser/listFilteredByStatusAndNumOrTopic`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params
			})
			return response
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.SUPPORT, errorOptions))
			return error.response
		}
	}
}

export function createSupportRequestUserHandler(
	data: ISupportRequestUserCreate,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<ISupportRequestUser> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<ISupportRequestUser>({
				method: 'POST',
				url: `${API_SUPPORT_PATH}/supportRequestUser`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				data
			})
			return response
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.SUPPORT, errorOptions))
			return error.response
		}
	}
}

export function updateSupportRequestUserByIdHandler(
	topicId: string,
	data: ISupportRequestUserUpdate,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<ISupportRequestUser> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<ISupportRequestUser>({
				method: 'PUT',
				url: `${API_SUPPORT_PATH}/supportRequestUser/${topicId}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				data
			})
			return response
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.SUPPORT, errorOptions))
			return error.response
		}
	}
}

export function deleteSupportRequestUserByIdHandler(
	topicId: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request({
				method: 'DELETE',
				url: `${API_SUPPORT_PATH}/supportRequestUser/${topicId}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				}
			})
			return response
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.SUPPORT, errorOptions))
			return error.response
		}
	}
}

export function getSupportRequestUserByIdHandler(
	id: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<ISupportRequestUser> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<ISupportRequestUser>({
				method: 'GET',
				url: `${API_SUPPORT_PATH}/supportRequestUser/${id}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id
				}
			})
			return response
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.SUPPORT, errorOptions))
			return error.response
		}
	}
}

export function createSupportRequestUserCommentHandler(
	ticketId: string,
	data: ISupportCommentCreate,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<ISupportComment> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<ISupportComment>({
				method: 'POST',
				url: `${API_SUPPORT_PATH}/supportRequestUser/${ticketId}/comment`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id: ticketId
				},
				data
			})
			return response
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.SUPPORT, errorOptions))
			return error.response
		}
	}
}

export function updateSupportRequestUserCommentByIdHandler(
	ticketId: string,
	commentId: string,
	data: ISupportCommentUpdate,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<ISupportComment> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<ISupportComment>({
				method: 'PUT',
				url: `${API_SUPPORT_PATH}/supportRequestUser/${ticketId}/comment/${commentId}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id: ticketId,
					commentId
				},
				data
			})
			return response
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.SUPPORT, errorOptions))
			return error.response
		}
	}
}

export function deleteSupportRequestUserCommentByIdHandler(
	ticketId: string,
	commentId: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request({
				method: 'DELETE',
				url: `${API_SUPPORT_PATH}/supportRequestUser/${ticketId}/comment/${commentId}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id: ticketId,
					commentId
				}
			})
			return response
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.SUPPORT, errorOptions))
			return error.response
		}
	}
}

export function createSupportRequestUserAttachHandler(
	ticketId: string,
	data: ISupportAttachCreate,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<ISupportAttach> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<ISupportAttach>({
				method: 'POST',
				url: `${API_SUPPORT_PATH}/supportRequestUser/${ticketId}/attach`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id: ticketId
				},
				data
			})
			return response
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.SUPPORT, errorOptions))
			return error.response
		}
	}
}

export function getSupportRequestUserAttachByIdHandler(
	id: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<Blob> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<Blob>({
				method: 'GET',
				responseType: 'blob',
				url: `${API_SUPPORT_PATH}/supportRequestUser/attach/${id}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id
				}
			})
			return response
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.SUPPORT, errorOptions))
			return error.response
		}
	}
}
