import React, { useEffect, useState } from 'react'
import { DATE_FORMAT, formatString, formatUsername } from '../../helpers/helpers'
import dayjs from 'dayjs'
import { ISupportComment } from '../../interfaces/support/common'
import { IUser } from '../../interfaces/passport/user'
import { useTranslation } from 'react-i18next'

type TProps = {
	comments: Array<ISupportComment>
	user: IUser
	onUpdate: (comment: ISupportComment) => void
	onRemove: (comment: ISupportComment) => void
}

interface ISupportCommentCustom extends ISupportComment {
	editable: boolean
}

const Comments = ({ comments, user, onUpdate, onRemove }: TProps) => {
	const { t } = useTranslation()

	const [commentsCustom, setCommentsCustom] = useState<ISupportCommentCustom[]>([])

	useEffect(() => {
		let lockIndex

		const commentsArray: ISupportCommentCustom[] = comments.map(item => {
			return {
				...item,
				editable: true
			}
		})

		for (let i = commentsArray.length - 1; i >= 0; i--) {
			if (commentsArray[i].ownerId !== user.id) {
				lockIndex = i
				break
			}
		}

		if (lockIndex !== undefined) {
			for (let i = lockIndex; i >= 0; i--) {
				commentsArray[i].editable = false
			}
		}

		setCommentsCustom(commentsArray)
	}, [comments, user.id])

	return (
		<>
			{commentsCustom.length > 0 && (
				<>
					<div className="support-comments-title">{t('pages:support:commentTitle')}</div>

					<div className="support-comments-list">
						{commentsCustom.map((item, idx) => {
							return (
								<div key={item.id} className="support-comments-item">
									<div className="support-comments-userpic">
										<div className="support-comments-userpic-image">
											<img src="/img/user/user.jpg" alt="" />
										</div>
									</div>

									<div className="support-comments-message">
										<div className="support-comments-heading">
											<div className="support-comments-name">
												{formatUsername({
													firstName: item.ownerFirstName,
													lastName: item.ownerLastName,
													patronymic: item.ownerPatronymic
												})}
											</div>
											<div className="support-comments-date">
												{t('pages:support:commentInfo', {
													date: dayjs(item.fromDate).format(DATE_FORMAT),
													time: dayjs(item.fromDate).format('HH:mm')
												})}
											</div>
										</div>

										<p dangerouslySetInnerHTML={{ __html: formatString(item.message) }} />
									</div>

									{item.editable && (
										<>
											<button
												className="support-comments-control support-comments-control--edit"
												type="button"
												onClick={() => onUpdate(item)}
											>
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
													<path d="M0 12.667v3.337h3.333l9.83-9.83L9.83 2.838zm15.74-9.074a.885.885 0 000-1.253L13.66.26a.885.885 0 00-1.253 0l-1.626 1.627 3.333 3.333z" />
												</svg>
											</button>

											<button
												className="support-comments-control support-comments-control--delete"
												type="button"
												onClick={() => onRemove(item)}
											>
												<svg xmlns="http://www.w3.org/2000/svg" width="12.444" height="16">
													<path d="M.889 14.222A1.783 1.783 0 002.667 16h7.111a1.783 1.783 0 001.778-1.778V3.556H.889zM12.444.889H9.333L8.444 0H4l-.889.889H0v1.778h12.444z" />
												</svg>
											</button>
										</>
									)}
								</div>
							)
						})}
					</div>
				</>
			)}
		</>
	)
}

export default Comments
