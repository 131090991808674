import React from 'react'
import { ITenantCustom } from '../ProductUpdates'

interface IProps {
	title: string
	list: Array<ITenantCustom>
	onChange: (item: ITenantCustom, selected: boolean) => void
	name: string
}

const MultiselectTenants = ({ title, list, onChange, name }: IProps) => {
	return (
		<div className="multiselect multiselect--short">
			<div className="multiselect-label">
				<span>{title}</span>
			</div>
			<ul className="list">
				{list.map((item, idx) => {
					return (
						<li className="list-item" key={item.id}>
							<label>
								<input
									type="checkbox"
									name={`${name}-${idx}`}
									onChange={event => onChange(item, event.target.checked)}
									checked={item.selected}
									disabled={item.disabled}
								/>
								<span>{item.name}</span>
							</label>
						</li>
					)
				})}
			</ul>
		</div>
	)
}

export default MultiselectTenants
