import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import resources from './locales/resources'

// DatePicker
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import en from 'date-fns/locale/en-US'
import ru from 'date-fns/locale/ru'

registerLocale('ru', ru)
registerLocale('en', en)

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources: resources,
		load: 'languageOnly',
		fallbackLng: 'en',
		debug: false,
		ns: ['common'],
		defaultNS: 'common',
		keySeparator: '.',
		interpolation: {
			escapeValue: false
		},
		react: {
			// wait: true,
			useSuspense: true
			// nsMode: 'default'
			// bindI18n: 'languageChanged loaded'
		}
	})
	.then(() => {
		setDefaultLocale(i18n.languages[0])
		// setDefaultLocale('ru')
	})

export default i18n
