import { MODAL_ERROR_HIDE, MODAL_ERROR_SHOW } from '../../actions/UI/modalError'
import { IModalErrorProps } from '../../../interfaces/UI/modalError'
import { ModalErrorActionTypes } from '../../../interfaces/UI/actionCreators/modalError'
import { ThunkAction } from 'redux-thunk'
import { RootState } from '../../reducers/rootReducer'
import { Action } from 'redux'

export function modalErrorShow(data: IModalErrorProps): ModalErrorActionTypes {
	return {
		type: MODAL_ERROR_SHOW,
		payload: data
	}
}

export function modalErrorHide(): ModalErrorActionTypes {
	return {
		type: MODAL_ERROR_HIDE
	}
}

export function modalErrorShowHandler(data: IModalErrorProps): ThunkAction<void, RootState, unknown, Action<string>> {
	return dispatch => dispatch(modalErrorShow(data))
}

export function modalErrorHideHandler(): ThunkAction<void, RootState, unknown, Action<string>> {
	return dispatch => dispatch(modalErrorHide())
}
