import React, { FC, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { modalConfirmShowHandler } from '../../../store/actionCreators/UI/modalConfirm'
import Button from '../../../components/UI/Button/Button'
import Input from '../../../components/UI/Input/Input'
import { IOrgSetting, IOrgSettingUpdate } from '../../../interfaces/cabinet/orgSettings'

type TProps = ConnectedProps<typeof connector> & {
	item: IOrgSetting
	hideModal: () => void
	onConfirm: (data: IOrgSettingUpdate) => Promise<any>
	onRemove: (id: string) => Promise<any>
}

interface IFormFields {
	value: string
}

const ModalEditSetting: FC<TProps> = ({ hideModal, showModalConfirm, onConfirm, item }) => {
	const { t } = useTranslation()
	const { register, handleSubmit, formState, watch, errors } = useForm<IFormFields>({
		defaultValues: {
			value: item.value
		}
	})
	const { isDirty } = formState
	const [isSubmitting, setIsSubmitting] = useState(false)

	const cancelHandler = () => {
		if (isDirty) {
			showModalConfirm({
				type: 'cancel',
				onConfirm: hideModal
			})
		} else {
			hideModal()
		}
	}

	const onSubmit = async (data: IFormFields) => {
		setIsSubmitting(true)

		try {
			const response = await onConfirm({
				...item,
				...data
			})
			if (response?.status === 200) {
				hideModal()
			}
		} finally {
			setIsSubmitting(false)
		}
	}

	return (
		<>
			<button className="modal-close" onClick={cancelHandler} disabled={isSubmitting} />

			<h3 className="modal-title">{t('common:appName')}</h3>

			<p className="modal-footnote">
				{t('pages:orgSettings:modalEditText')} <strong>{item.key}</strong>
			</p>

			<form className="form" onSubmit={handleSubmit(onSubmit)}>
				<div className="form-item">
					<div className="form-info-label">{t('pages:orgSettings:fieldKey')}</div>
					<div className="form-info-value">{item.key}</div>
				</div>

				<div className="form-item">
					<Input
						currentValue={watch('value')}
						type={'text'}
						name={'value'}
						placeholder={t('pages:orgSettings:fieldValue')}
						isDisabled={isSubmitting}
						isError={!!errors.value}
						isRequired={true}
						reference={register({
							required: true
						})}
						errors={errors.value}
					/>
				</div>

				<div className="form-item">
					<div className="form-info-label">{t('pages:orgSettings:fieldDescription')}</div>
					<div className="form-info-value">{item?.description || '-'}</div>
				</div>

				<div className="form-controls form-controls--justify">
					<Button type={'secondary'} htmlType={'button'} onClick={cancelHandler} isDisabled={isSubmitting}>
						{t('common:actionCancel')}
					</Button>

					<Button type={'primary'} htmlType={'submit'} isDisabled={isSubmitting || !isDirty}>
						{t('common:actionSave')}
					</Button>
				</div>
			</form>
		</>
	)
}

const mapDispatch = {
	showModalConfirm: modalConfirmShowHandler
}

const connector = connect(null, mapDispatch)

export default connector(ModalEditSetting)
