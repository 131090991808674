import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_SET_AUTHORIZED } from '../../actions/passport/auth'
import { ITokens } from '../../../interfaces/passport/auth'
import { AuthActionTypes } from '../../../interfaces/passport/actionCreators/auth'

interface IInitialState {
	tokens: ITokens
	isAuthorized: boolean
}

const initialState: IInitialState = {
	tokens: {
		access_token: '',
		expires_in: 0,
		'not-before-policy': 0,
		refresh_expires_in: 0,
		refresh_token: '',
		scope: '',
		session_state: '',
		token_type: ''
	},
	isAuthorized: false
}

export default function AuthReducer(state = initialState, action: AuthActionTypes): IInitialState {
	switch (action.type) {
		case AUTH_LOGIN:
			return {
				...state,
				tokens: action.payload
			}

		case AUTH_LOGOUT:
			return {
				...state,
				tokens: initialState.tokens
			}

		case AUTH_SET_AUTHORIZED:
			return {
				...state,
				isAuthorized: action.payload
			}

		default:
			return state
	}
}
