import React, { useState } from 'react'
import Button from '../../../components/UI/Button/Button'
import { IUser, IUserCreate } from '../../../interfaces/passport/user'
import { useTranslation } from 'react-i18next'

type TProps = {
	user: IUser
	hideModal: () => void
	onConfirm: (data: IUserCreate) => Promise<any>
}

interface IFormInputs {
	currentPassword: string
	email: string
}

function ModalUserExists({ user, hideModal, onConfirm }: TProps) {
	const { t } = useTranslation()
	const [isSubmitting, setIsSubmitting] = useState(false)

	const onSubmit = async () => {
		setIsSubmitting(true)

		try {
			await onConfirm({
				role: user.role,
				firstName: user.firstName,
				lastName: user.lastName,
				patronymic: user.patronymic,
				email: user.email,
				phone: user.phone
			})
			hideModal()
		} finally {
			setIsSubmitting(false)
		}
	}

	return (
		<>
			<button className="modal-close" onClick={hideModal} disabled={isSubmitting} />

			<h3 className="login-title">{t('common:appName')}</h3>

			<p>{t('pages:users:modalUserExistsInOtherAccount')}</p>

			<div className="form">
				<div className="form-row">
					<div className="form-col">
						<b>{t('pages:users:fieldEmail')}:</b>
					</div>
					<div className="form-col">{user.email}</div>
				</div>
				<div className="form-row">
					<div className="form-col">
						<b>{t('pages:users:fieldLastName')}:</b>
					</div>
					<div className="form-col">{user.lastName}</div>
				</div>
				<div className="form-row">
					<div className="form-col">
						<b>{t('pages:users:fieldFirstName')}:</b>
					</div>
					<div className="form-col">{user.firstName}</div>
				</div>
				<div className="form-row">
					<div className="form-col">
						<b>{t('pages:users:fieldPatronymic')}:</b>
					</div>
					<div className="form-col">{user.patronymic}</div>
				</div>
			</div>
			<div className="button-row button-row--right">
				<Button type={'secondary'} htmlType={'button'} onClick={hideModal} isDisabled={isSubmitting}>
					{t('common:actionCancel')}
				</Button>

				<Button type={'primary'} htmlType={'submit'} isDisabled={isSubmitting} onClick={onSubmit}>
					{t('common:actionAdd')}
				</Button>
			</div>
		</>
	)
}

export default ModalUserExists
