import React from 'react'
import { useTranslation } from 'react-i18next'
import Select, { NamedProps } from 'react-select'
import { DEFAULT_PAGE_SIZE, INFINITE_NUMBER } from '../../../helpers/helpers'
import { OptionType, selectProps } from './helpers'

interface IProps extends NamedProps {
	total: number
	placeholder?: string
	defaultValue?: OptionType
}

const SelectLimit = (props: IProps) => {
	const { t } = useTranslation()

	const options: OptionType[] = [
		{
			label: t('common:SelectLimitDefaultValue'),
			value: `${INFINITE_NUMBER}`
		},
		{
			label: '10',
			value: '10'
		},
		{
			label: '25',
			value: '25'
		},
		{
			label: '100',
			value: '100'
		}
	]

	const defaultValue = props.defaultValue || options.find(item => item.value === `${DEFAULT_PAGE_SIZE}`) || options[1]

	return (
		<Select
			{...props}
			name={'limit'}
			options={options}
			defaultValue={defaultValue}
			placeholder={props.placeholder || ''}
			{...selectProps}
		/>
	)
}

export default SelectLimit
