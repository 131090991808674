import React from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { selectProps, TOnChange } from './helpers'

const SelectLanguage = () => {
	const { t, i18n } = useTranslation()

	const changeHandler: TOnChange = option => {
		if (option) i18n.changeLanguage('' + option.value)
	}

	const options = [
		{
			value: 'en',
			label: 'English'
		},
		{
			value: 'ru',
			label: 'Русский'
		},

	]

	return (
		<Select
			name={'language'}
			placeholder={t('common:SelectLanguagePlaceholder')}
			options={options}
			onChange={changeHandler}
			defaultValue={options.find(item => item.value === i18n.languages[0]) || options[0]}
			{...selectProps}
		/>
	)
}

export default SelectLanguage
