import React, { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Button from '../../../components/UI/Button/Button'
import Input from '../../../components/UI/Input/Input'
import { loginHandler } from '../../../services/passport/auth'
import { useTranslation } from 'react-i18next'
import { REGEX_EMAIL } from '../../../helpers/helpers'
import { IAuthAPIError } from '../../../interfaces/passport/auth'
import { API_AUTH_PATH } from '../../../helpers/api'
import { toggleLoaderHandler } from '../../../store/actionCreators/UI/loader'
import SelectLanguage from '../../../components/UI/Select/SelectLanguage'

interface IFormInputs {
	username: string
	password: string
	language: string
	cval: string
}

interface ILocationState {
	from: {
		pathname: string
		status: string
	}
	[key: string]: any
}

type TProps = ConnectedProps<typeof connector> & {}

const Login = ({ login, toggleLoader }: TProps) => {
	const { t } = useTranslation()
	const [message, setMessage] = useState('')
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [captchaCode, setCaptchaCode] = useState('')
	const location = useLocation<ILocationState>()

	const { register, handleSubmit, errors, setValue, watch } = useForm<IFormInputs>({
		defaultValues: {
			username: '',
			password: '',
			cval: ''
		}
	})

	useEffect(() => {
		if (location.state?.from.status === 'EmailConfirmSuccess') {
			setMessage('common:messageEmailConfirmSuccess')
		}

		if (location.state?.from.status === 'RegistrationSuccess') {
			setMessage('common:messageRegistrationSuccess')
		}

		if (location.state?.from.status === 'RegistrationUserAlreadyConfirmed') {
			setMessage('common:messageRegistrationUserAlreadyConfirmed')
		}
	}, [location])

	const onSubmit = async (data: IFormInputs) => {
		toggleLoader(true)
		setIsSubmitting(true)

		setMessage('')

		try {
			const response = await login(
				{
					username: data.username,
					password: data.password,
					c_val: data.cval,
					c_id: captchaCode
				},
				{
					type: 'notification'
				}
			)

			if (response) {
				if (response?.status !== 200) {
					const data = (response.data as unknown) as IAuthAPIError

					if (data?.c_id) {
						setCaptchaCode(data.c_id)
						setValue('cval', '')
					}
				}

				if (response?.status === 200) {
					setCaptchaCode('')
					setValue('cval', '')
				}
			}
		} finally {
			setIsSubmitting(false)
			toggleLoader(false)
		}
	}

	return (
		<>
			{message && <div className="success-message">{t(message)}</div>}

			<div className="login">
				<h3 className="login-title">{t('common:appName')}</h3>
				<p className="login-footnote">{t('pages:login:intro')}</p>

				<form className="form" onSubmit={handleSubmit(onSubmit)}>
					{process.env.REACT_APP_MODE === 'development' && (
						<div className="form-item">
							<Button
								type="secondary"
								htmlType="button"
								onClick={() => {
									setValue('username', 'test@mail.ru')
									setValue('password', 'alGk#q349()')
								}}
								isDisabled={isSubmitting}
							>
								Админ облака
							</Button>
							<Button
								type="secondary"
								htmlType="button"
								onClick={() => {
									setValue('username', 'max-usoltsev@yandex.ru')
									setValue('password', 'Aa123456')
								}}
								isDisabled={isSubmitting}
							>
								Админ организации
							</Button>
							<Button
								type="secondary"
								htmlType="button"
								onClick={() => {
									setValue('username', 'detectiveshelby@gmail.com')
									setValue('password', 'Aa123456')
								}}
								isDisabled={isSubmitting}
							>
								Преподаватель
							</Button>
							<Button
								type="secondary"
								htmlType="button"
								onClick={() => {
									setValue('username', 'reguser2@mailinator.com')
									setValue('password', 'Aa123456')
								}}
								isDisabled={isSubmitting}
							>
								Студент
							</Button>
						</div>
					)}

					<div className="form-item">
						<Input
							currentValue={watch('username')}
							type={'text'}
							name={'username'}
							placeholder={t('pages:login:fieldUsername')}
							isDisabled={isSubmitting}
							isError={!!errors.username}
							reference={register({
								required: true,
								minLength: 6,
								pattern: REGEX_EMAIL
							})}
							rules={{
								minLength: 6
							}}
							errors={errors.username}
							onBlur={e => setValue('username', e.target.value.trim())}
							onKeyPress={e => {
								if (e.charCode === 13 || e.key === 'Enter') {
									setValue('username', e.currentTarget.value.trim())
								}
							}}
						/>
					</div>

					<div className="form-item">
						<Input
							currentValue={watch('password')}
							type={'password'}
							name={'password'}
							placeholder={t('pages:login:fieldPassword')}
							isDisabled={isSubmitting}
							isError={!!errors.password}
							reference={register({
								required: true,
								minLength: 8,
								maxLength: 255
							})}
							rules={{
								minLength: 8,
								maxLength: 255
							}}
							errors={errors.password}
						/>
					</div>

					<div className="form-item">
						<SelectLanguage />
					</div>

					{captchaCode && (
						<>
							<div className="form-item">
								<img src={`${API_AUTH_PATH}/auth/${captchaCode}`} alt="" />
							</div>

							<div className="form-item">
								<Input
									placeholder={t('pages:login:fieldCaptcha')}
									currentValue={watch('cval')}
									type={'text'}
									name={'cval'}
									isDisabled={isSubmitting}
									reference={register({
										required: true
									})}
									errors={errors.cval}
								/>
							</div>
						</>
					)}

					<div className="form-item">
						<Button type={'primary'} htmlType={'submit'} isDisabled={isSubmitting}>
							{t('pages:login:buttonEnter')}
						</Button>
					</div>

					<div className="form-item form-item--link">
						<Link to={'/recovery'}>{t('pages:login:linkForgot')}</Link>
					</div>

					<div className="login-separate">
						<span>{t('pages:login:separator')}</span>
					</div>

					<div className="form-item">
						<Link className="button button--secondary" to={'/activate'}>
							{t('pages:login:linkRegister')}
						</Link>
					</div>
				</form>
			</div>
		</>
	)
}

const mapDispatch = {
	login: loginHandler,
	toggleLoader: toggleLoaderHandler
}

const connector = connect(null, mapDispatch)

export default connector(Login)
