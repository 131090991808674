import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Account from '../Account/Account'
import { IUser } from '../../interfaces/passport/user'
import { IOrganization } from '../../interfaces/passport/organizations'
import { useTranslation } from 'react-i18next'
import { showSidebar } from '../../store/actionCreators/UI/sidebar'
import { connect, ConnectedProps } from 'react-redux'
import { USER } from '../../helpers/roles'

type TProps = ConnectedProps<typeof connector> & {
	user: IUser
	organization: IOrganization
}

function Header({ user, organization, showSidebar }: TProps) {
	const { t } = useTranslation()
	const [isHeaderFixed, setIsHeaderFixed] = useState(false)

	const handleScroll = () => setIsHeaderFixed(window.pageYOffset > 25)

	useEffect(() => {
		window.addEventListener('scroll', handleScroll)

		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])

	return (
		<div className={`header ${isHeaderFixed ? 'header--scroll' : ''}`}>
			{user.role === USER && (
				<button className="header-menu" type="button" onClick={showSidebar}>
					<span />
				</button>
			)}

			<div className="container">
				<div className="header-logotype">
					<Link className="logotype" to={'/'}>
						{t('common:appName')}
					</Link>
				</div>

				{user?.accounts?.[0]?.organization?.name && (
					<div className="header-title">
						<h3>{user.accounts?.[0]?.organization.name}</h3>
					</div>
				)}

				{!user?.accounts?.[0]?.organization && organization?.name && (
					<div className="header-title">
						<h3>{organization.name}</h3>
					</div>
				)}

				<Account user={user} />
			</div>
		</div>
	)
}

const mapDispatch = {
	showSidebar: showSidebar
}

const connector = connect(null, mapDispatch)

export default connector(Header)
