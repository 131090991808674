import React, { useState } from 'react'
import { TDatePickerDate } from '../../../../../interfaces/helpers'
import InputDatePicker from '../../../../../components/UI/InputDatePicker/InputDatePicker'
import dayjs from 'dayjs'
import { DATE_FORMAT_API_SHORT } from '../../../../../helpers/helpers'
import { useTranslation } from 'react-i18next'

interface IProps {
	onChange: (dates: Array<string>) => void
	isDisabled: boolean
	errors: any
	defaultValue?: Array<string>
}

const DateRangeLicensePackage = ({ onChange, errors, isDisabled, defaultValue }: IProps) => {
	const { t } = useTranslation()
	const defaultFrom = defaultValue?.[0] && dayjs(defaultValue[0]).toDate()
	const defaultTo = defaultValue?.[1] && dayjs(defaultValue[1]).toDate()

	const [dateFrom, setDateFrom] = useState<TDatePickerDate>(defaultFrom || null)
	const [minDateFrom] = useState<TDatePickerDate>(defaultFrom || new Date())
	const [maxDateFrom, setMaxDateFrom] = useState<TDatePickerDate>(defaultTo || null)
	const [dateTo, setDateTo] = useState<TDatePickerDate>(defaultTo || null)
	const [minDateTo, setMinDateTo] = useState<TDatePickerDate>(defaultFrom || new Date())
	const [maxDateTo] = useState<TDatePickerDate>(null)

	const dateFromHandler = (date: TDatePickerDate) => {
		setDateFrom(date)
		setMinDateTo(date)

		const dates = []

		if (date) {
			dates.push(dayjs(date).format(DATE_FORMAT_API_SHORT))
		} else {
			dates.push('')
		}

		if (dateTo) {
			dates.push(dayjs(dateTo).format(DATE_FORMAT_API_SHORT))
		} else {
			dates.push('')
		}

		onChange(dates)
	}

	const dateToHandler = (date: TDatePickerDate) => {
		setDateTo(date)
		setMaxDateFrom(date)

		const dates = []

		if (dateFrom) {
			dates.push(dayjs(dateFrom).format(DATE_FORMAT_API_SHORT))
		} else {
			dates.push('')
		}

		if (date) {
			dates.push(dayjs(date).format(DATE_FORMAT_API_SHORT))
		} else {
			dates.push('')
		}

		onChange(dates)
	}

	return (
		<>
			<div className="form-col">
				<div className="form-item">
					<InputDatePicker
						placeholderText={t('pages:licensePackages:fieldDateFrom')}
						name={'dateFrom'}
						selected={dateFrom}
						minDate={minDateFrom}
						maxDate={maxDateFrom}
						onChange={dateFromHandler}
						isError={!!errors}
						errors={errors}
						isDisabled={isDisabled}
					/>
				</div>
			</div>

			<div className="form-col">
				<div className="form-item">
					<InputDatePicker
						placeholderText={t('pages:licensePackages:fieldDateTo')}
						name={'dateTo'}
						selected={dateTo}
						minDate={minDateTo}
						maxDate={maxDateTo}
						onChange={dateToHandler}
						isError={!!errors}
						errors={errors}
						isDisabled={isDisabled}
					/>
				</div>
			</div>
		</>
	)
}

export default DateRangeLicensePackage
