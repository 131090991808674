import React from 'react'

export interface IMultiselectItem {
	id: string
	name: string
}

export interface IMultiselectProps {
	title: string
	list: Array<IMultiselectItem>
	onChange: (item: IMultiselectItem) => void
	onAdd: () => void
	onRemove: () => void
	isLoading: boolean
	isRemoveDisabled: boolean
}

const Multiselect = ({ title, list, onChange, onAdd, onRemove, isLoading, isRemoveDisabled }: IMultiselectProps) => {
	const name = title.charAt(0)

	return (
		<div className="multiselect">
			<div className="multiselect-label">
				<span>{title}</span>

				<button
					className="control-circle control-circle--minus"
					type="button"
					onClick={onRemove}
					disabled={!list || isLoading || isRemoveDisabled}
				/>

				<button className="control-circle control-circle--plus" type="button" onClick={onAdd} disabled={isLoading} />
			</div>

			<ul className="list">
				{list &&
					list.map(item => {
						return (
							<li className="list-item" key={item.id}>
								<label>
									<input type="radio" name={name} onChange={() => onChange(item)} />
									<span>{item.name}</span>
								</label>
							</li>
						)
					})}
			</ul>
		</div>
	)
}

export default Multiselect
