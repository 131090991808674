import axios from 'axios'
import { API_REPORTS_PATH, APIServices } from '../../helpers/api'
import { setErrorHandler } from '../../store/actionCreators/error'
import {
	TReportsLicenseUsageByOrganizationsAPIRequest,
	TReportsOrganizationsAPIRequest,
	TReportsOrganizationUsersAPIRequest,
	TReportsUsersAPIRequest
} from '../../interfaces/reports/reports'
import { AppThunk } from '../../interfaces/helpers'
import { serviceHandlerErrorOptions } from '../helpers'

export function reportsByOrganizationsHandler(
	params: TReportsOrganizationsAPIRequest,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<BlobPart> {
	return async (dispatch, getState) => {
		try {
			return await axios.request<BlobPart>({
				method: 'GET',
				url: `${API_REPORTS_PATH}/reports/license`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				responseType: 'blob',
				params
			})
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.REPORTS, errorOptions))
			return error.response
		}
	}
}

export function reportsByUsersHandler(
	params: TReportsUsersAPIRequest,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<BlobPart> {
	return async (dispatch, getState) => {
		try {
			return await axios.request<BlobPart>({
				method: 'GET',
				url: `${API_REPORTS_PATH}/reports/licenseUser`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				responseType: 'blob',
				params
			})
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.REPORTS, errorOptions))
			return error.response
		}
	}
}

export function reportsByOrganizationUsersHandler(
	params: TReportsOrganizationUsersAPIRequest,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<BlobPart> {
	return async (dispatch, getState) => {
		try {
			return await axios.request<BlobPart>({
				method: 'GET',
				url: `${API_REPORTS_PATH}/reports/license-usage-by-org-users`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				responseType: 'blob',
				params
			})
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.REPORTS, errorOptions))
			return error.response
		}
	}
}

export function reportsLicenseUsageUsageByOrganizationsHandler(
	params: TReportsLicenseUsageByOrganizationsAPIRequest,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<BlobPart> {
	return async (dispatch, getState) => {
		try {
			return await axios.request<BlobPart>({
				method: 'GET',
				url: `${API_REPORTS_PATH}/reports/license-usage-by-organizations`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				responseType: 'blob',
				params
			})
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.REPORTS, errorOptions))
			return error.response
		}
	}
}
