import React, { forwardRef, useRef, useState } from 'react'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import { DATE_FORMAT, DATE_FORMAT_DATEPICKER } from '../../../helpers/helpers'
import InputError from '../InputError/InputError'
// @ts-ignore
import { IMaskInput } from 'react-imask'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

interface ICustomInput extends Omit<ReactDatePickerProps, 'onChange'> {
	onClick?(): void
	onChange?(): void
}

interface IProps extends ReactDatePickerProps {
	isError?: boolean
	isRequired?: boolean
	isDisabled?: boolean
	errors?: any
	rules?: {
		minLength?: number
		maxLength?: number
	}
}

const InputDatePicker = ({
	errors,
	rules,
	isDisabled,
	isError,
	isRequired,
	maxDate,
	minDate,
	placeholderText,
	...rest
}: IProps) => {
	const classNames = ['field', 'field--calendar']
	const dateRef = useRef<any>(null)
	const initialValue = rest.selected || rest.value
	const [inputValue, setInputValue] = useState(initialValue ? dayjs(initialValue).format(DATE_FORMAT) : '')

	if (isError) classNames.push('field--error')
	if (isRequired) classNames.push('field--required')
	if (isDisabled) classNames.push('field--disabled')
	if (rest.selected || rest.value || inputValue) classNames.push('field--notempty')

	const CustomInput = forwardRef((props: ICustomInput, ref: React.Ref<HTMLInputElement>) => (
		<button className="field-button" onClick={props.onClick} type={'button'} />
	))

	let inputMinDate = minDate
	let inputMaxDate = maxDate

	if (minDate) {
		inputMinDate = dayjs(minDate).startOf('day').toDate()
	}

	if (maxDate) {
		inputMaxDate = dayjs(maxDate).endOf('day').toDate()
	}

	return (
		<>
			<label className={classNames.join(' ')}>
				<IMaskInput
					mask={Date}
					value={inputValue}
					onAccept={(value: string) => {
						setInputValue(value)
						if (value.length !== 10) dateRef.current.clear()
					}}
					onComplete={(value: string) => {
						dateRef.current.setSelected(dayjs(value, DATE_FORMAT).toDate())
					}}
					className="field-input"
					disabled={isDisabled}
					min={inputMinDate}
					max={inputMaxDate}
				/>
				{placeholderText && <span className="field-label">{placeholderText}</span>}

				<DatePicker
					{...rest}
					placeholderText={''}
					minDate={inputMinDate}
					maxDate={inputMaxDate}
					name={rest.name}
					dateFormat={DATE_FORMAT_DATEPICKER}
					disabled={isDisabled}
					autoComplete={'off'}
					customInput={<CustomInput />}
					onSelect={date => setInputValue(dayjs(date).format(DATE_FORMAT))}
					ref={dateRef}
					startOpen={false}
				/>
			</label>

			{isError && <InputError errors={errors} rules={rules} />}
		</>
	)
}

export default InputDatePicker
