import React, { ChangeEvent, FormEvent, useCallback, useEffect, useRef, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import Input from '../../../components/UI/Input/Input'
import { Trans, useTranslation } from 'react-i18next'
import Button from '../../../components/UI/Button/Button'
import { connect, ConnectedProps } from 'react-redux'
import { getUserActivationDataByCodeHandler, userActivationHandler } from '../../../services/passport/user'
import { AxiosResponse } from 'axios'
import { IUser, IUserActivationRequest, IUserActivationResponse } from '../../../interfaces/passport/user'
import { notificationAddHandler } from '../../../store/actionCreators/UI/notification'
import { ACTIVATED, CONFIRMED, USER } from '../../../helpers/roles'
import { Controller, useForm } from 'react-hook-form'
import { DEFAULT_LOCALE, LOCALES_EXISTS, REGEX_PASSWORD } from '../../../helpers/helpers'
import {
    getFacultyGroupsPublicByIdHandler,
    getFacultyPublicByIdHandler,
    getGradesPublicByIdHandler
} from '../../../services/passport/organization'
import Checkbox from '../../../components/UI/Checkbox/Checkbox'
import { toggleLoaderHandler } from '../../../store/actionCreators/UI/loader'
import Select from 'react-select'
import { selectProps } from '../../../components/UI/Select/helpers'
import { IFaculty, IFacultyGroup, IGrade } from '../../../interfaces/passport/organizations'
import Loading from '../../../components/UI/Loading/Loading'
import { TLocales, TStatus } from '../../../interfaces/helpers'
import { loginHandler, logoutHandler } from '../../../services/passport/auth'

interface IFormInputs {
    email: string
    lastName: string
    firstName: string
    patronymic: string
    faculty: string
    facultyGroup: string
    grade: string
    newPassword: string
    repeatPassword: string
    privacy: string
}

const Activate = ({
    login,
    logout,
    getUserActivationData,
    userActivation,
    notificationAdd,
    getFaculties,
    getFacultiesGroups,
    getGrades,
    toggleLoader
}: ConnectedProps<typeof connector>) => {
    const { t, i18n } = useTranslation()

    const [isCodeUsed, setIsCodeUsed] = useState(false)
    const [activationCodeInput, setActivationCodeInput] = useState('')
    const [activationCodeUri, setActivationCodeUri] = useState('')
    const [user, setUser] = useState<IUser>()
    const { code } = useParams()
    const history = useHistory()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isNoCodeTextVisible, setIsNoCodeTextVisible] = useState(false)

    // ACTIVATION
    const handleCodeInput = (e: ChangeEvent<HTMLInputElement>) => {
        setActivationCodeInput(e.target.value)
    }

    const handleCodeSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        toggleLoader(true)
        setIsSubmitting(true)

        try {
            const response = await getUserActivationData(activationCodeInput, {
                show: false
            })

            handleActivationCodeResponse(response)
        } finally {
            setIsSubmitting(false)
            toggleLoader(false)
        }
    }

    const handleActivationCodeResponse = useCallback(
        (response: AxiosResponse<IUserActivationResponse>) => {
            if (response?.status === 200) {
                const { data } = response

                if (data && !data.activated) {
                    setUser(data.user)
                } else {
                    notificationAdd(t('pages:activate:notificationCodeUsed'), 'warning')
                }
            } else {
                notificationAdd(t('pages:activate:notificationCodeError'), 'warning')
            }

            setIsSubmitting(false)
            toggleLoader(false)
        },
        [notificationAdd, toggleLoader, t]
    )

    useEffect(() => {
        if (code) {
            setActivationCodeUri(code)
        }
    }, [code])

    useEffect(() => {
        if (activationCodeUri && !isCodeUsed) {
            setActivationCodeInput(activationCodeUri)

            toggleLoader(true)
            setIsSubmitting(true)
            getUserActivationData(activationCodeUri, {
                show: false
            }).then(handleActivationCodeResponse)
        }
    }, [getUserActivationData, activationCodeUri, handleActivationCodeResponse, toggleLoader, isCodeUsed])

    useEffect(() => {
        if (user && user.role === USER) {
            if (user.status === ACTIVATED) {
                setIsCodeUsed(true)
            }

            if (user.status === CONFIRMED) {
                history.replace('/', {
                    from: {
                        status: 'RegistrationUserAlreadyConfirmed',
                        pathname: '/'
                    }
                })
            }
        }
    }, [user, history])

    useEffect(() => {
        if (user) {
            let locale = (user?.accounts?.[0]?.organization.locale || DEFAULT_LOCALE).toUpperCase() as TLocales

            if (!LOCALES_EXISTS.includes(locale)) {
                locale = DEFAULT_LOCALE
            }

            i18n.changeLanguage(locale.toLowerCase())
        }
    }, [user, i18n])

    // REGISTRATION
    const [status, setStatus] = useState<TStatus>('loading')
    const { register, errors, formState, watch, handleSubmit, control, setValue, getValues } = useForm<IFormInputs>()
    const { isDirty } = formState

    const [faculties, setFaculties] = useState<IFaculty[]>([])
    const [facultiesGroups, setFacultiesGroups] = useState<IFacultyGroup[]>([])
    const [grades, setGrades] = useState<IGrade[]>([])

    const selectFacultyGroupRef = useRef<any>(null)

    const changeFacultyHandle = async (value: string) => {
        if (user) {
            const organizationId = user?.accounts?.[0]?.organization?.id

            if (!organizationId) {
                return
            }

            setFacultiesGroups([])
            selectFacultyGroupRef.current.select.clearValue()
            setValue('facultyGroup', '')

            try {
                const response = await getFacultiesGroups(organizationId, value)

                if (response?.status === 200) {
                    setFacultiesGroups(response.data)
                }
            } finally {
            }
        }
    }

    const onSubmit = async (data: IFormInputs) => {
        if (user) {
            const organizationId = user?.accounts?.[0]?.organization?.id

            if (!organizationId) {
                return
            }

            toggleLoader(true)
            setIsSubmitting(true)

            try {
                const result: IUserActivationRequest = {
                    email: user.email,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    patronymic: data.patronymic,
                    accounts: [{
                        organization: organizationId,
                        faculty: data.faculty,
                        facultyGroup: data.facultyGroup,
                        grade: data.grade,
                    }],
                    role: user.role,
                    newPassword: data.newPassword,
                    activationCode: activationCodeInput
                }

                const response = await userActivation(result)

                if (response?.status === 200) {
                    logout(false)

                    const loginResponse = await login({
                        username: user.email,
                        password: data.newPassword
                    })

                    if (loginResponse.status === 200) {
                        setIsSubmitting(false)
                        toggleLoader(false)

                        history.replace('/')
                    }
                }
            } finally {
                setIsSubmitting(false)
                toggleLoader(false)
            }
        }
    }

    useEffect(() => {
        if (user && user?.accounts?.[0]?.student) {
            if (user?.accounts?.[0]?.faculty?.id && user?.accounts?.[0]?.facultyGroup?.id && user?.accounts?.[0]?.grade?.id) {
                const organizationId = user?.accounts?.[0]?.organization?.id

                if (!organizationId) {
                    return
                }

                getFaculties(organizationId).then(response => {
                    if (response?.status === 200) {
                        setFaculties(response.data)
                    }
                })

                getFacultiesGroups(organizationId, user?.accounts?.[0]?.faculty.id).then(response => {
                    if (response?.status === 200) {
                        setFacultiesGroups(response.data)
                    }
                })

                getGrades(organizationId).then(response => {
                    if (response?.status === 200) {
                        setGrades(response.data)
                    }
                })
            }
        }
    }, [user, getFaculties, getGrades, getFacultiesGroups])

    useEffect(() => {
        if (user) {
            if (user?.accounts?.[0]?.student) {
                if (grades.length && faculties.length && facultiesGroups.length) {
                    setStatus('ready')
                }
            } else {
                setStatus('ready')
            }
        }
    }, [user, grades, faculties, facultiesGroups, setValue])

    return (
        <>
            {!isCodeUsed ? (
                <div className="login">
                    <h3 className="login-title">{t('common:appName')}</h3>
                    <p className="login-footnote">{t('pages:activate:activationText')}</p>

                    <form className="form" onSubmit={handleCodeSubmit}>
                        <div className="form-item">
                            <Input
                                currentValue={activationCodeInput}
                                type={'text'}
                                name={'code'}
                                defaultValue={activationCodeInput}
                                onChange={handleCodeInput}
                                isDisabled={isSubmitting}
                            />
                        </div>

                        <div className="form-controls form-controls--justify">
                            <Link className="button button--secondary" to={'/'}>
                                {t('common:actionCancel')}
                            </Link>

                            <Button type={'primary'} htmlType={'submit'} isDisabled={!activationCodeInput || isSubmitting}>
                                {t('common:actionContinue')}
                            </Button>
                        </div>
                    </form>

                    <div className="login-bottom">
                        <button className="button-link" type="button" onClick={() => setIsNoCodeTextVisible(!isNoCodeTextVisible)}>
                            {t('pages:activate:noCode')}
                        </button>

                        {isNoCodeTextVisible && (
                            <div>
                                <br />
                                {t('pages:activate:noCodeText')}
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <>
                    {status === 'loading' ? (
                        <Loading />
                    ) : (
                        <div className="login login--registration">
                            <h3 className="login-title">{t('common:appName')}</h3>
                            <p className="login-footnote">{t('pages:activate:registrationText')}</p>

                            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-item">
                                    <Input
                                        type={'email'}
                                        name={'email'}
                                        placeholder={t('pages:activate:fieldEmail')}
                                        isReadonly={true}
                                        isError={!!errors.email}
                                        isRequired={true}
                                        reference={register({
                                            required: true
                                        })}
                                        errors={errors.email}
                                        defaultValue={user?.email}
                                    />
                                </div>

                                <div className="form-item">
                                    <Input
                                        currentValue={watch('lastName')}
                                        type={'text'}
                                        name={'lastName'}
                                        placeholder={t('pages:activate:fieldLastName')}
                                        isDisabled={isSubmitting}
                                        isError={!!errors.lastName}
                                        isRequired={true}
                                        reference={register({
                                            required: true
                                        })}
                                        errors={errors.lastName}
                                        defaultValue={user?.lastName}
                                    />
                                </div>

                                <div className="form-item">
                                    <Input
                                        currentValue={watch('firstName')}
                                        type={'text'}
                                        name={'firstName'}
                                        placeholder={t('pages:activate:fieldFirstName')}
                                        isDisabled={isSubmitting}
                                        isError={!!errors.firstName}
                                        isRequired={true}
                                        reference={register({
                                            required: true
                                        })}
                                        errors={errors.firstName}
                                        defaultValue={user?.firstName}
                                    />
                                </div>

                                <div className="form-item">
                                    <Input
                                        currentValue={watch('patronymic')}
                                        type={'text'}
                                        name={'patronymic'}
                                        placeholder={t('pages:activate:fieldPatronymic')}
                                        isDisabled={isSubmitting}
                                        isError={!!errors.patronymic}
                                        reference={register}
                                        errors={errors.patronymic}
                                        defaultValue={user?.patronymic}
                                    />
                                </div>

                                {user?.accounts?.[0]?.student && (
                                    <>
                                        <div className="form-item">
                                            <Controller
                                                render={({ onChange, name }) => (
                                                    <Select
                                                        name={name}
                                                        defaultValue={
                                                            faculties
                                                                .filter(item => item.id === user?.accounts?.[0]?.faculty?.id)
                                                                .map(item => {
                                                                    return {
                                                                        label: item.name,
                                                                        value: item.id
                                                                    }
                                                                })[0]
                                                        }
                                                        options={faculties.map(item => {
                                                            return {
                                                                label: item.name,
                                                                value: item.id
                                                            }
                                                        })}
                                                        placeholder={t('pages:users:fieldFaculty')}
                                                        isDisabled={isSubmitting || !user?.accounts?.[0]?.student || !faculties.length}
                                                        isRequired={user?.accounts?.[0]?.student}
                                                        isError={!!errors.faculty}
                                                        errors={errors.faculty}
                                                        onChange={option => {
                                                            if (option) {
                                                                onChange(option.value)
                                                                changeFacultyHandle(option.value)
                                                            }
                                                        }}
                                                        {...selectProps}
                                                    />
                                                )}
                                                name={'faculty'}
                                                control={control}
                                                rules={{
                                                    required: user?.accounts?.[0]?.student
                                                }}
                                                defaultValue={user?.accounts?.[0]?.faculty?.id}
                                            />
                                        </div>

                                        <div className="form-item">
                                            <Controller
                                                render={({ onChange, name }) => (
                                                    <Select
                                                        name={name}
                                                        defaultValue={
                                                            facultiesGroups
                                                                .filter(item => item.id === user?.accounts?.[0]?.facultyGroup?.id)
                                                                .map(item => {
                                                                    return {
                                                                        label: item.name,
                                                                        value: item.id
                                                                    }
                                                                })[0]
                                                        }
                                                        options={facultiesGroups.map(item => {
                                                            return {
                                                                label: item.name,
                                                                value: item.id
                                                            }
                                                        })}
                                                        placeholder={t('pages:profile:fieldFacultyGroup')}
                                                        isDisabled={isSubmitting || !user?.accounts?.[0]?.student || !facultiesGroups.length}
                                                        isRequired={user?.accounts?.[0]?.student}
                                                        isError={!!errors.facultyGroup}
                                                        errors={errors.facultyGroup}
                                                        onChange={option => option && onChange(option.value)}
                                                        ref={selectFacultyGroupRef}
                                                        {...selectProps}
                                                    />
                                                )}
                                                name={'facultyGroup'}
                                                control={control}
                                                rules={{
                                                    required: user?.accounts?.[0]?.student
                                                }}
                                                defaultValue={user?.accounts?.[0]?.facultyGroup?.id}
                                            />
                                        </div>

                                        <div className="form-item">
                                            <Controller
                                                render={({ onChange, name }) => (
                                                    <Select
                                                        name={name}
                                                        defaultValue={
                                                            grades
                                                                .filter(item => item.id === user?.accounts?.[0]?.grade?.id)
                                                                .map(item => {
                                                                    return {
                                                                        label: item.name,
                                                                        value: item.id
                                                                    }
                                                                })[0]
                                                        }
                                                        options={grades.map(item => {
                                                            return {
                                                                label: item.name,
                                                                value: item.id
                                                            }
                                                        })}
                                                        placeholder={t('pages:profile:fieldGrade')}
                                                        isDisabled={isSubmitting || !user?.accounts?.[0]?.student || !grades.length}
                                                        isRequired={user?.accounts?.[0]?.student}
                                                        isError={!!errors.grade}
                                                        errors={errors.grade}
                                                        onChange={option => option && onChange(option.value)}
                                                        {...selectProps}
                                                    />
                                                )}
                                                name={'grade'}
                                                control={control}
                                                rules={{
                                                    required: user?.accounts?.[0]?.student
                                                }}
                                                defaultValue={user?.accounts?.[0]?.grade?.id}
                                            />
                                        </div>
                                    </>
                                )}

                                <div className="form-item">
                                    <Input
                                        currentValue={watch('newPassword')}
                                        type={'password'}
                                        name={'newPassword'}
                                        placeholder={t('pages:activate:fieldNewPassword')}
                                        isDisabled={isSubmitting}
                                        isError={!!errors.newPassword}
                                        isRequired={true}
                                        reference={register({
                                            required: true,
                                            minLength: 8,
                                            maxLength: 255,
                                            pattern: {
                                                value: REGEX_PASSWORD,
                                                message: 'fieldPatternPassword'
                                            }
                                        })}
                                        rules={{
                                            minLength: 8,
                                            maxLength: 255
                                        }}
                                        errors={errors.newPassword}
                                    />
                                </div>

                                <div className="form-item">
                                    <Input
                                        currentValue={watch('repeatPassword')}
                                        type={'password'}
                                        name={'repeatPassword'}
                                        placeholder={t('pages:activate:fieldRepeatPassword')}
                                        isRequired={true}
                                        isDisabled={isSubmitting}
                                        isError={!!errors.repeatPassword}
                                        errors={errors.repeatPassword}
                                        reference={register({
                                            validate: {
                                                passwordMatches: value => value === getValues('newPassword')
                                            }
                                        })}
                                    />
                                </div>

                                <div className="form-item form-item--checkbox">
                                    <Checkbox
                                        name={'privacy'}
                                        isDisabled={isSubmitting}
                                        reference={register({
                                            required: true
                                        })}
                                        isError={!!errors.privacy}
                                        errors={errors.privacy}
                                    >
                                        <Trans i18nKey={'pages:activate:privacy'}>
                                            Я ознакомнен с <Link to={'/policy'}>Политикой конфиденциальности</Link> и согласен с{' '}
                                            <Link to={'/consent'}>Правилами обработки персональных данных</Link>
                                        </Trans>
                                    </Checkbox>
                                </div>

                                <div className="form-controls form-controls--right">
                                    <Link className="button button--secondary" to={'/'}>
                                        {t('common:actionCancel')}
                                    </Link>

                                    <Button type={'primary'} htmlType={'submit'} isDisabled={isSubmitting || !isDirty}>
                                        {t('pages:activate:registrationButton')}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    )}
                </>
            )}
        </>
    )
}

const mapDispatch = {
    login: loginHandler,
    logout: logoutHandler,
    getUserActivationData: getUserActivationDataByCodeHandler,
    userActivation: userActivationHandler,

    getFaculties: getFacultyPublicByIdHandler,
    getGrades: getGradesPublicByIdHandler,
    getFacultiesGroups: getFacultyGroupsPublicByIdHandler,

    notificationAdd: notificationAddHandler,
    toggleLoader: toggleLoaderHandler
}

const connector = connect(null, mapDispatch)

export default connector(Activate)
