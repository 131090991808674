import React from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
	errors?: any
	rules?: {
		required?: boolean
		pattern?: string
		minLength?: number
		maxLength?: number
		max?: number
		min?: number
	}
}

const InputError = ({ errors, rules }: IProps) => {
	const { t } = useTranslation()

	let message = ''

	if (errors?.type === 'required') {
		message = t('errors:fieldRequired')
	}

	if (errors?.type === 'pattern') {
		message = t('errors:fieldPattern')

		if (errors?.message) {
			message = t(`errors:${errors?.message}`, {
				value: rules?.minLength
			})
		}
	}

	if (errors?.type === 'minLength') {
		message = t('errors:fieldMinLength', {
			value: rules?.minLength
		})
	}

	if (errors?.type === 'maxLength') {
		message = t('errors:fieldMaxLength', {
			value: rules?.maxLength
		})
	}

	if (errors?.type === 'passwordMatches') {
		message = t('errors:fieldPasswordMatches')
	}

	if (errors?.type === 'currentPassword') {
		message = t('errors:fieldCurrentPassword')
	}

	if (errors?.type === 'dateRange') {
		message = t('errors:fieldDateRange')
	}

	if (errors?.type === 'min') {
		message = t('errors:fieldMin', {
			value: rules?.min
		})
	}

	if (errors?.type === 'max') {
		message = t('errors:fieldMax', {
			value: rules?.max
		})
	}

	if (errors?.type === 'duplicateValue') {
		message = t('errors:fieldDuplicateValue')
	}

	if (errors?.type === 'onlySpaces') {
		message = t('errors:fieldOnlySpaces')
	}

	return <span className="form-error">{message}</span>
}

export default InputError
