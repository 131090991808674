import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'

interface IProps {
	title: string
	crumbs?: Array<{
		to: string
		title: string
		onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
	}>
	children?: ReactNode
}

export default function Pagetitle({ title, crumbs, children }: IProps) {
	const classNames = ['pagetitle']

	if (crumbs) classNames.push('pagetitle--crumbs')

	return (
		<div className={classNames.join(' ')}>
			{crumbs &&
				crumbs.map((item, idx) => (
					<div className="pagetitle-parent" key={idx}>
						<Link to={item.to} onClick={item.onClick}>
							{item.title}
						</Link>
					</div>
				))}

			<h1>{title}</h1>
			{children && <div className="pagetitle-description">{children}</div>}
		</div>
	)
}
