import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { APIResponse, IPagination, TSortColumnsTable, TStatus } from '../../../interfaces/helpers'
import {
	IUser,
	IUserAPIRequest,
	IUserAccount,
	IUserSortAPIRequest,
	TUserSortFields
} from '../../../interfaces/passport/user'
import { BLOCKED, getUserRoleName } from '../../../helpers/roles'
import { DATE_FORMAT, getPlatformName, getUserStatusName } from '../../../helpers/helpers'
import Pagination from '../../../components/UI/Pagination/Pagination'
import NoResults from '../../../components/UI/NoResults/NoResults'
import { AxiosPromise } from 'axios'
import Loading from '../../../components/UI/Loading/Loading'
import Error from '../../Error/Error'
import { IActivationCodeListResponse } from '../../../interfaces/cabinet/activationCode'
import dayjs from 'dayjs'

interface ITableUserLicenseInfo {
	termless?: boolean
	versionName?: string
	licenseFrom?: string
	licenseTo?: string
	platform?: string
	platformName?: string
	metaData?: string
}

export interface ITableUser {
	id: string
	accountId?: string
	roleName: string
	firstName: string
	lastName: string
	patronymic?: string
	email: string
	phone?: string
	status: string
	statusName: string
	organizationName?: string
	groupName?: string

	licenses?: ITableUserLicenseInfo[]
	platforms?: string
	user: IUser

	selected: boolean
}

interface ITableColumn {
	width?: string
	title: string
	sortName?: TUserSortFields
	fieldName: keyof ITableUser
	mapValue?: (item: ITableUser) => JSX.Element | string
}

interface IUserTableProps {
	columns: ITableColumn[]
	pageSize: number
	filter?: Omit<IUserAPIRequest, 'userSortField' | 'pageSize' | 'pageNo' | 'desc'>
	selectable?: boolean
	onSelect?: (selected: ITableUser[]) => void
	onLoaded?: (page: IPagination) => void
	getUsers: (params: IUserAPIRequest) => AxiosPromise<APIResponse<IUser[]>>
	getLicenseInfo?: (userIds: string[], accountId?: string) => AxiosPromise<IActivationCodeListResponse[]>
}

export function userNameRender(parentRoute?: string) {
	return (item: ITableUser) => (
		<>
			<Link to={`${parentRoute}/users/${item.id}`}>
				{item.lastName} {item.firstName}
			</Link>
			<div className="table-nowrap">{item.email}</div>
		</>
	)
}

export function userLicenseRender(t: (c: string) => string) {
	return (item: ITableUser) => {
		const info: { name: string; period: string }[] = item.licenses
			? item.licenses.map(info => {
					let period: string = '-'
					if (info.termless) period = t('pages:users:termless')
					if (info.licenseFrom && info.licenseTo)
						period = `${dayjs(info.licenseFrom).format(DATE_FORMAT)} - ${dayjs(info.licenseTo).format(DATE_FORMAT)}`
					if (info.licenseFrom && !info.licenseTo)
						period = t('common:from') + ` ${dayjs(info.licenseFrom).format(DATE_FORMAT)}`
					if (!info.licenseFrom && info.licenseTo)
						period = t('common:to') + ` ${dayjs(info.licenseTo).format(DATE_FORMAT)}`
					return { name: info.versionName || '', period: `(${period})` }
			  })
			: []
		return (
			<>
				{info.length > 0 ? (
					info.map((licenseText, i) => (
						<div key={i}>
							<div className="table-nowrap">{licenseText.name}</div>
							<div className="table-nowrap">{licenseText.period}</div>
						</div>
					))
				) : (
					<div className="table-nowrap">{'-'}</div>
				)}
			</>
		)
	}
}

const UsersTable = forwardRef<unknown, React.PropsWithChildren<IUserTableProps>>(
	(
		{
			columns,
			pageSize,
			filter,
			selectable,
			onSelect,
			onLoaded,
			getUsers,
			getLicenseInfo,
			children
		}: React.PropsWithChildren<IUserTableProps>,
		ref
	) => {
		useImperativeHandle(ref, () => ({
			reset() {
				if (page === 0) {
					setSortParams({
						...sortParams
					})
				}
				setPage(0)
			}
		}))
		const { t } = useTranslation()
		const [status, setStatus] = useState<TStatus>('loading')
		const [isCheckboxAllChecked, setIsCheckboxAllChecked] = useState(false)
		const [table, setTable] = useState<ITableUser[]>([])
		const [pagination, setPagination] = useState<IPagination>({
			pageNo: 0,
			totalPages: 0,
			totalCount: 0
		})
		const [page, setPage] = useState<number>(0)
		const [sortParams, setSortParams] = useState<IUserSortAPIRequest>({
			userSortField: 'Name',
			desc: false
		})
		const [sortColumns, setSortColumns] = useState<TSortColumnsTable>({
			Name: {
				desc: false,
				classNames: ['table-sort', 'table-sort--top']
			},
			Login: {
				desc: true,
				classNames: ['table-sort']
			},
			Organization: {
				desc: true,
				classNames: ['table-sort']
			},
			Faculty: {
				desc: true,
				classNames: ['table-sort']
			},
			FacultyGroup: {
				desc: true,
				classNames: ['table-sort']
			},
			Grade: {
				desc: true,
				classNames: ['table-sort']
			},
			Role: {
				desc: true,
				classNames: ['table-sort']
			}
		})

		const sortHandle = (userSortField: TUserSortFields) => {
			const updatedSortColumns = { ...sortColumns }

			Object.keys(updatedSortColumns).forEach((key: string) => {
				if (key !== userSortField) {
					updatedSortColumns[key] = {
						desc: true,
						classNames: ['table-sort']
					}
				} else {
					updatedSortColumns[key].desc = !updatedSortColumns[key].desc
					updatedSortColumns[key].classNames = [
						'table-sort',
						updatedSortColumns[key].desc ? 'table-sort--bottom' : 'table-sort--top'
					]
				}
			})

			setSortParams({
				userSortField,
				desc: updatedSortColumns[userSortField].desc
			})
			setSortColumns(updatedSortColumns)
		}

		const checkboxHandle = (id: string, selected: boolean) => {
			const updatedTable = [...table]
			const index = updatedTable.findIndex(user => user.id === id)

			updatedTable[index].selected = selected

			setTable(updatedTable)
			controlsStatusHandle(updatedTable)
		}

		const checkboxAllHandle = (selected: boolean) => {
			const updatedTable = [...table]
			updatedTable.map(user => (user.selected = selected))

			setTable(updatedTable)
			controlsStatusHandle(updatedTable)
		}

		const controlsStatusHandle = (updated: ITableUser[]) => {
			const checked = updated.filter(user => user.selected)
			setIsCheckboxAllChecked(checked.length === updated.length)
			if (onSelect) onSelect(checked)
		}

		const getGroupName = (item: IUser, currentAccount?: IUserAccount) => {
			if (currentAccount) {
				return currentAccount.facultyGroup?.name || ''
			}
			return (
				item.accounts
					?.map(acc => acc.facultyGroup?.name)
					.filter(s => !!s)
					.join(',\n') || ''
			)
		}

		const getOrgName = (item: IUser, currentAccount?: IUserAccount) => {
			if (currentAccount) {
				return currentAccount.organization?.name || ''
			}
			return (
				item.accounts
					?.map(acc => acc.organization?.name)
					.filter(s => !!s)
					.join(',\n') || ''
			)
		}

		useEffect(() => {
			setPage(0)
		}, [pageSize, filter])

		useEffect(() => {
			setIsCheckboxAllChecked(false)

			const fetchData = async () => {
				setStatus("loading")
				const response = await getUsers({ ...filter, ...sortParams, pageNo: page, pageSize })
				if (response.status !== 200) {
					setStatus('error')
					return
				}

				const { data, pageNo, totalPages, totalCount } = response.data

				let users: Array<ITableUser> = data.map(item => {
					const account = item.accounts?.find(acc => acc.organization.id === filter?.orgFilter) || item.accounts?.[0]
					return {
						id: item.id,
						accountId: account?.accountId,
						roleName: getUserRoleName(item, account?.accountId),
						firstName: item.firstName,
						lastName: item.lastName,
						patronymic: item.patronymic,
						email: item.email,
						phone: item.phone,
						status: item.status,
						statusName: account && account.blocked ? getUserStatusName(BLOCKED) : getUserStatusName(item.status),
						groupName: getGroupName(item, account),
						organizationName: getOrgName(item, account),
						user: item,
						selected: false
					}
				})
				if (getLicenseInfo && users.length > 0) {
					const accountId = filter?.orgFilter ? users[0].accountId : undefined
					const responseLicenseInfo = await getLicenseInfo(
						users.map(u => u.id),
						accountId
					)
					if (responseLicenseInfo.status === 200) {
						const licenses = responseLicenseInfo.data
						users = users.map(user => {
							const userLicenseInfo = licenses.filter(info => info.userId === user.id)
							if (userLicenseInfo.length > 0) {
								user.licenses = userLicenseInfo.map(item => ({
									versionName: item.versionName,
									termless: item.termless,
									licenseFrom: item.dateFrom,
									licenseTo: item.dateTo,
									platform: item.platform,
									platformName: item.platform ? getPlatformName(item.platform) : undefined,
									metaData: item.hardwareMetaData
								}))
								user.platforms = userLicenseInfo
									.map(item => (item.platform ? getPlatformName(item.platform) : undefined))
									.filter(item => !!item)
									.join(', ')
							}
							return user
						})
					}
				}

				setTable(users)

				setPagination({
					pageNo,
					totalPages,
					totalCount
				})
				setStatus('ready')
				if (onLoaded)
					onLoaded({
						pageNo,
						totalPages,
						totalCount
					})
			}
			fetchData()
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [getUsers, filter, page, sortParams, pageSize])

		if (status === 'error') return <Error />
		if (status === 'loading') return <Loading />

		return table.length > 0 ? (
			<div className="pagination-wrapper">
				<div className="table">
					<table>
						<colgroup>
							{!!selectable && <col width="5.22%" />}
							{columns.map((col, i) => (
								<col key={i} width={col.width} />
							))}
						</colgroup>
						<thead>
							<tr>
								{!!selectable && (
									<th>
										<div className="table-checkbox">
											<label className="checkbox">
												<input
													type="checkbox"
													checked={isCheckboxAllChecked}
													onChange={e => checkboxAllHandle(e.target.checked)}
												/>
												<span />
											</label>
										</div>
									</th>
								)}
								{columns.map((col, i) => (
									<th key={i} onClick={col.sortName ? () => sortHandle(col.sortName!) : undefined}>
										<div className={col.sortName ? sortColumns[col.sortName].classNames.join(' ') : undefined}>
											{t(col.title)}
										</div>
									</th>
								))}
							</tr>
						</thead>

						<tbody>
							{table.map(item => {
								return (
									<tr key={item.id}>
										{!!selectable && (
											<td>
												<div className="table-checkbox">
													<label className="checkbox">
														<input
															type="checkbox"
															checked={item.selected}
															onChange={e => checkboxHandle(item.id, e.target.checked)}
														/>
														<span />
													</label>
												</div>
											</td>
										)}

										{columns.map((col, i) => (
											<td key={i}>
												{col.mapValue ? (
													col.mapValue(item)
												) : (
													<div className="table-nowrap">
														{item[col.fieldName] ? t(item[col.fieldName] as string) : ''}
													</div>
												)}
											</td>
										))}
									</tr>
								)
							})}
						</tbody>
					</table>
				</div>

				{children}

				<Pagination onClick={setPage} {...pagination} />
			</div>
		) : (
			<NoResults />
		)
	}
)

export default UsersTable
