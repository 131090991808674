import React, { useEffect, useRef, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useParams } from 'react-router-dom'
import { TStatus } from '../../../../interfaces/helpers'
import {
    ILicensePackageCreate,
    ILicensePackageUpdate,
} from '../../../../interfaces/cabinet/licensePackage'
import { INFINITE_NUMBER } from '../../../../helpers/helpers'
import Loading from '../../../../components/UI/Loading/Loading'
import {
    blockLicensePackageByIdHandler,
    createLicensePackageHandler,
    deleteLicensePackageByIdHandler,
    getLicensePackageByIdHandler,
    unblockLicensePackageByIdHandler,
    updateLicensePackageByIdHandler
} from '../../../../services/cabinet/licensePackage'
import { modalShowHandler } from '../../../../store/actionCreators/UI/modal'
import { getOrganizationByIdHandler } from '../../../../services/passport/organization'
import Error from '../../../Error/Error'
import ModalAddLicensePackage from './ModalAddLicensePackage'
import { toggleLoaderHandler } from '../../../../store/actionCreators/UI/loader'
import { productGetAllHandler } from '../../../../services/cabinet/products'
import { versionGetAllHandler } from '../../../../services/cabinet/versions'
import { hardwareKeyGetAllHandler } from '../../../../services/cabinet/hardwareKeys'
import ModalEditLicensePackage from './ModalEditLicensePackage'
import { IOrganization } from '../../../../interfaces/passport/organizations'
import LicensePackagesList from '../../../Common/LicensePackages/LicensePackages'
import NoResults from '../../../../components/UI/NoResults/NoResults'

const LicensePackages = ({
    getOrganization,
    getProducts,
    getVersions,
    getHardwareKeys,
    getLicensePackage,
    createLicensePackage,
    updateLicensePackage,
    removeLicensePackage,
    blockLicensePackage,
    unblockLicensePackage,
    showModal,
    toggleLoader
}: ConnectedProps<typeof connector>) => {
    const tableRef = useRef<{ reset: () => void }>(null)
    const { organizationId } = useParams<{ organizationId: string }>()
    const [status, setStatus] = useState<TStatus>('loading')
    const [organization, setOrganization] = useState<IOrganization | null>(null)

    const addHandler = async () => {
        toggleLoader(true)

        try {
            const products = await getProducts({
                desc: false,
                pageNo: 0,
                pageSize: INFINITE_NUMBER
            })

            const versions = await getVersions({
                desc: false,
                pageNo: 0,
                pageSize: INFINITE_NUMBER
            })

            const hardwareKeys = await getHardwareKeys({
                desc: false,
                pageNo: 0,
                pageSize: INFINITE_NUMBER
            })

            showModal({
                type: 'middle',
                Component: ModalAddLicensePackage,
                ComponentProps: {
                    accountId: organization?.accountId,
                    products: products.data?.data || [],
                    versions: versions.data?.data || [],
                    hardwareKeys: hardwareKeys.data?.data || [],
                    onConfirm: async (data: ILicensePackageCreate) => {
                        toggleLoader(true)

                        try {
                            const response = await createLicensePackage(data)

                            if (response?.status === 200) {
                                tableRef.current?.reset()
                            }

                            return response
                        } finally {
                            toggleLoader(false)
                        }
                    }
                }
            })
        } finally {
            toggleLoader(false)
        }
    }

    const changeHandler = async (id: string) => {
        toggleLoader(true)

        try {
            const licensePackage = await getLicensePackage(id)

            const products = await getProducts({
                desc: false,
                pageNo: 0,
                pageSize: INFINITE_NUMBER
            })

            const versions = await getVersions({
                desc: false,
                pageNo: 0,
                pageSize: INFINITE_NUMBER
            })

            const hardwareKeys = await getHardwareKeys({
                desc: false,
                pageNo: 0,
                pageSize: INFINITE_NUMBER
            })

            showModal({
                type: 'middle',
                Component: ModalEditLicensePackage,
                ComponentProps: {
                    licensePackage: licensePackage.data,
                    products: products.data?.data || [],
                    versions: versions.data?.data || [],
                    hardwareKeys: hardwareKeys.data?.data || [],
                    onConfirm: async (data: ILicensePackageUpdate) => {
                        toggleLoader(true)

                        try {
                            const response = await updateLicensePackage(id, data)

                            if (response?.status === 200) {
                                tableRef.current?.reset()
                            }

                            return response
                        } finally {
                            toggleLoader(false)
                        }
                    },
                    onRemove: async (id: string) => {
                        toggleLoader(true)

                        try {
                            const response = await removeLicensePackage(id)

                            if (response?.status === 200) {
                                tableRef.current?.reset()
                            }

                            return response
                        } finally {
                            toggleLoader(false)
                        }
                    },
                    onBlock: async (id: string) => {
                        toggleLoader(true)

                        try {
                            const response = await blockLicensePackage(id)

                            if (response?.status === 200) {
                                tableRef.current?.reset()
                            }

                            return response
                        } finally {
                            toggleLoader(false)
                        }
                    },
                    onUnblock: async (id: string) => {
                        toggleLoader(true)

                        try {
                            const response = await unblockLicensePackage(id)

                            if (response?.status === 200) {
                                tableRef.current?.reset()
                            }

                            return response
                        } finally {
                            toggleLoader(false)
                        }
                    }
                }
            })
        } finally {
            toggleLoader(false)
        }
    }

    useEffect(() => {
        getOrganization(organizationId)
            .then(response => {
                if (response?.status === 200) {
                    setOrganization(response.data)
                    setStatus('ready')
                } else {
                    setStatus('error')
                }
            })
            .catch(() => {
                setStatus('error')
            })
    }, [getOrganization, organizationId])

    if (status === 'loading') return <Loading />
    if (status === 'error') return <Error />

    return organization ? (
        <LicensePackagesList
            ref={tableRef}
            accountId={organization.accountId}
            crumbs={[
                {
                    to: `/organization/${organizationId}/`,
                    title: organization?.name || ''
                }
            ]}
            onAddClick={addHandler}
            onChangeClick={changeHandler}
        />
    ) : (
        <NoResults />
    )
}

const mapDispatch = {
    getOrganization: getOrganizationByIdHandler,
    getProducts: productGetAllHandler,
    getVersions: versionGetAllHandler,
    getHardwareKeys: hardwareKeyGetAllHandler,

    getLicensePackage: getLicensePackageByIdHandler,
    createLicensePackage: createLicensePackageHandler,
    updateLicensePackage: updateLicensePackageByIdHandler,
    removeLicensePackage: deleteLicensePackageByIdHandler,
    blockLicensePackage: blockLicensePackageByIdHandler,
    unblockLicensePackage: unblockLicensePackageByIdHandler,

    showModal: modalShowHandler,
    toggleLoader: toggleLoaderHandler
}

const connector = connect(null, mapDispatch)

export default connector(LicensePackages)
