import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export interface IPageMenuItem {
	title: string
	path: string
	icon: string
}

interface IProps {
	menu: Array<IPageMenuItem>
}

const PageMenu = ({ menu }: IProps) => {
	const { t } = useTranslation()

	return (
		<div className="organization-menu">
			<ul className="list">
				{menu.map(({ path, title, icon }, idx) => {
					return (
						<li key={idx} className="list-item">
							<Link className="list-link" to={path}>
								<div className="list-item-icon">
									<img src={icon} alt="" />
								</div>
								<div className="list-item-title">
									<strong>{t(title)}</strong>
								</div>
							</Link>
						</li>
					)
				})}
			</ul>
		</div>
	)
}

export default PageMenu
