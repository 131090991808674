import React, { ReactNode, useState } from 'react'

interface IProps {
	style?: {}
	title: string
	info: string
	isOpen?: boolean
	isDisabled?: boolean
	children: ReactNode
}

const AccordionLicensePackage = ({ title, info, isOpen = false, isDisabled = false, children, style }: IProps) => {
	const [isOpened, setIsOpened] = useState(isOpen)

	const classNames = ['form-license-pack__item']

	if (!isOpened) {
		classNames.push('form-license-pack__item--hide')
	}

	if (isDisabled) {
		classNames.push('form-license-pack__item--disabled')
	}

	return (
		<div className={classNames.join(' ')} style={style}>
			<div className="form-license-pack__heading" onClick={() => !isDisabled && setIsOpened(!isOpened)}>
				<h4 className="form-license-pack__title">{title}</h4>
				<div className="form-license-pack__separator" />
				<div className="form-license-pack__count">{info}</div>
			</div>

			<div className="form-license-pack__body">{children}</div>
		</div>
	)
}

export default AccordionLicensePackage
