import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { RootState } from '../../../store/reducers/rootReducer'
import UsersList from '../../Common/Users/Users'
import Error from '../../Error/Error'

const Users = ({ user }: ConnectedProps<typeof connector>) => {
	const account = user.accounts?.[0]

	if (!account) return <Error />

	return <UsersList organizationId={account.organization.id} accountId={account.accountId} />
}

const mapState = (state: RootState) => ({
	user: state.user.user
})

const connector = connect(mapState)

export default connector(Users)
