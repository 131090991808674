import React, { ChangeEvent, forwardRef, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InputError from '../InputError/InputError'

interface IDataItem {
	label: string
	value: string
}

interface IProps {
	data: Array<IDataItem>
	onChange?: (value: string) => void
	onSelect?: (value: string) => void
	onClear?: () => void
	isDisabled?: boolean
	defaultValue?: string
	placeholder?: string
	excludes?: Array<string>
	name?: string
	isError?: boolean
	isRequired?: boolean
	errors?: any
	ref: any
}

const Autocomplete = forwardRef(
	(
		{
			data = [],
			onChange,
			onSelect,
			isDisabled = false,
			defaultValue = '',
			placeholder = '',
			onClear,
			excludes = [],
			isError = false,
			isRequired = false,
			errors,
			name
		}: IProps,
		ref: any
	) => {
		const RESULTS_LIMIT = 10
		const { t } = useTranslation()
		const [value, setValue] = useState(defaultValue)
		const [filterValues, setFilterValues] = useState<IDataItem[]>([])
		const [isOpen, setIsOpen] = useState(false)

		const classNames = ['field', 'autocomplete-field']

		if (isError) classNames.push('field--error')
		if (isRequired) classNames.push('field--required')
		if (isDisabled) classNames.push('field--disabled')
		if (value || defaultValue) classNames.push('field--notempty')

		const onAutocompleteChange = useCallback(
			(e: ChangeEvent<HTMLInputElement>) => {
				const inputValue = e.target.value || ''

				setValue(inputValue)
				setFilterValues(
					data
						.filter(item => {
							if (excludes) {
								return !excludes.includes(item.value)
							}

							return item
						})
						.filter(item => item.label.toLowerCase().includes(inputValue.toLowerCase()))
				)

				if (typeof onChange === 'function') onChange(inputValue)

				setIsOpen(!!inputValue)
			},
			[data, excludes, onChange]
		)

		const onAutocompleteSelect = useCallback(
			(option: IDataItem) => {
				setValue(option.label)
				setFilterValues(
					data
						.filter(item => {
							if (excludes) {
								return !excludes.includes(item.value)
							}

							return item
						})
						.filter(item => item.label.toLowerCase().includes(option.label.toLowerCase()))
				)

				if (typeof onSelect === 'function') onSelect(option.value)

				setIsOpen(false)
			},
			[data, excludes, onSelect]
		)

		const onAutocompleteBlur = useCallback(() => {
			setTimeout(() => {
				setIsOpen(false)
			}, 250)
		}, [])

		const onAutocompleteFocus = useCallback((e: ChangeEvent<HTMLInputElement>) => {
			if (e.target.value !== '') {
				setIsOpen(true)
			} else {
				setIsOpen(false)
			}
		}, [])

		const onAutocompleteClear = useCallback(() => {
			setValue('')

			if (typeof onClear === 'function') onClear()

			setIsOpen(false)
		}, [onClear])

		useEffect(() => {
			if (ref && ref.current) {
				ref.current.autocompleteClear = onAutocompleteClear
			}
		}, [ref, onAutocompleteClear])

		return (
			<div
				className="autocomplete"
				style={{
					position: 'relative'
				}}
			>
				<label className={classNames.join(' ')}>
					<input
						type="text"
						className="field-input"
						name={name}
						disabled={isDisabled}
						onChange={onAutocompleteChange}
						onFocus={onAutocompleteFocus}
						onBlur={onAutocompleteBlur}
						value={value}
						ref={ref}
					/>

					{placeholder && <span className="field-label">{placeholder}</span>}

					{!isDisabled && (
						<button
							type="button"
							onClick={onAutocompleteClear}
							className="autocomplete-clear"
							style={{
								display: value ? 'block' : 'none'
							}}
						>
							&times;
						</button>
					)}
				</label>

				{isError && <InputError errors={errors} />}

				<div
					className="autocomplete-dropdown"
					style={{
						display: isOpen ? 'block' : 'none'
					}}
				>
					{filterValues.length > 0 ? (
						<ul className="list">
							{filterValues
								.map(item => (
									<li className="list-item" key={item.value} onClick={() => onAutocompleteSelect(item)}>
										{item.label}
									</li>
								))
								.slice(0, RESULTS_LIMIT)}
						</ul>
					) : (
						<div className="autocomplete-no-results">{t('common:noResults')}</div>
					)}
				</div>
			</div>
		)
	}
)

export default Autocomplete
