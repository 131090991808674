import { combineReducers } from 'redux'
import appReducer from './app'
import LicencePackageRequestReducer from './cabinet/licensePackageRequest.reducer'
import AuthReducer from './passport/auth'
import RecoveryReducer from './passport/recovery'
import UserReducer from './passport/user'
import OrganizationReducer from './passport/organization'
import NotificationReducer from './UI/notification'
import ModalReducer from './UI/modal'
import ModalConfirmReducer from './UI/modalConfirm'
import ModalErrorReducer from './UI/modalError'
import ModalLogReducer from './UI/modalLog'
import LoaderReducer from './UI/loader'
import SidebarReducer from './UI/sidebar'

export const rootReducer = combineReducers({
	app: appReducer,

	// Passport
	auth: AuthReducer,
	recovery: RecoveryReducer,
	user: UserReducer,
	organization: OrganizationReducer,
	licencePackageRequest: LicencePackageRequestReducer,

	// UI
	notification: NotificationReducer,
	modal: ModalReducer,
	modalConfirm: ModalConfirmReducer,
	modalError: ModalErrorReducer,
	modalLog: ModalLogReducer,
	loader: LoaderReducer,
	sidebar: SidebarReducer
})

export type RootState = ReturnType<typeof rootReducer>
