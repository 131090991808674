import React, { useEffect, useState } from 'react'
import Pagetitle from '../../../components/UI/Pagetitle/Pagetitle'
import SearchField from '../../../components/UI/SearchField/SearchField'
import { DEFAULT_PAGE_SIZE, INFINITE_NUMBER } from '../../../helpers/helpers'
import Button from '../../../components/UI/Button/Button'
import Pagination from '../../../components/UI/Pagination/Pagination'
import { connect, ConnectedProps } from 'react-redux'
import { createOrganizationHandler, getAllOrganizationsHandler } from '../../../services/passport/organization'
import { modalShowHandler } from '../../../store/actionCreators/UI/modal'
import { notificationAddHandler } from '../../../store/actionCreators/UI/notification'
import { Link } from 'react-router-dom'
import { toggleLoaderHandler } from '../../../store/actionCreators/UI/loader'
import ModalAddOrganization from './ModalAddOrganization'
import Loading from '../../../components/UI/Loading/Loading'
import {
	IOrganization,
	IOrganizationAPIRequest,
	IOrganizationCreate,
	TOrganizationSortFields
} from '../../../interfaces/passport/organizations'
import { IPagination, TSortColumnsTable, TStatus } from '../../../interfaces/helpers'
import SelectLimit from '../../../components/UI/Select/SelectLimit'
import { RootState } from '../../../store/reducers/rootReducer'
import Checkbox from '../../../components/UI/Checkbox/Checkbox'
import { useTranslation } from 'react-i18next'
import NoResults from '../../../components/UI/NoResults/NoResults'
import { TOnChange } from '../../../components/UI/Select/helpers'
import { tenantGetAllHandler, updateTenantByIdHandler } from '../../../services/apps/tenants'
import { getAllUsersByAccountsHandler } from '../../../services/passport/user'
import { IUserAPIRequest } from '../../../interfaces/passport/user'
import { USER } from '../../../helpers/roles'
import { ITenant, ITenantUpdate } from '../../../interfaces/apps/tenants'
import UsersTable from '../../Common/Users/UsersTable'

const Organizations = ({
	// organization,
	getOrganizations,
	getUsers,
	createOrganization,
	getTenants,
	updateTenant,
	showModal,
	notificationAdd,
	toggleLoader
}: ConnectedProps<typeof connector>) => {
	const { t } = useTranslation()
	const [status, setStatus] = useState<TStatus>('loading')

	const [tenants, setTenants] = useState<ITenant[]>([])
	const [tableOrganizations, setTableOrganizations] = useState<IOrganization[]>([])

	const [searchBy, setSearchBy] = useState<'organizations' | 'users'>('organizations')

	const [paginationOrganizations, setPaginationOrganizations] = useState<IPagination>({
		pageNo: 0,
		totalPages: 0,
		totalCount: 0
	})

	const [paginationUsers, setPaginationUsers] = useState<IPagination>({
		pageNo: 0,
		totalPages: 0,
		totalCount: 0
	})

	const [filterOrganizations, setFilterOrganizations] = useState<IOrganizationAPIRequest>({
		filter: '',
		orgSortField: 'Name',
		desc: false,
		pageSize: DEFAULT_PAGE_SIZE,
		pageNo: 0,
		showArchived: 'No'
	})

	const [pageSizeUsers, setPageSizeUsers] = useState<number>(DEFAULT_PAGE_SIZE)

	const [filterUsers, setFilterUsers] = useState<
		Omit<IUserAPIRequest, 'userSortField' | 'pageSize' | 'pageNo' | 'desc'>
	>({
		nameFilter: '',
		roleFilter: USER
	})

	const searchHandle = (value: string) => {
		if (searchBy === 'organizations') {
			setFilterOrganizations(prevState => ({
				...prevState,
				filter: value,
				pageNo: 0
			}))
		}

		if (searchBy === 'users') {
			setFilterUsers(prevState => ({
				...prevState,
				nameFilter: value,
				pageNo: 0
			}))
		}
	}

	const [sortColumnsOrganizations, setSortColumnsOrganizations] = useState<TSortColumnsTable>({
		Name: {
			desc: false,
			classNames: ['table-sort', 'table-sort--top']
		},
		ContactName: {
			desc: true,
			classNames: ['table-sort']
		},
		ContactEmail: {
			desc: true,
			classNames: ['table-sort']
		},
		Archived: {
			desc: true,
			classNames: ['table-sort']
		}
	})

	const paginationHandle = (pageNo: number) => {
		if (searchBy === 'organizations') {
			setFilterOrganizations(prevState => ({
				...prevState,
				pageNo
			}))
		}
	}

	const limitHandle: TOnChange = option => {
		if (option) {
			const limit = {
				pageSize: parseInt(option.value) || INFINITE_NUMBER,
				pageNo: 0
			}

			if (searchBy === 'organizations') {
				setFilterOrganizations(prevState => ({
					...prevState,
					...limit
				}))
			}

			if (searchBy === 'users') {
				setPageSizeUsers(parseInt(option.value) || INFINITE_NUMBER)
			}
		}
	}

	const sortOrganizationsHandle = (orgSortField: TOrganizationSortFields) => {
		const updatedSortColumns = { ...sortColumnsOrganizations }

		Object.keys(updatedSortColumns).forEach((key: string) => {
			if (key !== orgSortField) {
				updatedSortColumns[key] = {
					desc: true,
					classNames: ['table-sort']
				}
			} else {
				updatedSortColumns[key].desc = !updatedSortColumns[key].desc
				updatedSortColumns[key].classNames = [
					'table-sort',
					updatedSortColumns[key].desc ? 'table-sort--bottom' : 'table-sort--top'
				]
			}
		})

		setFilterOrganizations(prevState => ({
			...prevState,
			orgSortField,
			desc: updatedSortColumns[orgSortField].desc
		}))

		setSortColumnsOrganizations(updatedSortColumns)
	}

	const showArchivedHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFilterOrganizations({
			...filterOrganizations,
			showArchived: e.target.checked ? 'Ignore' : 'No'
		})
	}

	const addHandle = () => {
		showModal({
			type: 'large-form',
			Component: ModalAddOrganization,
			ComponentProps: {
				tenants,
				onConfirm: async (organization: IOrganizationCreate & { tenantId: string }) => {
					toggleLoader(true)

					const { tenantId, ...rest } = organization

					try {
						const response = await createOrganization(rest)

						if (response?.status === 200) {
							const tenant = tenants.filter(item => item.id === tenantId)[0]

							const updatedTenant: ITenantUpdate = {
								name: tenant.name,
								organizations: [
									...tenant.organizations,
									{
										organizationId: response.data.id,
										organizationName: response.data.name
									}
								]
							}

							const updateTenantResponse = await updateTenant(tenantId, updatedTenant)

							if (updateTenantResponse?.status === 200) {
								paginationHandle(0)
								notificationAdd(t('pages:organizations:notificationCreate'), 'info')
							}
						}

						return response
					} finally {
						toggleLoader(false)
					}
				}
			}
		})
	}

	// const removeHandle = (item: IOrganization) => {
	// 	showModalConfirm({
	// 		type: 'remove',
	// 		questionText: t('pages:organizations:modalRemoveText'),
	// 		elementText: item.name,
	// 		onConfirm() {
	// 			toggleLoader(true)
	//
	// 			removeOrganization(item.id).then(response => {
	// 				if (response?.status === 200) {
	// 					paginationHandle(0)
	//
	// 					if (organization?.id === item.id) {
	// 						clearCurrentOrganization()
	// 					}
	//
	// 					notificationAdd(t('pages:organizations:notificationRemove'), 'info')
	// 				}
	//
	// 				toggleLoader(false)
	// 			})
	// 		}
	// 	})
	// }

	useEffect(() => {
		;(async () => {
			if (searchBy === 'organizations') {
				const organizationsResponse = await getOrganizations(filterOrganizations)

				if (organizationsResponse?.status === 200) {
					const { data, pageNo, totalPages, totalCount } = organizationsResponse.data

					setTableOrganizations(data)

					setPaginationOrganizations({
						pageNo,
						totalPages,
						totalCount
					})
				}

				const tenantsResponse = await getTenants({
					desc: true,
					pageSize: INFINITE_NUMBER,
					pageNo: 0
				})

				if (tenantsResponse?.status === 200) {
					setTenants(tenantsResponse.data.data)
				}

				setStatus('ready')
			}
		})()
	}, [getOrganizations, filterOrganizations, searchBy, getTenants])

	if (status === 'loading') return <Loading />

	return (
		<>
			<Pagetitle title={t('pages:organizations:title')} />

			<div className="filters">
				<div className="filters-item filters-item--search-by">
					<div className="filters-item-label">{t('pages:organizations:searchBy')}</div>
					<div className="button-row">
						<Button
							type={searchBy === 'organizations' ? 'primary' : 'secondary'}
							htmlType={'button'}
							onClick={() => {
								setFilterOrganizations(prevState => ({
									...prevState,
									filter: ''
								}))
								setSearchBy('organizations')
							}}
						>
							{t('pages:organizations:searchByOrganizations')}
						</Button>

						<Button
							type={searchBy === 'users' ? 'primary' : 'secondary'}
							htmlType={'button'}
							onClick={() => {
								setFilterUsers(prevState => ({
									...prevState,
									nameFilter: ''
								}))
								setSearchBy('users')
							}}
						>
							{t('pages:organizations:searchByUsers')}
						</Button>
					</div>
				</div>

				<div
					className="filters-item filters-item--buttons"
					style={{
						flex: 'none'
					}}
				>
					<div className="button-row">
						<Button type={'primary'} htmlType={'button'} onClick={addHandle}>
							{t('pages:organizations:addNew')}
						</Button>
					</div>
				</div>
			</div>

			{searchBy === 'organizations' && (
				<div className="filters">
					<div className="filters-item filters-item--search">
						<SearchField onSubmit={searchHandle} placeholder={t('pages:organizations:searchField')} />
					</div>

					<div className="filters-item filters-item--view">
						<SelectLimit
							total={paginationOrganizations.totalCount}
							defaultValue={{
								label: `${filterOrganizations.pageSize}`,
								value: `${filterOrganizations.pageSize}`
							}}
							onChange={limitHandle}
						/>
					</div>

					<div className="filters-item filters-item--checkbox">
						<Checkbox name={'showArchived'} onChange={showArchivedHandler}>
							{t('pages:organizations:showArchived')}
						</Checkbox>
					</div>
				</div>
			)}

			{searchBy === 'users' && (
				<div className="filters">
					<div className="filters-item filters-item--search">
						<SearchField onSubmit={searchHandle} placeholder={t('pages:organizations:searchField')} />
					</div>

					<div className="filters-item filters-item--view">
						<SelectLimit
							total={paginationUsers.totalCount}
							defaultValue={{
								label: `${pageSizeUsers}`,
								value: `${pageSizeUsers}`
							}}
							onChange={limitHandle}
						/>
					</div>
				</div>
			)}

			{searchBy === 'organizations' && (
				<>
					{tableOrganizations.length > 0 ? (
						<div className="pagination-wrapper">
							<div className="table">
								<table>
									<colgroup>
										<col width="38.46%" />
										<col />
										<col />
										<col width="11.2%" />
									</colgroup>
									<thead>
										<tr>
											<th onClick={() => sortOrganizationsHandle('Name')}>
												<div className={sortColumnsOrganizations.Name.classNames.join(' ')}>
													{t('pages:organizations:tableHeadingName')}
												</div>
											</th>
											<th onClick={() => sortOrganizationsHandle('ContactName')}>
												<div className={sortColumnsOrganizations.ContactName.classNames.join(' ')}>
													{t('pages:organizations:tableHeadingContactName')}
												</div>
											</th>
											<th onClick={() => sortOrganizationsHandle('ContactEmail')}>
												<div className={sortColumnsOrganizations.ContactEmail.classNames.join(' ')}>
													{t('pages:organizations:tableHeadingContactEmail')}
												</div>
											</th>
											<th onClick={() => sortOrganizationsHandle('Archived')}>
												<div className={sortColumnsOrganizations.Archived.classNames.join(' ')}>
													{t('pages:organizations:tableHeadingArchive')}
													{/*{t('pages:organizations:tableHeadingDelete')}*/}
												</div>
											</th>
										</tr>
									</thead>

									<tbody>
										{tableOrganizations.map(item => {
											return (
												<tr key={item.id}>
													<td>
														<Link to={`/organization/${item.id}/`}>{item.name}</Link>
													</td>
													<td>{item.mainContact.name}</td>
													<td>
														<div className="table-nowrap">{item.mainContact.email}</div>
													</td>
													<td>
														{item.archived ? t('pages:organizations:isArchived') : t('pages:organizations:isActive')}
														{/*<div className="table-delete">*/}
														{/*	<button type="button" onClick={() => removeHandle(item)}>*/}
														{/*		<svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18">*/}
														{/*			<path d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V4H1v12zM14 1h-3.5l-1-1h-5l-1 1H0v2h14V1z" />*/}
														{/*		</svg>*/}
														{/*	</button>*/}
														{/*</div>*/}
													</td>
												</tr>
											)
										})}
									</tbody>
								</table>
							</div>

							<Pagination onClick={paginationHandle} {...paginationOrganizations} />
						</div>
					) : (
						<NoResults />
					)}
				</>
			)}

			{searchBy === 'users' && (
				<UsersTable
					columns={[
						{
							width: '30%',
							title: 'pages:users:tableHeadingName',
							fieldName: 'firstName',
							sortName: 'Name',
							mapValue: item =>
								item.user.accounts?.[0]?.organization?.id ? (
									<>
										<Link to={`/organization/${item.user.accounts?.[0]?.organization?.id}/users/${item.id}`}>
											{item.lastName} {item.firstName}
										</Link>
										<div className="table-nowrap">{item.email}</div>
									</>
								) : (
									<span>
										{item.lastName} {item.firstName}
									</span>
								)
						},
						{
							width: '15.5%',
							title: 'pages:users:tableHeadingRole',
							fieldName: 'roleName',
							sortName: 'Role'
						},
						{
							width: '30%',
							title: 'pages:users:tableHeadingOrganization',
							fieldName: 'organizationName'
						},
						{
							width: '15.5%',
							title: 'pages:users:tableHeadingStatus',
							fieldName: 'statusName'
						}
					]}
					pageSize={pageSizeUsers}
					filter={filterUsers}
					getUsers={getUsers}
					onLoaded={setPaginationUsers}
				/>
			)}
		</>
	)
}

const mapState = (state: RootState) => ({
	organization: state.organization.organization
})

const mapDispatch = {
	getOrganizations: getAllOrganizationsHandler,
	getUsers: getAllUsersByAccountsHandler,
	createOrganization: createOrganizationHandler,
	getTenants: tenantGetAllHandler,
	updateTenant: updateTenantByIdHandler,
	showModal: modalShowHandler,
	notificationAdd: notificationAddHandler,
	toggleLoader: toggleLoaderHandler
}

const connector = connect(mapState, mapDispatch)

export default connector(Organizations)
