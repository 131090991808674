import { connect, ConnectedProps } from 'react-redux'
import { IUserCreate } from '../../../../interfaces/passport/user'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import React, { useState } from 'react'
import Input from '../../../../components/UI/Input/Input'
import { REGEX_EMAIL, REGEX_PASSWORD, REGEX_PHONE } from '../../../../helpers/helpers'
import Button from '../../../../components/UI/Button/Button'
import { modalConfirmShowHandler } from '../../../../store/actionCreators/UI/modalConfirm'
import { IOrganization } from '../../../../interfaces/passport/organizations'

type TProps = ConnectedProps<typeof connector> & {
	hideModal: () => void
	organization: IOrganization
	onConfirm: (data: IUserCreate) => Promise<any>
}

interface IFormFields {
	firstName: string
	lastName: string
	patronymic?: string
	email: string
	phone?: string
	newPassword: string
	repeatPassword?: string
}

const ModalAddAdministrator = ({ hideModal, organization, onConfirm, showModalConfirm }: TProps) => {
	const { t } = useTranslation()
	const { register, watch, errors, handleSubmit, formState, getValues } = useForm<IFormFields>({
		defaultValues: {
			firstName: '',
			lastName: '',
			patronymic: '',
			email: '',
			phone: '',
			newPassword: '',
			repeatPassword: ''
		}
	})
	const { isDirty } = formState
	const [isSubmitting, setIsSubmitting] = useState(false)

	const onSubmit = async (data: IFormFields) => {
		setIsSubmitting(true)

		try {
			const response = await onConfirm({
				...data,
				role: 'ORG_ADMIN',
				accounts: [{ organization: organization.id }]
			})

			if (response?.status === 200) {
				hideModal()
			}
		} finally {
			setIsSubmitting(false)
		}
	}

	const cancelHandler = () => {
		if (isDirty) {
			showModalConfirm({
				type: 'cancel',
				onConfirm: hideModal
			})
		} else {
			hideModal()
		}
	}

	return (
		<>
			<button className="modal-close" onClick={cancelHandler} disabled={isSubmitting} />

			<h3 className="modal-title">{t('common:appName')}</h3>

			<p className="modal-footnote">{t('pages:systemUsers:modalNewText')}</p>

			<form className="form" onSubmit={handleSubmit(onSubmit)}>
				<div className="form-item">
					<Input
						currentValue={watch('lastName')}
						type={'text'}
						name={'lastName'}
						placeholder={t('pages:systemUsers:fieldLastName')}
						isRequired={true}
						isDisabled={isSubmitting}
						isError={!!errors.lastName}
						reference={register({
							required: true,
							maxLength: 255
						})}
						rules={{
							maxLength: 255
						}}
						errors={errors.lastName}
					/>
				</div>

				<div className="form-item">
					<Input
						currentValue={watch('firstName')}
						type={'text'}
						name={'firstName'}
						placeholder={t('pages:systemUsers:fieldFirstName')}
						isRequired={true}
						isDisabled={isSubmitting}
						isError={!!errors.firstName}
						reference={register({
							required: true,
							maxLength: 255
						})}
						rules={{
							maxLength: 255
						}}
						errors={errors.firstName}
					/>
				</div>

				<div className="form-item">
					<Input
						currentValue={watch('patronymic')}
						type={'text'}
						name={'patronymic'}
						placeholder={t('pages:systemUsers:fieldPatronymic')}
						isDisabled={isSubmitting}
						isError={!!errors.patronymic}
						reference={register({
							maxLength: 255
						})}
						rules={{
							maxLength: 255
						}}
						errors={errors.patronymic}
					/>
				</div>

				<div className="form-row">
					<div className="form-col">
						<div className="form-item">
							<Input
								currentValue={watch('email')}
								type={'email'}
								name={'email'}
								placeholder={t('pages:systemUsers:fieldEmail')}
								isRequired={true}
								isDisabled={isSubmitting}
								isError={!!errors.email}
								reference={register({
									required: true,
									minLength: 6,
									maxLength: 255,
									pattern: REGEX_EMAIL
								})}
								rules={{
									minLength: 6,
									maxLength: 255
								}}
								errors={errors.email}
							/>
						</div>
					</div>

					<div className="form-col">
						<div className="form-item">
							<Input
								currentValue={watch('phone')}
								type={'tel'}
								name={'phone'}
								placeholder={t('pages:systemUsers:fieldPhone')}
								isDisabled={isSubmitting}
								isError={!!errors.phone}
								reference={register({
									maxLength: 255,
									pattern: REGEX_PHONE
								})}
								rules={{
									maxLength: 255
								}}
								errors={errors.phone}
							/>
						</div>
					</div>
				</div>

				<div className="form-item">
					<Input
						currentValue={watch('newPassword')}
						type={'password'}
						name={'newPassword'}
						placeholder={t('pages:systemUsers:fieldNewPassword')}
						isRequired={true}
						isDisabled={isSubmitting}
						isError={!!errors.newPassword}
						reference={register({
							required: true,
							minLength: 8,
							maxLength: 255,
							pattern: {
								value: REGEX_PASSWORD,
								message: 'fieldPatternPassword'
							}
						})}
						rules={{
							minLength: 8,
							maxLength: 255
						}}
						errors={errors.newPassword}
					/>
				</div>

				<div className="form-item">
					<Input
						currentValue={watch('repeatPassword')}
						type={'password'}
						name={'repeatPassword'}
						placeholder={t('pages:systemUsers:fieldRepeatPassword')}
						isRequired={true}
						isDisabled={isSubmitting}
						isError={!!errors.repeatPassword}
						reference={register({
							validate: {
								passwordMatches: value => value === getValues('newPassword')
							}
						})}
						errors={errors.repeatPassword}
					/>
				</div>

				<div className="form-controls form-controls--justify">
					<Button type={'secondary'} htmlType={'button'} onClick={cancelHandler} isDisabled={isSubmitting}>
						{t('common:actionCancel')}
					</Button>

					<Button type={'primary'} htmlType={'submit'} isDisabled={isSubmitting && !isDirty}>
						{t('common:actionSave')}
					</Button>
				</div>
			</form>
		</>
	)
}

const mapDispatch = {
	showModalConfirm: modalConfirmShowHandler
}

const connector = connect(null, mapDispatch)

export default connector(ModalAddAdministrator)
