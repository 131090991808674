import {
	ORGANIZATION_CLEAR_CURRENT,
	ORGANIZATION_CREATE_ADDITIONAL_CONTACT_BY_ID,
	ORGANIZATION_DELETE_ADDITIONAL_CONTACT_BY_ID,
	ORGANIZATION_GET_BY_ID,
	ORGANIZATION_UPDATE_BY_ID,
	ORGANIZATION_UPDATE_CONTACT_BY_ID,
	ORGANIZATION_UPDATE_CURRENT
} from '../../actions/passport/organization'
import {
	IContact,
	IContactUpdate,
	IMainContactUpdate,
	IOrganization,
	IOrganizationUpdate
} from '../../../interfaces/passport/organizations'
import { OrganizationActionTypes } from '../../../interfaces/passport/actionCreators/organizations'

export function getOrganizationById(data: IOrganization): OrganizationActionTypes {
	return {
		type: ORGANIZATION_GET_BY_ID,
		payload: data
	}
}

export function updateOrganizationById(data: IOrganizationUpdate): OrganizationActionTypes {
	return {
		type: ORGANIZATION_UPDATE_BY_ID,
		payload: data
	}
}

export function createOrganizationContactById(data: IContact): OrganizationActionTypes {
	return {
		type: ORGANIZATION_CREATE_ADDITIONAL_CONTACT_BY_ID,
		payload: data
	}
}

export function updateOrganizationContactById(data: IMainContactUpdate | IContactUpdate): OrganizationActionTypes {
	return {
		type: ORGANIZATION_UPDATE_CONTACT_BY_ID,
		payload: data
	}
}

export function deleteOrganizationContactById(id: string): OrganizationActionTypes {
	return {
		type: ORGANIZATION_DELETE_ADDITIONAL_CONTACT_BY_ID,
		payload: id
	}
}

export function clearCurrentOrganization(): OrganizationActionTypes {
	return {
		type: ORGANIZATION_CLEAR_CURRENT
	}
}

export function updateCurrentOrganization(data: IOrganization): OrganizationActionTypes {
	return {
		type: ORGANIZATION_UPDATE_CURRENT,
		payload: data
	}
}
