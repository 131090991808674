import React, { useEffect, useState } from 'react'
import Button from '../../../components/UI/Button/Button'
import { modalConfirmShowHandler } from '../../../store/actionCreators/UI/modalConfirm'
import { connect, ConnectedProps } from 'react-redux'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import Input from '../../../components/UI/Input/Input'
import Textarea from '../../../components/UI/Textarea/Textarea'
import { IBuildCreate } from '../../../interfaces/cabinet/builds'
import { INPUT_FILE_ACCEPTED_FILES_HARDWARE_KEYS, PLATFORMS } from '../../../helpers/helpers'
import { IVersion } from '../../../interfaces/cabinet/versions'
import uniqBy from 'lodash/uniqBy'
import { useTranslation } from 'react-i18next'
import InputFile from '../../../components/UI/InputFile/InputFile'
import orderBy from 'lodash/orderBy'
import { selectProps } from '../../../components/UI/Select/helpers'
import Select from 'react-select'
import { MultiselectList } from '../../../components/UI/MultiselectList/MultiselectList'

type TProps = ConnectedProps<typeof connector> & {
	versions: IVersion[]
	onConfirm: (data: IBuildCreate, file: File[]) => Promise<any>
	hideModal: () => void
}

interface IFormInputs extends IBuildCreate {
	file: File[]
}

interface IProduct {
	id: string
	name: string
}

type IVersionWithSelect = IVersion & {
	selected: boolean
}

const ModalAddBuild = ({ versions, hideModal, showModalConfirm, onConfirm }: TProps) => {
	const { t } = useTranslation()
	const methods = useForm<IFormInputs>({
		defaultValues: {
			productId: '',
			versionIds: undefined,
			platform: '',
			buildVersionCode: '',
			downloadLink: '',
			description: ''
		}
	})

	const [productsList, setProductsList] = useState<IProduct[]>([])
	const [versionsList, setVersionsList] = useState<IVersionWithSelect[]>([])

	const { isDirty } = methods.formState
	const [isSubmitting, setIsSubmitting] = useState(false)

	const cancelHandler = () => {
		if (isDirty) {
			showModalConfirm({
				type: 'cancel',
				onConfirm: hideModal
			})
		} else {
			hideModal()
		}
	}

	const onSubmit = async (data: IFormInputs) => {
		setIsSubmitting(true)

		try {
			const { file, ...rest } = data

			await onConfirm(rest, file)
			hideModal()
		} finally {
			setIsSubmitting(false)
		}
	}

	useEffect(() => {
		const products = uniqBy(
			versions.map(item => {
				return { ...item.product }
			}),
			el => el.id
		)

		setProductsList(orderBy(products, ['name']))
	}, [versions])

	return (
		<>
			<button className="modal-close" onClick={cancelHandler} disabled={isSubmitting} />

			<h3 className="modal-title">{t('common:appName')}</h3>

			<p className="modal-footnote">{t('pages:builds:modalNewText')}</p>

			<FormProvider {...methods}>
				<form className="form" onSubmit={methods.handleSubmit(onSubmit)}>
					<div className="form-item">
						<Controller
							render={({ onChange, name }) => (
								<Select
									name={name}
									options={PLATFORMS.map(item => ({
										label: item,
										value: item
									}))}
									placeholder={t('pages:builds:fieldPlatform')}
									isDisabled={isSubmitting}
									isRequired={true}
									isError={!!methods.errors.platform}
									errors={methods.errors.platform}
									onChange={option => option && onChange(option.value)}
									{...selectProps}
								/>
							)}
							name={'platform'}
							control={methods.control}
							rules={{
								required: true
							}}
						/>
					</div>

					<div className="form-item">
						<Controller
							render={({ onChange, name }) => (
								<Select
									name={name}
									options={productsList.map(item => {
										return {
											label: item.name,
											value: item.id
										}
									})}
									placeholder={t('pages:builds:fieldProduct')}
									isDisabled={isSubmitting}
									isRequired={true}
									isError={!!methods.errors.productId}
									errors={methods.errors.productId}
									onChange={option => {
										if (option) {
											onChange(option.value)
											setVersionsList(
												versions
													.filter(item => item.product.id === option.value)
													.map(v => ({
														...v,
														selected: false
													}))
											)
											methods.setValue('versionId', '')
										}
									}}
									{...selectProps}
								/>
							)}
							name={'productId'}
							control={methods.control}
							rules={{
								required: true
							}}
						/>
					</div>

					<div className="form-item">
						<Controller
							render={({ onChange }) => (
								<MultiselectList
									variant="short"
									label={t('pages:builds:fieldAvailableVersions')}
									searchLabel={t('pages:builds:fieldSearchVersion')}
									isDisabled={isSubmitting || !methods.getValues().productId}
									isError={!!methods.errors.versionIds}
									isRequired
									onChange={(version, selected) => {
										const updatedVersions = versionsList.map(item => ({
											...item,
											selected: item.id === version.id ? selected : item.selected
										}))
										const selectedVersions = updatedVersions.filter(v => v.selected).map(v => v.id)

										onChange(selectedVersions.length ? selectedVersions : '')

										setVersionsList(updatedVersions)
									}}
									list={versionsList}
								/>
							)}
							name={'versionIds'}
							control={methods.control}
							rules={{
								required: true
							}}
							defaultValue={''}
						/>
					</div>

					<div className="form-item">
						<Input
							currentValue={methods.watch('buildVersionCode')}
							type={'text'}
							name={'buildVersionCode'}
							placeholder={t('pages:builds:fieldBuildVersionCode')}
							isDisabled={isSubmitting}
							isError={!!methods.errors.buildVersionCode}
							isRequired={true}
							reference={methods.register({
								required: true,
								maxLength: 255
							})}
							errors={methods.errors.buildVersionCode}
							rules={{
								maxLength: 255
							}}
						/>
					</div>

					<div className="form-item">
						<InputFile
							name={'file'}
							errors={methods.errors.file}
							isError={!!methods.errors.file}
							isDisabled={isSubmitting}
							placeholder={t('pages:builds:fieldFile')}
							// reference={register}
							accept={INPUT_FILE_ACCEPTED_FILES_HARDWARE_KEYS}
						/>
					</div>

					<div className="form-item">
						<Input
							currentValue={methods.watch('downloadLink')}
							type={'text'}
							name={'downloadLink'}
							placeholder={t('pages:builds:fieldDownloadLink')}
							isDisabled={isSubmitting}
							isError={!!methods.errors.downloadLink}
							isRequired={true}
							reference={methods.register({
								maxLength: 5000,
								required: true
							})}
							errors={methods.errors.downloadLink}
							rules={{
								maxLength: 5000
							}}
						/>
					</div>

					<div className="form-item">
						<Textarea
							currentValue={methods.watch('description')}
							name={'description'}
							placeholder={t('pages:builds:fieldDescription')}
							isDisabled={isSubmitting}
							isError={!!methods.errors.description}
							reference={methods.register({
								maxLength: 5000
							})}
							errors={methods.errors.description}
							rules={{
								maxLength: 5000
							}}
						/>
					</div>

					<div className="form-controls form-controls--justify">
						<Button type={'secondary'} htmlType={'button'} onClick={cancelHandler} isDisabled={isSubmitting}>
							{t('common:actionCancel')}
						</Button>

						<Button type={'primary'} htmlType={'submit'} isDisabled={isSubmitting || !isDirty}>
							{t('common:actionSave')}
						</Button>
					</div>
				</form>
			</FormProvider>
		</>
	)
}

const mapDispatch = {
	showModalConfirm: modalConfirmShowHandler
}

const connector = connect(null, mapDispatch)

export default connector(ModalAddBuild)
