import React from 'react'
import { IOrganizationCustom } from '../ModalAddNotification'

interface IProps {
	title: string
	list: Array<IOrganizationCustom>
	name: string
	onChange: (item: IOrganizationCustom, selected: boolean) => void
}

const Multiselect = ({ title, list, name, onChange }: IProps) => {
	return (
		<div className="multiselect">
			<div className="multiselect-label">
				<span>{title}</span>
			</div>

			<ul className="list">
				{list.map((item, idx) => {
					return (
						<li key={item.id} className="list-item">
							<label>
								<input
									type="checkbox"
									name={`${name}-${idx}`}
									checked={item.selected}
									onChange={event => onChange(item, event.target.checked)}
								/>
								<span>{item.name}</span>
							</label>
						</li>
					)
				})}
			</ul>
		</div>
	)
}

export default Multiselect
