import React, { useEffect, useState } from 'react'
import Pagetitle from '../../../components/UI/Pagetitle/Pagetitle'
import SearchField from '../../../components/UI/SearchField/SearchField'
import Button from '../../../components/UI/Button/Button'
import Pagination from '../../../components/UI/Pagination/Pagination'
import { DEFAULT_PAGE_SIZE, INFINITE_NUMBER } from '../../../helpers/helpers'
import { modalShowHandler } from '../../../store/actionCreators/UI/modal'
import { connect, ConnectedProps } from 'react-redux'
import {
	IHardwareKey,
	IHardwareKeyAPIRequest,
	IHardwareKeyCreate,
	IHardwareKeyUpdate,
	THardwareKeySortFields
} from '../../../interfaces/cabinet/hardwareKeys'
import {
	createHardwareKeyHandler,
	deleteHardwareKeyByIdsHandler,
	hardwareKeyGetAllHandler,
	updateHardwareKeyByIdHandler,
	uploadHardwareKeyHandler
} from '../../../services/cabinet/hardwareKeys'
import { IPagination, TSortColumnsTable, TStatus } from '../../../interfaces/helpers'
import ModalAddHardwareKey from './ModalAddHardwareKey'
import { modalConfirmShowHandler } from '../../../store/actionCreators/UI/modalConfirm'
import { toggleLoaderHandler } from '../../../store/actionCreators/UI/loader'
import { notificationAddHandler } from '../../../store/actionCreators/UI/notification'
import ModalEditHardwareKey from './ModalEditHardwareKey'
import ModalUpload from './ModalUpload'
import SelectLimit from '../../../components/UI/Select/SelectLimit'
import NoResults from '../../../components/UI/NoResults/NoResults'
import Loading from '../../../components/UI/Loading/Loading'
import { useTranslation } from 'react-i18next'
import { TOnChange } from '../../../components/UI/Select/helpers'
import { modalLogShowHandler } from '../../../store/actionCreators/UI/modalLog'

interface ITableHardwareKey extends IHardwareKey {
	selected: boolean
}

const HardwareKeys = ({
	getHardwareKeys,
	createHardwareKey,
	updateHardwareKey,
	removeHardwareKeys,
	uploadHardwareKeys,
	showModal,
	showModalConfirm,
	showModalLog,
	notificationAdd,
	toggleLoader
}: ConnectedProps<typeof connector>) => {
	const { t } = useTranslation()
	const [status, setStatus] = useState<TStatus>('loading')
	const [isCheckboxAllChecked, setIsCheckboxAllChecked] = useState(false)
	const [isRemoveButtonDisabled, setIsRemoveButtonDisabled] = useState(true)
	const [table, setTable] = useState<ITableHardwareKey[]>([])
	const [pagination, setPagination] = useState<IPagination>({
		pageNo: 0,
		totalPages: 0,
		totalCount: 0
	})

	const [filterHardwareKeys, setFilterHardwareKeys] = useState<IHardwareKeyAPIRequest>({
		filter: '',
		sortField: 'Code',
		desc: false,
		pageSize: DEFAULT_PAGE_SIZE,
		pageNo: 0
	})

	const searchHandle = (value: string) => {
		setFilterHardwareKeys({
			...filterHardwareKeys,
			filter: value,
			pageNo: 0
		})
	}

	const [sortColumns, setSortColumns] = useState<TSortColumnsTable>({
		Code: {
			desc: false,
			classNames: ['table-sort', 'table-sort--top']
		},
		Type: {
			desc: true,
			classNames: ['table-sort']
		},
		Account: {
			desc: true,
			classNames: ['table-sort']
		}
	})

	const paginationHandle = (pageNo: number) => {
		setFilterHardwareKeys({
			...filterHardwareKeys,
			pageNo
		})
	}

	const sortHandle = (sortField: THardwareKeySortFields) => {
		const updatedSortColumns = { ...sortColumns }

		Object.keys(updatedSortColumns).forEach((key: string) => {
			if (key !== sortField) {
				updatedSortColumns[key] = {
					desc: true,
					classNames: ['table-sort']
				}
			} else {
				updatedSortColumns[key].desc = !updatedSortColumns[key].desc
				updatedSortColumns[key].classNames = [
					'table-sort',
					updatedSortColumns[key].desc ? 'table-sort--bottom' : 'table-sort--top'
				]
			}
		})

		setFilterHardwareKeys({
			...filterHardwareKeys,
			sortField,
			desc: updatedSortColumns[sortField].desc
		})

		setSortColumns(updatedSortColumns)
	}

	const limitHandle: TOnChange = option => {
		if (option) {
			setFilterHardwareKeys({
				...filterHardwareKeys,
				pageSize: parseInt(option.value) || INFINITE_NUMBER,
				pageNo: 0
			})
		}
	}

	const checkboxHandle = (id: string, selected: boolean) => {
		const updatedTable = [...table]
		const index = updatedTable.findIndex(user => user.id === id)

		updatedTable[index].selected = selected

		setTable(updatedTable)
		controlsStatusHandle()
	}

	const checkboxAllHandle = (selected: boolean) => {
		const updatedTable = [...table]
		updatedTable.map(user => (user.selected = selected))

		setTable(updatedTable)
		controlsStatusHandle()
	}

	const controlsStatusHandle = () => {
		const checkedCount = table.filter(item => item.selected).length

		setIsRemoveButtonDisabled(checkedCount === 0)
		setIsCheckboxAllChecked(checkedCount === table.length)
	}

	const uploadHandler = () => {
		showModal({
			type: 'middle',
			Component: ModalUpload,
			ComponentProps: {
				onConfirm: async (file: File[]) => {
					toggleLoader(true)

					try {
						const response = await uploadHardwareKeys(file)

						if (response?.status === 200) {
							if (!response.data.errorCount) {
								notificationAdd(t('pages:hardwareKeys:notificationUpload'), 'info')
							} else {
								showModalLog({
									details: response.data
								})

								notificationAdd(t('pages:hardwareKeys:notificationUploadError'), 'info')
							}
						}

						return response
					} finally {
						paginationHandle(0)
						toggleLoader(false)
					}
				}
			}
		})
	}

	const createHandler = () => {
		showModal({
			type: 'middle',
			Component: ModalAddHardwareKey,
			ComponentProps: {
				onConfirm: async (data: IHardwareKeyCreate) => {
					toggleLoader(true)

					try {
						const response = await createHardwareKey(data)

						if (response?.status === 200) {
							paginationHandle(0)
						}

						return response
					} finally {
						toggleLoader(false)
					}
				}
			}
		})
	}

	const updateHandler = (item: IHardwareKey) => {
		showModal({
			type: 'description',
			Component: ModalEditHardwareKey,
			ComponentProps: {
				item,
				onConfirm: async (data: IHardwareKeyUpdate) => {
					toggleLoader(true)

					try {
						const response = await updateHardwareKey(item.id, data)

						if (response?.status === 200) {
							paginationHandle(0)
						}

						return response
					} finally {
						toggleLoader(false)
					}
				}
			}
		})
	}

	const removeHandler = async () => {
		const selectedElementsText = table
			.filter(item => item.selected)
			.map(item => `${item.code}`)
			.join(', ')

		const selectedElementsIds = table
			.filter(item => item.selected)
			.map(item => item.id)
			.join(',')

		showModalConfirm({
			type: 'remove',
			elementText: selectedElementsText,
			onConfirm: async () => {
				toggleLoader(true)

				try {
					const response = await removeHardwareKeys(selectedElementsIds)

					if (response?.status === 200) {
						paginationHandle(0)
					}

					return response
				} finally {
					setIsCheckboxAllChecked(false)
					setIsRemoveButtonDisabled(true)
					toggleLoader(false)
				}
			}
		})
	}

	useEffect(() => {
		getHardwareKeys(filterHardwareKeys).then(response => {
			if (response?.status === 200) {
				const { data, pageNo, totalPages, totalCount } = response.data

				const hardwareKeys: Array<ITableHardwareKey> = data.map(item => {
					return {
						...item,
						selected: false
					}
				})

				setTable(hardwareKeys)

				setPagination({
					pageNo,
					totalPages,
					totalCount
				})

				setStatus('ready')
			}
		})
	}, [getHardwareKeys, filterHardwareKeys])

	if (status === 'loading') return <Loading />

	return (
		<>
			<Pagetitle title={t('pages:hardwareKeys:title')} />

			<div className="filters">
				<div className="filters-item filters-item--search">
					<SearchField onSubmit={searchHandle} placeholder={t('pages:hardwareKeys:searchField')} />
				</div>

				<div className="filters-item filters-item--view">
					<SelectLimit total={pagination.totalCount} onChange={limitHandle} />
				</div>

				<div className="filters-item filters-item--buttons">
					<div className="button-row">
						<Button type={'secondary'} htmlType={'button'} onClick={uploadHandler}>
							{t('pages:hardwareKeys:uploadFile')}
						</Button>

						<Button type={'primary'} htmlType={'button'} onClick={createHandler}>
							{t('pages:hardwareKeys:addNew')}
						</Button>
					</div>
				</div>
			</div>

			{table.length > 0 ? (
				<div className="pagination-wrapper">
					<div className="table">
						<table>
							<colgroup>
								<col width="5.22%" />
								<col />
								<col width="25%" />
								<col width="28%" />
							</colgroup>
							<thead>
								<tr>
									<th>
										<div className="table-checkbox">
											<label className="checkbox">
												<input
													type="checkbox"
													checked={isCheckboxAllChecked}
													onChange={e => checkboxAllHandle(e.target.checked)}
												/>
												<span />
											</label>
										</div>
									</th>
									<th onClick={() => sortHandle('Code')}>
										<div className={sortColumns.Code.classNames.join(' ')}>
											{t('pages:hardwareKeys:tableHeadingCode')}
										</div>
									</th>
									<th onClick={() => sortHandle('Type')}>
										<div className={sortColumns.Type.classNames.join(' ')}>
											{t('pages:hardwareKeys:tableHeadingType')}
										</div>
									</th>
									<th onClick={() => sortHandle('Account')}>
										<div className={sortColumns.Account.classNames.join(' ')}>
											{t('pages:hardwareKeys:tableHeadingAccount')}
										</div>
									</th>
								</tr>
							</thead>

							<tbody>
								{table.map(item => {
									return (
										<tr key={item.id}>
											<td>
												<div className="table-checkbox">
													<label className="checkbox">
														<input
															type="checkbox"
															checked={item.selected}
															onChange={e => checkboxHandle(item.id, e.target.checked)}
														/>
														<span />
													</label>
												</div>
											</td>
											<td>
												<div className="table-break">
													<button className="button-link" type="button" onClick={() => updateHandler(item)}>
														{item.code}
													</button>
												</div>
											</td>
											<td>{item.type}</td>
											<td>
												<div className="table-break">{item.account}</div>
											</td>
										</tr>
									)
								})}
							</tbody>
						</table>
					</div>

					<div className="group-actions">
						<div className="group-actions-control">
							<Button type="secondary" htmlType={'button'} isDisabled={isRemoveButtonDisabled} onClick={removeHandler}>
								{t('common:actionRemove')}
							</Button>
						</div>
					</div>

					<Pagination onClick={paginationHandle} {...pagination} />
				</div>
			) : (
				<NoResults />
			)}
		</>
	)
}

const mapDispatch = {
	getHardwareKeys: hardwareKeyGetAllHandler,
	createHardwareKey: createHardwareKeyHandler,
	updateHardwareKey: updateHardwareKeyByIdHandler,
	removeHardwareKeys: deleteHardwareKeyByIdsHandler,
	uploadHardwareKeys: uploadHardwareKeyHandler,

	showModal: modalShowHandler,
	showModalConfirm: modalConfirmShowHandler,
	showModalLog: modalLogShowHandler,
	notificationAdd: notificationAddHandler,
	toggleLoader: toggleLoaderHandler
}

const connector = connect(null, mapDispatch)

export default connector(HardwareKeys)
