import React from 'react'
import { IPagination } from '../../../interfaces/helpers'
import { useTranslation } from 'react-i18next'

interface IProps extends IPagination {
	onClick: (page: number) => void
}

export default function Pagination({ onClick, pageNo, totalPages, totalCount }: IProps) {
	const { t } = useTranslation()

	return (
		<>
			{totalPages > 1 && (
				<ul className="list list--pagination">
					<li className="list-item list-item--start">
						<button className="list-link" onClick={() => onClick(0)} disabled={pageNo === 0}>
							{t('common:PaginationFirst')}
						</button>
					</li>
					<li className="list-item list-item--prev">
						<button className="list-link" onClick={() => onClick(pageNo - 1)} disabled={pageNo < 1}>
							{t('common:PaginationPrev')}
						</button>
					</li>

					{Array.from(Array(totalPages).keys()).map(page => {
						const classNames = ['list-item']

						if (page === pageNo) classNames.push('list-item--active')

						return (
							<li className={classNames.join(' ')} key={page}>
								<button className="list-link" onClick={() => onClick(page)}>
									{page + 1}
								</button>
							</li>
						)
					})}

					<li className="list-item list-item--next">
						<button className="list-link" onClick={() => onClick(pageNo + 1)} disabled={pageNo >= totalPages - 1}>
							{t('common:PaginationNext')}
						</button>
					</li>
					<li className="list-item list-item--end">
						<button className="list-link" onClick={() => onClick(totalPages - 1)} disabled={pageNo === totalPages - 1}>
							{t('common:PaginationLast')}
						</button>
					</li>
				</ul>
			)}
		</>
	)
}
