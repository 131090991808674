import axios from 'axios'
import { API_CABINET_PATH, APIServices } from '../../helpers/api'
import { setErrorHandler } from '../../store/actionCreators/error'
import { IProduct, IProductAPIRequest, IProductOrganization, IProductUpdate } from '../../interfaces/cabinet/products'
import { APIResponse, AppThunk } from '../../interfaces/helpers'
import { serviceHandlerErrorOptions } from '../helpers'

export function productGetAllHandler(
	params: IProductAPIRequest,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<APIResponse<IProduct[]>> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<APIResponse<IProduct[]>>({
				method: 'GET',
				url: `${API_CABINET_PATH}/product`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params
			})
			return response
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}

export function createProductHandler(
	data: IProductUpdate,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IProduct> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IProduct>({
				method: 'POST',
				url: `${API_CABINET_PATH}/product`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				data
			})
			return response
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}

export function updateProductByIdHandler(
	id: string,
	data: IProductUpdate,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IProduct> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IProduct>({
				method: 'PUT',
				url: `${API_CABINET_PATH}/product/${id}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id
				},
				data
			})
			return response
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}

export function deleteProductByIdHandler(id: string, errorOptions = serviceHandlerErrorOptions): AppThunk {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request({
				method: 'DELETE',
				url: `${API_CABINET_PATH}/product/${id}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id
				}
			})
			return response
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}

export function productOrgGetAllHandler(
	accountId: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<IProductOrganization[]> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<IProductOrganization[]>({
				method: 'GET',
				url: `${API_CABINET_PATH}/product/list`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					accountId
				}
			})
			return response
		} catch (error) {
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response
		}
	}
}
