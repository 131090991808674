import { USER_CLEAR_DATA, USER_GET_DATA, USER_SET_DATA } from '../../actions/passport/user'
import { IUser } from '../../../interfaces/passport/user'
import { UserActionTypes } from '../../../interfaces/passport/actionCreators/users'
import { USER } from '../../../helpers/roles'

interface IInitialState {
	user: IUser
}

const initialState: IInitialState = {
	user: {
		id: '',
		role: USER,
		firstName: '',
		lastName: '',
		email: '',
		status: ''
	}
}

export default function UserReducer(state = initialState, action: UserActionTypes): IInitialState {
	switch (action.type) {
		case USER_GET_DATA:
			return {
				...state,
				user: action.payload
			}

		case USER_SET_DATA:
			return {
				...state,
				user: action.payload
			}

		case USER_CLEAR_DATA:
			return {
				...state,
				user: initialState.user
			}

		default:
			return state
	}
}
