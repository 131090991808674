import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { modalConfirmShowHandler } from '../../../store/actionCreators/UI/modalConfirm'
import { connect, ConnectedProps } from 'react-redux'
import Button from '../../../components/UI/Button/Button'
import { IRequestTopic } from '../../../interfaces/support/requestTopic'
import Select from 'react-select'
import { selectProps } from '../../../components/UI/Select/helpers'
import Input from '../../../components/UI/Input/Input'
import Textarea from '../../../components/UI/Textarea/Textarea'
import { getStatusName, stringToLocalization, SUPPORT_STATUSES } from '../../../helpers/helpers'
import { TSupportStates } from '../../../interfaces/support/common'
import {
	ISupportRequestEmployee,
	ISupportRequestEmployeeUpdate
} from '../../../interfaces/support/supportRequestEmployee'
import omit from 'lodash/omit'

type TProps = ConnectedProps<typeof connector> & {
	ticket: ISupportRequestEmployee
	topics: Array<IRequestTopic>
	hideModal: () => void
	onConfirm: (data: ISupportRequestEmployeeUpdate) => Promise<any>
}

interface IFormInputs {
	state: TSupportStates
	topicId: string
	subject: string
	message: string
}

const ModalEditTicket = ({ ticket, topics, onConfirm, showModalConfirm, hideModal }: TProps) => {
	const { t } = useTranslation()

	const methods = useForm<IFormInputs>({
		defaultValues: {
			state: ticket.state,
			topicId: ticket.topic.id,
			subject: ticket.subject,
			message: ticket.message
		}
	})
	const { isDirty } = methods.formState
	const [isSubmitting, setIsSubmitting] = useState(false)

	const cancelHandler = () => {
		if (isDirty) {
			showModalConfirm({
				type: 'cancel',
				onConfirm: hideModal
			})
		} else {
			hideModal()
		}
	}

	const onSubmit = async (data: IFormInputs) => {
		setIsSubmitting(true)

		try {
			const result: ISupportRequestEmployeeUpdate = {
				...omit(ticket, ['topic', 'attaches', 'comments']),
				state: data.state,
				topicId: data.topicId,
				subject: data.subject,
				message: data.message
			}

			const response = await onConfirm(result)

			if (response?.status === 200) {
				hideModal()
			}
		} finally {
			setIsSubmitting(false)
		}
	}

	return (
		<>
			<button className="modal-close" onClick={cancelHandler} disabled={isSubmitting} />

			<h3 className="modal-title">{t('common:appName')}</h3>

			<p className="modal-footnote">{t('pages:support:modalUpdateTopicText')}</p>

			<FormProvider {...methods}>
				<form className="form" onSubmit={methods.handleSubmit(onSubmit)}>
					<div className="form-item">
						<Controller
							render={({ onChange, name }) => (
								<Select
									name={name}
									defaultValue={{
										label: t(getStatusName(ticket.state)),
										value: ticket.state
									}}
									options={SUPPORT_STATUSES.map(item => {
										return {
											label: t(getStatusName(item)),
											value: item
										}
									})}
									placeholder={t('pages:support:fieldState')}
									isDisabled={isSubmitting}
									isRequired={true}
									isError={!!methods.errors.state}
									errors={methods.errors.state}
									onChange={option => option && onChange(option.value)}
									{...selectProps}
								/>
							)}
							name={'state'}
							control={methods.control}
							rules={{
								required: true
							}}
							defaultValue={ticket.state}
						/>
					</div>

					<div className="form-item">
						<Controller
							render={({ onChange, name }) => (
								<Select
									name={name}
									defaultValue={
										topics
											.filter(item => item.id === ticket.topic.id)
											.map(item => {
												return {
													label: t(`common:topic_${stringToLocalization(item.name)}`),
													value: item.id
												}
											})[0]
									}
									options={topics.map(item => {
										return {
											label: t(`common:topic_${stringToLocalization(item.name)}`),
											value: item.id
										}
									})}
									placeholder={t('pages:support:fieldTopic')}
									isDisabled={isSubmitting}
									isRequired={true}
									isError={!!methods.errors.topicId}
									errors={methods.errors.topicId}
									onChange={option => option && onChange(option.value)}
									{...selectProps}
								/>
							)}
							name={'topicId'}
							control={methods.control}
							rules={{
								required: true
							}}
							defaultValue={ticket.topic.id}
						/>
					</div>

					<div className="form-item">
						<Input
							currentValue={methods.watch('subject')}
							type={'text'}
							name={'subject'}
							placeholder={t('pages:support:fieldSubject')}
							isDisabled={isSubmitting}
							isError={!!methods.errors.subject}
							isRequired={true}
							reference={methods.register({
								required: true,
								maxLength: 255
							})}
							errors={methods.errors.subject}
							rules={{
								maxLength: 255
							}}
						/>
					</div>

					<div className="form-item">
						<Textarea
							className={'field--textarea-big'}
							currentValue={methods.watch('message')}
							name={'message'}
							placeholder={t('pages:support:fieldMessage')}
							isDisabled={isSubmitting}
							isRequired={true}
							isError={!!methods.errors.message}
							reference={methods.register({
								required: true,
								maxLength: 5000
							})}
							errors={methods.errors.message}
							rules={{
								maxLength: 5000
							}}
						/>
					</div>

					<div className="form-controls form-controls--right">
						<Button type={'secondary'} htmlType={'button'} onClick={cancelHandler} isDisabled={isSubmitting}>
							{t('common:actionCancel')}
						</Button>

						<Button type={'primary'} htmlType={'submit'} isDisabled={isSubmitting || !isDirty}>
							{t('common:actionUpdate')}
						</Button>
					</div>
				</form>
			</FormProvider>
		</>
	)
}

const mapDispatch = {
	showModalConfirm: modalConfirmShowHandler
}

const connector = connect(null, mapDispatch)

export default connector(ModalEditTicket)
