import React from 'react'
import { ISelfLicense } from '../../../interfaces/cabinet/license'
import { useTranslation } from 'react-i18next'
import { DATE_FORMAT } from '../../../helpers/helpers'
import dayjs from 'dayjs'

type LicenseCardProps = {
	license: ISelfLicense
	onOpenModal: (license: ISelfLicense) => void
	onOpenModalDetails: (license: ISelfLicense) => void
	onActivationCode: (license: ISelfLicense) => void
	onUnlinkHardware: (license: ISelfLicense) => void
}
export const LicenseCard = ({
	license,
	onOpenModal,
	onOpenModalDetails,
	onActivationCode,
	onUnlinkHardware
}: LicenseCardProps) => {
	const { t } = useTranslation()
	const isCodeActivated = license.activated
	const isDeviceActivated = !!license.activationDate

	return (
		<div className="license-card">
			<div
				className="license-card__header"
				onClick={() => (isDeviceActivated ? onOpenModal(license) : onOpenModalDetails(license))}
			>
				{license.productName}
			</div>

			<div className="license-card__body">
				<div className="license-card__prop">
					<div className="license-card__propLabel">{t('pages:home:cardActivatedOnDevice')}:</div>
					<div className="license-card__propValue">{license.activeDevice || '-'}</div>
				</div>

				<div className="license-card__prop">
					<div className="license-card__propLabel">{t('pages:home:cardVersion')}:</div>
					<div
						className="license-card__propValue"
						onClick={() => (isDeviceActivated ? onOpenModal(license) : onOpenModalDetails(license))}
					>
						{license.versionName || '-'}
					</div>
				</div>

				<div className="license-card__prop">
					<div className="license-card__propLabel">{t('pages:home:cardDaysUntilLicenseExpires')}:</div>
					<div className="license-card__propValue">
						{license.termless ? t('pages:home:termless') : null}
						{license.dateFrom && license.dateTo && (
							<>
								{`${t('pages:home:valid')} ${t('common:from')} ${dayjs(license.dateFrom).format(DATE_FORMAT)} ${t(
									'common:to'
								)} ${dayjs(license.dateTo).format(DATE_FORMAT)}`}
							</>
						)}
						{license.dateFrom && !license.dateTo && (
							<>{`${t('pages:home:valid')} ${t('common:from')} ${dayjs(license.dateFrom).format(DATE_FORMAT)}`}</>
						)}
						{!license.dateFrom && license.dateTo && (
							<>{`${t('pages:home:valid')} ${t('common:to')} ${dayjs(license.dateTo).format(DATE_FORMAT)}`}</>
						)}
					</div>
				</div>
			</div>

			<div className="license-card__footer">
				{!isCodeActivated ? (
					<>
						{license.dateFrom && dayjs(license.dateFrom).unix() > dayjs().unix() ? (
							<>
								<div className="license-card__label">
									{t('pages:home:futureLicenseDate', {
										date: dayjs(license.dateFrom).format(DATE_FORMAT)
									})}
								</div>
								<button className="button" onClick={() => onOpenModalDetails(license)}>
									{t('common:actionDetails')}
								</button>
							</>
						) : (
							<button className="button" onClick={() => onActivationCode(license)}>
								{t('pages:home:activate')}
							</button>
						)}
					</>
				) : isDeviceActivated ? (
					<>
						{license.activeDevice && !license.termless && (
							<button className="button" onClick={() => onUnlinkHardware(license)}>
								{t('pages:home:unlink')}
							</button>
						)}
					</>
				) : null}
			</div>
		</div>
	)
}
