import { TLicensePackageRequestStatus } from '../interfaces/cabinet/licensePackageRequest'
import { TDatePickerDate, TLocales } from '../interfaces/helpers'
import dayjs from 'dayjs'
import axios from 'axios'
import { ACTIVATED, BLOCKED, CONFIRMED, DELETED } from './roles'

export const REGEX_EMAIL = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
export const REGEX_PHONE = /^((8|\+7)[- ]?)?(\(?\d{3,6}\)?[- ]?)?[\d\- ]{4,10}$/i
export const REGEX_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d\S]{8,}$/
export const REGEX_V4 = /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
export const REGEX_INN = /^(\d{10}|\d{12})$/i
export const REGEX_HARDWARE_KEY_CODE = /[0-9A-Fa-f]{8}/i

export const DATE_FORMAT = 'DD.MM.YYYY'
export const DATE_FORMAT_FULL = 'DD.MM.YYYY HH:mm'
export const DATE_FORMAT_INPUT = 'YYYY-MM-DD'
export const DATE_FORMAT_API = 'YYYY-MM-DDTHH:mm:ss.SSSZ'
export const DATE_FORMAT_API_SHORT = 'YYYY-MM-DD'
export const DATE_FORMAT_DATEPICKER = 'dd.MM.yyyy'

export const DATE_API_MIN = '1900-01-01'
export const DATE_API_MAX = '2999-12-31'

export const INFINITE_NUMBER = 999999999

export const MAX_ORGANIZATION_CONTACTS = 25

export const NEW = 'NEW'
export const PROCESSED = 'PROCESSED'
export const IN_PROCESSING = 'IN_PROCESSING'
export const TEMP_SOLUTION = 'TEMP_SOLUTION'
export const SOLVED = 'SOLVED'
export const REJECTED = 'REJECTED'
export const ACTIVE = 'ACTIVE'
export const BLOCK = 'BLOCK'

export const LOW = 'LOW'
export const MIDDLE = 'MIDDLE'
export const HIGH = 'HIGH'

export enum EPlatforms {
	'Windows_x64' = 'Windows_x64',
	'Linux_x86_64' = 'Linux_x86_64',
	'MacOS' = 'MacOS',
	'Android' = 'Android',
	'iOS' = 'iOS'
}

export const PLATFORMS = ['Windows_x64', 'Linux_x86_64', 'MacOS', 'Android', 'iOS']
export const HARDWARE_KEY_TYPES = [
	'sign',
	'microSign',
	'signTime',
	'signNet10',
	'signNet50',
	'signNetTime10',
	'signNetTime50'
]
export const LICENSE_PACKAGE_REQUEST_STATUSES: TLicensePackageRequestStatus[] = [NEW, PROCESSED]
export const SUPPORT_STATUSES = [NEW, IN_PROCESSING, TEMP_SOLUTION, SOLVED, REJECTED]
export const LICENSE_PACKAGE_STATUSES = [NEW, ACTIVE, BLOCK]
export const LOCALES: TLocales[] = ['RU', 'EN', 'KK', 'VI', 'DE', 'ZH']
export const LOCALES_EXISTS: TLocales[] = ['RU', 'EN']

export const DEFAULT_LOCALE = 'EN'
export const DEFAULT_PAGE_SIZE = 10

export const INPUT_FILE_ACCEPTED_IMAGES = 'image/png, image/gif, image/jpeg'
export const INPUT_FILE_ACCEPTED_FILES = '.csv'
export const INPUT_FILE_ACCEPTED_FILES_HARDWARE_KEYS = '.zip'
export const INPUT_FILE_LIMIT = 7340032

export const EMAIL_SUPPORT = 'support@nash-pirogov.ru'
export const EMAIL_ADMIN = 'admin@nash-pirogov.ru'

export const STORE_LINKS: {
	[key in 'ru' | 'en']: {
		[key in EPlatforms.iOS | EPlatforms.Android]: string
	}
} = {
	ru: {
		[EPlatforms.iOS]: 'https://apps.apple.com/ru/app/pirogov-anatomy/id1558999076',
		[EPlatforms.Android]: 'https://play.google.com/store/apps/details?id=ru.nash_pirogov.atlas.v21&hl=ru_RU'
	},
	en: {
		[EPlatforms.iOS]: 'https://apps.apple.com/en/app/pirogov-anatomy/id1558999076',
		[EPlatforms.Android]: 'https://play.google.com/store/apps/details?id=ru.nash_pirogov.atlas.v21&hl=en_US'
	}
}

export function getStatusName(status: string): string {
	switch (status) {
		case NEW:
			return 'common:statusNew'

		case PROCESSED:
			return 'common:statusProcessed'

		case IN_PROCESSING:
			return 'common:statusInProcessing'

		case TEMP_SOLUTION:
			return 'common:statusTempSolution'

		case SOLVED:
			return 'common:statusSolved'

		case REJECTED:
			return 'common:statusRejected'

		default:
			return status
	}
}

export function getPackageStatusName(status: string): string {
	switch (status) {
		case NEW:
			return 'common:statusPackageNew'

		case ACTIVE:
			return 'common:statusPackageActive'

		case BLOCK:
			return 'common:statusPackageBlock'

		default:
			return status
	}
}

export function getUserStatusName(status: string): string {
	switch (status) {
		case CONFIRMED:
			return 'common:statusUserConfirmed'

		case ACTIVATED:
			return 'common:statusUserActivated'

		case BLOCKED:
			return 'common:statusUserBlocked'

		case DELETED:
			return 'common:statusUserDeleted'

		default:
			return status
	}
}

export function getPlatformName(platform: string): string {
	switch (platform) {
		case 'Android':
			return 'common:platformAndroid'

		case 'iOS':
			return 'common:platformIOS'

		case 'Windows_x64':
			return 'common:platformWindows'

		case 'Linux_x86_64':
			return 'common:platformLinux'

		case 'MacOS':
			return 'common:platformMacOS'

		default:
			return platform
	}
}

export function getPriorityName(priority: string): string {
	switch (priority) {
		case LOW:
			return 'common:priorityLow'

		case MIDDLE:
			return 'common:priorityMiddle'

		case HIGH:
			return 'common:priorityHigh'

		default:
			return priority
	}
}

export function getLocaleName(locale: TLocales = DEFAULT_LOCALE): string {
	return `common:locale${locale}`
}

export const getDate = (date: TDatePickerDate, dateFormat = DATE_FORMAT_API): string =>
	date ? dayjs(date).format(dateFormat) : ''

export const getScrollBarWidth = (): number => window.innerWidth - document.body.clientWidth

export function truncateString(text: string = '', limit: number = 100): string {
	if (text.length <= limit) {
		return text
	}
	return `${text.slice(0, limit)}...`
}

export function declensionString(
	oneNominative: string = '',
	severalGenitive: string = '',
	severalNominative: string = '',
	number: number = 0
): string {
	let num = number % 100

	return num <= 14 && num >= 11
		? severalGenitive
		: (num %= 10) < 5
			? num > 2
				? severalNominative
				: num === 1
					? oneNominative
					: num === 0
						? severalGenitive
						: severalNominative
			: severalGenitive
}

export function formatUsername(data: { firstName?: string; lastName?: string; patronymic?: string }): string {
	const first = data.firstName ? `${data.firstName.charAt(0).toUpperCase()}.` : ''
	const patronymic = data.patronymic ? `${data.patronymic.charAt(0).toUpperCase()}.` : ''
	const lastName = data.lastName ? data.lastName : ''

	return `${lastName} ${first}${patronymic}`
}

export function formatString(text: string = ''): string {
	return text.replace(new RegExp('(?:\\r\\n|\\r|\\n)', 'g'), '<br />')
}

export function formatBytes(bytes: number = 0, decimals: number = 2): string {
	if (bytes === 0) return '0 Bytes'

	const k = 1024
	const dm = decimals < 0 ? 0 : decimals
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

	const i = Math.floor(Math.log(bytes) / Math.log(k))

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export async function fileToBase64(file: File | Blob): Promise<any> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()

		reader.onload = () => {
			const data = reader.result as string
			const result = data.split(',')[1] || ''

			return resolve(result)
		}
		reader.onerror = error => reject(error)

		reader.readAsDataURL(file)
	})
}

export async function blobToJson<T = any>(blob: Blob): Promise<T> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()

		reader.onload = () => {
			const json = JSON.parse(reader.result as string)
			resolve(json)
		}

		reader.onerror = error => reject(error)

		reader.readAsText(blob)
	})
}

export function stringToLocalization(string: string = ''): string {
	return string.toLowerCase().replace(new RegExp('[^0-9|A-Z|a-z|а-я|А-Я]', 'g'), '_')
}

export async function getLocalizationFileContent(page: string, language = 'ru', isFallback = false): Promise<any> {
	try {
		const response = await axios.get(`/locales/${language}/${page}`)

		if (response?.status === 200) {
			return response
		}
	} catch (e) {
		if (!isFallback) {
			return await getLocalizationFileContent(page, DEFAULT_LOCALE, true)
		}
	}
}

interface IContent {
	tag: string
	value: string | Array<IContent>
}

export function jsonToHtml(content: IContent[] = []): string {
	if (content.length) {
		return content
			.map(({ tag, value }) => {
				if (Array.isArray(value)) {
					return tag ? [`<${tag}>`, jsonToHtml(value), `</${tag}>`].join('') : jsonToHtml(value)
				} else {
					return tag ? `<${tag}>${value}</${tag}>` : value
				}
			})
			.join('')
	}

	return ''
}

interface IGetDeviceInfoResult {
	full: string
	device: string
	platform: string
}

export const getDeviceInfo = (string?: string): IGetDeviceInfoResult => {
	if (!string) return { full: "", device: "", platform: "" };
	const [full = '', device = '', platform = ''] = string?.match(/(.*)? \((.*)\)/) || []

	return {
		full,
		device,
		platform
	}
}
