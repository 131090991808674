import { RECOVERY_APPLY_RESET_PASSWORD, RECOVERY_INIT_RESET_PASSWORD } from '../../actions/passport/recovery'
import { IInitResetPassword } from '../../../interfaces/passport/auth'
import { RecoveryActionTypes } from '../../../interfaces/passport/actionCreators/recovery'

export function initResetPassword(data: IInitResetPassword): RecoveryActionTypes {
	return {
		type: RECOVERY_INIT_RESET_PASSWORD,
		payload: data
	}
}

export function applyResetPassword(): RecoveryActionTypes {
	return {
		type: RECOVERY_APPLY_RESET_PASSWORD
	}
}
