import axios, { AxiosError } from 'axios'
import { API_CABINET_PATH, APIServices } from '../../helpers/api'
import { ErrorData, setErrorHandler } from '../../store/actionCreators/error'
import {
	ILicensePackage,
	ILicensePackageAPIRequest,
	ILicensePackageAPIResponse,
	ILicensePackageCreate,
	ILicensePackageUpdate
} from '../../interfaces/cabinet/licensePackage'
import { APIResponse, AppThunk } from '../../interfaces/helpers'
import { serviceHandlerErrorOptions } from '../helpers'

export function licensePackageGetAllHandler(
	params: ILicensePackageAPIRequest,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<APIResponse<ILicensePackageAPIResponse[]>> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<APIResponse<ILicensePackageAPIResponse[]>>({
				method: 'GET',
				url: `${API_CABINET_PATH}/licensePackage`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params
			})
			return response
		} catch (ex) {
			const error: AxiosError<ErrorData> = ex as AxiosError<ErrorData>;
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response as any
		}
	}
}

export function getActiveLicensePackageWithGroupsHandler(
	accountId: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<ILicensePackage[]> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<ILicensePackage[]>({
				method: 'GET',
				url: `${API_CABINET_PATH}/licensePackage/available`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: { accountId }
			})
			return response
		} catch (ex) {
			const error: AxiosError<ErrorData> = ex as AxiosError<ErrorData>;
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response as any
		}
	}
}

export function getLicensePackageByIdHandler(
	id: string,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<ILicensePackage> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<ILicensePackage>({
				method: 'GET',
				url: `${API_CABINET_PATH}/licensePackage/${id}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id
				}
			})
			return response
		} catch (ex) {
			const error: AxiosError<ErrorData> = ex as AxiosError<ErrorData>;
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response as any
		}
	}
}

export function createLicensePackageHandler(
	data: ILicensePackageCreate,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<ILicensePackage> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<ILicensePackage>({
				method: 'POST',
				url: `${API_CABINET_PATH}/licensePackage`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				data
			})
			return response
		} catch (ex) {
			const error: AxiosError<ErrorData> = ex as AxiosError<ErrorData>;
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response as any
		}
	}
}

export function updateLicensePackageByIdHandler(
	id: string,
	data: ILicensePackageUpdate,
	errorOptions = serviceHandlerErrorOptions
): AppThunk<ILicensePackage> {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request<ILicensePackage>({
				method: 'PUT',
				url: `${API_CABINET_PATH}/licensePackage/${id}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id
				},
				data
			})
			return response
		} catch (ex) {
			const error: AxiosError<ErrorData> = ex as AxiosError<ErrorData>;
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response as any
		}
	}
}

export function deleteLicensePackageByIdHandler(id: string, errorOptions = serviceHandlerErrorOptions): AppThunk {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request({
				method: 'DELETE',
				url: `${API_CABINET_PATH}/licensePackage/${id}`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id
				}
			})
			return response
		} catch (ex) {
			const error: AxiosError<ErrorData> = ex as AxiosError<ErrorData>;
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response as any
		}
	}
}

export function blockLicensePackageByIdHandler(id: string, errorOptions = serviceHandlerErrorOptions): AppThunk {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request({
				method: 'PUT',
				url: `${API_CABINET_PATH}/licensePackage/${id}/block`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id
				}
			})
			return response
		} catch (ex) {
			const error: AxiosError<ErrorData> = ex as AxiosError<ErrorData>;
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response as any
		}
	}
}

export function unblockLicensePackageByIdHandler(id: string, errorOptions = serviceHandlerErrorOptions): AppThunk {
	return async (dispatch, getState) => {
		try {
			const response = await axios.request({
				method: 'PUT',
				url: `${API_CABINET_PATH}/licensePackage/${id}/unblock`,
				headers: {
					Authorization: `Bearer ${getState().auth.tokens.access_token}`
				},
				params: {
					id
				}
			})
			return response
		} catch (ex) {
			const error: AxiosError<ErrorData> = ex as AxiosError<ErrorData>;
			dispatch(setErrorHandler(error, APIServices.CABINET, errorOptions))
			return error.response as any
		}
	}
}
