import React, { useEffect, useState } from 'react'
import Pagetitle from '../../../components/UI/Pagetitle/Pagetitle'
import SelectLimit from '../../../components/UI/Select/SelectLimit'
import Pagination from '../../../components/UI/Pagination/Pagination'
import { IPagination, TDatePickerDate, TSortColumnsTable, TStatus } from '../../../interfaces/helpers'
import {
	DATE_API_MAX,
	DATE_API_MIN,
	DATE_FORMAT_API,
	declensionString,
	DEFAULT_PAGE_SIZE,
	INFINITE_NUMBER,
	truncateString
} from '../../../helpers/helpers'
import { connect, ConnectedProps } from 'react-redux'
import {
	createNotificationHandler,
	getNotificationByIdHandler,
	notificationGetAllHandler
} from '../../../services/cabinet/notifications'
import {
	INotification,
	INotificationAPIRequest,
	INotificationCreate,
	TNotificationSortFields
} from '../../../interfaces/passport/notifications'
import dayjs from 'dayjs'
import Button from '../../../components/UI/Button/Button'
import InputDatePicker from '../../../components/UI/InputDatePicker/InputDatePicker'
import { modalShowHandler } from '../../../store/actionCreators/UI/modal'
import { modalConfirmShowHandler } from '../../../store/actionCreators/UI/modalConfirm'
import { notificationAddHandler } from '../../../store/actionCreators/UI/notification'
import { toggleLoaderHandler } from '../../../store/actionCreators/UI/loader'
import ModalAddNotification from './ModalAddNotification'
import ModalViewNotification from './ModalViewNotification'
import { IOrganization } from '../../../interfaces/passport/organizations'
import { getAllOrganizationsHandler } from '../../../services/passport/organization'
import NoResults from '../../../components/UI/NoResults/NoResults'
import { useTranslation } from 'react-i18next'
import Loading from '../../../components/UI/Loading/Loading'
import { TOnChange } from '../../../components/UI/Select/helpers'

const Notifications = ({
	getOrganizations,
	getNotification,
	getNotifications,
	createNotification,
	showModal,
	toggleLoader
}: ConnectedProps<typeof connector>) => {
	const CONTACTS_PREVIEW_LIMIT = 8

	const { t } = useTranslation()
	const [status, setStatus] = useState<TStatus>('loading')
	const [organizations, setOrganizations] = useState<IOrganization[]>([])
	const [table, setTable] = useState<INotification[]>([])
	const [pagination, setPagination] = useState<IPagination>({
		pageNo: 0,
		totalPages: 0,
		totalCount: 0
	})

	const [filterNotifications, setFilterNotifications] = useState<INotificationAPIRequest>({
		sortField: 'SENT_TIME',
		fromDateTime: dayjs(DATE_API_MIN).format(DATE_FORMAT_API),
		toDateTime: dayjs(DATE_API_MAX).format(DATE_FORMAT_API),
		desc: true,
		pageSize: DEFAULT_PAGE_SIZE,
		pageNo: 0
	})

	const [sortColumns, setSortColumns] = useState<TSortColumnsTable>({
		SENT_TIME: {
			desc: true,
			classNames: ['table-sort', 'table-sort--bottom']
		},
		TOPIC: {
			desc: true,
			classNames: ['table-sort']
		}
	})

	const paginationHandle = (pageNo: number) => {
		setFilterNotifications({
			...filterNotifications,
			pageNo
		})
	}

	const limitHandle: TOnChange = option => {
		if (option) {
			setFilterNotifications({
				...filterNotifications,
				pageSize: parseInt(option.value) || INFINITE_NUMBER,
				pageNo: 0
			})
		}
	}

	const sortHandle = (sortField: TNotificationSortFields) => {
		const updatedSortColumns = { ...sortColumns }

		Object.keys(updatedSortColumns).forEach((key: string) => {
			if (key !== sortField) {
				updatedSortColumns[key] = {
					desc: true,
					classNames: ['table-sort']
				}
			} else {
				updatedSortColumns[key].desc = !updatedSortColumns[key].desc
				updatedSortColumns[key].classNames = [
					'table-sort',
					updatedSortColumns[key].desc ? 'table-sort--bottom' : 'table-sort--top'
				]
			}
		})

		setFilterNotifications({
			...filterNotifications,
			sortField,
			desc: updatedSortColumns[sortField].desc
		})

		setSortColumns(updatedSortColumns)
	}

	const [fromDateTime, setFromDateTime] = useState<TDatePickerDate>(null)
	const [minFromDateTime] = useState<TDatePickerDate>(null)
	const [maxFromDateTime, setMaxFromDateTime] = useState<TDatePickerDate>(null)
	const [toDateTime, setToDateTime] = useState<TDatePickerDate>(null)
	const [minToDateTime, setMinToDateTime] = useState<TDatePickerDate>(null)
	const [maxToDateTime] = useState<TDatePickerDate>(null)

	const fromDateTimeHandler = (date: TDatePickerDate) => {
		setFromDateTime(date)
		setMinToDateTime(date)

		let startDate = dayjs(DATE_API_MIN).format(DATE_FORMAT_API)
		let endDate = dayjs(DATE_API_MAX).format(DATE_FORMAT_API)

		if (date) startDate = dayjs(date).format(DATE_FORMAT_API)
		if (toDateTime) endDate = dayjs(toDateTime).format(DATE_FORMAT_API)

		setFilterNotifications({
			...filterNotifications,
			fromDateTime: startDate,
			toDateTime: endDate
		})
	}

	const toDateTimeHandler = (date: TDatePickerDate) => {
		setToDateTime(date)
		setMaxFromDateTime(date)

		let startDate = dayjs(DATE_API_MIN).format(DATE_FORMAT_API)
		let endDate = dayjs(DATE_API_MAX).format(DATE_FORMAT_API)

		if (fromDateTime) startDate = dayjs(fromDateTime).format(DATE_FORMAT_API)
		if (date) endDate = dayjs(date).format(DATE_FORMAT_API)

		setFilterNotifications({
			...filterNotifications,
			fromDateTime: startDate,
			toDateTime: endDate
		})
	}

	const viewHandler = async (item: INotification) => {
		toggleLoader(true)

		try {
			const response = await getNotification(item.id)

			if (response?.status === 200) {
				showModal({
					type: 'large',
					Component: ModalViewNotification,
					ComponentProps: {
						item: response.data
					}
				})
			}
		} finally {
			toggleLoader(false)
		}
	}

	const createHandler = () => {
		showModal({
			type: 'notification',
			Component: ModalAddNotification,
			ComponentProps: {
				organizations,
				onConfirm: async (data: INotificationCreate) => {
					toggleLoader(true)

					try {
						const response = await createNotification(data)

						if (response?.status === 200) {
							paginationHandle(0)
						}

						return response
					} finally {
						toggleLoader(false)
					}
				}
			}
		})
	}

	useEffect(() => {
		getOrganizations({
			desc: false,
			pageNo: 0,
			pageSize: INFINITE_NUMBER,
			fillContacts: true
		}).then(response => {
			if (response?.status === 200) {
				setOrganizations(response.data.data)
			}
		})
	}, [getOrganizations])

	useEffect(() => {
		getNotifications(filterNotifications).then(response => {
			if (response?.status === 200) {
				const { data, pageNo, totalPages, totalCount } = response.data

				setTable(data)

				setPagination({
					pageNo,
					totalPages,
					totalCount
				})

				setStatus('ready')
			}
		})
	}, [getNotifications, filterNotifications])

	if (status === 'loading') return <Loading />

	return (
		<>
			<Pagetitle title={t('pages:notifications:title')}>
				<p>{t('pages:notifications:text')}</p>
			</Pagetitle>

			<div className="filters">
				<div className="filters-item filters-item--calendar-inline">
					<InputDatePicker
						placeholderText={t('pages:notifications:fieldFromDateTime')}
						name={'fromDateTime'}
						selected={fromDateTime}
						minDate={minFromDateTime}
						maxDate={maxFromDateTime}
						onChange={fromDateTimeHandler}
					/>

					<span className="filters-item-delimiter">—</span>

					<InputDatePicker
						placeholderText={t('pages:notifications:fieldToDateTime')}
						name={'toDateTime'}
						selected={toDateTime}
						minDate={minToDateTime}
						maxDate={maxToDateTime}
						onChange={toDateTimeHandler}
					/>
				</div>

				<div className="filters-item filters-item--view">
					<SelectLimit total={pagination.totalCount} onChange={limitHandle} />
				</div>

				<div className="filters-item filters-item--buttons">
					<div className="button-row">
						<Button type={'primary'} htmlType={'button'} className={'button--large'} onClick={createHandler}>
							{t('pages:notifications:addNew')}
						</Button>
					</div>
				</div>
			</div>

			{table.length > 0 ? (
				<div className="pagination-wrapper">
					<div className="table">
						<table>
							<colgroup>
								<col width="21.8%" />
								<col width="29.5%" />
								<col />
							</colgroup>

							<thead>
								<tr>
									<th onClick={() => sortHandle('SENT_TIME')}>
										<div className={sortColumns.SENT_TIME.classNames.join(' ')}>
											{t('pages:notifications:tableHeadingDate')}
										</div>
									</th>
									<th>
										<div>{t('pages:notifications:tableHeadingRecipients')}</div>
									</th>
									<th onClick={() => sortHandle('TOPIC')}>
										<div className={sortColumns.TOPIC.classNames.join(' ')}>
											{t('pages:notifications:tableHeadingMessage')}
										</div>
									</th>
								</tr>
							</thead>

							<tbody>
								{table.map(item => {
									return (
										<tr key={item.id}>
											<td>
												<button className="button-link" type="button" onClick={() => viewHandler(item)}>
													{dayjs(item.sentTime).format('DD.MM.YYYY HH:mm')}
												</button>
											</td>

											<td>
												<ul className="list list--nomargin">
													{item.recipients.slice(0, CONTACTS_PREVIEW_LIMIT).map((el, idx) => {
														return (
															<li key={idx} className="list-item">
																<div className="table-nowrap">
																	{el.orgName} - {el.orgContactsAmount}&nbsp;
																	{declensionString(
																		t('pages:notifications:decContact1'),
																		t('pages:notifications:decContact2'),
																		t('pages:notifications:decContact3'),
																		el.orgContactsAmount
																	)}
																</div>
															</li>
														)
													})}
												</ul>

												{item.toAdmin && (
													<>
														<br />
														{t('pages:notifications:toAdmins')}
													</>
												)}
											</td>

											<td>
												<strong>{item.topic}</strong>
												<br />
												<br />
												{truncateString(item.message, 100)}
											</td>
										</tr>
									)
								})}
							</tbody>
						</table>
					</div>

					<Pagination onClick={paginationHandle} {...pagination} />
				</div>
			) : (
				<NoResults />
			)}
		</>
	)
}

const mapDispatch = {
	getOrganizations: getAllOrganizationsHandler,
	getNotifications: notificationGetAllHandler,
	getNotification: getNotificationByIdHandler,
	createNotification: createNotificationHandler,
	showModal: modalShowHandler,
	showModalConfirm: modalConfirmShowHandler,
	notificationAdd: notificationAddHandler,
	toggleLoader: toggleLoaderHandler
}

const connector = connect(null, mapDispatch)

export default connector(Notifications)
