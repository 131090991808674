import React, { useEffect, useState } from 'react'
import Pagetitle from '../../../components/UI/Pagetitle/Pagetitle'
import { connect, ConnectedProps } from 'react-redux'
import {
	createFacultyByIdHandler,
	createFacultyGroupsByIdHandler,
	createGradesByIdHandler,
	deleteFacultyByIdHandler,
	deleteFacultyGroupsByIdHandler,
	deleteGradesByIdHandler,
	getFacultyByIdHandler,
	getFacultyGroupsByIdHandler,
	getGradesByIdHandler
} from '../../../services/passport/organization'
import { modalShowHandler } from '../../../store/actionCreators/UI/modal'
import ModalAddRecord from '../../../components/Faculties/ModalAddRecord'
import { modalConfirmShowHandler } from '../../../store/actionCreators/UI/modalConfirm'
import { RootState } from '../../../store/reducers/rootReducer'
import { useTranslation } from 'react-i18next'
import orderBy from 'lodash/orderBy'
import Multiselect, { IMultiselectItem } from '../../../components/Faculties/Multiselect'
import { toggleLoaderHandler } from '../../../store/actionCreators/UI/loader'

const multiselectItemDefaultValue: IMultiselectItem = {
	id: '',
	name: ''
}

const orderByAsc = (data: Array<IMultiselectItem>): Array<IMultiselectItem> => orderBy(data, ['name'], ['asc'])

const Faculties = ({
	user,
	getFaculties,
	getGroups,
	getGrades,
	createFaculties,
	createGroups,
	createGrades,
	deleteFaculties,
	deleteGroups,
	deleteGrades,
	showModal,
	showModalConfirm,
	toggleLoader
}: ConnectedProps<typeof connector>) => {
	const { t } = useTranslation()

	const [faculties, setFaculties] = useState<IMultiselectItem[]>([])
	const [groups, setGroups] = useState<IMultiselectItem[]>([])
	const [grades, setGrades] = useState<IMultiselectItem[]>([])
	const [isFacultiesLoading, setIsFacultiesLoading] = useState(true)
	const [isGroupsLoading, setIsGroupsLoading] = useState(true)
	const [isGradesLoading, setIsGradesLoading] = useState(true)
	const [selectedFaculty, setSelectedFaculty] = useState<IMultiselectItem>(multiselectItemDefaultValue)
	const [selectedGrade, setSelectedGrade] = useState<IMultiselectItem>(multiselectItemDefaultValue)
	const [selectedGroup, setSelectedGroup] = useState<IMultiselectItem>(multiselectItemDefaultValue)

	useEffect(() => {
		const organizationId = user.accounts?.[0]?.organization.id

		if (!organizationId) {
			return
		}

		getFaculties(organizationId).then(response => {
			if (response?.status === 200) {
				setFaculties(orderByAsc(response.data))
				setIsFacultiesLoading(false)
			}
		})

		getGrades(organizationId).then(response => {
			if (response?.status === 200) {
				setGrades(orderByAsc(response.data))
				setIsGradesLoading(false)
			}
		})
	}, [getFaculties, getGrades, user])

	const onChangeFacultiesHandler = async (item: IMultiselectItem) => {
		const organizationId = user.accounts?.[0]?.organization.id

		if (!organizationId) {
			return
		}

		setIsGroupsLoading(true)

		try {
			setSelectedFaculty(item)

			const response = await getGroups(organizationId, item.id)

			if (response?.status === 200) {
				setGroups(orderByAsc(response.data))
				setSelectedGroup(multiselectItemDefaultValue)
			}

			return response
		} finally {
			setIsGroupsLoading(false)
		}
	}

	const onAddFacultiesHandler = () => {
		const organizationId = user.accounts?.[0]?.organization.id

		if (!organizationId) {
			return
		}

		showModal({
			Component: ModalAddRecord,
			ComponentProps: {
				items: faculties,
				onConfirm: async (value: string) => {
					toggleLoader(true)
					setIsFacultiesLoading(true)

					try {
						const response = await createFaculties(organizationId, value)

						if (response?.status === 200) {
							setFaculties(orderByAsc([...faculties, response.data]))
						}

						return response
					} finally {
						setIsFacultiesLoading(false)
						toggleLoader(false)
					}
				}
			}
		})
	}

	const onRemoveFacultiesHandler = () => {
		const organizationId = user.accounts?.[0]?.organization.id

		if (!organizationId) {
			return
		}

		showModalConfirm({
			type: 'remove',
			elementText: selectedFaculty.name,
			onConfirm: async () => {
				toggleLoader(true)
				setIsFacultiesLoading(true)

				try {
					const response = await deleteFaculties(organizationId, selectedFaculty.id)

					if (response?.status === 200) {
						setFaculties(faculties.filter(item => item.id !== selectedFaculty.id))
						setSelectedFaculty(multiselectItemDefaultValue)
					}

					return response
				} finally {
					setIsFacultiesLoading(false)
					toggleLoader(false)
				}
			}
		})
	}

	const onChangeGroupsHandler = (item: IMultiselectItem) => setSelectedGroup(item)

	const onAddGroupsHandler = () => {
		const organizationId = user.accounts?.[0]?.organization.id

		if (!organizationId) {
			return
		}

		showModal({
			Component: ModalAddRecord,
			ComponentProps: {
				items: groups,
				onConfirm: async (value: string) => {
					toggleLoader(true)
					setIsGroupsLoading(true)

					try {
						const response = await createGroups(organizationId, selectedFaculty.id, value)

						if (response?.status === 200) {
							setGroups(orderByAsc([...groups, response.data]))
						}

						return response
					} finally {
						setIsGroupsLoading(false)
						toggleLoader(false)
					}
				}
			}
		})
	}

	const onRemoveGroupsHandler = () => {
		const organizationId = user.accounts?.[0]?.organization.id

		if (!organizationId) {
			return
		}

		showModalConfirm({
			type: 'remove',
			elementText: selectedGroup.name,
			onConfirm: async () => {
				toggleLoader(true)
				setIsGroupsLoading(true)

				try {
					const response = await deleteGroups(organizationId, selectedFaculty.id, selectedGroup.id)

					if (response?.status === 200) {
						setGroups(groups.filter(item => item.id !== selectedGroup.id))
						setSelectedGroup(multiselectItemDefaultValue)
					}

					return response
				} finally {
					setIsGroupsLoading(false)
					toggleLoader(false)
				}
			}
		})
	}

	const onChangeGradesHandler = (item: IMultiselectItem) => setSelectedGrade(item)

	const onAddGradesHandler = () => {
		const organizationId = user.accounts?.[0]?.organization.id

		if (!organizationId) {
			return
		}

		showModal({
			Component: ModalAddRecord,
			ComponentProps: {
				items: grades,
				onConfirm: async (value: string) => {
					toggleLoader(true)
					setIsGradesLoading(true)

					try {
						const response = await createGrades(organizationId, value)

						if (response?.status === 200) {
							setGrades(orderByAsc([...grades, response.data]))
						}

						return response
					} finally {
						setIsGradesLoading(false)
						toggleLoader(false)
					}
				}
			}
		})
	}

	const onRemoveGradesHandler = () => {
		const organizationId = user.accounts?.[0]?.organization.id

		if (!organizationId) {
			return
		}

		showModalConfirm({
			type: 'remove',
			elementText: selectedGrade.name,
			onConfirm: async () => {
				toggleLoader(true)
				setIsGradesLoading(true)

				try {
					const response = await deleteGrades(organizationId, selectedGrade.id)

					if (response?.status === 200) {
						setGrades(grades.filter(item => item.id !== selectedGrade.id))
						setSelectedGrade(multiselectItemDefaultValue)
					}

					return response
				} finally {
					setIsGradesLoading(false)
					toggleLoader(false)
				}
			}
		})
	}

	return (
		<>
			<Pagetitle title={t('pages:faculties:title')} />

			<p>{t('pages:faculties:paragraph1')}</p>

			<div className="org-departmens">
				<div className="org-departmens-list">
					<Multiselect
						title={t('pages:faculties:labelFaculty')}
						list={faculties}
						onChange={onChangeFacultiesHandler}
						onAdd={onAddFacultiesHandler}
						onRemove={onRemoveFacultiesHandler}
						isLoading={isFacultiesLoading}
						isRemoveDisabled={!selectedFaculty.id}
					/>

					<Multiselect
						title={t('pages:faculties:labelFacultyGroup')}
						list={groups}
						onChange={onChangeGroupsHandler}
						onAdd={onAddGroupsHandler}
						onRemove={onRemoveGroupsHandler}
						isLoading={isGroupsLoading}
						isRemoveDisabled={!selectedGroup.id}
					/>
				</div>
			</div>

			<div className="org-departmens">
				<p>{t('pages:faculties:paragraph2')}</p>

				<div className="org-departmens-list">
					<Multiselect
						title={t('pages:faculties:labelGrade')}
						list={grades}
						onChange={onChangeGradesHandler}
						onAdd={onAddGradesHandler}
						onRemove={onRemoveGradesHandler}
						isLoading={isGradesLoading}
						isRemoveDisabled={!selectedGrade.id}
					/>
				</div>
			</div>
		</>
	)
}

const mapState = (state: RootState) => ({
	user: state.user.user
})

const mapDispatch = {
	getFaculties: getFacultyByIdHandler,
	getGroups: getFacultyGroupsByIdHandler,
	getGrades: getGradesByIdHandler,
	createFaculties: createFacultyByIdHandler,
	createGroups: createFacultyGroupsByIdHandler,
	createGrades: createGradesByIdHandler,
	deleteFaculties: deleteFacultyByIdHandler,
	deleteGroups: deleteFacultyGroupsByIdHandler,
	deleteGrades: deleteGradesByIdHandler,
	showModal: modalShowHandler,
	showModalConfirm: modalConfirmShowHandler,
	toggleLoader: toggleLoaderHandler
}

const connector = connect(mapState, mapDispatch)

export default connector(Faculties)
