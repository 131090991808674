import { NOTIFICATION_ADD, NOTIFICATION_REMOVE } from '../../actions/UI/notification'
import { INotification, TNotification } from '../../../interfaces/UI/notification'
import { NotificationActionTypes } from '../../../interfaces/UI/actionCreators/notifications'
import { ThunkAction } from 'redux-thunk'
import { RootState } from '../../reducers/rootReducer'
import { Action } from 'redux'

export function notificationAdd(data: INotification): NotificationActionTypes {
	return {
		type: NOTIFICATION_ADD,
		payload: data
	}
}

export function notificationRemove(id: number): NotificationActionTypes {
	return {
		type: NOTIFICATION_REMOVE,
		payload: id
	}
}

export function notificationAddHandler(
	message: string,
	type: TNotification
): ThunkAction<void, RootState, unknown, Action<string>> {
	return dispatch => {
		const notification: INotification = {
			id: new Date().getTime(),
			message,
			type
		}

		dispatch(notificationAdd(notification))

		setTimeout(() => {
			dispatch(notificationRemove(notification.id))
		}, 5000)
	}
}

export function notificationRemoveHandler(id: number): ThunkAction<void, RootState, unknown, Action<string>> {
	return dispatch => dispatch(notificationRemove(id))
}
