import { MODAL_LOG_HIDE, MODAL_LOG_SHOW } from '../../actions/UI/modalLog'
import { IModalLogProps } from '../../../interfaces/UI/modalLog'
import { ModalLogActionTypes } from '../../../interfaces/UI/actionCreators/modalLog'
import { ThunkAction } from 'redux-thunk'
import { RootState } from '../../reducers/rootReducer'
import { Action } from 'redux'

export function modalLogShow(data: IModalLogProps): ModalLogActionTypes {
	return {
		type: MODAL_LOG_SHOW,
		payload: data
	}
}

export function modalLogHide(): ModalLogActionTypes {
	return {
		type: MODAL_LOG_HIDE
	}
}

export function modalLogShowHandler(data: IModalLogProps): ThunkAction<void, RootState, unknown, Action<string>> {
	return dispatch => dispatch(modalLogShow(data))
}

export function modalLogHideHandler(): ThunkAction<void, RootState, unknown, Action<string>> {
	return dispatch => dispatch(modalLogHide())
}
