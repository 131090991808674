import React, { useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Input from '../../../components/UI/Input/Input'
import { REGEX_EMAIL } from '../../../helpers/helpers'
import { initResetPasswordHandler } from '../../../services/passport/recovery'
import Button from '../../../components/UI/Button/Button'
import { useTranslation } from 'react-i18next'
import { toggleLoaderHandler } from '../../../store/actionCreators/UI/loader'

interface IFormInputs {
	email: string
}

const Recovery = ({ resetPassword, toggleLoader }: ConnectedProps<typeof connector>) => {
	const { t } = useTranslation()
	const [isSuccess, setIsSuccess] = useState(false)
	const [isSubmitting, setIsSubmitting] = useState(false)

	const { register, handleSubmit, errors, watch } = useForm<IFormInputs>()

	const onSubmit = async (data: IFormInputs) => {
		toggleLoader(true)
		setIsSubmitting(true)

		try {
			const response = await resetPassword(data.email)

			if (response?.status === 200) {
				setIsSuccess(true)
			}
		} finally {
			setIsSubmitting(false)
			toggleLoader(false)
		}
	}

	return (
		<>
			<div className="login login--password">
				<h3 className="login-title">{t('common:appName')}</h3>
				<p className="login-footnote">{t('pages:recovery:intro')}</p>

				{isSuccess && (
					<div className="login-message text-center">
						<p>
							<strong>{t('pages:recovery:sent')}</strong>
						</p>
					</div>
				)}

				{!isSuccess && (
					<form className="form" onSubmit={handleSubmit(onSubmit)}>
						<div className="form-item">
							<Input
								currentValue={watch('email')}
								type={'email'}
								name={'email'}
								placeholder={t('pages:recovery:email')}
								isDisabled={isSubmitting}
								isError={!!errors.email}
								reference={register({
									required: true,
									minLength: 6,
									pattern: REGEX_EMAIL
								})}
								rules={{
									minLength: 6
								}}
								errors={errors.email}
							/>
						</div>

						<div className="form-controls">
							<Link className="button button--secondary" to={'/'}>
								{t('common:actionCancel')}
							</Link>

							<Button
								type={'primary'}
								htmlType={'submit'}
								className={'form-button-fullwidth'}
								isDisabled={isSubmitting}
							>
								{t('pages:recovery:send')}
							</Button>
						</div>
					</form>
				)}
			</div>
		</>
	)
}

const mapDispatch = {
	resetPassword: initResetPasswordHandler,
	toggleLoader: toggleLoaderHandler
}

const connector = connect(null, mapDispatch)

export default connector(Recovery)
