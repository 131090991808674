import { IVersion } from '../../../../interfaces/cabinet/versions'
import React, { useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { buildGetAllHandler, getBuildsByVersionHandler } from '../../../../services/cabinet/builds'
import { modalConfirmShowHandler } from '../../../../store/actionCreators/UI/modalConfirm'
import { INFINITE_NUMBER } from '../../../../helpers/helpers'

import Loading from '../../../../components/UI/Loading/Loading'
import Button from '../../../../components/UI/Button/Button'
import { MultiselectList } from '../../../../components/UI/MultiselectList/MultiselectList'

type Props = ConnectedProps<typeof connector> & {
	version: IVersion
	onConfirm: (data: FormFields) => Promise<any>
	hideModal: () => void
}

type FormFields = {
	buildIds: string[]
}

type IBuildWithSelect = {
	id: string
	name: string
	selected: boolean
}

const ModalBuilds = ({ version, hideModal, showModalConfirm, onConfirm, getBuilds, getBuildsByVersion }: Props) => {
	const { t } = useTranslation()

	const [loading, setLoading] = useState(true)
	const [builds, setBuilds] = useState<IBuildWithSelect[]>([])
	const [leftBuilds, setLeftBuilds] = useState<IBuildWithSelect[]>([])
	const [rightBuilds, setRightBuilds] = useState<IBuildWithSelect[]>([])

	const {
		setValue,
		handleSubmit,
		control,
		reset,
		formState: { errors, isSubmitting, isDirty }
	} = useForm<FormFields>()

	const onAddBuilds = () => {
		setRightBuilds(prev => [...prev, ...leftBuilds.filter(b => b.selected).map(b => ({ ...b, selected: false }))])
		setLeftBuilds(prev => prev.filter(b => !b.selected))
	}

	const onRemoveBuilds = () => {
		setLeftBuilds(prev => [...prev, ...rightBuilds.filter(b => b.selected).map(b => ({ ...b, selected: false }))])
		setRightBuilds(prev => prev.filter(b => !b.selected))
	}

	const onAddAllBuilds = () => {
		setRightBuilds(builds.map(b => ({ ...b, selected: false })))
		setLeftBuilds([])
	}

	const onRemoveAllBuilds = () => {
		setLeftBuilds(builds.map(b => ({ ...b, selected: false })))
		setRightBuilds([])
	}

	const cancelHandler = () => {
		if (isDirty) {
			showModalConfirm({
				type: 'cancel',
				onConfirm: hideModal
			})
		} else {
			hideModal()
		}
	}

	const onSubmitHandler = async (data: FormFields) => {
		await onConfirm(data)
		hideModal()
	}

	useEffect(() => {
		getBuilds({
			desc: false,
			pageSize: INFINITE_NUMBER,
			pageNo: 0,
			productId: version.product.id
		})
			.then(response => {
				if (response?.status !== 200) {
					setLoading(false)
					return
				}

				const productBuildsList =
					response.data?.data?.map(b => ({
						id: b.id,
						name: `${b.platform} (${b.buildVersionCode})`,
						selected: false
					})) || []

				setBuilds(productBuildsList)

				getBuildsByVersion(version.id).then(response => {
					if (response?.status !== 200) {
						setLoading(false)
						return
					}

					const buildIds = response?.data?.map(b => b.id) || []

					const buildsList = response?.data?.map(b => ({
						id: b.id,
						name: `${b.platform} (${b.buildVersionCode})`,
						selected: false
					}))

					setLeftBuilds(productBuildsList.filter(b => !buildIds.includes(b.id)))
					setRightBuilds(buildsList)

					reset({
						buildIds: buildIds
					})
				})
			})
			.finally(() => {
				setLoading(false)
			})
	}, [getBuilds, getBuildsByVersion, reset, version.id, version.product.id])

	useEffect(() => {
		if (rightBuilds.length) {
			setValue(
				'buildIds',
				rightBuilds.map(b => b.id),
				{
					shouldDirty: true
				}
			)
		} else {
			setValue('buildIds', '', {
				shouldDirty: true
			})
		}
	}, [rightBuilds, setValue])

	return (
		<>
			<button className="modal-close" onClick={cancelHandler} disabled={isSubmitting} />
			<h3 className="modal-title">{t('common:appName')}</h3>
			<p className="modal-footnote">{t('pages:versions:modalBuildsTitle')}</p>

			{loading ? (
				<Loading />
			) : (
				<>
					{builds.length === 0 ? (
						<div>No available builds</div>
					) : (
						<form className="form form--update" onSubmit={handleSubmit(onSubmitHandler)}>
							<div className="form-row">
								<div className="form-col form-col-39">
									<MultiselectList
										label={t('pages:versions:listBuilds')}
										searchLabel={t('pages:versions:listSearch')}
										list={leftBuilds}
										isDisabled={isSubmitting}
										onChange={(build, selected) => {
											const updatedBuilds = leftBuilds.map(item => ({
												...item,
												selected: item.id === build.id ? selected : item.selected
											}))

											setLeftBuilds(updatedBuilds)
										}}
									/>
								</div>

								<div className="form-col form-col-22 multiselect-col">
									<div className="multiselect-controls">
										<div className="multiselect-controls-item">
											<Button
												type={'secondary'}
												htmlType={'button'}
												onClick={onAddAllBuilds}
												isDisabled={!leftBuilds.length}
											>
												<span dangerouslySetInnerHTML={{ __html: t('common:actionMoveAddAll') }} />
											</Button>

											<Button
												type={'secondary'}
												htmlType={'button'}
												onClick={onAddBuilds}
												isDisabled={!leftBuilds.filter(b => b.selected).length}
											>
												<span dangerouslySetInnerHTML={{ __html: t('common:actionMoveAdd') }} />
											</Button>
										</div>

										<div className="multiselect-controls-item">
											<Button
												type={'secondary'}
												htmlType={'button'}
												onClick={onRemoveBuilds}
												isDisabled={!rightBuilds.filter(b => b.selected).length}
											>
												<span dangerouslySetInnerHTML={{ __html: t('common:actionMoveRemove') }} />
											</Button>

											<Button
												type={'secondary'}
												htmlType={'button'}
												onClick={onRemoveAllBuilds}
												isDisabled={!rightBuilds.length}
											>
												<span dangerouslySetInnerHTML={{ __html: t('common:actionMoveRemoveAll') }} />
											</Button>
										</div>
									</div>
								</div>

								<div className="form-col form-col-39">
									<Controller
										name="buildIds"
										control={control}
										rules={{
											required: true
										}}
										defaultValue={''}
										render={() => (
											<MultiselectList
												label={t('pages:versions:listSelectedBuilds')}
												searchLabel={t('pages:versions:listSearch')}
												isDisabled={isSubmitting}
												isRequired
												isError={!!errors.buildIds}
												list={rightBuilds}
												onChange={(build, selected) => {
													const updatedBuilds = rightBuilds.map(item => ({
														...item,
														selected: item.id === build.id ? selected : item.selected
													}))

													setRightBuilds(updatedBuilds)
												}}
											/>
										)}
									/>
								</div>
							</div>

							<div className="form-controls form-controls--right">
								<Button type={'secondary'} htmlType={'button'} onClick={cancelHandler} isDisabled={isSubmitting}>
									{t('common:actionCancel')}
								</Button>

								<Button type={'primary'} htmlType={'submit'} isDisabled={isSubmitting}>
									{t('common:actionSave')}
								</Button>
							</div>
						</form>
					)}
				</>
			)}
		</>
	)
}

const mapDispatch = {
	getBuilds: buildGetAllHandler,
	getBuildsByVersion: getBuildsByVersionHandler,
	showModalConfirm: modalConfirmShowHandler
}

const connector = connect(null, mapDispatch)

export default connector(ModalBuilds)
