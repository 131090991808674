import React, { useEffect, useState } from 'react'
import {
	archiveOrganizationByIdHandler,
	createOrganizationContactByIdHandler,
	deleteOrganizationByIdHandler,
	deleteOrganizationContactByIdHandler,
	getOrganizationByIdHandler,
	updateOrganizationByIdHandler,
	updateOrganizationContactByIdHandler
} from '../../../../services/passport/organization'
import { connect, ConnectedProps } from 'react-redux'
import { Link, useParams, useHistory } from 'react-router-dom'
import Error from '../../../Error/Error'
import Pagetitle from '../../../../components/UI/Pagetitle/Pagetitle'
import Button from '../../../../components/UI/Button/Button'
import { modalShowHandler } from '../../../../store/actionCreators/UI/modal'
import { modalConfirmShowHandler } from '../../../../store/actionCreators/UI/modalConfirm'
import { notificationAddHandler } from '../../../../store/actionCreators/UI/notification'
import { toggleLoaderHandler } from '../../../../store/actionCreators/UI/loader'
import Loading from '../../../../components/UI/Loading/Loading'
import ModalEditOrganizationInfo, {
	IOrganizationUpdateCustom
} from '../../../../components/OrganizationInfo/ModalEditOrganizationInfo'
import { REGEX_V4 } from '../../../../helpers/helpers'
import { sleep } from '../../../../helpers/api'
import { RootState } from '../../../../store/reducers/rootReducer'
import { useTranslation } from 'react-i18next'
import { TStatus } from '../../../../interfaces/helpers'
import OrganizationInfo from '../../../../components/OrganizationInfo/OrganizationInfo'
import { clearCurrentOrganization } from '../../../../store/actionCreators/passport/organization'

const Info = ({
	user,
	organization,
	getOrganization,
	archiveOrganization,
	updateOrganization,
	removeOrganization,
	createContact,
	updateContact,
	removeContact,
	clearCurrentOrganization,
	showModal,
	showModalConfirm,
	notificationAdd,
	toggleLoader
}: ConnectedProps<typeof connector>) => {
	const { t } = useTranslation()
	const [status, setStatus] = useState<TStatus>('loading')
	const { organizationId } = useParams()
	const history = useHistory()

	const archiveHandle = () => {
		showModalConfirm({
			type: 'update',
			questionText: t('pages:organizations:modalArchiveText'),
			buttonText: t('common:actionYes'),
			onConfirm: async () => {
				toggleLoader(true)

				try {
					const response = await archiveOrganization(organizationId)

					if (response?.status === 200) {
						clearCurrentOrganization()
						history.replace('/')
						notificationAdd(t('pages:organizations:notificationArchive'), 'info')
					}
				} finally {
					toggleLoader(false)
				}
			}
		})
	}

	const removeHandle = () => {
		showModalConfirm({
			type: 'remove',
			questionText: t('pages:organizations:modalRemoveText'),
			elementText: organization.name,
			buttonText: t('common:actionYes'),
			onConfirm: async () => {
				toggleLoader(true)

				try {
					const response = await removeOrganization(organizationId)

					if (response?.status === 200) {
						setStatus('error')
						clearCurrentOrganization()
						history.replace('/')
						notificationAdd(t('pages:organizations:notificationRemove'), 'info')
					}
				} finally {
					toggleLoader(false)
				}
			}
		})
	}

	const updateHandle = () => {
		showModal({
			type: 'large-form',
			Component: ModalEditOrganizationInfo,
			ComponentProps: {
				type: 'update',
				user,
				organization,
				onConfirm: async (data: IOrganizationUpdateCustom) => {
					toggleLoader(true)

					try {
						const {
							organization: updatedOrganization,
							mainContact,
							newContacts,
							removedContacts,
							updatedContacts
						} = data
						const updateData = []

						// Update organization
						if (updatedOrganization) {
							updateData.push(await sleep(), await updateOrganization(organizationId, updatedOrganization))
						}

						// Update main contact
						if (mainContact) {
							updateData.push(await sleep(), await updateContact(organizationId, mainContact))
						}

						// Update current contacts
						if (updatedContacts.length > 0) {
							for (let i = 0; i < updatedContacts.length; i++) {
								updateData.push(await sleep(), await updateContact(organizationId, updatedContacts[i]))
							}
						}

						// Add new contacts
						if (newContacts.length > 0) {
							for (let i = 0; i < newContacts.length; i++) {
								updateData.push(await sleep(), await createContact(organizationId, newContacts[i]))
							}
						}

						// Remove contacts
						if (removedContacts.length > 0) {
							for (let i = 0; i < removedContacts.length; i++) {
								updateData.push(await sleep(), await removeContact(organizationId, removedContacts[i]))
							}
						}

						const responseAll = await Promise.all(updateData)

						return responseAll.filter((res: any) => res)
					} finally {
						toggleLoader(false)
					}
				}
			}
		})
	}

	useEffect(() => {
		if (!organizationId.match(new RegExp(REGEX_V4))) {
			setStatus('error')
		} else {
			getOrganization(organizationId).then(response => {
				if (response?.status === 200) {
					setStatus('ready')
				} else {
					setStatus('error')
				}
			})
		}
	}, [getOrganization, organizationId])

	if (status === 'loading') return <Loading />
	if (status === 'error') return <Error />

	return (
		<>
			<Pagetitle
				title={t('pages:organizations:infoTitle')}
				crumbs={[
					{
						to: `/organization/${organizationId}/`,
						title: organization.name
					}
				]}
			/>

			<OrganizationInfo organization={organization} />

			<div className="button-row button-row--options">
				<Button type={'secondary'} htmlType={'button'} onClick={removeHandle}>
					{t('common:actionRemove')}
				</Button>

				<Button type={'secondary'} htmlType={'button'} isDisabled={organization.archived} onClick={archiveHandle}>
					{t('common:actionArchive')}
				</Button>

				<Link to={`/organization/${organizationId}/`} className="button button--secondary">
					{t('common:actionCancel')}
				</Link>

				<Button type={'primary'} htmlType={'button'} onClick={updateHandle}>
					{t('common:actionEdit')}
				</Button>
			</div>
		</>
	)
}

const mapState = (state: RootState) => ({
	organization: state.organization.organization,
	user: state.user.user
})

const mapDispatch = {
	getOrganization: getOrganizationByIdHandler,
	archiveOrganization: archiveOrganizationByIdHandler,
	removeOrganization: deleteOrganizationByIdHandler,
	updateOrganization: updateOrganizationByIdHandler,

	createContact: createOrganizationContactByIdHandler,
	updateContact: updateOrganizationContactByIdHandler,
	removeContact: deleteOrganizationContactByIdHandler,

	clearCurrentOrganization: clearCurrentOrganization,

	showModal: modalShowHandler,
	showModalConfirm: modalConfirmShowHandler,
	notificationAdd: notificationAddHandler,
	toggleLoader: toggleLoaderHandler
}

const connector = connect(mapState, mapDispatch)

export default connector(Info)
