import { AccountType } from '../interfaces/passport/organizations'

export const ORGANIZATION_REGIONS = [
	{
		label: 'РФ',
		value: 'RUS'
	},
	{
		label: 'СНГ',
		value: 'SNG'
	},
	{
		label: 'ВЭД',
		value: 'VED'
	}
]

export const ORGANIZATION_PROFILES = [
	{
		label: 'Медицинский государственный ВУЗ',
		value: 'MEDICAL_UNIVERSITY'
	},
	{
		label: 'Государственный ВУЗ',
		value: 'STATE'
	},
	{
		label: 'Педагогический ВУЗ',
		value: 'PEDAGOGICAL'
	},
	{
		label: 'Медицинский колледж',
		value: 'MEDICAL_COLLEGE'
	},
	{
		label: 'Дистрибьютор',
		value: 'PARTNER'
	},
	{
		label: 'Организация ДПО',
		value: 'EXT_PROF_EDUCATION_ORGANIZATION'
	},
	{
		label: 'Частный ВУЗ',
		value: 'PRIVATE'
	},
	{
		label: 'Клиника',
		value: 'CLINIC'
	},
	{
		label: 'Олимпиады',
		value: 'OLYMPIADS'
	},
	{
		label: 'Иное',
		value: 'OTHER'
	}
]

export const ORGANIZATION_ACCOUNT: Array<{
	label: string
	value: AccountType
}> = [
	// {
	// 	label: 'B2C',
	// 	value: 'B2C'
	// },
	{
		label: 'B2B',
		value: 'B2B'
	},
	{
		label: 'B2G',
		value: 'B2G'
	}
]
