import React, { useState } from 'react'
import Button from '../../../components/UI/Button/Button'
import { useForm, FormProvider } from 'react-hook-form'
import { modalConfirmShowHandler } from '../../../store/actionCreators/UI/modalConfirm'
import { connect, ConnectedProps } from 'react-redux'
import InputFile from '../../../components/UI/InputFile/InputFile'
import Progress from '../../../components/UI/Progress/Progress'
import { RootState } from '../../../store/reducers/rootReducer'
import { useTranslation } from 'react-i18next'
import { INPUT_FILE_ACCEPTED_FILES } from '../../../helpers/helpers'

type TProps = ConnectedProps<typeof connector> & {
	hideModal: () => void
	onConfirm: (file: File[]) => Promise<any>
	accept?: string
}

interface IFormInputs {
	file: File[]
}

const ModalUpload = ({ hideModal, showModalConfirm, onConfirm, progress, accept }: TProps) => {
	const { t } = useTranslation()
	const methods = useForm<IFormInputs>({
		defaultValues: {
			file: []
		}
	})
	const { isDirty } = methods.formState
	const [isSubmitting, setIsSubmitting] = useState(false)

	const cancelHandler = () => {
		if (isDirty) {
			showModalConfirm({
				type: 'cancel',
				onConfirm: hideModal
			})
		} else {
			hideModal()
		}
	}

	const onSubmit = async (data: IFormInputs) => {
		setIsSubmitting(true)

		try {
			await onConfirm(data.file)
			hideModal()
		} finally {
			setIsSubmitting(false)
		}
	}

	return (
		<>
			{progress === 0 && <button className="modal-close" onClick={cancelHandler} />}

			<h3 className="modal-title">{t('common:appName')}</h3>

			<p className="modal-footnote">{t('pages:hardwareKeys:modalUploadText')}</p>

			{progress === 0 ? (
				<FormProvider {...methods}>
					<form className="form" onSubmit={methods.handleSubmit(onSubmit)}>
						<InputFile
							name={'file'}
							errors={methods.errors.file}
							isError={!!methods.errors.file}
							isDisabled={isSubmitting}
							placeholder={t('pages:hardwareKeys:fieldFile')}
							accept={accept || INPUT_FILE_ACCEPTED_FILES}
						/>

						<div className="form-controls form-controls--right">
							<Button type={'secondary'} htmlType={'button'} onClick={cancelHandler} isDisabled={isSubmitting}>
								{t('common:actionCancel')}
							</Button>

							<Button type={'primary'} htmlType={'submit'} isDisabled={isSubmitting || !methods.watch('file').length}>
								{t('common:actionUpload')}
							</Button>
						</div>
					</form>
				</FormProvider>
			) : (
				<Progress value={progress} />
			)}
		</>
	)
}

const mapState = (state: RootState) => ({
	progress: state.app.progress
})

const mapDispatch = {
	showModalConfirm: modalConfirmShowHandler
}

const connector = connect(mapState, mapDispatch)

export default connector(ModalUpload)
